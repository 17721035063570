<template>
  <div
    :style="{
      background: '#FAFAFA',
      'margin-right': 'auto',
      'margin-left': 'auto',
      height: 'calc(100vh - 180px)',
    }"
  >
    <v-container>
      <PageTitle :title-name="$t('lessonRequest.title')" />
      <ChangeLocale />
      <!-- レッスンリクエスト一覧 -->
      <div v-if="lessonRequests" class="lesson-request-area">
        <v-data-table
          :headers="headers"
          :items="lessonRequestItems"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :class="{ 'elevation-1': true }"
          hide-default-footer
          disable-sort
          :loading="isLoading"
          :no-data-text="$t('lessonRequest.noLessonRequest')"
          @click:row="clickRow"
        >
          <template v-slot:[`item.status`]="{ item }">
            <span
              class="statusIcon rounded-pill"
              :style="{
                'background-color':
                  item.status === $t('lessonRequest.completed')
                    ? '#CFD2D1'
                    : '#DCC268',
              }"
              >{{ item.status }}</span
            >
          </template>
        </v-data-table>
        <v-dialog
          v-model="showLessonRequestDetail"
          v-if="showLessonRequestDetail"
          max-width="500px"
        >
          <LessonRequestDetailCard
            :requestInfo="selectedItem"
            @complete="completeRequest"
            @close="closeDetailCard"
          />
        </v-dialog>
        <v-pagination
          v-model="page"
          :length="pageCount"
          class="pagenation"
          circle
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import PageTitle from "../Atoms/PageTitle";
import LessonRequestDetailCard from "../Molecules/LessonRequestDetailCard";
import { mapGetters, mapActions } from "vuex";
import ChangeLocale from "../Molecules/ChangeLocale";

export default {
  name: "LessonRequestList",
  inject: ["reloadHeader"],
  components: {
    PageTitle,
    LessonRequestDetailCard,
    ChangeLocale,
  },
  data: () => ({
    isShowTable: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    showLessonRequestDetail: false,
    selectedItem: null,
  }),
  async created() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      lessonRequests: "lessonRequest/lessonRequests",
      lessonRequestLoading: "lessonRequest/loading",
      userInfo: "user/getUserInfo",
    }),
    lessonRequestItems() {
      let lessonRequestItems = [];
      for (let lessonRequest of this.lessonRequests) {
        const request = {
          id: lessonRequest.id,
          status: lessonRequest.status
            ? this.$t("lessonRequest.completed")
            : this.$t("lessonRequest.backlog"),
          studentId: lessonRequest.Student.id,
          studentName: lessonRequest.Student.name,
          timeZone:
            lessonRequest.timeZone === "Asia/Tokyo" ? "日本時間" : "ドイツ時間",
          preferredDate: lessonRequest.preferredDate,
          message: lessonRequest.message,
          createdAt: lessonRequest.createdAt,
        };
        lessonRequestItems.push(request);
      }
      return lessonRequestItems;
    },
    isLoading() {
      return this.lessonRequestLoading;
    },
    headers() {
      return [
        { text: this.$t("lessonRequest.status"), value: "status" },
        { text: this.$t("lessonRequest.studentName"), value: "studentName" },
        { text: this.$t("lessonRequest.timeZone"), value: "timeZone" },
        {
          text: this.$t("lessonRequest.preferredDate"),
          value: "preferredDate",
        },
        { text: this.$t("lessonRequest.message"), value: "message" },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchLessonRequest: "lessonRequest/fetchLessonRequest",
      completeLessonRequest: "lessonRequest/completeLessonRequest",
    }),
    loadData() {
      this.fetchLessonRequest({
        teacherId: this.userInfo.teacherId,
        accessToken: this.userInfo.accessToken,
      });
    },
    async reloadTable() {
      this.isShowTable = false;
      await this.$nextTick();
      this.loadData();
      this.isShowTable = true;
    },
    clickRow(row) {
      this.showLessonRequestDetail = true;
      this.selectedItem = row;
    },
    async completeRequest() {
      if (confirm(this.$t("lessonRequest.dialog.confirmation"))) {
        const isSuccess = await this.completeLessonRequest({
          requestId: this.selectedItem.id,
          accessToken: this.userInfo.accessToken,
        });
        if (isSuccess) {
          this.loadData();
          this.reloadHeader();
          this.closeDetailCard();
        }
      }
    },
    closeDetailCard() {
      this.showLessonRequestDetail = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-request-area {
  margin-top: 50px;
}
.statusIcon {
  color: white;
  padding: 6px 24px;
  font-weight: bold;
}
</style>
