<!-- 定期予約受付可能日時の登録（フォーム） -->
<template>
  <VmDialogCard
    :title="cardTitle"
    :buttonTitle="$t('teacherSchedule.registerScheduleButton')"
    :cancelButtonTitle="$t('teacherSchedule.cancel')"
    @click="registerRegularSchedule"
    @close="cancel"
    :disabled="!buttonValidation"
  >
    <template #cardContent>
      <v-container>
        <!-- 曜日選択 -->
        <v-row>
          <!-- 曜日 -->
          <p class="subtitle">{{ $t("teacherSchedule.dayOfWeek") }}</p>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-select
              :items="dayOfWeekItems"
              v-model="selectedDayOfWeek"
              :label="$t('teacherSchedule.requireSelection')"
              solo
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <!-- 時間 -->
          <p class="subtitle">{{ $t("teacherSchedule.time") }}</p>
          <TimeZoneIcon />
        </v-row>
        <v-row no-gutters class="date-form-area">
          <!-- 開始時間タイムピッカー -->
          <v-col cols="9" xs="9" sm="5" md="5" lg="5" class="picker">
            <v-row no-gutters justify="start">
              <TimePicker v-model="lessonStartTime" :minuteInterval="5" />
            </v-row>
          </v-col>
          <!-- 終了時間タイムピッカー -->
          <v-col cols="1" xs="1" sm="2" md="2" lg="2" class="hyphen-text">
            -
          </v-col>
          <v-col cols="9" xs="9" sm="5" md="5" lg="5" class="picker">
            <v-row no-gutters justify="start">
              <TimePicker
                v-model="lessonEndTime"
                :minuteInterval="5"
                format="kk:mm"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <!-- 公開日 -->
          <p class="subtitle">{{ $t("teacherSchedule.openDay") }}</p>
        </v-row>
        <v-row class="attention-text-area">
          <v-col cols="12">
            <!-- 公開日になればこの定期予定が公開されます。基本的には現在表示されている今日の日付で問題ありません。 -->
            <p class="attention-text">
              {{ $t("teacherSchedule.openDayAttention") }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8" xs="5" sm="5" md="5" lg="5" class="picker">
            <DatePicker
              v-model="receptionStartDate"
              :showedDate="computedScheduleStartDate"
              :minDate="selectableStartMinDate"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </VmDialogCard>
</template>

<script>
import TimeZoneIcon from "../Atoms/TimeZoneIcon";
import DatePicker from "../Atoms/DatePicker";
import TimePicker from "../Atoms/TimePicker";
import VmDialogCard from "../Molecules/VmDialogCard";

export default {
  name: "TeacherSchedulesRegularCard",
  inject: ["reloadRouter"],
  components: {
    TimeZoneIcon,
    TimePicker,
    DatePicker,
    VmDialogCard,
  },
  data: () => ({
    startTimeMenu: false,
    endTimeMenu: false,
    selectedDayOfWeek: "",
    lessonStartTime: "00:00",
    lessonEndTime: "24:00",
    receptionStartDate: null,
  }),
  props: ["courseName", "cardTitle", "userInfo"],
  computed: {
    dayOfWeekItems() {
      const weekDayArrayJa = ["日", "月", "火", "水", "木", "金", "土"];
      const weekDayArrayDe = ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."];
      switch (this.$i18n.locale) {
        case "ja":
          return weekDayArrayJa;
        case "de":
          return weekDayArrayDe;
        default:
          return weekDayArrayJa;
      }
    },
    // 曜日を数字で扱う
    computedIntDayOfWeek() {
      const weekDayArrayJa = ["日", "月", "火", "水", "木", "金", "土"];
      const weekDayArrayDe = ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."];
      switch (this.$i18n.locale) {
        case "ja":
          return weekDayArrayJa.indexOf(this.selectedDayOfWeek);
        case "de":
          return weekDayArrayDe.indexOf(this.selectedDayOfWeek);
        default:
          return weekDayArrayJa.indexOf(this.selectedDayOfWeek);
      }
    },
    computedStartTime() {
      return "2021-01-01 " + this.lessonStartTime;
    },
    computedEndTime() {
      if (this.lessonEndTime === "00:00") {
        return "2021-01-01 " + "24:00";
      }
      return "2021-01-01 " + this.lessonEndTime;
    },
    buttonValidation() {
      if (this.lessonEndTime === "24:30") {
        return false;
      }

      if (!this.selectedDayOfWeek) {
        return false;
      }

      if (!this.lessonTimeValidation) {
        return false;
      }
      return true;
    },
    // バリデーション - 終了時刻 > 開始時刻になっていればok
    lessonTimeValidation() {
      const start = this.$moment("2021-01-01 " + this.lessonStartTime);
      const end = this.$moment("2021-01-01 " + this.lessonEndTime);

      // 終了時刻 = 開始時刻になっているためアウト
      if (this.lessonStartTime === this.lessonEndTime) {
        return false;
      }

      // 00:00は24:00とみなす
      if (this.lessonEndTime === "00:00") {
        return true;
      }

      if (end.isBefore(start)) {
        return false;
      }
      return true;
    },
    selectableStartMinDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    computedScheduleStartDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.receptionStartDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.receptionStartDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
  },
  mounted() {
    this.initScheduleDate();
  },
  methods: {
    initScheduleDate() {
      this.receptionStartDate = this.$moment().format("YYYY-MM-DD");
    },
    registerRegularSchedule() {
      if (
        confirm(
          `${this.$t(
            "teacherSchedule.registeRegularAvailableConfirmation"
          )} \n ${this.$t("teacherSchedule.dayOfWeek")}: ${
            this.selectedDayOfWeek
          } \n ${this.$t("teacherSchedule.startTime")}： ${
            this.lessonStartTime
          } \n ${this.$t("teacherSchedule.endTime")}： ${
            this.lessonEndTime === "00:00" ? "24:00" : this.lessonEndTime
          } \n ${this.$t("teacherSchedule.openDay")}: ${
            this.receptionStartDate
          } `
        )
      ) {
        const startTime = this.$convertToDateFormat(
          this.computedIntDayOfWeek,
          this.lessonStartTime
        );
        const endTime = this.$convertToDateFormat(
          this.computedIntDayOfWeek,
          this.lessonEndTime
        );

        const regularScheduleData = {
          teacherId: this.userInfo.teacherId,
          studentId: null,
          lessonCourseId: 2, // 今は必ずプライベートコースなので
          startTime: startTime,
          endTime: endTime,
          receptionStartDate: this.$convertToPost(this.receptionStartDate),
        };

        this.registerRequest(regularScheduleData);
      }
    },
    registerRequest(regularScheduleData) {
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      this.axios
        .post("/api/regularSchedules", regularScheduleData, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.cancel();
          this.reloadRouter();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    cancel() {
      this.$emit("cancelAction");
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 1.4em;
  font-weight: bold;
  margin: 20px 0 15px 0;
}
.button {
  margin-bottom: 20px;
}
.hyphen-text {
  font-size: 1.2em;
  margin: 30px 0 0 0;
}
.picker {
  margin: 10px 0 0 0;
}
.attention-text-area {
  margin-top: 0;
}
.attention-text {
  margin: 0;
  color: red;
}
.date-form-area {
  margin: 30px 0 0 0;
}
</style>
