<template>
  <div class="lesson-data-card">
    <p class="white--text lesson-data-title" :style="lessonDataTitleStyle">
      <!-- レッスン時間 or レッスン回数 -->
      {{ type === "HOURS" ? $t("top.amountOfLessonTime") :$t("top.timesOfLessons") }}
    </p>
    <p class="white--text lesson-data-txt" :style="lessonDataTxtStyle">
      <span class="lesson-data-num" :style="lessonDataNumStyle">{{
        type === "HOURS" ? lessonData.totalHours : lessonData.totalNumOfTimes
      }}</span>
      <span class="unit-txt">{{ type === "HOURS" ? $t("top.hour") : $t("top.times") }}</span>
    </p>
    <v-img
      :src="icon"
      :max-width="imageSize"
      contain
      :style="lessonDataIconStyle"
    ></v-img>
  </div>
</template>

<script>

export default {
  name: 'LessonData',
  created() {
  },
  data: () => ({

  }),
  props: ['type','lessonData'],
  computed: {
    lessonDataTitleStyle(){
      return {
        "font-size": this.isPC ? "0.8em" : "0.6em",
        "margin": this.isPC ? "0 0 0 30px" : "0 0 0 10px",
        "top": this.isPC ? "5px" : "0",
      }
    },
    lessonDataNumStyle() {
      return {
        "font-size": this.isPC ? "2em" : "1.5em",
      }
    },
    lessonDataTxtStyle() {
      return {
        "right": this.isPC ? "0" : "15px"
      }
    },
    icon() {
      return this.type === "HOURS" ? require('../../assets/lesson_time_icon.png') : require('../../assets/lesson_times_icon.png')
    },
    imageSize() {
      return this.type === "HOURS" ? "120px" : "80px"
    },
    lessonDataIconStyle() {
      return {
        "position": "absolute",
        "right": this.type === "HOURS" ? "-40px" : "2px",
        "bottom": this.type === "HOURS" ? "-40px" : "2px",
      }
    }
  },
  methods: {

  },
  components: {
  }
};
</script>

<style lang="scss" scoped>
.lesson-data-card {
  background-color: #de755e;
  aspect-ratio: 8 / 3;
  border-radius: 5px;
  position: relative;
}
.lesson-data-title {
  font-weight: bold;
  padding: 20px 0 0 0;
  position: relative;
}
.lesson-data-num {
  margin: 0 20px 0 30px;
}
.lesson-data-txt {
  font-weight: bold;
  margin: 0;
  position: relative;
  right: 10px;
}
.unit-txt {
  font-size: 1em;
  position: relative;
  right: 15px;
}
</style>
