<template>
  <v-text-field
    v-model="inputtedSearch"
    outlined
    label="キーワード"
    background-color="white"
    dense
    @keydown.enter="onClick"
  >
    <template v-slot:append>
      <v-icon @click="onClick"> mdi-magnify </v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "",
  props: ["value"],
  components: {},
  data: () => ({}),
  created() {},
  mounted() {},
  computed: {
    inputtedSearch: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    onClick() {
      this.$emit("search", this.inputtedSearch);
    },
  },
};
</script>

<style lang="scss" scoped></style>
