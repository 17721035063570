<template>
  <validationProvider
    rules="max:400"
    v-slot="{ errors, valid }"
    immediate
    name="対応可能な教科書"
  >
    <p :style="{'margin': '0'}">
      <FormTitleText :titleText="$t('profile.availableTextbook')" />
      <FormAttentionText :attentionText="$t('profile.max400')" />
    </p>
    <p>
      <FormAttentionText
        :attentionText="$t('profile.availableTextbookAttention')"
      />
    </p>
    <v-textarea
      v-model="inputtedAvailableTextbook"
      :placeholder="$t('profile.availableTextbookLabel')"
      outlined
      background-color="white"
      counter
      :success="valid"
    ></v-textarea>
    <FormErrorText :errorText="errors[0]" />
  </validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText';
import FormErrorText from '../Atoms/FormErrorText';
import FormAttentionText from '../Atoms/FormAttentionText';

export default {
  name: 'AvailableTextbookForm',
  data: () => ({
  }),
  props: ['value'],
  mounted() {
  },
  computed: {
    inputtedAvailableTextbook: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
  },
  components: {
    FormTitleText,
    FormErrorText,
    FormAttentionText
  }
};
</script>

<style lang="scss" scoped>
</style>
