var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VmDialogCard',{attrs:{"title":"レッスンリクエスト","description":_vm.dialogDescription,"buttonTitle":"レッスンリクエストを行う","cancelButtonTitle":"閉じる","disabled":!_vm.buttonValidation},on:{"click":_vm.request,"close":_vm.cancel},scopedSlots:_vm._u([{key:"cardContent",fn:function(){return [_c('p',{style:({
        'font-size': '1.4em',
        'font-weight': 'bold',
        margin: '20px 0 5px 0',
      })},[_vm._v(" 希望日時 ")]),_c('p',{style:({
        'font-size': '0.9em',
        color: '#6D6F6C',
      })},[_vm._v(" 受講を希望する日時を記入してください。候補が多いほど、スケジュール調整がスムーズです。なお、ご希望に添えない場合もございますので予めご了承ください。 ")]),_c('v-select',{style:({
        margin: '5px 0 5px 0',
      }),attrs:{"items":_vm.timeZoneItems,"label":"タイムゾーン","hide-details":"","outlined":"","background-color":"white","dense":""},model:{value:(_vm.selectedTimeZone),callback:function ($$v) {_vm.selectedTimeZone=$$v},expression:"selectedTimeZone"}}),_c('v-textarea',{style:({
        'white-space': 'pre-line',
      }),attrs:{"placeholder":_vm.preferredDateLabel,"outlined":"","background-color":"white","counter":""},model:{value:(_vm.preferredDate),callback:function ($$v) {_vm.preferredDate=$$v},expression:"preferredDate"}}),_c('p',{style:({
        'font-size': '1.4em',
        'font-weight': 'bold',
        margin: '20px 0 5px 0',
      })},[_vm._v(" 講師への質問やメッセージ ")]),_c('p',{style:({
        'font-size': '0.9em',
        color: '#6D6F6C',
      })},[_vm._v(" 質問やメッセージを簡単にご記入ください。ネイティブ講師の場合はドイツ語での記入を推奨します。 ")]),_c('v-textarea',{attrs:{"placeholder":_vm.messageLabel,"outlined":"","background-color":"white","counter":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('SharedStudentInfo',{style:({ 'margin-left': '10px', 'margin-top': '-20px' })})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }