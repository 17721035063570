<template>
  <v-btn
    color="dangerous white--text"
    @click="clickAction"
    :style="{ fontWeight: textFontWeight }"
    block
    tile
    :disabled="isDisabled"
  >{{buttonTitle}}</v-btn>
</template>

<script>
export default {
  name: 'ActiveDangerousButton',
  data: () => ({
    textFontWeight:"bold"
  }),
  props: ['buttonTitle', 'isDisabled'],
  methods: {
      clickAction(){
        this.$emit('clickAction');
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
