var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-container',[_c('div',{class:{ 'clickable-area': !_vm.disabled },on:{"click":_vm.click}},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('img',{style:({
              'object-fit': 'cover',
              width: '100%',
              'aspect-ratio': '1 / 1',
              'border-radius': '10px',
              'margin-top': '10px',
            }),attrs:{"src":_vm.computedImageUrl,"alt":"teacherImage"}})]),_c('v-col',{style:({
            'padding-left': ' 15px',
          }),attrs:{"cols":"7"}},[_c('v-row',{style:({
              'font-size': '1em',
              'font-weight': 'bold',
              'padding-top': ' 0.5em',
            }),attrs:{"no-gutters":""}},[_c('p',[_vm._v(_vm._s(_vm.teacherInfo.teacherName)+"先生")]),_vm._v(" "+_vm._s(_vm.countryEmoji)+" ")]),_c('v-spacer'),(_vm.showPrivExpertIcon)?_c('v-row',{style:({}),attrs:{"no-gutters":""}},[_c('div',{staticClass:"tag rounded-pill",style:({
                'margin-top': '4px',
              })},[_c('p',[_vm._v("プライベートベテラン")])])]):_vm._e(),(_vm.showConvExpertIcon)?_c('v-row',{style:({}),attrs:{"no-gutters":""}},[_c('div',{staticClass:"tag rounded-pill",style:({
                'margin-top': '4px',
              })},[_c('p',[_vm._v("会話ベテラン")])])]):_vm._e(),(_vm.teacherInfo.category)?_c('v-row',{style:({}),attrs:{"no-gutters":""}},[_c('div',{staticClass:"tag rounded-pill",style:({
                'margin-top': '8px',
              })},[_c('p',[_vm._v(_vm._s(_vm.categoryText))])])]):_vm._e(),(_vm.showEmpty)?_c('v-row',{staticClass:"empty-plan",style:({
              'font-size': '0.8em',
              'font-weight': 'bold',
              'padding-top': '2px',
            }),attrs:{"no-gutters":""}},[_c('p',[_vm._v("空き: "+_vm._s(_vm.emptyText))]),_c('div',{style:({
                'max-width': '16px',
                margin: '3px 0 0 5px',
              })},[_c('v-img',{style:({
                  'object-fit': 'contain',
                }),attrs:{"src":_vm.emptyIcon}})],1)]):_vm._e(),_c('div',{staticClass:"compatible-level"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('p',{staticClass:"content-text compatible-level__title"},[_vm._v("レベル:")]),_c('p',{staticClass:"content-text"},[_vm._v(_vm._s(_vm.compatibleLevelText))])])],1)],1)],1),(!_vm.disabled)?_c('v-row',{style:({
          'margin-top': '10px',
        }),attrs:{"no-gutters":""}},[_c('VmButton',{style:({}),attrs:{"type":"primary","text":"予約する"},on:{"click":_vm.click}})],1):_vm._e(),_c('v-row',{style:({
          'font-size': '1em',
          'font-weight': 'bold',
          'margin-top': '20px',
        }),attrs:{"no-gutters":""}},[_c('p',[_vm._v(_vm._s(_vm.teacherInfo.catchPhrase))])]),_c('v-row',{staticClass:"card-subtitle",style:({ 'margin-top': '30px' }),attrs:{"no-gutters":""}},[_c('p',[_vm._v("得意分野")])]),_c('v-row',{staticClass:"content-text",attrs:{"no-gutters":""}},[_c('p',{class:{ specialities__hidden: !_vm.showDetail }},[_vm._v(" "+_vm._s(_vm.teacherInfo.specialities)+" ")])])],1),(!_vm.showDetail)?_c('v-row',{staticClass:"more-button",attrs:{"no-gutters":"","dense":"","justify":"center"}},[_c('v-btn',{style:({
          'text-transform': 'none',
          'font-weight': 'bold',
        }),attrs:{"text":""},on:{"click":_vm.openDetail}},[_vm._v(" more "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1):_vm._e(),(_vm.showDetail)?_c('div',{style:({})},[_c('v-row',{staticClass:"card-subtitle",style:({ 'margin-top': '30px' }),attrs:{"no-gutters":""}},[_c('p',[_vm._v("経歴")])]),_c('v-row',{staticClass:"content-text",attrs:{"no-gutters":""}},[_c('p',[_vm._v(_vm._s(_vm.teacherInfo.background))])]),_c('v-row',{staticClass:"card-subtitle",style:({ 'margin-top': '30px' }),attrs:{"no-gutters":""}},[_c('p',[_vm._v("趣味/特技")])]),_c('v-row',{staticClass:"content-text",attrs:{"no-gutters":""}},[_c('p',[_vm._v(_vm._s(_vm.teacherInfo.hobbies))])]),_c('v-row',{staticClass:"card-subtitle",style:({ 'margin-top': '30px' }),attrs:{"no-gutters":""}},[_c('p',[_vm._v("コメント")])]),_c('v-row',{staticClass:"content-text",attrs:{"no-gutters":""}},[_c('p',[_vm._v(_vm._s(_vm.teacherInfo.selfIntroduction))])]),(_vm.teacherInfo.availableTextbook && _vm.showAt !== 'CONVERSATION')?_c('div',[_c('v-row',{staticClass:"card-subtitle",style:({ 'margin-top': '30px' }),attrs:{"no-gutters":""}},[_c('p',[_vm._v("対応可能な教科書")])]),_c('v-row',{staticClass:"content-text",attrs:{"no-gutters":""}},[_c('p',[_vm._v(_vm._s(_vm.teacherInfo.availableTextbook))])])],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":"","dense":"","justify":"center"}},[_c('v-btn',{style:({
            'text-transform': 'none',
            'font-weight': 'bold',
            'margin-top': '20px',
            'margin-bottom': '20px',
          }),attrs:{"text":""},on:{"click":_vm.closeDetail}},[_vm._v(" close "),_c('v-icon',[_vm._v("mdi-chevron-up")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }