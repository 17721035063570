<template>
  <VmDialogCard
    title="レッスンリクエスト"
    :description="dialogDescription"
    buttonTitle="レッスンリクエストを行う"
    cancelButtonTitle="閉じる"
    @click="request"
    @close="cancel"
    :disabled="!buttonValidation"
  >
    <template #cardContent>
      <p
        :style="{
          'font-size': '1.4em',
          'font-weight': 'bold',
          margin: '20px 0 5px 0',
        }"
      >
        希望日時
      </p>
      <p
        :style="{
          'font-size': '0.9em',
          color: '#6D6F6C',
        }"
      >
        受講を希望する日時を記入してください。候補が多いほど、スケジュール調整がスムーズです。なお、ご希望に添えない場合もございますので予めご了承ください。
      </p>
      <v-select
        :items="timeZoneItems"
        v-model="selectedTimeZone"
        label="タイムゾーン"
        hide-details
        outlined
        background-color="white"
        dense
        :style="{
          margin: '5px 0 5px 0',
        }"
      ></v-select>
      <v-textarea
        v-model="preferredDate"
        :style="{
          'white-space': 'pre-line',
        }"
        :placeholder="preferredDateLabel"
        outlined
        background-color="white"
        counter
      ></v-textarea>
      <p
        :style="{
          'font-size': '1.4em',
          'font-weight': 'bold',
          margin: '20px 0 5px 0',
        }"
      >
        講師への質問やメッセージ
      </p>
      <p
        :style="{
          'font-size': '0.9em',
          color: '#6D6F6C',
        }"
      >
        質問やメッセージを簡単にご記入ください。ネイティブ講師の場合はドイツ語での記入を推奨します。
      </p>
      <v-textarea
        v-model="message"
        :placeholder="messageLabel"
        outlined
        background-color="white"
        counter
      ></v-textarea>
      <SharedStudentInfo
        :style="{ 'margin-left': '10px', 'margin-top': '-20px' }"
      />
    </template>
  </VmDialogCard>
</template>

<script>
import VmDialogCard from "../Molecules/VmDialogCard";
import SharedStudentInfo from "../Molecules/SharedStudentInfo";

export default {
  name: "LessonRequestForm",
  props: ["teacherId"],
  created() {
    this.fetchInitialData();
  },
  mounted() {},
  components: {
    VmDialogCard,
    SharedStudentInfo,
  },
  data: () => ({
    dialogDescription:
      "空き予定が入っていない日時でも相談次第では対応できる場合があります。「希望日時」「講師への質問・メッセージ」を記入し、リクエストしてください。後ほど講師からメールにてご連絡します。",
    preferredDate: "",
    preferredDateLabel:
      "月金 20:00-22:00\n火曜 16:00-17:00\n直近だと3月10日16:00〜が希望です。",
    timeZoneItems: ["日本時間", "ドイツ時間"],
    selectedTimeZone: "",
    message: "",
    messageLabel:
      "○月に控えているスピーキング試験の対策をお願いしたいのですが、可能でしょうか？お返事お待ちしております。",
    userInfo: "",
  }),
  computed: {
    // okならtrue
    buttonValidation() {
      if (!this.preferredDate) {
        return false;
      }

      if (!this.selectedTimeZone) {
        return false;
      }

      if (!this.message) {
        return false;
      }
      return true;
    },
    timeZone() {
      switch (this.selectedTimeZone) {
        case "日本時間":
          return "Asia/Tokyo";
        case "ドイツ時間":
          return "Europe/Berlin";
        default:
          return "";
      }
    },
  },
  methods: {
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    request() {
      if (confirm("レッスンリクエストを送信しますか？")) {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        const requestData = {
          teacherId: this.teacherId,
          studentId: this.userInfo.studentId,
          timeZone: this.timeZone,
          preferredDate: this.preferredDate,
          message: this.message,
        };

        this.axios
          .post("/api/lessons/request", requestData, header)
          .then((response) => {
            console.log("response.data", response.data);
            alert("リクエストを送信しました");
            this.cancel();
          })
          .catch((error) => {
            alert(error.response.data.error.message);
          });
      }
    },
    cancel() {
      this.$emit("cancelAction");
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin-bottom: 20px;
}
</style>
