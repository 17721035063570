<template>
  <v-container>
    <v-row justify="center">
      <h1
        :style="{
          fontSize: fontSize,
          marginTop: '20px',
          marginBottom: marginBottom,
        }"
      >
        {{ titleName }}
      </h1>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'PageTitle',
  props: ['titleName'],
  data: () => ({
  }),
  components: {
  },
  computed: {
    fontSize() {
      if(this.isPC) {
        return "2em"
      } else {
        return "1.3em"
      }
    },
    marginBottom() {
      if(this.isPC) {
        return "20px"
      } else {
        return "10px"
      }
    }
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
h1 {
  color: #dcc268;
  /* margin: 0 0 30px 0; */
}
</style>

