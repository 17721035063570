export default {
  computed: {
    // PCかSPかを判定してくれる
    isPC() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false
        case "sm":
        case "md":
        case "lg":
        case "xl":
          return true
        default:
          return false
      }
    },
    isLogin() {
      const userInfo = this.$store.getters["user/getUserInfo"];
      if (userInfo.accessToken) {
        return true
      } else {
        return false
      }
    }
  }
}
