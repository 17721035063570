<template>
  <!-- TODO: stripeがokになるまであげない -->
  <!-- <VmDialogCard
    title="チケット申込"
    buttonTitle="チケットを購入する"
    @click="buyTicket"
    @close="close"
    :disabled="!buttonValidation"
  > -->
  <!-- TODO: stripeがokになるまでこちらをprodにあげる -->
  <VmDialogCard
    title="チケット申込"
    buttonTitle="チケット申込みをする"
    @click="apply"
    @close="close"
    :disabled="!buttonValidation"
  >
    <template #cardContent>
      <v-row>
        <v-col cols="10">
          <p class="label-text">チケット枚数（1枚＝20分レッスン1回）</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-select
            :items="ticketNumItems"
            v-model="selectedTicket"
            label="チケット枚数を選択してください"
            solo
            hide-details
          >
            <template v-slot:item="{ item }">
              <div v-if="item === 10">
                {{ item }}
                <span
                  :style="{
                    color: 'white',
                    'background-color': '#DCC268',
                    padding: '5px 10px',
                    'margin-left': '10px',
                    'border-radius': '3px',
                    'font-weight': 'bold',
                  }"
                >
                  おすすめ！
                </span>
              </div>
              <span v-else>{{ item }}</span>
            </template>
          </v-select>
          <v-text-field
            label="チケット枚数を入力（半角数字）"
            v-model="inputtedNum"
            solo
            hide-details
            v-if="selectedTicket === 'その他（手動入力）'"
            type="number"
            :style="{ 'margin-top': '10px' }"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <p
            class="unit-text"
            :style="
              selectedTicket === 'その他（手動入力）'
                ? { 'margin-top': '80px' }
                : { 'margin-top': '25px' }
            "
          >
            枚
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <p class="label-text">
            フィードバック
            <br />
            <span class="explanation-text">
              担当講師からのフィードバック（いいところ・よくなるところ）がレッスン後メールで届きます。チケット枚数に限らず料金は「1100円」です。また講師によってはフィードバックがドイツ語になる場合もございます。
            </span>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <VmAccordionMenu
          text="フィードバック例はこちら"
          @click="showFeedbackExample = !showFeedbackExample"
          :style="{ 'margin-left': '12px' }"
        />
      </v-row>
      <v-row v-if="showFeedbackExample">
        <div class="feedback-example">
          <span :style="{ 'font-weight': 'bold' }">いいところ</span> <br />
          {{ $t("top.goodPointPlaceHolder") }}
          <br />
          <br />
          <span :style="{ 'font-weight': 'bold' }">もっとよくなるところ</span>
          <br />
          {{ $t("top.getBetterPointPlaceHolder") }}
        </div>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-select
            :items="monthlyFeedbackItems"
            v-model="monthlyFeedback"
            label="選択してください"
            solo
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <!-- TODO: stripeがokになるまであげない -->
      <!-- <div>
        <v-row>
          <v-col cols="10">
            <p class="label-text">
              支払い方法
              <br />
              <span class="explanation-text">
                クレジットカードの場合は外部サイトにて決済後、チケットが追加されます。
                銀行振込、Paypalの場合は、vollmond事務局が入金を確認した後チケットを追加致します。
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <v-select
              :items="paymentItems"
              v-model="selectedPayment"
              label="選択してください"
              solo
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </div> -->
      <!-- TODO: stripeがokになるまであげないのはここまで -->
      <v-row>
        <v-col cols="10"> 合計金額: {{ computedTotalAmount }}円 </v-col>
      </v-row>
    </template>
  </VmDialogCard>
</template>

<script>
import VmDialogCard from "../Molecules/VmDialogCard";
import VmAccordionMenu from "../Atoms/VmAccordionMenu";

export default {
  name: "BuyTicketFormCard",
  inject: ["reloadHeader"],
  components: {
    VmDialogCard,
    VmAccordionMenu,
  },
  data: () => ({
    selectedTicket: null,
    inputtedNum: null,
    ticketNumItems: [5, 10, 15, "その他（手動入力）"],
    monthlyFeedback: null,
    monthlyFeedbackItems: ["あり", "なし"],
    paymentItems: ["クレジットカード", "振込/Paypal"],
    selectedPayment: "",
    showFeedbackExample: false,
  }),
  props: ["userInfo"],
  computed: {
    computedTotalAmount() {
      let amount = 0;
      amount = this.ticketNum * 1100;
      if (this.translateMonthlyFeedback(this.monthlyFeedback)) {
        amount = amount + 1100;
      }
      return amount;
    },
    buttonValidation() {
      if (!this.selectedTicket) {
        return false;
      }
      // TODO: stripeがokになるまであげない
      // if (!this.selectedPayment) {
      //   return false;
      // }

      if (this.selectedTicket === "その他（手動入力）") {
        if (!this.inputtedNum) {
          return false;
        }
      }
      if (!this.monthlyFeedback) {
        return false;
      }
      return true;
    },
    ticketNum() {
      if (this.selectedTicket != "その他（手動入力）") {
        return this.selectedTicket;
      } else {
        return this.inputtedNum;
      }
    },
  },
  mounted() {
    // 画面表示時にチケット枚数を取得して更新する
  },
  methods: {
    buyTicket() {
      // 選択した支払い方法によってボタンの挙動を変更する
      if (this.selectedPayment === "クレジットカード") {
        this.toPaymentLink();
      } else {
        this.apply();
      }
    },
    // Stripeの購入画面へ
    toPaymentLink() {
      this.$store.commit("loading/SET_LOADING", true);
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      this.axios
        .get(
          `/api/stripes/ticket/paymentlink?numOfTicket=${
            this.ticketNum
          }&isFeedBack=${this.translateMonthlyFeedback(
            this.monthlyFeedback
          )}&studentId=${this.userInfo.studentId}`,
          header
        )
        .then((response) => {
          window.open(response.data.paymentLink);
          this.$store.commit("loading/SET_LOADING", false);
          this.close(false);
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    apply() {
      if (
        confirm(
          `チケット購入の申請を行います。\n\n＜購入情報＞\nチケット枚数: ${this.ticketNum}\n月末フィードバック: ${this.monthlyFeedback}`
        )
      ) {
        this.$store.commit("loading/SET_LOADING", true);

        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        const ticketApplyData = {
          studentId: this.userInfo.studentId,
          numberOfTicket: this.ticketNum,
          monthlyFeedback: this.translateMonthlyFeedback(this.monthlyFeedback),
          amount: this.computedTotalAmount,
        };
        this.axios
          .post("/api/tickets/apply", ticketApplyData, header)
          .then((response) => {
            console.log("response.data", response.data);
            this.$store.commit("loading/SET_LOADING", false);
            // 「申込ありがとうございました」のメッセージを出しつつ、フォームを閉じる
            this.close(true);
          })
          .catch((error) => {
            alert(error.response.data.error.message);
          });
      }
    },
    close(isApplied) {
      this.$emit("cancelAction", isApplied);
    },
    translateMonthlyFeedback(value) {
      switch (value) {
        case "あり":
          return true;
        case "なし":
          return false;
        default:
          return false;
      }
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
.scroll {
  overflow-y: auto;
}
.label-text {
  font-size: 1.3em;
  font-weight: bold;
  margin: 0;
  margin: 20px 0 0 0;
}
.explanation-text {
  margin: 10px 0 0 0;
  font-weight: normal;
  font-size: 0.7em;
}
.button {
  margin-bottom: 20px;
}
.feedback-example {
  position: relative;
  margin: 10px 0;
  padding: 1em 1em;
  border: solid 2px #6881dc;
  border-radius: 8px;
}
</style>
