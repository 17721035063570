<template>
  <p :class="isPC ? 'subtitle--pc' : 'subtitle--sp'">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: "VmDialogSubTitle",
  components: {},
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  created() {},
  mounted() {},
  computed: {
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 5px;
}
.subtitle {
  &--pc {
    font-weight: bold;
    font-size: 1.2em;
  }
  &--sp {
    font-weight: bold;
    font-size: 1.2em;
  }
}
</style>
