<template>
  <v-container>
    <!-- タイトル -->
    <PageTitle title-name="メール文一覧" />
    <VmBox>
      <template #header>
      </template>
      <template #content>
        <v-row justify="center">
          <v-col
            cols="5"
            v-for="(mailText, index) of mailTexts"
            :key="index"
            class="mail-text-area"
          >
            <p class="mail-title">{{ mailText.description }}</p>
            <p>to: {{ mailText.sentTo }}</p>
            <p>件名:</p>
            <v-text-field
              v-model="editedTexts[index].subject"
              :style="{ fontWeight: 'bold' }"
              outlined
              background-color="white"
              hide-details
              readonly
              disabled
            ></v-text-field>
            <p>本文:</p>
            <v-textarea
              v-model="editedTexts[index].content"
              placeholder=""
              outlined
              background-color="white"
              counter
              readonly
              disabled
            ></v-textarea>
            <ActiveButton
              button-title="編集する"
              @clickAction="openEditForm(index)"
              class="button"
            />
          </v-col>
          <v-dialog
            v-model="showEditingDialog"
            v-if="showEditingDialog"
            offset-x
            max-width="1000"
          >
            <AdminEditMailTextFormCard
              @cancelAction="closeEditForm()"
              @reload="reload()"
              :mailText="editedMailText"
            />
          </v-dialog>
        </v-row>
      </template>
    </VmBox>
  </v-container>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton';
import AdminEditMailTextFormCard from '../Organisms/AdminEditMailTextFormCard'
import PageTitle from "../Atoms/PageTitle";
import VmBox from '../Atoms/VmBox'

export default {
  name: 'AdminMailText',
  data: () => ({
    mailTexts: [],
    editedTexts: [],
    showEditingDialog: false,
    editedMailText: {}
  }),
  computed: {
  },
  props:[],
  components: {
    ActiveButton,
    AdminEditMailTextFormCard,
    PageTitle,
    VmBox
  },
  async created() {
    try {
      await this.fetchMailTexts()
    } catch(error) {
      console.log(error)
    }
  },
  mounted() {
  },
  methods: {
    reload() {
      this.fetchMailTexts()
    },
    fetchMailTexts() {
      return new Promise((resolve, reject) => {
        this.axios
          .get(`/api/mailTexts`)
          .then(response => {
            // 安易に this.mailTexts = [] のリロードをすると上にスクロールしてしまうのでこの実装になっている
            if (this.mailTexts.length != 0) {
              response.data.mailTexts.forEach( (mailText, index) => {
                this.mailTexts[index].subject = mailText.subject
                this.mailTexts[index].content = mailText.content
              })
            } else {
              this.mailTexts = response.data.mailTexts
            }
            this.editedTexts = this.mailTexts
            resolve()
          })
          .catch(error => {
            console.log(error);
            reject()
          });
      })
    },
    openEditForm(index) {
      this.showEditingDialog = true
      this.editedMailText =  this.editedTexts[index]
    },
    closeEditForm() {
      this.showEditingDialog = false
    }
  },
};
</script>

<style lang="scss" scoped>
.mail-text-area {
  border: 0.5px dotted;
  border-radius: 10px;
  margin: 10px;
}
.mail-title {
  font-size: 1.2em;
  font-weight: bold;
}
p {
  margin: 20px 0 0 0;
}
</style>
