<template>
  <div>
    <!-- タイトル -->
    <PageTitle title-name="講師一覧" />
    <VmBox>
      <template #header>
        <v-col class="csv-upload-area">
          <CsvUploadArea type="TEACHER" @reload="reloadTable" />
        </v-col>
      </template>
      <template #content>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="teacherArray"
          :search="search"
          multi-sort
          class="elevation-1 overflow-y-auto"
          disable-pagination
          no-data-text="講師がいません"
          hide-default-footer
          :loading="isLoading"
          fixed-header
          :height="teacherArray.length > 15 ? 48 * 15 - 1 : undefined"
        >
          <template v-slot:top>
            <v-dialog v-model="addDialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                  新規講師
                </v-btn>
              </template>
              <AdminAddFormCard
                @closeAction="closeAdd"
                @addAction="add"
                :editedItem="editedItem"
                formTitle="講師追加"
                @changeFile="changeFile($event)"
              />
            </v-dialog>
            <v-dialog v-model="dialog" max-width="500px">
              <AdminEditingFormCard
                @closeAction="close"
                @saveAction="save"
                :editedItem.sync="editedItem"
                formTitle="講師情報編集"
                @changeFile="changeFile($event)"
              />
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <AdminDeleteFormCard
                @closeDeleteAction="closeDelete"
                @deleteItemConfirmAction="deleteItemConfirm"
                formTitle="この講師情報を削除しますか？"
              />
            </v-dialog>
            <v-text-field
              v-model="search"
              label="Search"
              class="filter-item"
            ></v-text-field>
          </template>
          <!-- 文字数制限をかけるため以下のような実装をする -->
          <template
            v-for="(headerItem, key, index) in headers"
            v-slot:[`item.${headerItem.value}`]="{ item }"
          >
            <p :style="{ margin: '0' }" :key="index">
              {{ $limitedText30(item[headerItem.value]) }}
            </p>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <img :src="item.image" class="table-img" />
          </template>
        </v-data-table>
      </template>
    </VmBox>
  </div>
</template>

<script>
import AdminEditingFormCard from "../Organisms/AdminEditingFormCard";
import AdminDeleteFormCard from "../Organisms/AdminDeleteFormCard";
import AdminAddFormCard from "../Organisms/AdminAddFormCard";
import CsvUploadArea from "../Molecules/CsvUploadArea.vue";
import PageTitle from "../Atoms/PageTitle";
import VmBox from '../Atoms/VmBox'

export default {
  name: "AdminTeacherList",
  components: {
    AdminEditingFormCard,
    AdminDeleteFormCard,
    AdminAddFormCard,
    CsvUploadArea,
    PageTitle,
    VmBox
  },
  data: () => ({
    teachers: null,
    selected: [],
    teacherArray: [],
    isShowOnlyDone: false,
    isShowFromToday: false,
    search: "",
    dialog: false,
    addDialog: false,
    dialogDelete: false,
    selectedTeacherId: 0,
    defaultItem: {
      name: "",
      teacherName: "",
      email: "",
      lessonCourseIds: "",
      password: "",
      birthDay: null,
      sex: 0,
      country: "",
      skypeId: "",
      selfIntroduction: "",
      specialities: "",
      hobbies: "",
      catchPhrase: "",
      background: "",
      lessonFee: "",
      schedule: "",
      category: "",
      teachingLevel: "",
      isMainBusiness: 0,
      compatibleLevel: "",
      feeForFirstLesson: 0,
      feeForStudent: 0,
      feeForRegular: 0,
      isVisibleForPriv: "",
      isVisibleForConv: "",
      feeMessage: "",
      classInterval: 0,
      isAdmin: 0,
    },
    editedItem: {
      name: "",
      teacherName: "",
      email: "",
      lessonCourseIds: "",
      password: "",
      birthDay: null,
      sex: 0,
      country: "",
      skypeId: "",
      selfIntroduction: "",
      specialities: "",
      hobbies: "",
      catchPhrase: "",
      background: "",
      lessonFee: "",
      schedule: "",
      category: "",
      teachingLevel: "",
      isMainBusiness: 0,
      compatibleLevel: "",
      feeForFirstLesson: 0,
      feeForStudent: 0,
      feeForRegular: 0,
      isVisibleForPriv: "",
      isVisibleForConv: "",
      feeMessage: "",
      classInterval: 0,
      isAdmin: 0,
    },
    isLoading: false,
  }),
  computed: {
    headers() {
      return [
        { text: "Actions", value: "edit", align: "start", sortable: false },
        {
          text: "teacherId",
          value: "teacherId",
        },
        { text: "名前", value: "name" },
        { text: "講師名", value: "teacherName" },
        { text: "email", value: "email" },
        { text: "担当コース", value: "lessonCourseIds" },
        { text: "生年月日", value: "birthDay" },
        { text: "性別", value: "sex" },
        { text: "国", value: "country" },
        { text: "スカイプID", value: "skypeId" },
        { text: "自己紹介", value: "selfIntroduction" },
        { text: "得意分野", value: "specialities" },
        { text: "趣味・特技", value: "hobbies" },
        { text: "キャッチフレーズ", value: "catchPhrase" },
        { text: "経歴", value: "background" },
        { text: "レッスン料金", value: "lessonFee" },
        { text: "スケジュール", value: "schedule" },
        { text: "カテゴリー", value: "category" },
        { text: "（旧）レベル", value: "teachingLevel" },
        { text: "プライベートレベル", value: "privLevel" },
        { text: "会話レベル", value: "convLevel" },
        { text: "本業", value: "isMainBusiness" },
        { text: "対応可能レベル", value: "compatibleLevel" },
        { text: "初回レッスン料金", value: "feeForFirstLesson" },
        { text: "学生レッスン料金", value: "feeForStudent" },
        { text: "通常レッスン料金", value: "feeForRegular" },
        { text: "プライベートリスト表示", value: "isVisibleForPriv" },
        { text: "会話リスト表示", value: "isVisibleForConv" },
        { text: "料金メッセージ", value: "feeMessage" },
        { text: "授業間の休み", value: "classInterval" },
        { text: "プライベートリマインド", value: "isRemindedPriv" },
        { text: "会話リマインド", value: "isRemindedConv" },
        { text: "管理者", value: "isAdmin" },
        { text: "画像", value: "image" },
        { text: "Actions", value: "delete", sortable: false },
      ];
    },
  },
  props: [],
  created() {
    this.fetchInitialData();
    this.reloadTable();
  },
  mounted() {},
  methods: {
    reloadTable() {
      this.fetchTeachers()
        .then(() => {
          this.makeTeacherArray();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      const user_info = this.$store.getters["user/getUserInfo"];
      this.accessToken = user_info.accessToken;
    },
    fetchTeachers() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        };
        this.axios
          .get(`/api/teachers`, header)
          .then((response) => {
            this.teachers = response.data.teachers;
            this.isLoading = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    makeTeacherArray() {
      this.teacherArray = [];
      this.teachers.forEach((teacher, index) => {
        // Teacher - TeacherLessonCoursesで結合しているので、複数コースを担当している人がいたら
        // 複数データが連続で入ることになる（2コース担当していたら2連続）
        // 連続で同じ人のデータが入っていたら"lessonCourseIds"の値を"1,2"みたいな感じでコンマ区切りで表示できるようにする
        if (index != 0 && teacher.email === this.teachers[index - 1].email) {
          const length = this.teacherArray.length;
          this.teacherArray[length - 1].lessonCourseIds +=
            "," + teacher["TeacherLessonCourses.lessonCourseId"];
        } else {
          const teacherData = {
            teacherId: teacher.id,
            name: teacher.name,
            teacherName: teacher.teacherName,
            email: teacher.email,
            lessonCourseIds: teacher["TeacherLessonCourses.lessonCourseId"],
            birthDay: teacher.birthDay
              ? this.$moment(teacher.birthDay).format("YYYY-MM-DD")
              : "",
            sex: teacher.sex,
            country: teacher.country,
            skypeId: teacher.skypeId,
            selfIntroduction: teacher.selfIntroduction,
            specialities: teacher.specialities,
            hobbies: teacher.hobbies,
            catchPhrase: teacher.catchPhrase,
            background: teacher.background,
            lessonFee: teacher.lessonFee,
            schedule: teacher.schedule,
            category: teacher.category,
            teachingLevel: teacher.teachingLevel,
            privLevel: teacher.privLevel,
            convLevel: teacher.convLevel,
            isMainBusiness: teacher.isMainBusiness,
            compatibleLevel: teacher.compatibleLevel,
            feeForFirstLesson: teacher.feeForFirstLesson,
            feeForStudent: teacher.feeForStudent,
            feeForRegular: teacher.feeForRegular,
            isVisibleForPriv: teacher.isVisibleForPriv,
            isVisibleForConv: teacher.isVisibleForConv,
            feeMessage: teacher.feeMessage,
            classInterval: teacher.classInterval,
            isRemindedPriv: teacher.isRemindedPriv,
            isRemindedConv: teacher.isRemindedConv,
            isAdmin: teacher.isAdmin,
            image: teacher.imageUrl
              ? teacher.imageUrl
              : require("../../assets/no_teacher.png"),
          };
          this.teacherArray.push(teacherData);
        }
      });
    },
    editItem(item) {
      this.selectedTeacherId = item.teacherId;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.selectedTeacherId = item.teacherId;
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    addTeacher(item) {
      this.editedItem = Object.assign({}, item);
      this.addDialog = true;
    },
    deleteItemConfirm() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      };

      this.axios
        .delete(`/api/teachers/${this.selectedTeacherId}`, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.closeDelete();
          this.reloadTable();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeAdd() {
      this.addDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    save() {
      let formData = new FormData();

      console.log("this.editedItem", this.editedItem);

      const obj = this.editedItem;
      Object.keys(obj).forEach(function (key) {
        if (obj[key] != null) {
          formData.append(key, obj[key]);
        }
      });
      formData.append("image", this.uploadedImage);

      const header = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      this.axios
        .put(`/api/teachers/${this.selectedTeacherId}`, formData, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.close();
          this.reloadTable();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    add() {
      let formData = new FormData();

      const obj = this.editedItem;
      Object.keys(obj).forEach(function (key) {
        if (obj[key] != null) {
          formData.append(key, obj[key]);
        }
      });
      formData.append("image", this.uploadedImage);

      const header = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      this.axios
        .post(`/api/teachers`, formData, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.closeAdd();
          this.reloadTable();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    changeFile(elem) {
      this.uploadedImage = "";
      console.log("elem", elem);
      const files = elem.target.files || elem.dataTransfer.files;
      this.uploadedImage = files[0];
    },
  },
};
</script>

<style lang="scss" scoped>
v-data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #dcc268;
}

thead.v-data-table-header {
  background-color: #dcc268;
}

.filter-area {
  padding: 0 20px;
}
.filter-item {
  padding: 10px 5px;
}
.table-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.csv-upload-area {
  margin-bottom: 10px;
}
.v-data-table {
  white-space: nowrap;
}
</style>
