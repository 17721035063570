<template>
  <div>
    <p>{{ formTitle }}: {{ levelText }}</p>
  </div>
</template>

<script>
export default {
  name: "TeacherLevelForm",
  data: () => ({
    isEditedEmail: false,
  }),
  props: ["formTitle", "level"],
  mounted() {},
  computed: {
    levelText() {
      switch (this.level) {
        case "BEGINNER":
          return this.$t("profile.beginner");
        case "EXPERT":
          return this.$t("profile.expert");
        default:
          return "-";
      }
    },
  },
  methods: {},
  components: {},
};
</script>

<style lang="scss" scoped></style>
