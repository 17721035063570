<template>
  <span class="attention">{{ attentionText }}</span>
</template>

<script>
export default {
  name: 'FormAttentionText',
  data: () => ({
  }),
  props: ['attentionText'],
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.attention {
  font-size: 0.8em;
}
</style>
