<template>
  <v-btn text @click="clickAction">
    <p>
      {{buttonTitle}}
      <v-icon
        v-if="!isDetailShown"
        size="18"
        class="icon"
      >
        mdi-chevron-down-circle-outline
      </v-icon>
      <v-icon
        v-if="isDetailShown"
        size="18"
        class="icon"
      >
        mdi-chevron-up-circle-outline
      </v-icon>
    </p>
  </v-btn>
</template>

<script>

export default {
  name: 'TeacherCardDetailButton',
  data: () => ({
  }),
  components: {
  },
  methods: {
    clickAction() {
      this.$emit('clickAction');
    }
  },
  props:['buttonTitle', 'isDetailShown'],
};
</script>

<style lang="scss" scoped>
p {
  font-weight: bold;
  font-size: 0.8em;
  margin-bottom: 0;
  text-align: right;
}
.icon {
  position: relative;
  bottom: 2px;
}
</style>

