<template>
  <validationProvider
    rules="required|max:250"
    v-slot="{ errors, valid }"
    immediate
    name="経歴"
  >
    <p>
      <FormTitleText :titleText="$t('profile.background')" />
      <FormAttentionText :attentionText="$t('profile.max250')" />
    </p>
    <v-textarea
      v-model="inputedBackground"
      :placeholder="backgroundLabel"
      outlined
      background-color="white"
      counter
      :success="valid"
    ></v-textarea>
    <FormErrorText :errorText="errors[0]" />
  </validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText';
import FormErrorText from '../Atoms/FormErrorText';
import FormAttentionText from '../Atoms/FormAttentionText';
import mypageText from '@/data/mypage/mypageText';

export default {
  name: 'BackgroundForm',
  data: () => ({
    backgroundLabel: mypageText.backgroundLabel,
  }),
  props: ['value'],
  mounted() {
  },
  computed: {
    inputedBackground: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
  },
  components: {
    FormTitleText,
    FormErrorText,
    FormAttentionText
  }
};
</script>

<style lang="scss" scoped>
</style>
