<template>
  <div :style="{ background: '#FAFAFA' }">
    <v-container>
      <PageTitle title-name="Vollmond講師一覧" />
      <v-row justify="center" no-gutters>
        <p
          class="attention"
          :style="{
            fontSize: attentionFontSize,
          }"
        >
          このページではVollmondに登録している先生一覧を表示しています。気になる先生がいたら、<a
            href="https://vollmond.online/deutschkurse/"
            target="_blank"
            rel="noopener"
            >各コース受付ページ</a
          >から申し込んで下さい。
          <br />
          <span
            :style="{
              fontSize: '0.8em',
            }"
          >
            ※各講師、キャパシティがございます。新規申し込みを停止している講師もおりますこと、予めご了承ください。
          </span>
        </p>
      </v-row>
      <SortListArea
        @changeAction="changeSortCondition"
        :lessonCourseId="2"
        atAllList
      />
      <v-row justify="center" no-gutters :style="{ 'margin-left': '2px' }">
        <v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
          <v-checkbox
            v-if="showCheckBox"
            v-model="showOnlyAvailableTeacher"
            label="現在受付中の講師のみ表示する"
            color="#DCC268"
          ></v-checkbox>
        </v-col>
      </v-row>
      <div
        v-if="this.showedTeachers && this.showedTeachers.length"
        :style="{ 'min-height': '800px' }"
      >
        <v-row v-if="isPC">
          <v-col
            cols="4"
            xs="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            v-for="(teacher, i) in this.showedTeachers"
            :key="i"
          >
            <PCTeacherCard
              :teacherInfo="teacher"
              :style="{ 'margin-bottom': '20px' }"
              :show-empty="false"
              :disabled="!canReserve(teacher)"
              @clickAction="toSchedule(teacher)"
              showAt="ALL"
            />
          </v-col>
        </v-row>
        <div v-for="(teacher, i) in this.showedTeachers" :key="i" v-else>
          <v-row justify="center">
            <v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="6">
              <SPTeacherCard
                :teacherInfo="teacher"
                :style="{ 'margin-bottom': '20px' }"
                :show-empty="false"
                :disabled="!canReserve(teacher)"
                @clickAction="toSchedule(teacher)"
                showAt="ALL"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else :style="{ 'min-height': '800px' }">
        <v-row no-gutters justify="center">
          <p v-if="isChild" :style="{ 'text-align': 'center' }">
            お子様に対応できる講師には限りがあります。<br />お手数ですが「おすすめしてもらう」よりお問い合わせいただきますようお願いいたします。
          </p>
          <p v-else>条件に該当する講師がいません</p>
        </v-row>
      </div>
    </v-container>
    <v-dialog
      v-model="showChoiceOfCourseDialog"
      v-if="showChoiceOfCourseDialog"
      offset-x
      max-width="500px"
    >
      <ChoiceCourseDialog
        @close="showChoiceOfCourseDialog = false"
        @toPriv="toPriv(selectedTeacherId)"
        @toConv="toConv(selectedTeacherId)"
      />
    </v-dialog>
  </div>
</template>

<script>
import PageTitle from "../Atoms/PageTitle";
import PCTeacherCard from "../Organisms/PCTeacherCard";
import SortListArea from "../Organisms/SortListArea";
import SPTeacherCard from "../Organisms/SPTeacherCard";
import ChoiceCourseDialog from "../Molecules/ChoiceCourseDialog.vue";
// import _ from "lodash";

export default {
  name: "AllTeacherList",
  components: {
    PageTitle,
    PCTeacherCard,
    SortListArea,
    SPTeacherCard,
    ChoiceCourseDialog,
  },
  data: () => ({
    teachers: null,
    userInfo: null,
    showTransitionDialog: false,
    selectedTeacherId: null,
    showCheckBox: false,
    showOnlyAvailableTeacher: false,
    filterItems: null,
    showChoiceOfCourseDialog: false,
  }),
  created() {
    this.fetchInitialData();
    if (Object.keys(this.$route.query).length) {
      this.searchTeachers();
    } else {
      this.fetchTeacherList();
    }
    // レッスンコース選択がされている場合はチェックボックスを表示する
    if (this.$route.query.lessonCourseId) {
      this.showCheckBox = true;
    } else {
      this.showCheckBox = false;
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();

    if (Object.keys(this.$route.query).length) {
      this.searchTeachers();
    }
  },
  computed: {
    showedTeachers() {
      if (!this.teachers) return [];
      // 管理者はどのユーザーでも表示しない
      let teachers = this.teachers.filter((v) => !v.isAdmin);
      // テストユーザーでない場合はテスト先生を見ることができない
      if (this.userInfo.userName !== "フォルモント生徒（テスト）") {
        teachers = teachers.filter(
          (v) => v.teacherName !== "フォルモント先生（テスト）"
        );
      }
      // 「現在受付中の講師のみ表示する」にチェックが入っている場合は、受付中のみの表示に絞る
      if (this.showOnlyAvailableTeacher && this.filterItems) {
        if (this.filterItems.lessonCourseId === 1) {
          teachers = teachers.filter((v) => v.isVisibleForConv);
        } else if (this.filterItems.lessonCourseId === 2) {
          teachers = teachers.filter((v) => v.isVisibleForPriv);
        }
      }
      return teachers;
    },
    attentionFontSize() {
      if (this.isPC) {
        return "1em";
      } else {
        return "0.8em";
      }
    },
    computedTeacherLength() {
      if (!this.teachers || this.teachers.length === 0) {
        return 2;
      } else {
        return this.teachers.length;
      }
    },
    isChild() {
      return this.userInfo.studentCategory == "CHILD";
    },
  },
  methods: {
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    fetchTeacherList() {
      // 区分が子供の時は取得しない
      if (!this.isChild) {
        this.$store.commit("loading/SET_LOADING", true);
        this.axios
          .get(`/api/teachers/allList`)
          .then((response) => {
            this.teachers = response.data.teachers;
            this.$store.commit("loading/SET_LOADING", false);
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("loading/SET_LOADING", false);
          });
      }
    },
    // ソート条件を変更した後、APIを叩きデータを再取得する
    changeSortCondition(value) {
      this.filterItems = value;

      // レッスンコース選択がされている場合はチェックボックスを表示する
      if (value.lessonCourseId) {
        this.showCheckBox = true;
      } else {
        this.showCheckBox = false;
      }

      const level =
        value.compatibleLevel === "完全初級" ? "A0" : value.compatibleLevel;

      const routeQuery = {
        lessonCourseId: value.lessonCourseId,
        country: value.country,
        compatibleLevel: level,
        teachingLevel: value.teachingLevel,
        empty: value.empty,
        keyword: value.keyword,
      };
      if (
        JSON.stringify(Object.entries(routeQuery).sort()) !=
        JSON.stringify(Object.entries(this.$route.query).sort())
      ) {
        this.$router
          .push({
            name: "AllTeacherList",
            query: routeQuery,
          })
          .catch((err) => console.log(err));
      }
    },
    searchTeachers() {
      this.teachers = [];
      const query = this.$route.query;
      this.axios
        .get(
          `/api/teachers/allList?lessonCourseId=${query.lessonCourseId}` +
            `&country=${query.country}` +
            `&compatibleLevel=${query.compatibleLevel}` +
            `&teachingLevel=${
              !query.lessonCourseId ? query.teachingLevel : ""
            }` +
            `&privLevel=${
              query.lessonCourseId == "2" ? query.teachingLevel : ""
            }` +
            `&convLevel=${
              query.lessonCourseId == "1" ? query.teachingLevel : ""
            }` +
            `&keyword=${query.keyword ? query.keyword : ""}`
        )
        .then((response) => {
          this.teachers = response.data.teachers;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    canConvReserve(teacher) {
      return teacher.lessonCourseIds.includes(1) && teacher.isVisibleForConv;
    },
    canPrivReserve(teacher) {
      return teacher.lessonCourseIds.includes(2) && teacher.isVisibleForPriv;
    },
    canReserve(teacher) {
      teacher.lessonCourseIds.includes(1);
      return this.canConvReserve(teacher) || this.canPrivReserve(teacher);
    },
    toSchedule(teacher) {
      if (this.canConvReserve(teacher) && this.canPrivReserve(teacher)) {
        this.selectedTeacherId = teacher.id;
        this.showChoiceOfCourseDialog = true;
        return;
      }
      if (this.canConvReserve(teacher)) {
        this.toConv(teacher.id);
        return;
      }
      if (this.canPrivReserve(teacher)) {
        this.toPriv(teacher.id);
        return;
      }
    },
    toConv(teacherId) {
      this.$router
        .push({
          path: "conversationTeacherSchedule",
          query: { teacherId: teacherId },
        })
        .catch((err) => console.log(err));
    },
    toPriv(teacherId) {
      this.$router
        .push({
          path: "privateTeacherSchedule",
          query: { teacherId: teacherId },
        })
        .catch((err) => console.log(err));
    },
  },
  head: {
    title: {
      inner: "teacher-list",
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  white-space: pre-line;
}
.attention {
  margin: 0 0 30px 0;
  text-align: center;
  line-height: 2em;
  color: #6d6f6c;
}
.v-input--selection-controls {
  margin-top: 0;
}
</style>
