import { i18n } from '../i18n.js'

export const getLessonCourseName = (lessonCourseId) => {
  switch (lessonCourseId) {
    case 1:
      return i18n.t('labels.conversationCourse');
    case 2:
      return i18n.t('labels.privateCourse');
    case 3:
      return i18n.t('labels.textCourse');
    default:
      return ''
  }
}
