<template>
  <div>
    <!-- タイトル -->
    <PageTitle title-name="生徒データ分析" />
    <VmBox>
      <template #header>
        <v-col>
          <VueJsonToCsv
            :json-data="downloadedCSVData"
            :csv-title="computedCSVTitle"
          >
            <v-btn class="csv-button"> csvダウンロード </v-btn>
          </VueJsonToCsv>
        </v-col>
        <!-- 月変更できるところ -->
        <v-row>
          <SelectMonth
            v-model="selectedMonth"
            @fetchData="reloadTable"
            @reload="reloadTable"
          />
        </v-row>
      </template>
      <template #content>
        <v-data-table
          :headers="headers"
          :items="studentDataArray"
          multi-sort
          class="elevation-1 overflow-y-auto"
          disable-pagination
          no-data-text="データがありません"
          hide-default-footer
          :loading="isLoading"
          fixed-header
          :height="studentDataArray.length > 15 ? 48 * 15 - 1 : undefined"
        >
        </v-data-table>
      </template>
    </VmBox>
  </div>
</template>

<script>
import PageTitle from "../Atoms/PageTitle";
import SelectMonth from "../Molecules/SelectMonth";
import VmBox from "../Atoms/VmBox";

export default {
  name: "AdminStudentDataAnalysis",
  components: {
    PageTitle,
    SelectMonth,
    VmBox,
  },
  data: () => ({
    studentDataArray: [],
    isLoading: false,
    downloadedCSVData: [],
    selectedMonth: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: "studentId",
          value: "studentId",
          align: "start",
          sortable: false,
        },
        { text: "生徒名", value: "studentName" },
        { text: "総時間", value: "totalHours" },
        { text: "総回数", value: "totalNumOfTimes" },
      ];
    },
    // CSVファイルのタイトル
    computedCSVTitle() {
      return this.$moment(this.selectedMonth).format("YYYY_MM_") + "生徒データ";
    },
  },
  props: [],
  created() {
    this.fetchInitialData();
  },
  mounted() {},
  methods: {
    reloadTable() {
      this.downloadedCSVData = [];
      this.studentDataArray = [];
      this.fetchAnalysisData();
    },
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      const user_info = this.$store.getters["user/getUserInfo"];
      this.accessToken = user_info.accessToken;
    },
    fetchAnalysisData() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        };

        const startTime = this.$moment(this.selectedMonth)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm");
        const endTime = this.$moment(this.selectedMonth)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm");

        this.axios
          .get(
            `/api/analysis/student?startTime=${startTime}&endTime=${endTime}`,
            header
          )
          .then((response) => {
            const studentData = response.data.studentData;
            this.makeStudentDataArray(studentData);
            this.isLoading = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    makeStudentDataArray(dataArray) {
      dataArray.forEach((data) => {
        const studentData = {
          studentId: data.studentId,
          studentName: data.studentName,
          totalHours: data.totalHours,
          totalNumOfTimes: data.totalNumOfTimes,
        };
        this.studentDataArray.push(studentData);
        // CSV出力用に入れておく
        this.downloadedCSVData.push(studentData);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
v-data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #dcc268;
}

thead.v-data-table-header {
  background-color: #dcc268;
}

.filter-area {
  padding: 0 20px;
}
.filter-item {
  padding: 10px 5px;
}
.table-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.csv-upload-area {
  margin-bottom: 10px;
}
.v-data-table {
  white-space: nowrap;
}
</style>
