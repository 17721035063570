<template>
  <div>
    <BaseCirclePicture class="base-circle" :imageUrl="imageUrl" :imageSize="imageSize" :enableHoverAct="enableHoverAct" />
  </div>
</template>

<script>
import BaseCirclePicture from '../Atoms/BaseCirclePicture'

export default {
  name: 'YellowCirclePicture',
  data: () => ({
  }),
  components: {
    BaseCirclePicture
  },
  methods: {
  },
  props:['imageUrl', 'imageSize', 'enableHoverAct'],
};
</script>

<style lang="scss" scoped>
.base-circle {
  border-width: 3px;
  border-color: #dcc268;
  border-style: solid;
  width: 100%;
  aspect-ratio: 1 / 1;
}
</style>

