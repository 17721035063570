<template>
  <div :style="{ background: '#FAFAFA' }">
    <v-container>
      <PageTitle title-name="プライベートコース講師一覧" />
      <v-row justify="center">
        <v-col cols="11">
          <p
            class="attention"
            :style="{
              fontSize: attentionFontSize,
            }"
          >
            現在空きスケジュールのある講師のみ表示しています。
            <br />
            講師はそれぞれのタイミングで受付可能日時を登録するので、タイミングによって予約できる講師が変わります。
          </p>
        </v-col>
      </v-row>
      <SortListArea
        @changeAction="changeSortCondition"
        :lessonCourseId="2"
        :studentLevel="userInfo.level"
      />
      <v-row
        justify="center"
        no-gutters
        :style="{
          'margin-bottom': '20px',
          'margin-left': '2px',
        }"
      >
        <v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
          <RecommendTeacherButton
            v-if="!!this.userInfo.studentId"
            :lesson-course-id="2"
          />
        </v-col>
      </v-row>
      <div
        v-if="this.showedTeachers && this.showedTeachers.length && !isChild"
        :style="{ 'min-height': '800px' }"
      >
        <v-row v-if="isPC">
          <v-col
            cols="4"
            xs="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            v-for="(teacher, i) in this.showedTeachers"
            :key="i"
          >
            <PCTeacherCard
              :teacherInfo="teacher"
              :style="{ 'margin-bottom': '20px' }"
              showAt="PRIVATE"
              @clickAction="toSchedule(teacher.id)"
            />
          </v-col>
        </v-row>
        <div v-for="(teacher, i) in this.showedTeachers" :key="i" v-else>
          <v-row justify="center">
            <v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="6">
              <SPTeacherCard
                :teacherInfo="teacher"
                :style="{ 'margin-bottom': '20px' }"
                showAt="PRIVATE"
                @clickAction="toSchedule(teacher.id)"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else :style="{ 'min-height': '800px' }">
        <v-row no-gutters justify="center">
          <p v-if="isChild" :style="{ 'text-align': 'center' }">
            お子様に対応できる講師には限りがあります。<br />お手数ですが「おすすめしてもらう」よりお問い合わせいただきますようお願いいたします。
          </p>
          <p v-else>条件に該当する講師がいません</p>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../Atoms/PageTitle";
import SortListArea from "../Organisms/SortListArea";
import SPTeacherCard from "../Organisms/SPTeacherCard";
import PCTeacherCard from "../Organisms/PCTeacherCard";
import RecommendTeacherButton from "../Organisms/RecommendTeacherButton";

export default {
  name: "PrivateTeacherList",
  data: () => ({
    teachers: null,
    userInfo: null,
  }),
  components: {
    PageTitle,
    SortListArea,
    PCTeacherCard,
    SPTeacherCard,
    RecommendTeacherButton,
  },
  created() {
    this.fetchInitialData();
    if (Object.keys(this.$route.query).length) {
      this.searchTeachers();
    } else {
      this.fetchTeacherList();
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();

    if (Object.keys(this.$route.query).length) {
      this.searchTeachers();
    }
  },
  computed: {
    showedTeachers() {
      if (!this.teachers) return [];
      // 管理者はどのユーザーでも表示しない
      let teachers = this.teachers.filter((v) => !v.isAdmin);
      // テストユーザーでない場合はテスト先生を見ることができない
      if (this.userInfo.userName !== "フォルモント生徒（テスト）") {
        teachers = teachers.filter(
          (v) => v.teacherName !== "フォルモント先生（テスト）"
        );
      }
      return teachers;
    },
    attentionFontSize() {
      if (this.isPC) {
        return "0.8em";
      } else {
        return "0.6em";
      }
    },
    computedTeacherLength() {
      if (!this.teachers || this.teachers.length === 0) {
        return 2;
      } else {
        return this.teachers.length;
      }
    },
    isChild() {
      return this.userInfo.studentCategory == "CHILD";
    },
  },
  methods: {
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    toSchedule(teacherId) {
      this.$router.push({
        path: "privateTeacherSchedule",
        query: { teacherId: teacherId },
      });
    },
    fetchTeacherList() {
      // 区分が子供の時は取得しない
      if (!this.isChild) {
        this.$store.commit("loading/SET_LOADING", true);
        this.axios
          .get(`/api/teachers/list?lessonCourseId=2`)
          .then((response) => {
            this.teachers = response.data.teachers;
            this.$store.commit("loading/SET_LOADING", false);
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("loading/SET_LOADING", false);
          });
      }
    },
    // ソート条件を変更した後、APIを叩きデータを再取得する
    changeSortCondition(value) {
      const level =
        value.compatibleLevel === "完全初級" ? "A0" : value.compatibleLevel;

      this.$router.push({
        name: "PrivateTeacherList",
        query: {
          country: value.country,
          compatibleLevel: level,
          teachingLevel: value.teachingLevel,
          empty: value.empty,
          keyword: value.keyword,
        },
      });
    },
    searchTeachers() {
      this.teachers = [];
      this.axios
        .get(
          `/api/teachers/list?lessonCourseId=2&country=${
            this.$route.query.country
          }&compatibleLevel=${this.$route.query.compatibleLevel}&privLevel=${
            this.$route.query.teachingLevel
          }&keyword=${
            this.$route.query.keyword ? this.$route.query.keyword : ""
          }`
        )
        .then((response) => {
          // 空き具合でのfilteringもあればソートする
          const teachers = response.data.teachers;
          if (this.$route.query.empty) {
            this.teachers = teachers.filter((teacher) => {
              switch (this.$route.query.empty) {
                case "多い":
                  return teacher.availableTimes >= 2400;
                case "普通":
                  return (
                    teacher.availableTimes >= 1200 &&
                    teacher.availableTimes < 2400
                  );
                case "わずか":
                  return (
                    teacher.availableTimes >= 0 && teacher.availableTimes < 1200
                  );
                default:
                  return true;
              }
            });
          } else {
            this.teachers = teachers;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  head: {
    title: {
      inner: "teacher-list",
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  white-space: pre-line;
}
.attention {
  margin: 0 0 30px 0;
  text-align: center;
  line-height: 2em;
  color: #6d6f6c;
}
</style>
