<template>
  <v-col cols="12" :style="{ padding: '20px 0 0 0' }">
    <v-row justify="center" no-gutters>
      <v-col cols="12" xs="12" sm="2" md="4" lg="4">
        <v-btn text @click="toTop" :disabled="!isLogin">
          <v-img
            contain
            :src="require('../../assets/Vollmond_logo.png')"
            class="logo-image"
            :max-width="logMaxWidth"
          />
        </v-btn>
      </v-col>
      <v-spacer v-if="showIcon" />
      <v-col cols="2" xs="2" sm="3" md="3" lg="3" v-if="showIcon">
        <v-img
          alt="Vollmond Logo"
          contain
          :src="require('../../assets/headerIcon.png')"
          max-width="90"
        />
      </v-col>
      <!-- <v-spacer /> -->
      <v-col cols="9" xs="9" sm="2" md="2" lg="2">
        <div v-if="isShowNumOfTicket">
          <p class="header-text-student">
            会話チケット: {{ userInfo.numberOfTicket }}枚
          </p>
        </div>
        <v-spacer v-if="!isPC" />
      </v-col>

      <v-spacer v-if="!showMenu" />
      <!-- メニューアイコン -->
      <v-col cols="2" xs="2" sm="1" md="1" lg="1" v-if="showMenu">
        <v-menu
          bottom
          nudge-left="70"
          transition="scale-transition"
          origin="top right"
          offset-y
          open-on-hover
        >
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" class="userIcon">
              <BaseCirclePicture :imageUrl="iconImage" imageSize="40" />
              <div class="iconBadge" v-if="!isStudent && wipCounts">
                <span v-if="wipCounts >= 10" class="badge rounded-pill">{{
                  wipCounts
                }}</span>
                <span v-else class="badge rounded-circle">{{ wipCounts }}</span>
              </div>
            </v-btn>
          </template>
          <v-card>
            <v-card-actions>
              <v-col>
                <v-row justify="center">
                  <div>
                    <p class="teacher-name">
                      {{ computedName }}
                    </p>
                  </div>
                </v-row>
                <v-row justify="center">
                  <v-btn @click="toMypage" text>
                    <p class="icon-menu-text">
                      {{ $t("profile.profile") }}
                    </p>
                  </v-btn>
                </v-row>
                <v-row v-if="!isStudent" justify="center">
                  <v-btn @click="toLessonRequestList" text>
                    <p class="icon-menu-text">
                      {{ $t("lessonRequest.title") }}
                      <span v-if="wipCounts">
                        <span
                          v-if="wipCounts >= 10"
                          class="badge rounded-pill"
                          >{{ wipCounts }}</span
                        >
                        <span v-else class="badge rounded-circle">{{
                          wipCounts
                        }}</span>
                      </span>
                    </p>
                  </v-btn>
                </v-row>
                <v-row justify="center" v-if="userInfo.isReservedStripe">
                  <v-btn @click="toCustomerPortal" text>
                    <p class="icon-menu-text">支払設定＆継続停止</p>
                  </v-btn>
                </v-row>
                <v-row justify="center">
                  <v-btn @click="toInquiries" text>
                    <p class="icon-menu-text">
                      {{ $t("header.inquiries") }}
                    </p>
                  </v-btn>
                </v-row>
                <v-row justify="center">
                  <v-btn
                    color="primary"
                    :style="{ fontWeight: 'bold' }"
                    @click="logout"
                    text
                  >
                    <p class="icon-menu-text">
                      {{ $t("profile.logout") }}
                    </p>
                  </v-btn>
                </v-row>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <hr />
  </v-col>
</template>

<script>
import BaseCirclePicture from "../Atoms/BaseCirclePicture";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Header",
  data: () => ({}),
  created() {},
  mounted() {
    if (!this.userInfo.accessToken) {
      return;
    }
    if (this.userInfo.userAuthority === "STUDENT") {
      // 画面表示時にチケット枚数を取得して更新する
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      this.axios
        .get(`/api/students/${this.userInfo.studentId}`, header)
        .then((response) => {
          this.$store.commit(
            "user/SET_USER_TICKETNUM",
            response.data.student.numberOfTicket
          );
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    } else {
      this.fetchWipLessonRequest({
        teacherId: this.userInfo.teacherId,
        accessToken: this.userInfo.accessToken,
      });
    }
  },
  computed: {
    ...mapGetters({
      wipCounts: "lessonRequest/wipCounts",
      lessonRequestLoading: "lessonRequest/loading",
      userInfo: "user/getUserInfo",
    }),
    userName() {
      return this.userInfo.userName ? this.userInfo.userName : "未設定";
    },
    showIcon() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
        default:
          return true;
      }
    },
    showMenu() {
      // ログイン時のみメニューボタンを出す
      if (this.isLogin) {
        return true;
      } else {
        return false;
      }
    },
    isStudent() {
      if (this.userInfo.userAuthority === "STUDENT") {
        return true;
      } else {
        return false;
      }
    },
    computedName() {
      if (this.userInfo.userAuthority === "STUDENT") {
        return this.userName + "さん";
      } else {
        return this.userName + "先生";
      }
    },
    isShowName() {
      if (this.$route.path.match("/admin/*")) {
        return false;
      } else {
        return true;
      }
    },
    isShowNumOfTicket() {
      // admin画面では表示しない
      if (this.$route.path.match("/admin/*")) {
        return false;
      }

      // プライベートコースしか受講していない生徒には表示しない
      if (this.isOnlyPrivateStudent) {
        return false;
      }

      if (this.userInfo.userAuthority === "STUDENT") {
        return true;
      } else {
        return false;
      }
    },
    iconImage() {
      if (!this.userInfo.imageUrl) {
        if (this.isStudent) {
          return require("../../assets/no_student.png");
        } else {
          return require("../../assets/no_teacher.png");
        }
      } else {
        return this.userInfo.imageUrl;
      }
    },
    // プライベートコース専任講師かどうか判断する
    isOnlyPrivateStudent() {
      const lessonCourseArray = this.userInfo.lessonCourseArray;

      // プライベートコース以外のコースが1つでもあればfalse
      for (let lessonCourse of lessonCourseArray) {
        if (lessonCourse != "PRIVATE") {
          return false;
        }
      }
      return true;
    },
    logMaxWidth() {
      if (!this.isPC) {
        return 120;
      }
      return 185;
    },
  },
  methods: {
    ...mapActions({
      fetchWipLessonRequest: "lessonRequest/fetchWipLessonRequest",
    }),
    toTop() {
      if (
        this.$route.path != "/studentTop" &&
        this.$route.path != "/teacherTop" &&
        this.$route.path != "/admin/top"
      ) {
        if (this.isStudent) {
          this.$router.push("/studentTop");
        } else {
          this.$router.push("/teacherTop");
        }
      }
    },
    logout() {
      this.$router.push("/");
      window.localStorage.removeItem("vuex");
    },
    toMypage() {
      if (this.isStudent) {
        this.$router.push("/studentMypage");
      } else {
        this.$router.push("/teacherMypage");
      }
    },
    toLessonRequestList() {
      this.$router.push("/lessonRequestList");
    },
    toCustomerPortal() {
      // ポップアップブロックのため、事前にwindowを開いてから読み込むようにする
      var win = window.open("", "child", "width=800, height=600");
      win.document.body.innerHTML = "loading...";
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      this.axios
        .get(`/api/stripes/customerPortal/${this.userInfo.studentId}`, header)
        .then((response) => {
          win.location.href = response.data.customerPortalUrl;
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    toInquiries() {
      window.open("https://form.run/@vollmond-system-issue");
    },
  },

  components: {
    BaseCirclePicture,
  },
};
</script>

<style lang="scss" scoped>
.userIcon {
  position: relative;
}
.iconBadge {
  position: absolute;
  top: 0;
  left: 25px;
}
.badge {
  background-color: #dcc268;
  padding: 4px 6px;
  // border-radius: 100vh;
  // border-radius: 50%;
  font-weight: bold;
  color: white;
}
.logo-image {
  padding: 30px 0 0 0;
}
.header-text-student {
  font-size: 0.9em;
  margin: 10px 5px 0 0;
  color: #dcc268;
  font-weight: bold;
}
.logout-text {
  margin: 15px 0 0 0;
}
hr {
  height: 2px;
  background-color: #dcc268;
  border: none;
  color: #dcc268;
  margin-top: -2px;
}
.teacher-name {
  font-size: 0.8em;
  margin: 0 0 5px 0;
}
.icon-menu-text {
  margin: 0;
}

@media screen and (max-width: 600px) {
  hr {
    margin-top: 10px;
  }
}
</style>
