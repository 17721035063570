var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VmDialogCard',{attrs:{"title":"チケット申込","buttonTitle":"チケット申込みをする","disabled":!_vm.buttonValidation},on:{"click":_vm.apply,"close":_vm.close},scopedSlots:_vm._u([{key:"cardContent",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"label-text"},[_vm._v("チケット枚数（1枚＝20分レッスン1回）")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-select',{attrs:{"items":_vm.ticketNumItems,"label":"チケット枚数を選択してください","solo":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item === 10)?_c('div',[_vm._v(" "+_vm._s(item)+" "),_c('span',{style:({
                  color: 'white',
                  'background-color': '#DCC268',
                  padding: '5px 10px',
                  'margin-left': '10px',
                  'border-radius': '3px',
                  'font-weight': 'bold',
                })},[_vm._v(" おすすめ！ ")])]):_c('span',[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.selectedTicket),callback:function ($$v) {_vm.selectedTicket=$$v},expression:"selectedTicket"}}),(_vm.selectedTicket === 'その他（手動入力）')?_c('v-text-field',{style:({ 'margin-top': '10px' }),attrs:{"label":"チケット枚数を入力（半角数字）","solo":"","hide-details":"","type":"number"},model:{value:(_vm.inputtedNum),callback:function ($$v) {_vm.inputtedNum=$$v},expression:"inputtedNum"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"unit-text",style:(_vm.selectedTicket === 'その他（手動入力）'
              ? { 'margin-top': '80px' }
              : { 'margin-top': '25px' })},[_vm._v(" 枚 ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"label-text"},[_vm._v(" フィードバック "),_c('br'),_c('span',{staticClass:"explanation-text"},[_vm._v(" 担当講師からのフィードバック（いいところ・よくなるところ）がレッスン後メールで届きます。チケット枚数に限らず料金は「1100円」です。また講師によってはフィードバックがドイツ語になる場合もございます。 ")])])])],1),_c('v-row',[_c('VmAccordionMenu',{style:({ 'margin-left': '12px' }),attrs:{"text":"フィードバック例はこちら"},on:{"click":function($event){_vm.showFeedbackExample = !_vm.showFeedbackExample}}})],1),(_vm.showFeedbackExample)?_c('v-row',[_c('div',{staticClass:"feedback-example"},[_c('span',{style:({ 'font-weight': 'bold' })},[_vm._v("いいところ")]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("top.goodPointPlaceHolder"))+" "),_c('br'),_c('br'),_c('span',{style:({ 'font-weight': 'bold' })},[_vm._v("もっとよくなるところ")]),_c('br'),_vm._v(" "+_vm._s(_vm.$t("top.getBetterPointPlaceHolder"))+" ")])]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-select',{attrs:{"items":_vm.monthlyFeedbackItems,"label":"選択してください","solo":"","hide-details":""},model:{value:(_vm.monthlyFeedback),callback:function ($$v) {_vm.monthlyFeedback=$$v},expression:"monthlyFeedback"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" 合計金額: "+_vm._s(_vm.computedTotalAmount)+"円 ")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }