<template>
  <VmDialogCard
    :title="cardTitle"
    :buttonTitle="$t('teacherSchedule.addLessonButton')"
    :cancelButtonTitle="$t('teacherSchedule.cancel')"
    @click="registerLesson"
    @close="cancel"
    :disabled="!buttonValidation"
  >
    <template #cardContent>
      <v-container>
        <v-row>
          <p class="report-subtitle">
            <!-- レッスン時間 -->
            {{ $t("teacherSchedule.lessonTime") }}
          </p>
          <TimeZoneIcon />
        </v-row>
        <v-row>
          {{ $t("teacherSchedule.attentionForFirstLesson") }}
        </v-row>

        <div class="date-form-area">
          <!-- 開始日時のフォーム -->
          <DateAndTimeForm
            :formLabelText="$t('teacherSchedule.startTime')"
            :date.sync="scheduleStartDate"
            :showedDate="computedScheduleStartDate"
            :minDate="selectableStartMinDate"
            :minTime="selectableStartMinTime"
            :maxTime="selectableMaxTime"
            @closeDatePicker="inputEndDate"
            @changeDatePicker="activeButton"
            :time.sync="scheduleStartTime"
            :minuteInterval="5"
            @changeTimePicker="activeButton"
            @closeTimePicker="inputEndTime"
          />
          <!-- 終了日時のフォーム -->
          <DateAndTimeForm
            :formLabelText="$t('teacherSchedule.endTime')"
            :date.sync="scheduleEndDate"
            :showedDate="computedScheduleEndDate"
            :minDate="selectableMinEndDate"
            :minTime="selectableEndMinTime"
            :maxTime="selectableMaxTime"
            @changeDatePicker="activeButton"
            :time.sync="scheduleEndTime"
            :minuteInterval="5"
            @changeTimePicker="activeButton"
          />
        </div>
      </v-container>
      <v-container>
        <v-row>
          <p class="report-subtitle">
            <!-- レッスンコース -->
            {{ $t("teacherSchedule.lessonCourse") }}
          </p>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            v-for="courseName of courseNames"
            :key="courseName"
            class="checkbox-col"
          >
            <v-checkbox
              v-model="selectedCourseNames"
              :label="courseName"
              :value="courseName"
              color="#DCC268"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <p class="report-subtitle">
            <!-- 生徒名 -->
            {{ $t("teacherSchedule.studentName") }}
          </p>
        </v-row>
        <!-- 生徒を選択してください -->
        <v-row>
          <v-select
            :items="studentNames"
            v-model="selectedStudent"
            :label="$t('teacherSchedule.requireSelectionStudents')"
            solo
            hide-details
            no-data-text="担当生徒がいません"
          ></v-select>
        </v-row>
      </v-container>
    </template>
  </VmDialogCard>
</template>

<script>
import TimeZoneIcon from "../Atoms/TimeZoneIcon";
import DateAndTimeForm from "../Molecules/DateAndTimeForm";
import VmDialogCard from "../Molecules/VmDialogCard";

export default {
  name: "AddLessonCardForTeacher",
  inject: ["reloadCalendar"],
  components: {
    TimeZoneIcon,
    DateAndTimeForm,
    VmDialogCard,
  },
  data: () => ({
    scheduleStartDate: "2020-12-01",
    scheduleEndDate: "2020-12-01",
    scheduleStartTime: "00:00",
    scheduleEndTime: "00:00",
    isButtonDisabled: true,
    selectedCourseNames: [],
    studentNames: [],
    selectedStudent: "",
    hasGivenClassesStudents: [],
  }),
  props: [
    "courseId",
    "cardTitle",
    "selectedOpen",
    "teacherScheduleId",
    "userInfo",
    "calendarValue",
  ],
  computed: {
    selectableStartMinDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    selectableMinEndDate() {
      return this.scheduleStartDate;
    },
    selectableStartMinTime() {
      // 当日のレッスン予定を入れるときは、現在時刻以降しか選択できないようにする。
      if (this.scheduleStartDate === this.$moment().format("YYYY-MM-DD")) {
        return this.$moment().add(1, "hours").format("HH:00");
      }
      return "00:00";
    },
    selectableEndMinTime() {
      // 当日のレッスン予定を入れるときは、現在時刻以降しか選択できないようにする。
      if (this.scheduleEndDate === this.$moment().format("YYYY-MM-DD")) {
        return this.$moment().add(1, "hours").format("HH:00");
      }
      return "00:00";
    },
    selectableMaxTime() {
      return "23:00";
    },
    computedStartTime() {
      const start = this.scheduleStartDate + " " + this.scheduleStartTime;
      return this.$moment(start).format("YYYY-MM-DD HH:mm");
    },
    computedEndTime() {
      const end = this.scheduleEndDate + " " + this.scheduleEndTime;
      return this.$moment(end).format("YYYY-MM-DD HH:mm");
    },
    computedScheduleStartDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.scheduleStartDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.scheduleStartDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
    computedScheduleEndDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.scheduleEndDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.scheduleEndDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
    // どのチェックボックスを用意するか計算する
    courseNames() {
      // プライベートコースだけ用意する
      let courseNames = [];
      courseNames.push(this.$t("teacherSchedule.PRIVATE"));

      return courseNames;
    },
    buttonValidation() {
      // 時間がおかしかったらアウト
      if (!this.lessonTimeValidation) {
        return false;
      }

      // 誰も選択されていなかったらアウト
      if (!this.selectedStudent) {
        return false;
      }

      return true;
    },

    // バリデーション - 終了時刻 > 開始時刻になっていればok
    // 30分単位になっていればok
    lessonTimeValidation() {
      const start = this.$moment(
        this.scheduleStartDate + " " + this.scheduleStartTime
      );
      const end = this.$moment(
        this.scheduleEndDate + " " + this.scheduleEndTime
      );

      const lessonTime = this.$moment(end).diff(start, "minutes");

      // 30分で割り切れなかったらアウト
      if (lessonTime % 30 != 0) {
        return false;
      }

      // 終了時刻 = 開始時刻になっているためアウト
      if (this.scheduleStartTime === this.scheduleEndTime) {
        return false;
      }

      if (this.$moment(end).diff(start, "minutes") > 90) {
        return false;
      }

      // 終了時刻 < 開始時刻になっているのでok
      if (end.isBefore(start)) {
        return false;
      }
      return true;
    },
    // 会話コース専任講師かどうか判断する
    isOnlyConversationTeacher() {
      const lessonCourseArray = this.userInfo.lessonCourseArray;

      // 会話コース以外のコースが1つでもあればfalse
      for (let lessonCourse of lessonCourseArray) {
        if (lessonCourse != "CONVERSATION") {
          return false;
        }
      }
      return true;
    },
    computedCourseName: function () {
      return function (lessonCourseId) {
        switch (lessonCourseId) {
          case 1:
            return this.$t("teacherSchedule.CONVERSATION");
          case 2:
            return this.$t("teacherSchedule.PRIVATE");
          default:
            return "";
        }
      };
    },
  },
  async mounted() {
    this.initScheduleDate();
    this.initCheckBox();

    try {
      await this.fetchStudentsInCharge();
    } catch (error) {
      console.log(error);
    }

    this.connectStudentIdAndName();
  },
  methods: {
    initScheduleDate() {
      this.scheduleStartDate = this.$moment(this.calendarValue).format(
        "YYYY-MM-DD"
      );
      this.scheduleEndDate = this.$moment(this.calendarValue).format(
        "YYYY-MM-DD"
      );
    },
    initCheckBox() {
      // 初めからプライベートコースにチェックが入っている状態にする
      this.selectedCourseNames.push(this.$t("teacherSchedule.PRIVATE"));
    },
    fetchStudentsInCharge() {
      // APIから今まで授業をしたことのある生徒情報を取得する
      return new Promise((resolve) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(
            `/api/students/hasGivenClasses?teacherId=${this.userInfo.teacherId}&lessonCourseId=2`,
            header
          )
          .then((response) => {
            this.hasGivenClassesStudents = response.data.students.filter(
              (v) => v
            );

            resolve();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    connectStudentIdAndName() {
      // 生徒情報を加工して 123_example の形にする
      for (let student of this.hasGivenClassesStudents) {
        const idName = student.id + "_" + student.name;
        this.studentNames.push(idName);
      }
    },
    async registerLesson() {
      if (!this.scheduleValidation()) {
        return;
      }

      // 加工済みの生徒名からstudentIdを取り出す
      const studentId = Number(this.selectedStudent.split("_")[0]);

      let isFirstLesson = null;
      try {
        isFirstLesson = await this.judgeFirstLesson(
          this.userInfo.teacherId,
          studentId
        );
      } catch (error) {
        console.log(error);
      }

      // 以下のレッスンを新しく追加しますか？\n ※生徒にレッスン予約完了メールが送信されます \n 生徒: \n 開始: \n 終了:
      if (
        confirm(
          `${this.$t("teacherSchedule.addLessonConfirmation")}\n\n ${this.$t(
            "teacherSchedule.student"
          )}: ${this.selectedStudent} \n ${this.$t(
            "teacherSchedule.startTime"
          )}： ${this.computedStartTime}  \n ${this.$t(
            "teacherSchedule.endTime"
          )}： ${this.computedEndTime}\n`
        )
      ) {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        const lessonData = {
          teacherId: this.userInfo.teacherId,
          studentId: studentId,
          startTime: this.$convertToPost(this.computedStartTime),
          endTime: this.$convertToPost(this.computedEndTime),
          isFirstLesson: isFirstLesson,
          lessonCourseId: 2,
        };

        this.axios
          .post("/api/lessons/privateReserve", lessonData, header)
          .then((response) => {
            console.log("response.data", response.data);
            this.cancel();
            this.reloadCalendar(this.calendarValue);
          })
          .catch((error) => {
            alert(error.response.data.error.message);
          });
      }
    },
    cancel() {
      this.$emit("cancelAction");
    },
    inputEndDate(val) {
      this.scheduleEndDate = val;
    },
    activeButton() {
      this.isButtonDisabled = false;
    },
    scheduleValidation() {
      if (this.computedStartTime >= this.computedEndTime) {
        alert("終了時刻は開始時刻より後の時刻を選択してください");
        return false;
      }

      if (
        this.$moment(this.computedEndTime).diff(
          this.computedStartTime,
          "minutes"
        ) > 90
      ) {
        alert("授業時間は最大で1時間半です。");
        return false;
      }
      return true;
    },
    // 初回授業かどうかの判定のために、studentIdと今開いている講師のteacherIdで授業を絞って取得する
    judgeFirstLesson(teacherId, studentId) {
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        this.axios
          .get(
            `/api/lessons?teacherId=${teacherId}&studentId=${studentId}&lessonCourseId=2&status=1`,
            header
          )
          .then((response) => {
            const lessons = response.data.lessons;

            if (lessons.length > 0) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    inputEndTime(val) {
      // 開始時刻のtimepickerが入力された後の挙動

      // 入力されたstartTimeの時間を分解
      const valHour = Number(val.split(":")[0]);
      const valMinute = Number(val.split(":")[1]);

      // それ以外の場合は1時間プラスした時間を入れる
      let strValHour = String(valHour + 1);
      if (valHour + 1 < 10) {
        strValHour = "0" + (valHour + 1);
      }

      let strValMinute = String(valMinute);
      if (valMinute < 10) {
        strValMinute = "0" + valMinute;
      }
      this.scheduleEndTime = strValHour + ":" + strValMinute;
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
.report-subtitle {
  font-size: 1.4em;
  font-weight: bold;
  margin: 20px 0 15px 0;
}
.button {
  margin-bottom: 20px;
}
.date-form-area {
  margin: 20px 0 0 0;
}
.checkbox-col {
  padding: 0;
}
</style>
