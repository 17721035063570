<template>
  <v-container
    ><PageTitle titleName="Willkommen!!" />
    <!-- ウェルカム画像 -->
    <v-row no-gutters justify="center">
      <v-col cols="10" xs="10" sm="3" md="3" lg="3">
        <v-img :src="require('../../assets/welcome.png')" contain></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <p
        :style="{
          'font-weight': 'bold',
          'margin-top': '50px',
          'font-size': this.isPC ? '0.8em' : '0.7em',
          'margin-bottom': '-20px',
        }"
      >
        さっそくログインしてレッスンを予約してみましょう！
      </p>
    </v-row>
    <!-- 登録ボタン -->
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="6" md="6" lg="6">
        <v-btn
          color="primary"
          tile
          elevation="0"
          class="rounded-sm"
          :style="{
            'font-size': '1em',
            'margin-bottom': '50px',
          }"
          block
          @click="toLogin"
          height="44px"
        >
          <p :style="{ fontWeight: 'bold' }">ログイン画面へすすむ</p>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from "../Atoms/PageTitle";

export default {
  name: 'CompletedAccountRegistration',
  props: [],
  components: {
    PageTitle
  },
  data: () => ({

  }),
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    toLogin() {
      this.$router.push({name: 'Login'})
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  white-space: pre-line;
}
</style>
