<template>
  <VmDialogCard
    :title="$t('lessonRequest.dialog.title')"
    :description="$t('lessonRequest.dialog.description')"
    :buttonTitle="$t('lessonRequest.dialog.buttonTitle')"
    :cancelButtonTitle="$t('labels.close')"
    @click="completeRequest"
    @close="close"
    :disabled="requestInfo.status === $t('lessonRequest.completed')"
  >
    <template #cardContent>
      <VmDialogSubTitle :text="$t('top.studentInfo')" class="subTitle" />
      <VmStudentInfo :student="student" :style="{ 'margin-top': '5px' }" />
      <div class="request__date">
        <p class="request__content">
          {{ requestedTime }}
        </p>
      </div>
      <VmDialogSubTitle
        :text="$t('lessonRequest.preferredDate')"
        class="subTitle"
      />
      <p class="request__content">
        {{ $t("lessonRequest.timeZone") }}: {{ requestInfo.timeZone }} <br />
        {{ requestInfo.preferredDate }}
      </p>
      <VmDialogSubTitle :text="$t('lessonRequest.message')" class="subTitle" />
      <p class="request__content">
        {{ requestInfo.message }}
      </p>
    </template>
  </VmDialogCard>
</template>
<script>
import VmDialogCard from "../Molecules/VmDialogCard";
import VmDialogSubTitle from "../Atoms/VmDialogSubTitle";
import VmStudentInfo from "../Molecules/VmStudentInfo";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LessonRequestDetailCard",
  components: {
    VmDialogCard,
    VmDialogSubTitle,
    VmStudentInfo,
  },
  data: () => ({}),
  props: {
    requestInfo: { type: Object, default: () => {} },
  },
  created() {
    this.fetchStudentInfo({
      studentId: this.requestInfo.studentId,
      accessToken: this.userInfo.accessToken,
    });
  },
  computed: {
    ...mapGetters({
      student: "student/student",
      studentLoading: "student/loading",
      userInfo: "user/getUserInfo",
    }),
    requestedTime() {
      return this.$moment(this.requestInfo.createdAt).format(
        "YYYY/MM/DD HH:mm"
      );
    },
  },
  methods: {
    ...mapActions({
      fetchStudentInfo: "student/fetchStudentInfo",
    }),
    completeRequest() {
      this.$emit("complete");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}
.subTitle {
  margin-top: 32px;
  &:first-child {
    margin-top: 0;
  }
}
.request {
  &__date {
    display: flex;
    justify-content: flex-end;
    font-size: 0.8em;
  }
  &__content {
    font-weight: bold;
  }
}
</style>
