<template>
  <v-container>
    <!-- タイトル -->
    <PageTitle title-name="生徒への請求金額一覧" />
    <VmBox>
      <template #header>
        <VueJsonToCsv
          :json-data="downloadedAllListData"
          :csv-title="computedAllListCSVTitle"
        >
          <v-btn class="csv-button"> 請求金額一覧ダウンロード </v-btn>
        </VueJsonToCsv>
        <VueJsonToCsv
          :json-data="downloadedSumListData"
          :csv-title="computedSumListCSVTitle"
        >
          <v-btn class="csv-button" @click="downloadSumList">
            生徒別合計一覧ダウンロード
          </v-btn>
        </VueJsonToCsv>
        <!-- 月変更できるところ -->
        <v-row class="tool-area">
          <v-btn fab text small color="grey darken-2" @click="prevMonth">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ toJaSelectedMonth }}
          </v-toolbar-title>
          <v-btn fab text small color="grey darken-2" @click="nextMonth">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
        <!-- 月ごとの合計金額 -->
        <v-row class="tool-area">
          <p class="tool-area-title">合計金額</p>
        </v-row>
        <v-row class="tool-area">
          <div v-if="isLoading">
            <vue-loading
              type="spin"
              color="#DCC268"
              :size="{ width: '20px', height: '20px' }"
            ></vue-loading>
          </div>
          <p v-else>{{ computedTotal.toLocaleString() }}円</p>
        </v-row>
        <v-row class="tool-area">
          <p class="tool-area-title">ソート条件</p>
        </v-row>
        <v-row class="tool-area">
          <v-col cols="6" xs="6" sm="4" md="4" lg="4">
            <p class="studet-order-text">生徒番号順:</p>
            <v-select
              :items="studentNumReverseOrderItems"
              v-model="computedOrder"
              label="選択してください"
              solo
              hide-details
              @change="reload"
            ></v-select>
          </v-col>
          <v-col cols="6" xs="6" sm="4" md="4" lg="4">
            <p class="studet-order-text">生徒名:</p>
            <v-text-field
              v-model="sortedStudentName"
              @change="sortList"
            ></v-text-field>
          </v-col>
          <v-col cols="6" xs="6" sm="4" md="4" lg="4">
            <p class="studet-order-text">講師名:</p>
            <v-text-field
              v-model="sortedTeacherName"
              @change="sortList"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template #content>
        <!-- 各人の請求金額一覧 -->
        <v-container class="payment-list-area" v-if="studentLessonList.length > 0">
          <v-container
            v-for="(studentLesson, index) of studentLessonList"
            :key="studentLesson.studentId"
          >
            <v-row>
              <v-col>
                <!-- 生徒番号 -->
                {{ studentLesson.studentIdNum }} -
                <!-- 名前 -->
                {{ studentLesson.studentName }}さん -
                <!-- 請求金額 -->
                請求金額:
                {{
                  computedTotalPayment(
                    studentLesson.lessonForPaymentList
                  ).toLocaleString()
                }}円
                <!-- 「詳細を見る」ボタン -->
                <v-btn
                  color="primary"
                  text
                  @click="openDetail(index)"
                  :style="{ position: 'relative', right: '10px', bottom: '2px' }"
                >
                  詳細を見る
                  <v-icon small> mdi-chevron-down </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- 請求詳細テーブル -->
            <v-data-table
              :headers="headers"
              :items="studentLesson.lessonForPaymentList"
              disable-sort
              disable-pagination
              no-data-text="担当したレッスンはありません"
              hide-default-footer
              v-show="isShowDataTable(index)"
              class="elevation-1 overflow-y-auto"
              :style="{ 'max-height': '400px' }"
            >
            </v-data-table>
          </v-container>
        </v-container>
        <v-container class="payment-list-area" v-else>
          <div v-if="isLoading">
            <vue-loading
              type="spin"
              color="#DCC268"
              :size="{ width: '50px', height: '50px' }"
            ></vue-loading>
          </div>
          <v-row justify="center" v-else>
            <p class="no-data-text">表示する請求金額データがありません</p>
          </v-row>
        </v-container>
      </template>
    </VmBox>
  </v-container>
</template>

<script>
import PageTitle from "../Atoms/PageTitle";
import VmBox from '../Atoms/VmBox'

export default {
  name: 'AdminChargeForStudentList',
  components: {
    PageTitle,
    VmBox
  },
  data: () => ({
    selectedMonth: "",
    userInfo: null,
    headers: [
      { text: "開始時間", value: 'startTime' },
      { text: "終了時間", value: 'endTime' },
      { text: '講師', value: 'teacherName' },
      { text: 'レッスンコース', value: 'lessonCourseName' },
      { text: '生徒属性', value: 'studentAttribute' },
      { text: '初回レッスン', value: 'isFirstLesson' },
      { text: '単価（30分）', value: 'unitPrice' },
      { text: '時間（分）', value: 'lessonTime' },
      { text: 'レッスンステータス', value: 'lessonStatus' },
      { text: '金額', value: 'amount' },
    ],
    fetchedThisMonthLessons:[],
    studentLessonList:[],
    openedIndexArray: [],
    downloadedAllListData: [],
    downloadedSumListData: [],
    studentNumReverseOrder: false, //false → 昇順, true → 降順,
    studentNumReverseOrderItems: ["昇順", "降順"],
    sortedStudentName: "",
    sortedTeacherName: "",
    isLoading: false
  }),
  computed: {
    toJaSelectedMonth() {
      return this.$moment(this.selectedMonth).format("YYYY年 MM月")
    },
    isShowDataTable: function() {
      return function(index) {
        if (this.openedIndexArray.indexOf(index) != -1) {
          return true
        } else {
          return false
        }
      }
    },
    // 合計金額を算出
    computedTotal: function() {
      let amount = 0
        for (let studentLesson of this.studentLessonList) {
          amount += this.computedTotalPayment(studentLesson.lessonForPaymentList)
        }
      return amount
    },
    // 生徒ごとの合計金額を算出
    computedTotalPayment: function() {
      return function(lessonForPaymentList) {
        let amount = 0
        for (let lesson of lessonForPaymentList) {
          amount = amount + lesson.amount
        }
        return amount
      }
    },
    toJaLessonCourseName: function() {
      return function(lessonCourseName) {
        switch(lessonCourseName) {
          case "CONVERSATION":
            return "会話"
          case "PRIVATE":
            return "プライベート"
          default:
            return ""
        }
      }
    },
    toJaStudentAttribute: function() {
      return function(studentAttribute) {
        switch(studentAttribute) {
          case "ADULT":
            return "大人"
          case "STUDENT":
            return "学生"
          case "CHILD":
            return "子ども"
          default:
            return ""
        }
      }
    },
    toStringIsFirstLesson: function() {
      return function(isFirstLesson) {
        if (isFirstLesson) {
          return "初"
        } else {
          return ""
        }
      }
    },
    // 生徒属性や初回レッスンかどうかなどから単価を算出
    computedUnitPrice: function() {
      return function (isFirstLesson, studentAttribute, feeForFirstLesson, feeForStudent, feeForRegular) {
        if (isFirstLesson) {
          return feeForFirstLesson
        }
        switch(studentAttribute) {
          case "ADULT":
            return feeForRegular
          case "STUDENT":
            return feeForStudent
          case "CHILD":
            return feeForRegular
        }
      }
    },
    // レッスン時間を算出
    computedLessonTime: function() {
      return function(startTime, endTIme) {
        const start = this.$moment(startTime)
        const end = this.$moment(endTIme)
        return this.$moment(end).diff(start, 'minutes')
      }
    },
    toStringLessonStatus: function() {
      return function(lessonStatus) {
        switch(lessonStatus) {
          case 0:
            return "未"
          case 1:
            return "完了"
          case 2:
            return "キャンセル"
        }
      }
    },
    computedAmount: function() {
      return function(unitPrice, lessonTime, lessonStatus) {
        let amount = 0
        switch(lessonStatus) {
          // 未
          case 0:
            return 0
          // 完了
          case 1:
            amount = lessonTime / 30 * unitPrice
            return Math.round(amount)
          // キャンセル
          case 2:
            amount = lessonTime / 30 * unitPrice / 2 //キャンセル時の料金は50%
            return Math.round(amount)
        }
      }
    },
    computedAllListCSVTitle() {
      return this.$moment(this.selectedMonth).format("YYYY_MM_") + "生徒への請求一覧"
    },
    computedSumListCSVTitle() {
      return this.$moment(this.selectedMonth).format("YYYY_MM_") + "生徒別合計一覧"
    },
    computedOrder: {
      get() {
        if (this.studentNumReverseOrder) {
          return "降順"
        } else {
          return "昇順"
        }
      },
      set(value) {
        switch (value) {
          case "昇順" :
            this.studentNumReverseOrder = false
            break;
          case "降順":
            this.studentNumReverseOrder = true
            break;
        }
      }
    },
  },
  async created() {
    this.fetchInitialData()
    this.initSelectedMonth()
    try {
      await this.fetchThisMonthLessons()
    } catch(error) {
      console.log("error", error)
    }

    this.divideLessonsByStudent()

  },
  methods: {
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    initSelectedMonth() {
      this.selectedMonth = this.$moment()
    },
    // 月を変える時とかに使用される関数
    async updateLessonList() {
      try {
        await this.fetchThisMonthLessons()
      } catch(error) {
        console.log("error", error)
      }

      this.divideLessonsByStudent()

      // ソート条件を残しておく
      this.sortList()
      this.openedIndexArray = []
    },
    // 当月のlessonを全て取得する(teacherTB・lessonCourseTBあたりの情報も合体させて)
    fetchThisMonthLessons(studentName, teacherName) {
      this.isLoading = true
      this.studentLessonList = []

      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`
          }
        };
        const statTime = this.$moment(this.selectedMonth).startOf('month').format("YYYY-MM-DD HH:mm")
        const endTime =  this.$moment(this.selectedMonth).endOf('month').format("YYYY-MM-DD HH:mm")

        this.axios
          .get(`/api/lessons/listForPayment?startTime=${statTime}&endTime=${endTime}&studentName=${studentName}&teacherName=${teacherName}&lessonCourseId=2`, header)
          .then(response => {
            this.fetchedThisMonthLessons = response.data.lessons
            this.isLoading = false
            resolve()
          })
          .catch(error => {
            console.log(error);
            reject()
          });
      })
    },

    // 生徒ごとに仕分ける
    divideLessonsByStudent() {
      this.studentLessonList = []
      this.downloadedAllListData = []

      // 今月の請求がある生徒一覧の配列を作成する
      let requiredPaymentStudents = []
      let studentIds = [] //被りを排除するためだけに使う
      for (let thisMonthLesson of this.fetchedThisMonthLessons) {
        const requiredPaymentStudent = {
          id: thisMonthLesson["Student.id"],
          studentIdNum: thisMonthLesson["Student.studentIdNum"],
          name: thisMonthLesson["Student.name"],
          email: thisMonthLesson["Student.email"]
        }

        // 重複を弾くためのif文
        // Array.indexOf()は該当がなかったら -1になる
        if (studentIds.indexOf(requiredPaymentStudent.id) === -1) {
          requiredPaymentStudents.push(requiredPaymentStudent)
          studentIds.push(thisMonthLesson["Student.id"])
        }
      }

      // 生徒番号順に並び替える
      requiredPaymentStudents = this.arrangeStudentsWithNum(requiredPaymentStudents)

      // 請求がある生徒一覧をfor文で回してその生徒ごとのデータを取得する
      for (let student of requiredPaymentStudents) {
        const lessonForPaymentList = []
        for (let thisMonthLesson of this.fetchedThisMonthLessons) {
          if (student.id === thisMonthLesson["Student.id"]) {
            const startTime = this.$moment(thisMonthLesson.startTime).format("YYYY/MM/DD HH:mm")
            const endTime = this.$moment(thisMonthLesson.endTime).format("YYYY/MM/DD HH:mm")
            const isFirstLesson = thisMonthLesson.isFirstLesson
            const studentAttribute = thisMonthLesson["Student.category"]
            const feeForFirstLesson = thisMonthLesson["Teacher.feeForFirstLesson"]
            const feeForStudent = thisMonthLesson["Teacher.feeForStudent"]
            const feeForRegular = thisMonthLesson["Teacher.feeForRegular"]
            const lessonStatus = thisMonthLesson.status
            const unitPrice = this.computedUnitPrice(isFirstLesson, studentAttribute, feeForFirstLesson, feeForStudent, feeForRegular)
            const lessonTime = this.computedLessonTime(startTime, endTime)
              const lessonForPaymentItem = {
                startTime: startTime,
                endTime: endTime,
                teacherName: thisMonthLesson["Teacher.teacherName"],
                teacherSystemId: thisMonthLesson["Teacher.id"],
                studentName: thisMonthLesson["Student.name"],
                studentEmail: thisMonthLesson["Student.email"],
                studentSystemId: thisMonthLesson["Student.id"],
                studentVollmondNumber: thisMonthLesson["Student.studentIdNum"],
                lessonCourseName: this.toJaLessonCourseName(thisMonthLesson["LessonCourse.courseName"]),
                studentAttribute: this.toJaStudentAttribute(studentAttribute),
                isFirstLesson: this.toStringIsFirstLesson(isFirstLesson),
                feeForFirstLesson: feeForFirstLesson,
                feeForStudent: feeForStudent,
                feeForRegular: feeForRegular,
                unitPrice: unitPrice,
                lessonTime: lessonTime,
                lessonStatus: this.toStringLessonStatus(lessonStatus),
                amount: this.computedAmount(unitPrice, lessonTime, lessonStatus)
              }
              lessonForPaymentList.push(lessonForPaymentItem)

              // CSV出力用に入れておく
              this.downloadedAllListData.push(lessonForPaymentItem)
            }
          }
        const studentLessonItem = {
          studentName: student.name,
          studentIdNum: student.studentIdNum,
          studentId: student.id,
          studentEmail: student.email,
          lessonForPaymentList: lessonForPaymentList
        }
        this.studentLessonList.push(studentLessonItem)
      }
    },

    arrangeStudentsWithNum(students) {
      students.sort((a, b)=> {
        if (this.studentNumReverseOrder) {
          // 降順
          if (a.studentIdNum > b.studentIdNum) {
            return -1
          } else {
            return 1
          }
        } else {
          // 昇順
          if (a.studentIdNum > b.studentIdNum) {
            return 1
          } else {
            return -1
          }
        }
      })

      return students
    },

    prevMonth() {
      this.selectedMonth = this.$moment(this.selectedMonth).subtract(1, 'months')
      this.updateLessonList()
    },

    nextMonth() {
      this.selectedMonth = this.$moment(this.selectedMonth).add(1, 'months')
      this.updateLessonList()
    },

    openDetail(index) {
      if (this.isShowDataTable(index)) {
        // 既に開いている場合はindexを消す
        const deletedIndex = this.openedIndexArray.indexOf(index)
        this.openedIndexArray.splice(deletedIndex, 1)
      } else {
        // まだ開かれていない時はindexを追加する
        this.openedIndexArray.push(index)
      }
    },
    reload() {
      this.divideLessonsByStudent()
    },
    async sortList() {
      try {
        await this.fetchThisMonthLessons(this.sortedStudentName, this.sortedTeacherName)
      } catch(error) {
        console.log("error", error)
      }

      this.divideLessonsByStudent()
    },
    downloadSumList() {
      this.downloadedSumListData = []

      for (let studentLesson of this.studentLessonList) {
        const downloadedSumItem = {
          studentSystemId: studentLesson.studentId,
          studentVollmondNumber: studentLesson.studentIdNum,
          studentName: studentLesson.studentName,
          studentEmail: studentLesson.studentEmail,
          totalReward: this.computedTotalPayment(studentLesson.lessonForPaymentList)
        }
        this.downloadedSumListData.push(downloadedSumItem)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.payment-list-area {
  min-height: 700px;
}
.no-data-text {
  margin: 80px 0 0 0;
}
.csv-button {
  margin: 15px;
}
.studet-order-text {
  margin: 15px 15px 0 0;
}
.tool-area {
  padding: 20px 20px 0 20px;
}
.tool-area-title {
  margin: 0;
  font-weight: bold;
  font-size: 1.5em;
}
</style>
