<template>
  <VmDialogCard
    :title="isFirstLesson ? '初回レッスン予約' : 'レッスン予約'"
    buttonTitle="予約完了する"
    @click="reserve"
    @close="cancel"
    :disabled="!buttonValidation || loading"
  >
    <template #cardContent>
      <v-container>
        <v-row>
          <p class="report-subtitle">レッスン情報</p>
        </v-row>
        <v-row>
          <p>
            ◎担当講師:
            <span>{{ teacherName }} 先生</span>
          </p>
        </v-row>
        <v-row>
          <p>
            ◎受講コース:
            <span>{{ courseName }}</span>
          </p>
        </v-row>
        <SharedStudentInfo />
      </v-container>
      <v-container>
        <v-row>
          <p class="report-subtitle">レッスン時間</p>
          <TimeZoneIcon />
        </v-row>
        <v-row>
          <p v-if="isFirstLesson">※初回授業は30分です</p>
        </v-row>
        <div class="date-form-area" v-if="isFormShow">
          <!-- 開始日時のフォーム -->
          <DateAndTimeForm
            :formLabelText="$t('teacherSchedule.startTime')"
            :date.sync="lessonStartDate"
            :showedDate="computedLessonStartDate"
            :minDate="selectableStartMinDate"
            :maxDate="selectableStartMaxDate"
            @closeDatePicker="inputEndDate"
            :time.sync="lessonStartTime"
            :minuteInterval="5"
            :minTime="selectableStartMinTime"
            :maxTime="selectableStartMaxTime"
            @closeTimePicker="inputEndTime"
          />
          <!-- 終了日時のフォーム -->
          <DateAndTimeForm
            :formLabelText="$t('teacherSchedule.endTime')"
            :date.sync="lessonEndDate"
            :showedDate="computedLessonEndDate"
            :minDate="selectableStartMinDate"
            :maxDate="selectableStartMaxDate"
            :time.sync="lessonEndTime"
            :minuteInterval="5"
            :minTime="selectableEndMinTime"
            :maxTime="selectableEndMaxTime"
            v-if="!isFirstLesson"
          />
          <v-row v-else>
            <v-col cols="3" xs="3" sm="2" md="2" lg="2">
              <p>終了：</p>
            </v-col>
            <v-col cols="9" xs="9" sm="10" md="10" lg="10" class="end-time">
              <p>{{ computedEndTime }}</p>
            </v-col>
          </v-row>
        </div>
        <v-row v-if="!buttonValidation">
          <v-col class="validation-text-col">
            <p class="validation-text">
              {{
                !totalTimevalidation
                  ? "予約できる時間は30分、1時間、1時間半です。"
                  : "講師予定内の時刻を選択してください。"
              }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <p class="report-subtitle">先生へのメッセージ</p>
        </v-row>
        <v-row>
          <v-textarea
            v-model="messageForTeacher"
            :placeholder="messageForTeacherLabel"
            solo
            counter
          ></v-textarea>
        </v-row>
        <p :style="{ 'margin-left': '-10px' }">
          ネイティブ講師の場合はドイツ語での記入を推奨します。
        </p>
      </v-container>
    </template>
  </VmDialogCard>
</template>

<script>
import TimeZoneIcon from "../Atoms/TimeZoneIcon";
import DateAndTimeForm from "../Molecules/DateAndTimeForm";
import VmDialogCard from "../Molecules/VmDialogCard";
import SharedStudentInfo from "../Molecules/SharedStudentInfo";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PrivateReservationFormCard",
  inject: ["reloadHeader"],
  components: {
    TimeZoneIcon,
    DateAndTimeForm,
    VmDialogCard,
    SharedStudentInfo,
  },
  data: () => ({
    textFontWeight: "bold",
    lessonStartDate: "2020-12-01",
    lessonEndDate: "2020-12-01",
    lessonStartTime: "00:00",
    lessonEndTime: "00:00",
    startDate: "2020-12-01",
    endDate: "2020-12-01",
    messageForTeacher: "",
    isFormShow: true,
  }),

  props: [
    "teacherName",
    "courseName",
    "startTime",
    "endTime",
    "selectedOpen",
    "studentId",
    "teacherId",
    "lessonCourseId",
    "isFirstLesson",
  ],

  computed: {
    ...mapGetters({
      userInfo: "user/getUserInfo",
      loading: "reserve/loading",
    }),
    selectableStartMinDate() {
      return this.$moment(this.startTime).format("YYYY-MM-DD");
    },
    selectableStartMaxDate() {
      return this.$moment(this.endTime).format("YYYY-MM-DD");
    },
    selectableStartMinTime() {
      // ①選択範囲の開始日と同じ場合は"this.startTime"の時刻が最小
      // ②それ以外の場合は00:00

      if (this.lessonStartDate === this.startDate) {
        return this.$moment(this.startTime).format("HH:mm");
      } else {
        return "00:00";
      }
    },
    selectableStartMaxTime() {
      // ①選択範囲の終了日と同じ場合は"this.endTime"の時刻が最大
      // ②それ以外の場合は24:00

      if (this.lessonStartDate === this.endDate) {
        return this.$moment(this.endTime).format("HH:mm");
      } else {
        return "24:00";
      }
    },
    selectableEndMinTime() {
      // ①選択範囲の開始日と同じ場合は"this.startTime"の時刻が最小
      // ②それ以外の場合は00:00

      if (this.lessonEndDate === this.startDate) {
        return this.$moment(this.startTime).format("HH:mm");
      } else {
        return "00:00";
      }
    },
    selectableEndMaxTime() {
      // ①選択範囲の終了日と同じ場合は"this.endTime"の時刻が最大
      // ②それ以外の場合は24:00

      if (this.lessonEndDate === this.endDate) {
        return this.$moment(this.endTime).format("HH:mm");
      } else {
        return "24:00";
      }
    },
    computedStartTime() {
      const start = this.lessonStartDate + " " + this.lessonStartTime;
      return this.$moment(start).format("YYYY-MM-DD HH:mm");
    },
    computedEndTime() {
      if (this.isFirstLesson) {
        return this.$moment(this.computedStartTime)
          .add(30, "m")
          .format("YYYY-MM-DD HH:mm");
      } else {
        const end = this.lessonEndDate + " " + this.lessonEndTime;
        return this.$moment(end).format("YYYY-MM-DD HH:mm");
      }
    },
    // バリデーション - 選択した時間が1時間半以下になるようにする
    totalTimevalidation() {
      const start = this.$moment(this.computedStartTime);
      const end = this.$moment(this.computedEndTime);
      const lessonTime = this.$moment(end).diff(start, "minutes");

      // 30分で割り切れなかったらアウト
      if (lessonTime % 30 != 0) {
        return false;
      }

      const numberOfTableItem = lessonTime / 30;

      // 開始が終了より遅い時間ならアウト
      if (this.$moment(end).isBefore(this.$moment(start))) {
        return false;
      }

      // 0分の場合アウト
      if (numberOfTableItem === 0) {
        return false;
      }

      // 2時間以上の場合アウト
      if (numberOfTableItem > 3) {
        return false;
      }
      return true;
    },
    timeRangevalidation() {
      // 講師予定の開始時刻 < 開始時刻となっているか
      if (
        !this.$moment(this.computedStartTime).isSameOrAfter(
          this.$moment(this.startTime)
        )
      ) {
        return false;
      }
      // 終了時刻 < 講師予定の終了時刻となっているか
      if (
        !this.$moment(this.endTime).isSameOrAfter(
          this.$moment(this.computedEndTime)
        )
      ) {
        return false;
      }

      return true;
    },
    buttonValidation() {
      // バリデーション - 指定した時間が講師予定の範囲に収まっているかどうか判定
      if (!this.timeRangevalidation) {
        return false;
      }

      // バリデーション - 選択した時間が1時間半以下になるようにする
      if (!this.totalTimevalidation) {
        return false;
      }
      return true;
    },
    computedLessonStartDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.lessonStartDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.lessonStartDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
    computedLessonEndDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.lessonEndDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.lessonEndDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
    messageForTeacherLabel() {
      if (this.isFirstLesson) {
        return "初めまして、○○と申します。\n文法と会話を中心にしたレッスンを希望します。過去に少し勉強していたことがありますがほぼ完全初心者です。日本語での説明を希望します。\n初回レッスンを楽しみにしています！";
      } else {
        return "レッスンで行いたい具体的な希望がある場合記入してください。";
      }
    },
  },
  mounted() {
    this.initLessonStartDate();
    this.initSelectedScheduleStartDate();
  },
  methods: {
    ...mapActions({
      privateReserve: "reserve/privateReserve",
    }),
    initLessonStartDate() {
      this.lessonStartDate = this.$moment(this.startTime).format("YYYY-MM-DD");
      this.lessonEndDate = this.$moment(this.startTime).format("YYYY-MM-DD");
      this.lessonStartTime = this.$moment(this.startTime).format("HH:mm");
      this.lessonEndTime = this.$moment(this.startTime).format("HH:mm");
    },
    initSelectedScheduleStartDate() {
      this.startDate = this.$moment(this.startTime).format("YYYY-MM-DD");
      this.endDate = this.$moment(this.endTime).format("YYYY-MM-DD");
    },
    async reserve() {
      if (
        confirm(
          `【予約確認】\n 予約を完了しますか？ \n 担当講師：${this.teacherName}先生 \n コース名：プライベートコース \n レッスン開始時刻： ${this.computedStartTime} \n レッスン終了時刻： ${this.computedEndTime} `
        )
      ) {
        const reserveData = {
          studentId: this.studentId,
          teacherId: this.teacherId,
          lessonCourseId: this.lessonCourseId,
          startTime: this.$moment(this.computedStartTime).format(),
          endTime: this.$moment(this.computedEndTime).format(),
          messageForTeacher: this.messageForTeacher,
          status: 0,
          isFirstLesson: this.isFirstLesson,
        };

        const isSuccess = await this.privateReserve({
          accessToken: this.userInfo.accessToken,
          reserveData: reserveData,
        });

        if (isSuccess) {
          this.cancel();
          this.$router.push({
            name: "CompletePrivateReservation",
            params: {
              teacherId: this.teacherId,
            },
          });
        }
      }
    },
    cancel() {
      this.$emit("cancelAction");
    },
    inputEndDate(val) {
      this.lessonEndDate = val;
    },
    inputEndTime(val) {
      // 開始時刻のtimepickerが入力された後の挙動

      // 入力されたstartTimeの時間を分解
      const valHour = Number(val.split(":")[0]);
      const valMinute = Number(val.split(":")[1]);
      const afterOneHourValTime = (valHour + 1) * 60 * 60 + valMinute * 60;

      // 入力可能な最大時間を分解
      const selectableMaxHour = Number(this.selectableEndMaxTime.split(":")[0]);
      const selectableMaxMinute = Number(
        this.selectableEndMaxTime.split(":")[1]
      );
      const selectableMaxTime =
        selectableMaxHour * 60 * 60 + selectableMaxMinute * 60;

      if (afterOneHourValTime > selectableMaxTime) {
        // 1時間後が指定可能な時刻を超えてしまう場合
        // → 選択可能時刻の最大値を入れる
        this.lessonEndTime = this.selectableEndMaxTime;
      } else {
        // それ以外の場合は1時間プラスした時間を入れる
        let strValHour = String(valHour + 1);
        if (valHour + 1 < 10) {
          strValHour = "0" + (valHour + 1);
        }

        let strValMinute = String(valMinute);
        if (valMinute < 10) {
          strValMinute = "0" + valMinute;
        }
        this.lessonEndTime = strValHour + ":" + strValMinute;
      }
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
.scroll {
  overflow-y: auto;
}
.report-subtitle {
  font-size: 1.4em;
  font-weight: bold;
  margin: 20px 0 15px 0;
}
.validation-text-col {
  padding: 0 0 0 12px;
}
.validation-text {
  color: red;
  margin: 0;
}
.date-form-area {
  margin: 20px 0 0 0;
}
</style>
