<template>
  <!-- 外部ライブラリをラッピング -->
  <div class="my-time-picker">
    <VueTimepicker
      lazy
      :format="format"
      v-model="inputedTime"
      close-on-complete
      hide-clear-button
      :minute-interval="minuteInterval"
      :hour-range="computedHourRange"
      :minute-range="computedMinuteRange"
      @input="closeTimePicker"
      @change="changeTimePicker"
      hour-label="Stunde"
      minute-label="Minute"
    />
  </div>
</template>

<script>
export default {
  name: "TimePicker",
  data: () => ({}),
  props: {
    value: String,
    minuteInterval: Number,
    format: {
      type: String,
      default: "HH:mm",
    },
    minTime: String,
    maxTime: String,
  },
  computed: {
    inputedTime: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    computedHourRange() {
      let tmpMaxTime = this.maxTime;

      // if (this.maxTime === "00:00") {
      //   tmpMaxTime = "23:00" // "24:00"にしてしまうと0時が含まれてしまう
      // }

      if (!this.minTime && !this.maxTime) {
        // 講師がスケジュールを入れるときは基本こっち
        return [[0, 24]];
      }
      const minHour = Number(this.minTime.split(":")[0]);
      const maxHour = Number(tmpMaxTime.split(":")[0]);
      return [[minHour, maxHour]];
    },
    computedMinuteRange() {
      if (!this.minTime && !this.maxTime) {
        // 講師がスケジュールを入れるときは基本こっち
        if (this.inputedTime === "24:00") {
          // 24:30みたいな値を入れられないようにする
          return [[0, 0]];
        } else {
          return [[0, 60]];
        }
      }
      const minHour = Number(this.minTime.split(":")[0]);
      const minMinute = Number(this.minTime.split(":")[1]);
      const maxHour = Number(this.maxTime.split(":")[0]);
      const maxMinute = Number(this.maxTime.split(":")[1]);
      const inputedHour = Number(this.inputedTime.split(":")[0]);

      if (inputedHour === minHour && inputedHour === maxHour) {
        return [[minMinute, maxMinute]];
      }

      if (inputedHour === minHour) {
        return [[minMinute, 60]];
      }

      if (inputedHour === maxHour) {
        return [[0, maxMinute]];
      }
      return [[0, 60]];
    },
  },
  methods: {
    closeTimePicker(val) {
      this.$emit("closeTimePicker", val);
    },
    changeTimePicker() {
      this.$emit("changeTimePicker");
    },
  },
};
</script>

<style scoped>
.my-time-picker >>> .vue__time-picker .dropdown ul li:not([disabled]).active,
.my-time-picker
  >>> .vue__time-picker
  .dropdown
  ul
  li:not([disabled]).active:focus,
.my-time-picker
  >>> .vue__time-picker
  .dropdown
  ul
  li:not([disabled]).active:hover {
  background: #dcc268;
}
.vue__time-picker {
  width: 100%;
}
.my-time-picker >>> .vue__time-picker input.display-time {
  padding: 27px 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  border-color: rgba(0, 0, 0, 0.38);
  font-family: Roboto, sans-serif;
}
/* ホバー時の挙動（v-text-fieldを頑張って真似た） */
.my-time-picker >>> .vue__time-picker input.display-time:hover {
  border-color: rgba(0, 0, 0, 0.87);
  transition: border-color 0.3s;
}
/* dropdownのタイトルを消す */
.my-time-picker >>> .vue__time-picker .dropdown {
  margin-top: 25px;
}
.my-time-picker >>> .vue__time-picker .dropdown .hint {
  display: none;
}
</style>
