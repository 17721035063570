<template>
  <v-row justify="center">
    <BaseHeading heading-text="少人数コース" />
  </v-row>
</template>

<script>
import BaseHeading from '../Atoms/BaseHeading';

export default {
  name: 'SmallGroupHeading',
  data: () => ({
  }),
  components: {
    BaseHeading
  },
  methods: {
  },
  head: {
    link: [
      {rel: 'stylesheet', href: 'https://use.fontawesome.com/releases/v5.6.1/css/all.css'}
    ]
  }
};
</script>

<style lang="scss" scoped>
h2:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0c0";
}
</style>

