<template>
  <div>
    <p>
      <FormTitleText titleText="【重要】キャンセルポリシー" />
      <RequiredIcon v-if="rules && rules.includes('required')" />
    </p>
    <v-textarea
      :value="content"
      placeholder=""
      outlined
      background-color="white"
      readonly
    ></v-textarea>
    <validationProvider
      :rules="{ required: { allowFalse: false } }"
      v-slot="{ errors }"
      name="キャンセルポリシーへの同意"
    >
      <v-checkbox
        v-model="isChecked"
        label="キャンセルポリシーに同意する"
        value="キャンセルポリシーに同意する"
        color="#DCC268"
        hide-details
        @change="check"
        :style="{ margin: '-20px 0 0 0' }"
      ></v-checkbox>

      <FormErrorText :errorText="errors[0]" />
    </validationProvider>
  </div>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText';
import FormErrorText from '../Atoms/FormErrorText';
import RequiredIcon from '../Atoms/RequiredIcon';

export default {
  name: 'CancelPolicyForm',
  data: () => ({
    content: 'レッスン開始時刻の【48時間前】に予約を確定させていただきます。それ以降のキャンセル・時間変更(例：予定1時間→実施30分)につきましては、以下の通りそれぞれ請求させていただきます。\n\n・キャンセル：予定時間分の50%の料金\n・時間変更：予定時間分の料金\n\n詳しくは: https://vollmond.online/cancel-policy/\n',
    isChecked: false
  }),
  props: ['rules'],
  mounted() {
  },
  computed: {
  },
  methods: {
    check() {
      this.$emit('input', this.isChecked)
    }
  },
  components: {
    FormTitleText,
    FormErrorText,
    RequiredIcon
  }
};
</script>

<style lang="scss" scoped>
</style>
