<template>
  <div>
    <!-- タイトル -->
    <PageTitle title-name="紐付け一覧" />
    <VmBox>
      <template #header>
        <v-btn color="primary" dark class="mb-2" @click="openAddForm()">
          新規紐付け追加
        </v-btn>
      </template>
      <template #content>
        <!-- 各人の紐付け一覧 -->
        <div
          class="regular-schedule-list-area"
          v-if="connectionDataList.length > 0"
          :style="{ padding: '15px 15px' }"
        >
          <div
            v-for="(connectionData, index) of connectionDataList"
            :key="connectionData.teacherId"
          >
            <v-row>
              <v-col cols="12" xs="6" sm="6" md="3" lg="3">
                <!-- 講師番号 -->
                {{ connectionData.teacherId }} -
                <!-- 名前 -->
                {{ connectionData.teacherName }} 先生
                <!-- 「詳細を見る」ボタン -->
                <v-btn
                  color="primary"
                  text
                  @click="openDetail(index)"
                  :style="{ position: 'relative', right: '10px', bottom: '2px' }"
                >
                  詳細を見る
                  <v-icon small> mdi-chevron-down </v-icon>
                </v-btn>
              </v-col>

              <!-- 簡易紐付けエリア -->
              <v-col cols="6" xs="6" sm="3" md="3" lg="3">
                <v-text-field
                  label="studentId"
                  color="#F0AF67"
                  v-model="connectionData.studentId"
                  outlined
                  background-color="white"
                  dense
                  class="login-text-field"
                  hide-details
                  width="50"
                ></v-text-field>
              </v-col>
              <v-col cols="6" xs="6" sm="3" md="3" lg="3">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  @click="link(connectionData.teacherId, connectionData.studentId)"
                >
                  紐付け
                </v-btn>
              </v-col>
              <v-spacer />
            </v-row>
            <!-- 紐付け詳細テーブル -->
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="connectionData.regularScheduleArray"
                  :class="{ 'elevation-1': true }"
                  disable-sort
                  disable-pagination
                  no-data-text="担当生徒はいません"
                  hide-default-footer
                  v-show="computedShowDataTable(index)"
                  :loading="isLoading"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="deleteRegularLesson(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:[`item.image`]="{ item }">
                    <img :src="item.image" class="table-img" />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="regular-schedule-list-area" v-else>
          <div v-if="isLoading">
            <vue-loading
              type="spin"
              color="#DCC268"
              :size="{ width: '50px', height: '50px' }"
            ></vue-loading>
          </div>
          <v-row justify="center" v-else>
            <p class="no-data-text">表示する紐付けデータがありません</p>
          </v-row>
        </div>
      </template>
    </VmBox>
    <v-dialog v-model="showAddDialog" v-if="showAddDialog" max-width="500px">
      <AdminAddFormCard
        @closeAction="closeAdd"
        @addAction="add"
        :editedItem="addedItem"
        formTitle="新規紐付け追加"
        :explanation="addRegularExplanation"
        :noImage="true"
      />
    </v-dialog>
  </div>
</template>

<script>
import AdminAddFormCard from "../Organisms/AdminAddFormCard";
import PageTitle from "../Atoms/PageTitle";
import VmBox from '../Atoms/VmBox'

export default {
  name: "AdminConnectionList",
  components: {
    AdminAddFormCard,
    PageTitle,
    VmBox
  },
  data: () => ({
    userInfo: null,
    connectionDataArray: [],
    connectionDataList: [],
    regularScheduleArray: [],
    selectedRegularScheduleId: null,
    deletedItem: {
      teacherId: null,
      studentId: null,
    },
    addedItem: {
      teacherId: null,
      studentId: null,
    },
    // フォームを閉じた時のリセットに使われている
    defaultItem: {
      teacherId: null,
      studentId: null,
    },
    showEditDialog: false,
    showAddDialog: false,
    openedIndexArray: [],
    addRegularExplanation: "",
    isShowDataTable: true,
    isLoading: false,
    linkedStudentId: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: "regularScheduleId",
          align: "start",
          value: "regularScheduleId",
        },
        {
          text: "teacherId",
          value: "teacherId",
        },
        {
          text: "講師名",
          value: "teacherName",
        },
        {
          text: "studentId",
          value: "studentId",
        },
        {
          text: "生徒名",
          value: "studentName",
        },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    computedShowDataTable: function () {
      return function (index) {
        if (!this.isShowDataTable) {
          return false;
        }
        if (this.openedIndexArray.indexOf(index) != -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  props: [],
  created() {
    this.fetchInitialData();

    this.reloadTable();
  },
  mounted() {},
  methods: {
    async reloadTable() {
      try {
        await this.fetchRegularSchedules();
      } catch (error) {
        console.log("error", error);
      }
      this.isShowDataTable = false;

      await this.$nextTick();
      this.connectionDataList = [];
      this.divideRegularScheduleArray();

      this.isShowDataTable = true;
    },
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    fetchRegularSchedules() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(`/api/regularSchedules/connections`, header)
          .then((response) => {
            this.connectionDataArray = response.data.connections;
            this.isLoading = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    // 講師ごとに仕分ける
    divideRegularScheduleArray() {
      this.regularScheduleArray = [];

      // 定期予定がある講師一覧を作成する
      let regularScheduleTeachers = [];
      let teacherIds = []; //被りを排除するためだけに使う
      for (let regularSchedule of this.connectionDataArray) {
        const regularScheduleTeacher = {
          id: regularSchedule.teacherId,
          name: regularSchedule["Teacher.teacherName"],
          email: regularSchedule["Teacher.email"],
        };

        // 重複を弾くためのif文
        // Array.indexOf()は該当がなかったら -1になる
        if (teacherIds.indexOf(regularScheduleTeacher.id) === -1) {
          regularScheduleTeachers.push(regularScheduleTeacher);
          teacherIds.push(regularSchedule.teacherId);
        }
      }

      // 生徒番号順に並び替える
      regularScheduleTeachers = this.arrangeTeacherWithId(
        regularScheduleTeachers
      );

      // 請求がある生徒一覧をfor文で回してその生徒ごとのデータを取得する
      for (let teacher of regularScheduleTeachers) {
        let regularScheduleArray = [];
        for (let regularSchedule of this.connectionDataArray) {
          if (teacher.id === regularSchedule.teacherId) {
            const regularScheduleData = {
              regularScheduleId: regularSchedule.id,
              teacherId: regularSchedule.teacherId,
              teacherName: regularSchedule["Teacher.teacherName"],
              studentId: regularSchedule.studentId,
              studentName: regularSchedule["Student.name"],
              lessonCourseId: regularSchedule.lessonCourseId,
            };
            regularScheduleArray.push(regularScheduleData);
          }
        }
        const teacherRegularScheduleItem = {
          teacherId: teacher.id,
          teacherName: teacher.name,
          regularScheduleArray: regularScheduleArray,
          studentId: null,
        };
        this.connectionDataList.push(teacherRegularScheduleItem);
      }
    },

    arrangeTeacherWithId(teachers) {
      teachers.sort((a, b) => {
        // 昇順
        if (a.id > b.id) {
          return 1;
        } else {
          return -1;
        }
      });

      return teachers;
    },

    openDetail(index) {
      if (this.computedShowDataTable(index)) {
        // 既に開いている場合はindexを消す
        const deletedIndex = this.openedIndexArray.indexOf(index);
        this.openedIndexArray.splice(deletedIndex, 1);
      } else {
        // まだ開かれていない時はindexを追加する
        this.openedIndexArray.push(index);
      }
    },
    addRegularSchedule(item) {
      this.addedItem = Object.assign({}, item);
      this.showAddDialog = true;
    },
    deleteRegularLesson(item) {
      this.deletedItem = Object.assign({}, item);
      this.selectedRegularScheduleId = item.regularScheduleId;

      if (
        confirm(
          `以下の予定を削除します \n講師:${this.deletedItem.teacherName} \n生徒:${this.deletedItem.studentName}`
        )
      ) {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        // 紐付けの場合はregularSchedulesのidで削除するだけ
        this.axios
          .delete(
            `/api/regularSchedules/${this.selectedRegularScheduleId}`,
            header
          )
          .then((response) => {
            console.log("response.data", response.data);
            this.reloadTable();
          })
          .catch((error) => {
            alert(error.response.data.error.message);
          });
      }
    },
    closeAdd() {
      this.showAddDialog = false;
      this.$nextTick(() => {
        this.addedItem = Object.assign({}, this.defaultItem);
      });
    },
    // 紐付けの追加
    add() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      this.axios
        .post(`/api/regularSchedules/connections`, this.addedItem, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.closeAdd();
          this.reloadTable();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    openAddForm(teacherId) {
      if (teacherId) {
        this.addedItem.teacherId = teacherId;
      }
      this.addedItem.lessonCourseId = 2;
      this.showAddDialog = true;
    },
    // 生徒と講師の紐付け
    link(teacherId, studentId) {
      this.isLoading = true;
      const linkRequestBody = {
        teacherId: teacherId,
        studentId: studentId,
      };
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      this.axios
        .post(`/api/regularSchedules/connections`, linkRequestBody, header)
        .then(() => {
          this.linkedStudentId = null;
          this.reloadTable();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
v-data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #dcc268;
}

thead.v-data-table-header {
  background-color: #dcc268;
}

.filter-area {
  padding: 0 20px;
}
.filter-item {
  padding: 0 5px;
}
.table-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.v-data-table {
  white-space: nowrap;
}
.regular-schedule-list-area {
  max-height: 700px;
  overflow: auto;
}
</style>
