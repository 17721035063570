<template>
  <div>
    <p class="no-margin-text">
      <FormTitleText titleText="お名前" />
      <RequiredIcon v-if="rules && rules.includes('required')" />
      <FormAttentionText attentionText="アルファベットのみ" />
    </p>
    <v-row no-gutters>
      <v-col
        cols="6"
        :style="isPC ? { padding: '0 10px 0 0' } : { padding: '0 2px 0 0' }"
      >
        <validationProvider
          :rules="{
            required: true,
            regex: /^[a-zA-Z]*$/,
          }"
          name="姓"
          v-slot="{ errors, valid }"
        >
          姓
          <v-text-field
            label="Yamada"
            v-model="familyName"
            outlined
            background-color="white"
            @change="changeName"
            :success="valid"
            hide-details
          ></v-text-field>
          <FormErrorText :errorText="errors[0]" />
        </validationProvider>
      </v-col>

      <v-col
        cols="6"
        :style="isPC ? { padding: '0 0 0 10px' } : { padding: '0 0 0 2px' }"
      >
        <validationProvider
          :rules="{
            required: true,
            regex: /^[a-zA-Z]*$/,
          }"
          name="名"
          v-slot="{ errors, valid }"
        >
          名
          <v-text-field
            label="Hanako"
            v-model="firstName"
            outlined
            background-color="white"
            @change="changeName"
            :success="valid"
            hide-details
          ></v-text-field>
          <FormErrorText :errorText="errors[0]" />
        </validationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText';
import FormErrorText from '../Atoms/FormErrorText';
import FormAttentionText from '../Atoms/FormAttentionText';
import RequiredIcon from '../Atoms/RequiredIcon';

export default {
  name: 'RegisterNameForm',
  data: () => ({
    isEditedEmail: false,
    firstName: "",
    familyName: "",
  }),
  props: ['value', 'rules'],
  mounted() {
    this.firstName = this.value.split(' ')[0]
    this.familyName = this.value.split(' ')[1]
  },
  computed: {
  },
  methods: {
    changeName() {
      this.$emit('input', this.firstName + " " + this.familyName)
    }
  },
  components: {
    FormTitleText,
    FormErrorText,
    FormAttentionText,
    RequiredIcon
  }
};
</script>

<style lang="scss" scoped>
</style>
