<template>
  <div>
    <div class="basic-info">
      <TeacherPicture
        :imageUrl="teacherImageUrl"
        :imageSize="imageSize"
        class="teacher-picture"
      />
      <div>
        <TeacherCardNameLabel :name="teacherName" class="name-label" />
        <!-- 料金一覧 -->
        <div v-if="isPrivate" class="fee-area">
          <p class="blackColor--text fee-text" v-if="isVisibleFirstLessonFee">
            <span class="fee-block">初回</span
            >{{ teacherInfo.feeForFirstLesson }}円 / 30分
          </p>
          <p class="blackColor--text fee-text" v-if="isVisibleStudentFee">
            <span class="fee-block">学生</span>{{ teacherInfo.feeForStudent }}円
            / 30分
          </p>
          <p class="blackColor--text fee-text" v-if="isVisibleRegularFee">
            <span class="fee-block">通常</span>{{ teacherInfo.feeForRegular }}円
            / 30分
          </p>
          <p class="red--text fee-text" v-if="isVisibleFeeMessage">
            ※{{ teacherInfo.feeMessage }}
          </p>
        </div>
        <!-- 料金について -->
        <TeacherInfoFeeArea v-if="isPC && showFeeArea" />
      </div>
    </div>
    <!-- 料金について -->
    <TeacherInfoFeeArea v-if="!isPC && showFeeArea" class="fee-note-area" />
    <!-- 講師からのメッセージ -->
    <MessageFromTeacher
      :message="messageFromTeacher"
      v-if="messageFromTeacher"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TeacherCardNameLabel from "../Atoms/TeacherCardNameLabel";
import TeacherPicture from "../Molecules/TeacherPicture";
import MessageFromTeacher from "../Atoms/MessageFromTeacher";
import TeacherInfoFeeArea from "../Molecules/TeacherInfoFeeArea";

export default {
  name: "SimpleTeacherCard",
  components: {
    TeacherCardNameLabel,
    TeacherPicture,
    MessageFromTeacher,
    TeacherInfoFeeArea,
  },
  props: {
    teacherInfo: {
      type: Object,
      default: () => {},
    },
    teacherName: {
      type: String,
      default: "",
    },
    teacherImageUrl: {
      type: String,
      default: "",
    },
    lessonCourseId: {
      type: Number,
      required: false,
    },
    messageFromTeacher: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      isTeacher: "user/isTeacher",
    }),
    showFeeArea() {
      return this.isPrivate && !this.isTeacher;
    },
    calcFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1.2;
        case "sm":
          return 1.2;
        case "md":
          return 1.5;
        case "lg":
          return 1.3;
        default:
          return 1.2;
      }
    },
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 125 + "px";
        case "sm":
          return 150 + "px";
        case "md":
          return 150 + "px";
        case "lg":
          return 200 + "px";
        case "xl":
          return 300 + "px";
        default:
          return 125 + "px";
      }
    },
    computedCourseName() {
      switch (this.lessonCourseId) {
        case 1:
          return this.$t("teacherSchedule.CONVERSATION");
        case 2:
          return "プライベートコース";
        default:
          return "";
      }
    },
    isPrivate() {
      if (this.lessonCourseId === 2) {
        return true;
      } else {
        return false;
      }
    },
    isVisibleRegularFee() {
      if (this.teacherInfo.feeForRegular !== null) {
        return true;
      } else {
        return false;
      }
    },
    isVisibleStudentFee() {
      if (this.teacherInfo.feeForStudent !== null) {
        return true;
      } else {
        return false;
      }
    },
    isVisibleFirstLessonFee() {
      if (this.teacherInfo.feeForFirstLesson !== null) {
        return true;
      } else {
        return false;
      }
    },
    isVisibleFeeMessage() {
      if (!this.teacherInfo.feeMessage) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.basic-info {
  display: flex;
}
.name-label {
  margin-top: 8px;
}
.teacher-course {
  margin-top: 4px;
}
.fee-text {
  font-size: 0.8em;
  margin-bottom: 6px;
}
.fee-block {
  border: 0.5px solid #272727;
  padding: 4px 8px;
  margin-right: 8px;
}
.teacher-picture {
  margin: 12px 12px 0 0;
}
.fee-area {
  margin-top: 16px;
}
.fee-note-area {
  margin-bottom: 32px;
}
</style>
