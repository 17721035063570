<template>
  <v-container>
    <PageTitle :title-name="$t('teacherSchedule.teacherSchedule')" />
    <v-row justify="center" class="change-lang-area">
      <a @click="changeLocale('ja')"><p class="change-lang">日本語</p></a>
      /
      <a @click="changeLocale('de')"><p class="change-lang">Deutsch</p></a>
    </v-row>
    <SwitchTimeZone v-if="isShowTimeZone" @reload="reloadAll()" />
    <component
      :is="teacherInfoComponent"
      :teacherName="teacherName"
      :teacherImageUrl="teacherImageUrl"
      :skypeId="skypeId"
    ></component>
    <component
      class="calendar-area"
      v-if="isShowCalendar"
      :is="calendarComponent"
      :teacherSchedules="teacherSchedules"
      :plannedTeacherLessons="plannedTeacherLessons"
      :userInfo="userInfo"
      :teacherName="teacherName"
      :usedMonth="usedMonth"
      @reload="reloadCalendar()"
    ></component>
    <v-row v-if="isPrivate">
      <v-col cols="12" xs="12" sm="8" md="9" lg="9" xl="9">
        <p
          class="primary--text"
          :style="{ fontSize: calcFontSize * 1.2 + 'em', fontWeight: 'bold' }"
        >
          {{ $t("teacherSchedule.regularLessonStatus") }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <TeacherScheduleRegularTable
        v-if="isShowTable && isPrivate"
        @reloadCalendar="reloadCalendar()"
      />
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from "../Atoms/PageTitle";
import TeacherScheduleTeacherInfo from "../Organisms/TeacherScheduleTeacherInfo";
import TeacherScheduleCalendar from "../Organisms/TeacherScheduleCalendar";
import TeacherScheduleRegularTable from "../Organisms/TeacherScheduleRegularTable";
import SwitchTimeZone from "../Atoms/SwitchTimeZone";

export default {
  name: "RegisterTeacherSchedule",
  data: () => ({
    teacherSchedules: [],
    plannedTeacherLessons: [],
    calendarComponent: null,
    teacherInfoComponent: null,
    teacherName: "-",
    teacherImageUrl: require("../../assets/no_teacher.png"),
    skypeId: "",
    userInfo: null,
    loading: true,
    isShowCalendar: true,
    isShowTable: true,
    isShowTimeZone: true,
    fetchedAvailableRegularSchedules: null,
    arrangedRegularSchedules: [],
    usedMonth: null,
  }),
  provide() {
    return {
      reloadCalendar: this.reloadCalendar,
    };
  },
  components: {
    PageTitle,
    TeacherScheduleTeacherInfo,
    TeacherScheduleCalendar,
    TeacherScheduleRegularTable,
    SwitchTimeZone,
  },
  computed: {
    calcFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1.2;
        case "sm":
          return 1.2;
        case "md":
          return 1.5;
        case "lg":
          return 1.3;
        default:
          return 1.2;
      }
    },
    isPrivate() {
      if (!this.userInfo.lessonCourseArray) {
        return false;
      }

      if (this.userInfo.lessonCourseArray.includes("PRIVATE")) {
        return true;
      } else {
        return false;
      }
    },
    judgeMatchDayOfWeek: function () {
      return function (schedule, dayOfWeek) {
        const scheduleDayOfWeek = Number(schedule.startTime.format("DD") - 1);

        if (scheduleDayOfWeek > 29) {
          if (Number(dayOfWeek) === 6) {
            return true;
          }
        } else if (scheduleDayOfWeek > 6) {
          if (Number(dayOfWeek) === 0) {
            return true;
          }
        } else {
          return scheduleDayOfWeek === dayOfWeek;
        }
      };
    },
  },
  async created() {
    this.init();
  },
  mounted() {},
  methods: {
    async init() {
      this.fetchInitialData();
      this.fetchTeacherInfo();

      try {
        await this.fetchTeacherSchedule();
      } catch (error) {
        console.log("error", error);
      }

      try {
        await this.fetchAvailableRegularSchedules();
      } catch (error) {
        console.log(error);
      }

      try {
        await this.addDayOfWeekToSchedules();
      } catch (error) {
        console.log(error);
      }

      try {
        await this.makeLessonFromRegularSchedule();
      } catch (error) {
        console.log(error);
      }

      try {
        await this.fetchPlannedTeacherLessons();
      } catch (error) {
        console.log(error);
      }
    },
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    fetchTeacherSchedule() {
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(
            `/api/teacherSchedules?teacherId=${this.userInfo.teacherId}`,
            header
          )
          .then((response) => {
            if (response.data) {
              this.teacherSchedules = response.data.teacherSchedules;
              resolve();
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    // 講師の定期予定スケジュールの空き予定を取得
    fetchAvailableRegularSchedules() {
      return new Promise((resolve) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        this.axios
          .get(
            `/api/regularSchedules/available?teacherId=${this.userInfo.teacherId}&lessonCourseId=2`,
            header
          )
          .then((response) => {
            this.fetchedAvailableRegularSchedules =
              response.data.availableRegularSchedules;

            resolve();
          })
          .catch((error) => {
            console.log("error", error);
            alert(error.response.data.error.message);
          });
      });
    },

    // 取得した予定に曜日情報を付け加える
    addDayOfWeekToSchedules() {
      // fetchedAvailableRegularSchedulesで日を跨ぐものは分割する
      const dividedAvailableRegularSchedules =
        this.$divideStraddlingDaySchedule(
          this.fetchedAvailableRegularSchedules
        );

      for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
        const schedulesFilteredByWeekDay =
          dividedAvailableRegularSchedules.filter((schedule) =>
            this.judgeMatchDayOfWeek(schedule, dayOfWeek)
          );

        this.arrangedRegularSchedules.push({
          dayOfWeek: dayOfWeek,
          combinedSchedules: schedulesFilteredByWeekDay,
          lessonCourseId: 2,
        });
      }
    },

    // 曜日情報を付け加えられたスケジュール情報をカレンダー情報に変換していく
    // 定期予定の情報（曜日と時間）から、向こう2ヶ月分データを作る
    makeLessonFromRegularSchedule() {
      const scheduleItems = this.$makeScheduleFromRegularInfo(
        this.arrangedRegularSchedules
      );
      this.teacherSchedules = this.teacherSchedules.concat(scheduleItems);
    },
    fetchPlannedTeacherLessons() {
      return new Promise((resolve) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(
            `/api/lessons/planned?teacherId=${this.userInfo.teacherId}`,
            header
          )
          .then((response) => {
            if (response.data) {
              this.plannedTeacherLessons = response.data.lessons;

              // ドイツじこくの場合はサマータイムの影響を受けているレッスン予定の時刻を修正する
              // 1. サマータイムじゃない時に作られている、サマータイムの予定
              // 2. サマータイムに作られている、サマータイムじゃない予定
              // TODO: この表示は必要なさそう
              // if (this.userInfo.timeZone === 'Europe/Berlin') {
              //   this.plannedTeacherLessons = this.$modifySummerTimeLesson(this.plannedTeacherLessons)
              // }

              // 【注意】データを取得したあとに再読み込みするために必要
              this.calendarComponent = TeacherScheduleCalendar;
            }
            resolve();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    fetchTeacherInfo() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };
      this.axios
        .get(`/api/teachers/${this.userInfo.teacherId}`, header)
        .then((response) => {
          if (response.data) {
            this.teacherName = response.data.teacher.teacherName;
            if (response.data.teacher.imageUrl) {
              this.teacherImageUrl = response.data.teacher.imageUrl;
            }
            this.teacherInfoComponent = TeacherScheduleTeacherInfo;
            this.skypeId = response.data.teacher.skypeId;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async changeLocale(lang) {
      this.$i18n.locale = lang;

      // 言語の変更をしたタイミングでカレンダーとテーブルを再読み込みして反映する
      this.$vuetify.lang.current = lang;
      this.isShowCalendar = false;
      this.isShowTable = false;
      this.isShowTimeZone = false;
      await this.$nextTick();
      this.isShowCalendar = true;
      this.isShowTable = true;
      this.isShowTimeZone = true;
    },

    // カレンダーをリロードする関数
    // 今月以外の特定の月を編集したあと、今月になってしまわないように実装
    //例: 講師の人が7月の予定を編集したあとは、また7月が表示される。

    // TODO:init()と書いていることが丸かぶり。もっと短くしたい。
    // initでPromise関数を返したいが、init内にもawaitを使っている、かつpromiseでasync関数は
    // 使えないそうで、困っている
    async reloadCalendar(value) {
      this.isShowCalendar = false;
      await this.$nextTick();

      //リセット
      this.teacherSchedules = [];
      this.arrangedRegularSchedules = [];

      try {
        await this.fetchTeacherSchedule();
      } catch (error) {
        console.log("error", error);
      }

      try {
        await this.fetchAvailableRegularSchedules();
      } catch (error) {
        console.log(error);
      }

      try {
        await this.addDayOfWeekToSchedules();
      } catch (error) {
        console.log(error);
      }

      try {
        await this.makeLessonFromRegularSchedule();
      } catch (error) {
        console.log(error);
      }

      try {
        await this.fetchPlannedTeacherLessons();
      } catch (error) {
        console.log(error);
      }
      this.usedMonth = value;
      this.isShowCalendar = true;
    },
    async reloadTable() {
      this.isShowTable = false;
      await this.$nextTick();
      this.isShowTable = true;
    },
    reloadAll() {
      this.reloadCalendar();
      this.reloadTable();
    },
  },
  head: {
    title: {
      inner: "Teacher-schedule",
    },
  },
};
</script>

<style lang="scss" scoped>
.change-lang {
  white-space: pre;
}
.calendar-area {
  margin-bottom: 100px;
}
.change-lang-area {
  margin: 3px 0 0 0;
}
</style>
