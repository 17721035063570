<template>
  <v-main>
    <v-container>
      <v-row justify="center" align-content="center" class="message-area">
        <v-col cols="12">
          <h1 class="primary--text">予約が完了しました！</h1>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10" xs="10" sm="10" md="8" lg="8" xl="8">
          <p>
            <span class="accent-text"
              >初めての先生の場合は、続いてスカイプで<br />講師にメッセージを送ってください</span
            >
            <br />
            <br />
            <a
              href="https://vollmond.online/lernen/skype/"
              target="_blank"
              rel="noopener"
              >スカイプの使い方はこちら！</a
            >
          </p>
          <v-row justify="center">
            <p class="important-attention">
              ⚠︎レッスン開始時刻24時間前までに行ってください⚠︎
            </p>
          </v-row>
          <p class="attention-message">
            {{ attentionMessage2 }}
            ・キャンセルポリシー：<a
              href="https://vollmond.online/cancel-policy/"
              target="_blank"
              rel="noopener"
              >https://vollmond.online/cancel-policy/</a
            >
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10" xs="10" sm="10" md="8" lg="8" xl="8">
          <active-button
            button-title="続けて予約する"
            @clickAction="toSchedule"
            class="to-reserve-button"
          />
          <router-link to="/studentTop" style="text-decoration: none">
            <p>トップ画面に戻る</p>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton';

export default {
  name: 'CompleteConversationReservation',
  props: ["teacherId"],
  data: () => ({
    attentionMessage2: "■日時変更やキャンセルについて\n・レッスン開始時刻48時間前までであれば、講師と相談の上変更ができます。直接スカイプメッセージにて交渉しください。\n\n・48時間前以降の日時変更は原則受け付けず、キャンセル扱いとなります。\n\n"
  }),
  methods: {
    toSchedule() {
      this.$router.push({ path: 'conversationTeacherSchedule', query: { teacherId: this.teacherId } })
    }
  },

  components: {
    ActiveButton
  }
};
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
}
p {
  margin-top: 30px;
  text-align: center;
}
.to-reserve-button {
  margin-top: 40px;
}
.attention-message {
  margin: 40px 0 0 0;
  text-align: left;
  white-space: pre-line;
  padding: 30px;
  background-color: #f8f8f8;
  line-height: 1.8em;
}
.bold-text {
  font-weight: bold;
  margin: 0;
}
.important-attention {
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: red;
  background-color: yellow;
}
.accent-text {
  font-size: 1.2em;
  font-weight: bold;
  margin: 50px 0 0 0;
  line-height: 1.8em;
}
</style>
