<template>
  <validationProvider :rules="rules" v-slot="{ errors, valid }" name="区分">
    <p>
      <FormTitleText titleText="区分" />
      <RequiredIcon v-if="rules && rules.includes('required')" />
    </p>
    <v-select
      :items="categoryItems"
      v-model="inputedCategory"
      :label="$t('profile.requireSelection')"
      outlined
      background-color="white"
      hide-details
      :success="valid"
    ></v-select>
    <FormErrorText :errorText="errors[0]" />
  </validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText';
import FormErrorText from '../Atoms/FormErrorText';
import RequiredIcon from '../Atoms/RequiredIcon';

export default {
  name: 'EnterCategoryForm',
  data: () => ({
    categoryItems:["大人","学生","子ども"],
  }),
  props: ['value', 'rules'],
  mounted() {
  },
  computed: {
    inputedCategory: {
      get () {
        switch (this.value) {
          case "ADULT":
            return "大人"
          case "STUDENT":
            return "学生"
          case "CHILD":
            return "子ども"
          default:
            return ""
        }
      },
      set (value) {
        switch (value) {
          case "大人" :
            this.$emit('input', "ADULT")
            break;
          case "学生":
            this.$emit('input', "STUDENT")
            break;
          case "子ども":
            this.$emit('input', "CHILD")
            break;
          default:
            return ""
        }
      }
    },
  },
  methods: {
  },
  components: {
    FormTitleText,
    FormErrorText,
    RequiredIcon
  }
};
</script>

<style lang="scss" scoped>
</style>
