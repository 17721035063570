<template>
  <v-container :class="containerClass">
    <PageTitle title-name="HOME" />
    <SwitchTimeZone v-if="isShowTimeZone" @reload="reload()" />
    <div v-if="!isPC" class="internal-link-area">
      <v-row no-gutters>
        <a href="#" v-scroll-to="'#private'">
          <p class="internal-link-text">プライベート</p>
        </a>
        <a href="#" v-scroll-to="'#conversation'">
          <p class="internal-link-text">会話</p>
        </a>
        <a href="#" v-scroll-to="'#text'">
          <p class="internal-link-text">テキスト</p>
        </a>
        <a href="#" v-scroll-to="'#smallGroup'">
          <p class="internal-link-text">少人数</p>
        </a>
        <a href="#" v-scroll-to="'#videoLearning'">
          <p class="internal-link-text">動画</p>
        </a>
      </v-row>
    </div>
    <!-- レッスン情報 -->
    <ScheduleHeading class="heading" />

    <!-- 総時間 - 生徒 -->
    <v-row class="lesson-data-area" justify="start" no-gutters>
      <v-col cols="6" xs="12" sm="4" md="4" lg="2">
        <LessonData
          class="lesson-data"
          type="HOURS"
          :lessonData="studentLessonData"
        />
      </v-col>
      <v-col cols="6" xs="12" sm="4" md="4" lg="2">
        <LessonData
          class="lesson-data"
          type="TIMES"
          :lessonData="studentLessonData"
        />
      </v-col>
    </v-row>

    <!-- 応援メッセージ - 生徒 -->
    <CheerUpArea />

    <!-- レッスン予定一覧 -->
    <div>
      <SubHeading
        sub-heading-text="予約済みレッスンスケジュール"
        class="subheading"
      />
      <component
        v-if="isShowTable"
        :is="scheduleTableComponent"
        :lessons="lessons"
        :isTeacher="false"
      />
    </div>
    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
      <ActiveButtonWithArrow
        button-title="レッスン受講履歴"
        @clickAction="toLessonHistory"
        :style="{ margin: '20px 0 10px 0' }"
        leftIcon="mdi-clipboard-text-clock"
      />
    </v-col>

    <!-- 生徒画面注意事項 -->
    <div>
      <div v-if="attendConversation" class="attention">
        <p>
          ※初めての先生の場合は、レッスン<span style="font-weight: bold"
            >開始時刻24時間前までに</span
          >スカイプで先生に連絡して下さい
          <br />
          <a
            href="https://vollmond.online/lernen/skype/"
            target="_blank"
            rel="noopener"
            class="how-to-skype-link"
            >スカイプの使い方はこちら！</a
          >
        </p>
        <p>
          ※マイページより予約したレッスンの「キャンセル」を受講生自身で行えるのは【レッスン開始時刻48時間前】までです。
          <br />
          レッスン開始48時間前以降のキャンセルについては、講師へ直接ご連絡ください。Vollmondの定める<a
            href="https://vollmond.online/cancel-policy/"
            target="_blank"
            rel="noopener"
            >キャンセルポリシー</a
          >に従い、キャンセル料が発生する場合がございます。
        </p>
      </div>
    </div>

    <!-- 生徒 - プライベートコース -->
    <div id="private">
      <PrivateHeading class="heading" />
      <div v-if="attendPrivate">
        <SubHeading sub-heading-text="最近予約した講師" class="subheading" />
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="3"
          v-if="isShowLatestPrivateTeachers"
        >
          <div
            v-for="latestPrivateTeacher of this.latestPrivateTeachers"
            :key="latestPrivateTeacher.id"
          >
            <SimpleTeacherCard
              class="teacher-card"
              :catchPhrase="
                computedCatchPhrase(latestPrivateTeacher.catchPhrase)
              "
              :teacherName="
                computedTeacherName(latestPrivateTeacher.teacherName)
              "
              :imageUrl="computedImageUrl(latestPrivateTeacher.imageUrl)"
              :skypeId="computedSkypeId(latestPrivateTeacher.skypeId)"
              @clickAction="toPrivateSchedule(latestPrivateTeacher)"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="3"
          v-if="!isShowLatestPrivateTeachers"
        >
          <div>
            <p class="no-latest-teacher">まだ最近の予約はありません</p>
          </div>
        </v-col>
      </div>
      <div v-if="attendPrivate">
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
          <ActiveButtonWithArrow
            button-title="プライベートコース担当講師一覧"
            @clickAction="toPrivateTeachers"
          />
        </v-col>
      </div>
      <div v-else>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
          <p class="no-latest-teacher">まだ担当講師はいません</p>
          <a
            href="https://vollmond.online/privatkurs-lehrer/"
            class=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <ActiveButtonWithArrow button-title="プライベートコース申し込み" />
          </a>
        </v-col>
      </div>
    </div>

    <!-- 生徒 - 会話コース -->
    <div id="conversation">
      <ConversationHeading class="heading" />
      <div v-if="attendConversation">
        <SubHeading
          sub-heading-text="所持会話チケット枚数"
          class="subheading"
        />
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
          <p class="ticket-num-area">
            <span class="ticket-num">{{ userInfo.numberOfTicket }}</span
            >枚
          </p>
          <div v-if="this.userInfo.numberOfTicket != 0">
            有効期限:{{ computedTicketLimit }}
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
          <ActiveButtonWithArrow
            button-title="会話チケットを追加購入する"
            @clickAction="toBuyTicket"
          />
        </v-col>
        <SubHeading sub-heading-text="最近予約した講師" class="subheading" />
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="3"
          v-if="isShowLatestConversationTeachers"
        >
          <div
            v-for="latestConversationTeacher of this.latestConversationTeachers"
            :key="latestConversationTeacher.id"
          >
            <SimpleTeacherCard
              class="teacher-card"
              :catchPhrase="
                computedCatchPhrase(latestConversationTeacher.catchPhrase)
              "
              :teacherName="
                computedTeacherName(latestConversationTeacher.teacherName)
              "
              :imageUrl="computedImageUrl(latestConversationTeacher.imageUrl)"
              :skypeId="computedSkypeId(latestConversationTeacher.skypeId)"
              @clickAction="toConversationSchedule(latestConversationTeacher)"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="6"
          xl="3"
          v-if="!isShowLatestConversationTeachers"
        >
          <div>
            <p class="no-latest-teacher">まだ最近の予約はありません</p>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
          <ActiveButtonWithArrow
            button-title="会話コース担当講師一覧"
            @clickAction="toConversationTeachers"
          />
        </v-col>
      </div>
      <div v-else>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
          <p class="no-latest-teacher">まだ担当講師はいません</p>
          <a
            href="https://vollmond.online/konversationskurs/"
            class=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <ActiveButtonWithArrow button-title="会話コース申し込み" />
          </a>
        </v-col>
      </div>
    </div>

    <!-- 生徒 - テキストコース -->
    <div id="text">
      <TextHeading class="heading" />
      <!-- テキストコース受講している人には 解約・マニュアル・講師変更を出す -->
      <div v-if="attendText">
        <div
          v-for="textCourseInfo of this.textCourseInfoArray"
          :key="textCourseInfo.id"
        >
          <SubHeading sub-heading-text="選択中の頻度" class="subheading" />
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
            <p class="ticket-num-area">
              <span class="text-course-fequency">{{
                computedFrequencyName(textCourseInfo.frequency)
              }}</span>
            </p>
          </v-col>
          <SubHeading sub-heading-text="担当講師" class="subheading" />
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
            <TeacherCardWithDetail
              :teacherInfo="textCourseInfo"
              showedAt="text"
            />
          </v-col>
        </div>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
          <a
            href="https://vollmond.online/kontaktieren/"
            class=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <ActiveButtonWithArrow button-title="講師変更" />
          </a>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
          <ActiveButtonWithArrow
            v-if="userInfo.isReservedStripe"
            @clickAction="toCustomerPortal"
            button-title="テキストコース解約"
          />
        </v-col>
        <div :style="{ margin: '0 0 0 20px' }">
          <a
            href="https://vollmond.online/lektion/kundigen/"
            target="_blank"
            rel="noopener"
            :style="{ 'font-weight': 'bold', 'font-size': '0.9em' }"
            >テキストコースマニュアル</a
          >
        </div>
      </div>
      <div v-else>
        <div>
          <p class="no-latest-teacher">まだ担当講師はいません</p>
        </div>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
          <a
            href="https://vollmond.online/uebungskurs/"
            class=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <ActiveButtonWithArrow button-title="テキストコース申し込み" />
          </a>
          <p class="apply-for-textCourse-attention">※外部サイトに接続します</p>
        </v-col>
      </div>
    </div>
    <!-- 生徒 - 少人数コース -->
    <div id="smallGroup">
      <SmallGroupHeading class="heading" />
      <SubHeading sub-heading-text="コース情報" class="subheading" />
      <div v-if="isShowGroupLessonReservation">
        <p class="no-latest-teacher">
          現在{{ groupLessonReservationMonth }}月申し込み受付中です！
        </p>
      </div>
      <div v-else>
        <p class="no-latest-teacher">次回申し込みは20日に始まります</p>
      </div>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
        <a
          href="https://vollmond.online/gruppenkurse/"
          class=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <ActiveButtonWithArrow button-title="少人数コース申し込み" />
        </a>
        <p class="apply-for-textCourse-attention">※外部サイトに接続します</p>
      </v-col>
    </div>
    <!-- 生徒 - 動画学習コース  -->
    <div id="videoLearning">
      <VideoLearningHeading class="heading" />
      <SubHeading sub-heading-text="コース情報" class="subheading" />
      <p class="no-latest-teacher">現在申し込み受付中です！</p>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
        <a
          href="https://vollmond.online/videokurs/"
          class=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <ActiveButtonWithArrow button-title="動画学習コース申し込み" />
        </a>
        <p class="apply-for-textCourse-attention">※外部サイトに接続します</p>
      </v-col>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../Atoms/PageTitle";
import ScheduleHeading from "../Molecules/ScheduleHeading";
import ConversationHeading from "../Molecules/ConversationHeading";
import PrivateHeading from "../Molecules/PrivateHeading";
import TextHeading from "../Molecules/TextHeading";
import SmallGroupHeading from "../Molecules/SmallGroupHeading";
import VideoLearningHeading from "../Molecules/VideoLearningHeading";
import ScheduleTable from "../Organisms/ScheduleTable";
import SubHeading from "../Atoms/SubHeading";
import SimpleTeacherCard from "../Organisms/SimpleTeacherCard";
import ActiveButton from "../Atoms/ActiveButton";
import ActiveButtonWithArrow from "../Atoms/ActiveButtonWithArrow";
import SwitchTimeZone from "../Atoms/SwitchTimeZone";
import userInfoTranslate from "@/data/translation/userInfoTranslate";
import TeacherCardWithDetail from "../Organisms/TeacherCardWithDetail";
import TextCourseTable from "../Organisms/TextCourseTable";
import LessonData from "../Molecules/LessonData";
import CheerUpArea from "../Molecules/CheerUpArea";
import MessageForTeacher from "../Atoms/MessageForTeacher";

export default {
  name: "Top",
  data: () => ({
    userInfo: null,
    lessons: [],
    scheduleTableComponent: null,
    latestPrivateTeachers: [],
    regularTeachers: [],
    latestConversationTeachers: [],
    isShowTable: true,
    isShowTimeZone: true,
    textCourseInfoArray: [],
    studentLessonData: {},
    teacherLessonData: {},
  }),
  components: {
    PageTitle,
    ScheduleHeading,
    ConversationHeading,
    PrivateHeading,
    TextHeading,
    SmallGroupHeading,
    VideoLearningHeading,
    ScheduleTable,
    SubHeading,
    SimpleTeacherCard,
    ActiveButton,
    ActiveButtonWithArrow,
    SwitchTimeZone,
    TeacherCardWithDetail,
    TextCourseTable,
    LessonData,
    CheerUpArea,
    MessageForTeacher,
  },
  mounted() {},
  async created() {
    this.fetchInitialData();

    // 最近予約した講師情報の取得（会話）
    this.fetchRecentlyConversationTeacherInfo();

    // 最近予約した講師情報の取得（プライベート）
    this.fetchRecentlyPrivateTeacherInfo();

    // テキストコースの講師を取得
    this.fetchTextCourseInfo();

    // 生徒の授業回数、授業時間などを取得する
    this.fetchStudentLessonData();

    this.getPlannedLessons();
  },
  computed: {
    containerClass() {
      if (this.isPC) {
        return "wide-padding";
      } else {
        return "narrow-padding";
      }
    },
    attendConversation() {
      if (this.userInfo.lessonCourseArray.includes("CONVERSATION")) {
        return true;
      }
      return false;
    },
    attendPrivate() {
      if (this.userInfo.lessonCourseArray.includes("PRIVATE")) {
        return true;
      }
      return false;
    },
    attendText() {
      if (this.userInfo.lessonCourseArray.includes("TEXT")) {
        return true;
      }
      return false;
    },
    isShowLatestPrivateTeachers() {
      if (!this.latestPrivateTeachers.length) {
        return false;
      } else {
        return true;
      }
    },
    isShowLatestConversationTeachers() {
      if (!this.latestConversationTeachers.length) {
        return false;
      } else {
        return true;
      }
    },
    isShowTextCourseInfo() {
      if (!this.textCourseInfoArray.length) {
        return false;
      } else {
        return true;
      }
    },
    computedTeacherName: function () {
      return function (teacherName) {
        if (!teacherName) {
          return "-";
        } else {
          return teacherName;
        }
      };
    },
    computedCatchPhrase: function () {
      return function (catchPhrase) {
        if (catchPhrase) {
          return catchPhrase;
        } else {
          return "-";
        }
      };
    },
    computedImageUrl: function () {
      return function (imageUrl) {
        if (imageUrl) {
          return imageUrl;
        } else {
          return require("../../assets/no_teacher.png");
        }
      };
    },
    computedSkypeId: function () {
      return function (skypeId) {
        if (skypeId) {
          return skypeId;
        } else {
          return "-";
        }
      };
    },
    computedTicketLimit() {
      const today = this.$moment();
      const limitDate = this.$moment().endOf("month").subtract(2, "d");

      let ticketLimit = null;
      if (limitDate < today) {
        ticketLimit = this.$moment()
          .add(1, "M")
          .endOf("month")
          .subtract(2, "d");

        // ドイツ時刻が選択されている場合は 月末-2日前-日本とドイツの時差
        // 例: 月末→ 7/31 23:59:59
        if (this.userInfo.timeZone === "Europe/Berlin") {
          return this.$moment(ticketLimit)
            .subtract(
              9 - Number(this.$moment(ticketLimit).format("ZZ").split("")[2]),
              "hours"
            )
            .format("YYYY/MM/DD HH:mm:ss");
        } else {
          return this.$moment(ticketLimit).format("YYYY/MM/DD HH:mm:ss");
        }
      } else {
        ticketLimit = this.$moment().endOf("month").subtract(2, "d");

        if (this.userInfo.timeZone === "Europe/Berlin") {
          return this.$moment(ticketLimit)
            .subtract(
              9 - Number(this.$moment(ticketLimit).format("ZZ").split("")[2]),
              "hours"
            )
            .format("YYYY/MM/DD HH:mm:ss");
        } else {
          return this.$moment(ticketLimit).format("YYYY/MM/DD HH:mm:ss");
        }
      }
    },
    computedFrequencyName: function () {
      return function (frequencyCode) {
        return userInfoTranslate[frequencyCode];
      };
    },
    isShowGroupLessonReservation() {
      // 少人数コースの日付を表示させるかどうかのフラグ
      // 日付表示させる時以外は「次回申し込みは20日に始まります」の文言を表示
      const currentMonth = Number(this.$moment().format("MM"));
      const currentDate = Number(this.$moment().format("DD"));

      // 基本的に20日以降は表示
      if (currentDate >= 20) {
        return true;
      }

      // 例外的に1月は -1/2まで表示させる
      if (currentMonth === 1 && currentDate <= 2) {
        return true;
      }

      return false;
    },
    groupLessonReservationMonth() {
      const currentMonth = Number(this.$moment().format("MM"));
      const currentDate = Number(this.$moment().format("DD"));

      // 他は20日以降、次の月の分
      // 1/20 - → 2月分
      if (currentDate >= 20) {
        return this.$moment().add(1, "M").format("M");
      }

      // 例外的に1月は -1/2までは1月分
      if (currentMonth === 1 && currentDate <= 2) {
        return 1;
      }
      return 0;
    },
  },
  methods: {
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    toBuyTicket() {
      this.$router.push({ path: "buyTicketTop" });
    },
    toConversationTeachers() {
      if (this.userInfo.level) {
        this.$router.push({
          name: "ConversationTeacherList",
          query: {
            country: "",
            compatibleLevel: this.userInfo.level,
            teachingLevel: "",
            empty: "",
          },
        });
      } else {
        this.$router.push({ path: "ConversationTeacherList" });
      }
    },
    toPrivateTeachers() {
      if (this.userInfo.level) {
        this.$router.push({
          name: "PrivateTeacherList",
          query: {
            country: "",
            compatibleLevel: this.userInfo.level,
            teachingLevel: "",
            empty: "",
          },
        });
      } else {
        this.$router.push({ path: "PrivateTeacherList" });
      }
    },
    // 生徒が「会話コースの予約スケジュール」に遷移
    toConversationSchedule(latestConversationTeacher) {
      const teacherId = latestConversationTeacher.id;
      this.$router.push({
        path: "conversationTeacherSchedule",
        query: { teacherId: teacherId },
      });
    },
    // 生徒が「プライベートコースの予約スケジュール」に遷移
    toPrivateSchedule(latestConversationTeacher) {
      const teacherId = latestConversationTeacher.id;
      this.$router.push({
        path: "privateTeacherSchedule",
        query: { teacherId: teacherId },
      });
    },
    toLessonHistory() {
      this.$router.push("lessonHistory");
    },
    getPlannedLessons() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      this.axios
        .get(
          `/api/lessons/planned?studentId=${this.userInfo.studentId}`,
          header
        )
        .then((response) => {
          this.lessons = response.data.lessons;
          this.scheduleTableComponent = ScheduleTable;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchRecentlyConversationTeacherInfo() {
      return new Promise((resolve) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(
            `/api/teachers/recentlyReservation?studentId=${this.userInfo.studentId}&lessonCourseId=1`,
            header
          )
          .then((response) => {
            this.latestConversationTeachers = response.data.teachers.filter(
              (v) => v
            );
            resolve();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    fetchRecentlyPrivateTeacherInfo() {
      return new Promise((resolve) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(
            `/api/teachers/recentlyReservation?studentId=${this.userInfo.studentId}&lessonCourseId=2`,
            header
          )
          .then((response) => {
            this.latestPrivateTeachers = response.data.teachers.filter(
              (v) => v
            );
            resolve();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    fetchTextCourseInfo() {
      return new Promise((resolve) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(`/api/textCourses?studentId=${this.userInfo.studentId}`, header)
          .then((response) => {
            this.textCourseInfoArray = response.data.textCourses;

            // teacherCardに情報を伝えていく時にincludeしたままの状態だとうまく伝わらないので、無理矢理こんな実装に…
            for (let textCourseInfo of this.textCourseInfoArray) {
              textCourseInfo.teacherName =
                textCourseInfo["Teacher.teacherName"];
              textCourseInfo.country = textCourseInfo["Teacher.country"];
              textCourseInfo.catchPhrase =
                textCourseInfo["Teacher.catchPhrase"];
              textCourseInfo.category = textCourseInfo["Teacher.category"];
              textCourseInfo.teachingLevel =
                textCourseInfo["Teacher.teachingLevel"];
              textCourseInfo.imageUrl = textCourseInfo["Teacher.imageUrl"];
              textCourseInfo.selfIntroduction =
                textCourseInfo["Teacher.selfIntroduction"];
              textCourseInfo.specialities =
                textCourseInfo["Teacher.specialities"];
              textCourseInfo.hobbies = textCourseInfo["Teacher.hobbies"];
            }

            resolve();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 生徒のレッスンデータ（総受講時間・総受講回数）を取得
    fetchStudentLessonData() {
      return new Promise((resolve) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(`/api/lessons/data?studentId=${this.userInfo.studentId}`, header)
          .then((response) => {
            this.studentLessonData = response.data.lessonData;
            resolve();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    reload() {
      this.reloadTable();
    },
    async reloadTable() {
      this.isShowTable = false;
      await this.$nextTick();
      this.isShowTable = true;
    },
    toCustomerPortal() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      this.axios
        .get(`/api/stripes/customerPortal/${this.userInfo.studentId}`, header)
        .then((response) => {
          window.open(response.data.customerPortalUrl);
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
  },
  head: {
    title: {
      inner: "home",
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  white-space: pre-line;
}
a {
  text-decoration: none;
}
.narrow-padding {
  padding: 5px;
}
.wide-padding {
  padding: 12px;
}
.change-lang {
  white-space: pre;
}
.teacher-card {
  margin-bottom: 30px;
}
.attention {
  font-size: 0.9em;
  margin: 10px;
  white-space: pre-line;
  color: #6e6b6b;
}
.how-to-skype-link {
  text-decoration: none;
}
.ticket-num-area {
  margin: 0 0 0 30px;
}
.ticket-num {
  color: #dcc268;
  font-size: 5em;
  font-weight: bold;
  padding: 20px;
}
.text-course-fequency {
  color: #dcc268;
  font-size: 3em;
  font-weight: bold;
  padding: 20px 20px 20px 0;
}
.add-schedule-button {
  margin: 70px 0;
}
.no-latest-teacher {
  color: #dcc268;
  font-weight: bold;
  font-size: 0.9em;
  margin: 30px 0 30px 20px;
}
.to-add-ticket-link {
  font-size: 0.9em;
  text-decoration: none;
}
.notice {
  font-size: 0.8em;
}
.change-lang-area {
  margin: 3px 0 0 0;
}
.heading {
  margin: 50px 0 0 0;
}
.subheading {
  margin: 20px 0 0 0;
}
.attention-subtitle {
  font-weight: bold;
  font-size: 1.1em;
}
.apply-for-textCourse-attention {
  margin-top: 10px;
  font-size: 0.8em;
}
.internal-link-area {
  margin: 20px 10px;
}
.internal-link-text {
  text-align: center;
  margin: 0 8px 8px 0;
  font-size: 0.8em;
  font-weight: bold;
  background-color: #cfd2d1;
  color: white;
  padding: 0 8px;
  border-radius: 4px;
}
.internal-link-text:after {
  font-family: "Font Awesome 5 Free";
  content: "\f078";
  margin-left: 4px;
}
.lesson-data {
  margin: 0 5px 10px 5px;
}
</style>
