<template>
  <div :style="{ background: '#FAFAFA' }">
    <v-container>
      <PageTitle :titleName="$t('profile.editTeacherProfile')" />
      <v-row justify="center" v-if="isTeacher" class="change-lang-area">
        <a @click="changeLocale('ja')"><p class="change-lang">日本語</p></a>
        /
        <a @click="changeLocale('de')"><p class="change-lang">Deutsch</p></a>
      </v-row>
      <v-row justify="center" v-if="isShowProfile">
        <v-col cols="12" xs="12" sm="8" md="6" lg="6">
          <ValidationObserver ref="observer" v-slot="{ invalid }" immediate>
            <!-- 画像 -->
            <FormTitleText
              class="image-form-title"
              :titleText="$t('profile.profileImage')"
            />
            <div class="image-form">
              <validationProvider
                rules="size:2000"
                v-slot="{ errors, valid }"
                name="プロフィール画像"
              >
                <v-row justify="center">
                  <label>
                    <v-row justify="center">
                      <YellowCirclePicture
                        :imageUrl="uploadImageUrl"
                        imageSize="200"
                        :enableHoverAct="true"
                      />
                    </v-row>
                    <v-file-input
                      show-size
                      counter
                      accept="image/png, image/jpeg, image/bmp"
                      :placeholder="$t('profile.requireFile')"
                      prepend-icon="mdi-camera"
                      @change="getFileContent"
                      :success="valid"
                      v-model="uploadImage"
                    >
                    </v-file-input>
                    <span class="error-text">{{ errors[0] }}</span>
                  </label>
                </v-row>
              </validationProvider>
            </div>
            <p class="red--text">{{ $t("profile.attention") }}</p>
            <!-- 名前 -->
            <div class="form">
              <NameForm
                :formTitle="$t('profile.name')"
                :name="profileForm.name"
              />
            </div>
            <!-- 講師名 -->
            <div class="form">
              <NameForm
                :formTitle="$t('profile.teacherName')"
                :name="profileForm.teacherName"
              />
            </div>
            <!-- メールアドレス -->
            <div class="form">
              <EmailForm v-model="profileForm.email" rules="required" />
            </div>
            <!-- 生年月日 -->
            <div class="form">
              <BirthdayForm v-model="profileForm.birthDay" rules="" />
            </div>
            <!-- 性別 -->
            <div class="form">
              <SexForm v-model="profileForm.sex" rules="" />
            </div>
            <!-- 国 -->
            <div class="form">
              <TeacherCountryForm
                v-model="profileForm.country"
                rules="required"
              />
            </div>
            <!-- スカイプID -->
            <div class="form">
              <SkypeIdForm v-model="profileForm.skypeId" />
            </div>
            <!-- 自己紹介 -->
            <div class="form">
              <SelfIntroductionForm v-model="profileForm.selfIntroduction" />
            </div>
            <!-- 得意分野 -->
            <div class="form">
              <SpecialitiesForm v-model="profileForm.specialities" />
            </div>
            <!-- 趣味・特技 -->
            <div class="form">
              <HobbiesForm v-model="profileForm.hobbies" />
            </div>
            <!-- キャッチフレーズ -->
            <div class="form">
              <CatchPraseForm v-model="profileForm.catchPhrase" />
            </div>
            <!-- 経歴 -->
            <div class="form">
              <BackgroundForm v-model="profileForm.background" />
            </div>
            <!-- 講師実績 -->
            <!-- TODO: okになるまで上げない -->
            <!-- <div class="form">
              <AchievementForm v-model="profileForm.achievement" />
            </div> -->
            <!-- プライベートレッスンでできること -->
            <!-- TODO: okになるまで上げない -->
            <!-- <div class="form" v-if="hasPrivClass">
              <LessonContentForm v-model="profileForm.lessonContent" />
            </div> -->
            <!-- 対応可能な教科書 -->
            <div class="form" v-if="hasPrivClass">
              <AvailableTextbookForm v-model="profileForm.availableTextbook" />
            </div>
            <!-- 授業間隔 -->
            <div class="form">
              <ClassIntervalForm
                v-model="profileForm.classInterval"
                rules="required"
              />
            </div>
            <!-- 講師からのメッセージを表示する -->
            <div class="form">
              <FormTitleText :titleText="$t('profile.meessageToStudent')" />
              <p>
                <FormAttentionText
                  :attentionText="$t('profile.messageFromTeacher')"
                />
              </p>
              <TeacherMessageForm
                type="CONVERSATION"
                :title="$t('profile.CONVERSATION')"
                v-model="profileForm.convMessage"
                v-if="hasConvClass"
              />
              <TeacherMessageForm
                type="PRIVATE"
                :title="$t('profile.PRIVATE')"
                v-model="profileForm.privMessage"
                v-if="hasPrivClass"
              />
            </div>
            <!-- リマインドメールの受け取り -->
            <div class="form">
              <FormTitleText :titleText="$t('profile.receiveRemind')" />
              <p>
                <FormAttentionText
                  :attentionText="$t('profile.remindExplanation')"
                />
              </p>
              <!-- 会話コース -->
              <RemindMailForm
                :text="$t('profile.CONVERSATION')"
                v-model="profileForm.isRemindedConv"
                v-if="hasConvClass"
              />
              <!-- プライベートコース -->
              <RemindMailForm
                :text="$t('profile.PRIVATE')"
                v-model="profileForm.isRemindedPriv"
                v-if="hasPrivClass"
              />
            </div>
            <!-- 対応レベル -->
            <div class="form">
              <CompatibleLessonForm v-model="profileForm.compatibleLevel" />
            </div>
            <!-- 担当コース -->
            <div class="form">
              <LessonCourseInChargeForm
                :lessonCourses="userInfo.lessonCourseArray"
              />
            </div>
            <!-- 新規生徒受付状況 -->
            <div class="form" v-if="hasPrivClass">
              <p>
                <FormTitleText :titleText="$t('profile.receptionStatus')" />
              </p>
              <ReceptionStatusForm
                v-if="hasConvClass"
                :formTitle="$t('profile.CONVERSATION')"
                :status="profileForm.isVisibleForConv"
              />
              <ReceptionStatusForm
                v-if="hasPrivClass"
                :formTitle="$t('profile.PRIVATE')"
                :status="profileForm.isVisibleForPriv"
              />
            </div>
            <!-- プライベートコース設定受講料（30分） -->
            <div class="form" v-if="hasPrivClass">
              <p><FormTitleText :titleText="$t('profile.lessonFeeTitle')" /></p>
              <FormAttentionText
                :attentionText="$t('profile.lessonFeeAttention')"
              />
              <div :style="{ 'margin-top': '20px' }">
                <LessonFeeForm
                  :formTitle="$t('profile.regularLessonFee')"
                  :fee="profileForm.feeForRegular"
                />
                <LessonFeeForm
                  :formTitle="$t('profile.studentLessonFee')"
                  :fee="profileForm.feeForStudent"
                />
                <LessonFeeForm
                  :formTitle="$t('profile.firstLessonFee')"
                  :fee="profileForm.feeForFirstLesson"
                />
              </div>
            </div>
            <!-- ドイツ語講師は本業ですか？ -->
            <div class="form">
              <IsMainBusinessForm v-model="profileForm.isMainBusiness" />
            </div>
            <!-- 講師経験 -->
            <div class="form">
              <p>
                <FormTitleText :titleText="$t('profile.teacherLevelTitle')" />
              </p>
              <TeacherLevelForm
                v-if="hasConvClass"
                :formTitle="$t('profile.CONVERSATION')"
                :level="profileForm.convLevel"
              />
              <TeacherLevelForm
                v-if="hasPrivClass"
                :formTitle="$t('profile.PRIVATE')"
                :level="profileForm.privLevel"
              />
            </div>
            <v-row justify="center" class="button-area">
              <v-col cols="6" sm="6" md="3">
                <active-button
                  :buttonTitle="$t('profile.save')"
                  @clickAction="saveProfile"
                  :isDisabled="invalid"
                />
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PageTitle from "../Atoms/PageTitle";
import FormTitleText from "../Atoms/FormTitleText";
import FormAttentionText from "../Atoms/FormAttentionText";
import YellowCirclePicture from "../Molecules/YellowCirclePicture";
import ActiveButton from "../Atoms/ActiveButton";
import mypageText from "@/data/mypage/mypageText";
import NameForm from "../Molecules/NameForm";
import EmailForm from "../Molecules/EmailForm";
import BirthdayForm from "../Molecules/BirthdayForm";
import SexForm from "../Molecules/SexForm";
import TeacherCountryForm from "../Molecules/TeacherCountryForm";
import SkypeIdForm from "../Molecules/SkypeIdForm";
import SelfIntroductionForm from "../Molecules/SelfIntroductionForm";
import SpecialitiesForm from "../Molecules/SpecialitiesForm";
import HobbiesForm from "../Molecules/HobbiesForm";
import CatchPraseForm from "../Molecules/CatchPraseForm";
import BackgroundForm from "../Molecules/BackgroundForm";
// TODO: okになるまで上げない
// import AchievementForm from "../Molecules/AchievementForm";
// import LessonContentForm from "../Molecules/LessonContentForm";
import AvailableTextbookForm from "../Molecules/AvailableTextbookForm";
import IsMainBusinessForm from "../Molecules/IsMainBusinessForm";
import ClassIntervalForm from "../Molecules/ClassIntervalForm";
import RemindMailForm from "../Molecules/RemindMailForm";
import TeacherMessageForm from "../Molecules/TeacherMessageForm";
import CompatibleLessonForm from "../Molecules/CompatibleLessonForm";
import LessonFeeForm from "../Molecules/LessonFeeForm";
import TeacherLevelForm from "../Molecules/TeacherLevelForm";
import LessonCourseInChargeForm from "../Molecules/LessonCourseInChargeForm";
import ReceptionStatusForm from "../Molecules/ReceptionStatusForm";

export default {
  name: "Mypage",
  inject: ["reloadRouter", "reloadHeader"],
  components: {
    PageTitle,
    FormTitleText,
    FormAttentionText,
    YellowCirclePicture,
    ActiveButton,
    NameForm,
    EmailForm,
    BirthdayForm,
    SexForm,
    TeacherCountryForm,
    SkypeIdForm,
    SelfIntroductionForm,
    SpecialitiesForm,
    HobbiesForm,
    CatchPraseForm,
    BackgroundForm,
    // TODO: okになるまで上げない
    // AchievementForm,
    // LessonContentForm,
    AvailableTextbookForm,
    IsMainBusinessForm,
    ClassIntervalForm,
    RemindMailForm,
    TeacherMessageForm,
    CompatibleLessonForm,
    LessonFeeForm,
    TeacherLevelForm,
    LessonCourseInChargeForm,
    ReceptionStatusForm,
  },
  data: () => ({
    profileForm: {
      email: "",
      birthDay: "",
      sex: "",
      country: "",
      skypeId: "",
      selfIntroduction: "",
      specialities: "",
      hobbies: "",
      catchPhrase: "",
      background: "",
      schedule: "",
      achievement: "",
      lessonContent: "",
      availableTextbook: "",
      isMainBusiness: false,
      classInterval: null,
      isRemindedConv: true,
      isRemindedPriv: true,
      convMessage: "",
      privMessage: "",
      isVisibleForPriv: false,
      compatibleLevel: "",
      image: null,
    },
    scheduleLabel: mypageText.scheduleLabel,
    uploadImageUrl: require("../../assets/user_placeholder_.png"),
    uploadImage: null,
    compatibleLevelItems: ["完全初級", "A1", "A2", "B1", "B2", "C1", "C2"],
    selectedCompatibleLevelArray: [],
    imageRules: [
      (value) =>
        !value ||
        value.size < 1000000 ||
        "ファイルサイズを1Mバイト以下にして下さい。",
    ],
    content: "",
    isEnableButton: false,
    isEditedEmail: false,
    isShowProfile: true,
  }),
  computed: {
    ...mapState({
      userInfo: (state) => state.user,
    }),
    isTeacher() {
      if (this.userInfo.userAuthority === "TEACHER") {
        return true;
      } else {
        return false;
      }
    },
    hasConvClass() {
      return this.userInfo.lessonCourseArray.includes("CONVERSATION");
    },
    hasPrivClass() {
      return this.userInfo.lessonCourseArray.includes("PRIVATE");
    },
  },
  created() {
    this.fetchTeacherInfo();
  },
  mounted() {},
  methods: {
    fetchTeacherInfo() {
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(`/api/teachers/${this.userInfo.teacherId}`, header)
          .then((response) => {
            const teacher = response.data.teacher;
            this.profileForm = teacher;

            // 日付の表示を変更
            this.profileForm.birthDay = this.$moment(
              this.profileForm.birthDay
            ).format("YYYY-MM-DD");

            // 「対応可能レベル」を配列に変更
            this.selectedCompatibleLevelArray = this.profileForm.compatibleLevel
              .split(/\n・|・/)
              .filter((item) => {
                return item != "";
              });

            // 画像をセット
            this.uploadImageUrl = this.profileForm.imageUrl;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    async getFileContent(file) {
      try {
        const content = await this.readFileAsync(file);
        this.uploadImageUrl = content;
        this.uploadImage = file;
      } catch (e) {
        this.uploadImageUrl = require("../../assets/user_placeholder_.png");
        console.log(e);
      }
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
          resolve(reader.result);
        });
        reader.onerror = reject;
      });
    },
    saveProfile() {
      console.log("profileForm.country", this.profileForm.country);
      //バリデーション通るかチェック
      this.$refs.observer.validate().then((result) => {
        if (result) {
          // 日付のフォーマットを変更する
          this.profileForm.birthDay = this.$moment(
            this.profileForm.birthDay
          ).format();

          // 画像を含むためformDataで送信
          let formData = new FormData();

          const obj = this.profileForm;
          Object.keys(obj).forEach(function (key) {
            if (obj[key] != null) {
              formData.append(key, obj[key]);
            }
          });
          formData.append("image", this.uploadImage);
          const header = {
            headers: {
              Authorization: `Bearer ${this.userInfo.accessToken}`,
              "Content-Type": "multipart/form-data",
            },
          };

          this.axios
            .put(`/api/teachers/${this.userInfo.teacherId}`, formData, header)
            .then((response) => {
              // 変更を保存しました
              alert(this.$t("profile.doneSave"));
              this.$store.commit(
                "user/SET_USER_IMAGE",
                response.data.teacher.imageUrl
              );

              // リロード
              this.reloadRouter();
              this.reloadHeader();
            })
            .catch((error) => {
              alert(error.response.data.error.message);
            });
          this.profileForm.birthDay = this.$moment(
            this.profileForm.birthDay
          ).format("YYYY-MM-DD");
        } else {
          alert("入力された内容が不十分な箇所があります。");
        }
      });
    },
    async changeLocale(lang) {
      this.$i18n.locale = lang;

      // 言語の変更をしたタイミングでテーブルを再読み込みして反映する
      this.$vuetify.lang.current = lang;
      this.isShowProfile = false;
      await this.$nextTick();
      this.isShowProfile = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.change-lang-area {
  margin: 3px 0 0 0;
}
.image-form-title {
  margin-bottom: 50px;
}
.image-form {
  margin: 50px 0;
}
.form {
  margin: 50px 0;
}
.attention {
  font-size: 0.8em;
}
.error-text {
  color: red;
  margin: 0;
  font-size: 0.8em;
}
.button-area {
  margin-bottom: 30px;
}
</style>
