<template>
  <v-col cols="12" v-if="this.messageArray.length">
    <div class="message-area">
      <span class="box-title">{{ $t("top.notice") }}</span>
      <p v-html="$sanitize(computedMessage)" />
    </div>
  </v-col>
</template>

<script>
export default {
  name: "MessageForTeacher",
  data: () => ({
    messageArray: [],
  }),
  props: [],
  created() {
    this.fetchMessageForTeacher();
  },
  computed: {
    computedMessage() {
      const randomNum = this.getRandomInt(this.messageArray.length);
      return this.messageArray[randomNum];
    },
  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    // 講師向けメッセージを取得
    fetchMessageForTeacher() {
      return new Promise((resolve, reject) => {
        this.axios
          .get(`/api/messages?displayFor=TEACHER`)
          .then((response) => {
            for (let message of response.data.message) {
              this.messageArray.push(message.content);
            }
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.message-area {
  position: relative;
  margin: 10px 0;
  padding: 1em 1em;
  border: solid 2px #6881dc;
  border-radius: 8px;
}
.message-area .box-title {
  position: absolute;
  display: inline-block;
  top: -8px;
  left: 10px;
  padding: 0 9px;
  font-size: 0.8em;
  line-height: 1;
  background: #fff;
  color: #6881dc;
  font-weight: bold;
}
.message-area p {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  word-wrap: break-word;
}
</style>
