<template>
  <v-container>
    <!-- 月変更できるところ -->
    <v-row>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn fab text small color="grey darken-2" @click="prevMonth">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ computedSelectedMonth }}
          </v-toolbar-title>
          <v-btn fab text small color="grey darken-2" @click="nextMonth">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-sheet>
    </v-row>
    <!-- 完了レッスン一覧テーブル -->
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="doneLessonList"
          :class="{ 'elevation-1': true }"
          disable-sort
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          no-data-text="担当したレッスンはありません"
          hide-default-footer
          v-if="isShowDoneList"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-pagination
      v-model="page"
      :length="pageCount"
      class="pagenation"
      circle
    ></v-pagination>
  </v-container>
</template>

<script>
export default {
  name: 'DoneLessonTable',
  inject: ['reloadRouter'],
  data: () => ({
    selectedMonth: "",
    userInfo: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      { text: "開始時間", value: 'startTime' },
      { text: "終了時間", value: 'endTime' },
      { text: '生徒', value: 'studentName' },
      { text: 'レッスンコース', value: 'lessonCourseName' },
      { text: '生徒属性', value: 'studentAttribute' },
      { text: '時間（分）', value: 'lessonTime' },
      { text: '初回', value: 'isFirstLesson' },
      { text: 'レッスンステータス', value: 'lessonStatus' },
    ],
    doneLessonList: [],
    fetchedThisMonthLessons:[],
    teacherLessonList:[],
    openedIndexArray: [],
    isShowDoneList: true
  }),
  computed: {
    computedSelectedMonth() {
      return this.$moment(this.selectedMonth).format("YYYY/ MM")
    },
    toJaLessonCourseName: function() {
      return function(lessonCourseName) {
        switch(lessonCourseName) {
          case "CONVERSATION":
            return this.$t('top.conversationCourse')
          case "PRIVATE":
            return this.$t('top.privateCourse')
          default:
            return ""
        }
      }
    },
    toJaStudentAttribute: function() {
      return function(studentAttribute) {
        switch(studentAttribute) {
          case "ADULT":
            return this.$t('top.adult')
          case "STUDENT":
            return this.$t('top.student')
          case "CHILD":
            return this.$t('top.child')
          default:
            return ""
        }
      }
    },
    // レッスン時間を算出
    computedLessonTime: function() {
      return function(startTime, endTIme) {
        const start = this.$moment(startTime)
        const end = this.$moment(endTIme)
        return this.$moment(end).diff(start, 'minutes')
      }
    },
    toStringLessonStatus: function() {
      return function(lessonStatus) {
        switch(lessonStatus) {
          case 0:
            return this.$t('top.unfinished')
          case 1:
            return this.$t('top.completed')
          case 2:
            return this.$t('top.canceledLesson')
        }
      }
    },
  },
  props:[],
  components: {
  },
  async created() {
    this.fetchInitialData()
    this.initSelectedMonth()

    try {
      await this.fetchThisMonthLessons()
    } catch(error) {
      console.log("error", error)
    }

    this.makeListItem()
    this.setupColumn()
  },
  mounted() {
  },
  methods: {
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    initSelectedMonth() {
      this.selectedMonth = this.$moment()
    },

    setupColumn() {
      // 多言語対応のため
      this.headers[0].text = this.$t('top.startTime')
      this.headers[1].text = this.$t('top.endTime')
      this.headers[2].text = this.$t('top.studentInCharge')
      this.headers[3].text = this.$t('top.course')
      this.headers[4].text = this.$t('top.studentAttribute')
      this.headers[5].text = this.$t('top.time')
      this.headers[6].text = this.$t('top.isFirstLesson')
      this.headers[7].text = this.$t('top.status')
    },

    prevMonth() {
      this.selectedMonth = this.$moment(this.selectedMonth).subtract(1, 'months')
      this.updateLessonList()
    },

    nextMonth() {
      this.selectedMonth = this.$moment(this.selectedMonth).add(1, 'months')
      this.updateLessonList()
    },
    // 月を変える時とかに使用される関数
    async updateLessonList() {
      this.isShowDoneList = false
      this.doneLessonList = []
      try {
        await this.fetchThisMonthLessons()
      } catch(error) {
        console.log("error", error)
      }

      this.makeListItem()
      this.isShowDoneList = true
    },

    // 当月のlessonを全て取得する(teacherTB・lessonCourseTBあたりの情報も合体させて)
    fetchThisMonthLessons() {
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`
          }
        };
        const statTime = this.$moment(this.selectedMonth).startOf('month').format("YYYY-MM-DD HH:mm")
        const endTime =  this.$moment(this.selectedMonth).endOf('month').format("YYYY-MM-DD HH:mm")

        this.axios
          .get(`/api/lessons/listForPayment?startTime=${statTime}&endTime=${endTime}&teacherId=${this.userInfo.teacherId}`, header)
          .then(response => {
            this.fetchedThisMonthLessons = response.data.lessons
            resolve()
          })
          .catch(error => {
            console.log(error);
            reject()
          });
      })
    },

    makeListItem() {
      for (let thisMonthLesson of this.fetchedThisMonthLessons) {
        const startTime = this.$moment(thisMonthLesson.startTime).format("YYYY/MM/DD HH:mm")
        const endTime = this.$moment(thisMonthLesson.endTime).format("YYYY/MM/DD HH:mm")
        const studentAttribute = thisMonthLesson["Student.category"]
        const lessonStatus = thisMonthLesson.status
        const lessonTime = this.computedLessonTime(startTime, endTime)

        const lessonForPaymentItem = {
          startTime: startTime,
          endTime: endTime,
          studentName: thisMonthLesson["Student.name"],
          lessonCourseName: this.toJaLessonCourseName(thisMonthLesson["LessonCourse.courseName"]),
          studentAttribute: this.toJaStudentAttribute(studentAttribute),
          lessonTime: lessonTime,
          isFirstLesson: thisMonthLesson.isFirstLesson ? this.$t('top.isFirstLesson') : "-",
          lessonStatus: this.toStringLessonStatus(lessonStatus),
        }
        this.doneLessonList.push(lessonForPaymentItem)
      }
    }
  },
};
</script>

<style>
.hoverPointer td {
  cursor: pointer;
}
.pagenation {
  margin: 20px 0 0 0;
}
</style>

