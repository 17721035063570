<!-- 定期予約受付可能日時の削除 -->
<template>
  <VmDialogCard
    :title="cardTitle"
    :dangerousButtonTitle="$t('teacherSchedule.deleteAvailableRegularSchedule')"
    :cancelButtonTitle="$t('teacherSchedule.cancel')"
    @clickDangerous="deleteRegularSchedule"
    @close="cancel"
  >
    <template #cardContent>
      <v-container>
        <v-row>
          <v-col>
            <!-- ※現時刻より48時間以降、以下日時に予約されている定期予約が全て削除されます。まだ生徒がいない場合は空き予定のみ削除されます。 -->
            <p class="attention-text">
              {{ $t("teacherSchedule.deleteRegularAttention") }}
            </p>
          </v-col>
        </v-row>
        <!-- 現在の定期予約情報 -->
        <v-row>
          <p class="subtitle">
            {{ $t("teacherSchedule.currentRegularScheduleInfo") }}
          </p>
          <TimeZoneIcon />
        </v-row>
        <v-row>
          <v-col class="student-info">
            <!-- 生徒名: -->
            <p v-if="scheduleInfo.studentId" class="regularScheduleInfoItem">
              {{ $t("teacherSchedule.student") }}:
              {{ scheduleInfo.studentName }}
            </p>
            <!-- 曜日: -->
            <p class="regularScheduleInfoItem">
              {{ $t("teacherSchedule.dayOfWeek") }}:
              {{ scheduleInfo.dayOfWeek }}
            </p>
            <!-- 開始時間: -->
            <p class="regularScheduleInfoItem">
              {{ $t("teacherSchedule.startTime") }}:
              {{ scheduleInfo.startTime }}
            </p>
            <!-- 開始時間: -->
            <p class="regularScheduleInfoItem">
              {{ $t("teacherSchedule.endTime") }}: {{ scheduleInfo.endTime }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </VmDialogCard>
</template>

<script>
import TimeZoneIcon from "../Atoms/TimeZoneIcon";
import VmDialogCard from "../Molecules/VmDialogCard";

export default {
  name: "DeleteRegularScheduleCard",
  inject: ["reloadRouter"],
  components: {
    TimeZoneIcon,
    VmDialogCard,
  },
  data: () => ({
    selectedDayOfWeek: "",
  }),
  props: ["courseName", "cardTitle", "userInfo", "scheduleInfo"],
  computed: {
    // 曜日を数字で扱う
    computedIntDayOfWeek() {
      const weekDayArrayJa = ["日", "月", "火", "水", "木", "金", "土"];
      const weekDayArrayDe = ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."];
      switch (this.$i18n.locale) {
        case "ja":
          return weekDayArrayJa.indexOf(this.scheduleInfo.dayOfWeek);
        case "de":
          return weekDayArrayDe.indexOf(this.scheduleInfo.dayOfWeek);
        default:
          return weekDayArrayJa.indexOf(this.scheduleInfo.dayOfWeek);
      }
    },
    computedStartTime() {
      return "2021-01-01 " + this.scheduleInfo.startTime;
    },
    computedEndTime() {
      return "2021-01-01 " + this.scheduleInfo.endTime;
    },
  },
  mounted() {},
  methods: {
    deleteRegularSchedule() {
      // 以下の定期予定受付可能日時を削除しますか？
      if (
        confirm(
          `${this.$t("teacherSchedule.deleteRegularConfirmation")}\n 曜日: ${
            this.scheduleInfo.dayOfWeek
          } \n レッスン開始時刻： ${
            this.scheduleInfo.startTime
          }\n レッスン終了時刻： ${this.scheduleInfo.endTime}`
        )
      ) {
        // dayOfWeek(2), startTime("00:00")を入れたら
        // startTime 2021-01-03 00:00 を返してくれる関数！
        const startTime = this.$convertToDateFormat(
          this.computedIntDayOfWeek,
          this.scheduleInfo.startTime
        );
        const endTime = this.$convertToDateFormat(
          this.computedIntDayOfWeek,
          this.scheduleInfo.endTime
        );

        const regularScheduleData = {
          regularScheduleId: this.scheduleInfo.regularScheduleId,
          teacherId: this.userInfo.teacherId,
          studentId: this.scheduleInfo.studentId,
          lessonCourseId: 2, // 今は必ずプライベートコースなので
          startTime: startTime,
          endTime: endTime,
        };

        this.deleteRequest(regularScheduleData);
      }
    },
    deleteRequest(regularScheduleData) {
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      this.axios
        .post("/api/regularSchedules/delete", regularScheduleData, header)
        .then(() => {
          this.cancel();
          this.reloadRouter();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    cancel() {
      this.$emit("cancelAction");
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.subtitle {
  font-size: 1.4em;
  font-weight: bold;
  margin: 20px 0 15px 0;
}
.student-info {
  margin: 0;
  padding: 0 12px 0 12px;
}
.button {
  margin-bottom: 20px;
}
.hyphen-text {
  font-size: 1.2em;
  margin: 20px 0 0 0;
  text-align: center;
}
.regularScheduleInfoItem {
  font-size: 1.2em;
  margin: 10px 0;
}
.attention-text {
  color: red;
}
</style>
