<template>
  <span
    :style="{
      background: 'red',
      padding: '3px',
      color: 'white',
      'font-weight': 'bold',
      'font-size': '0.5em',
      'border-radius': '3px',
      margin: '0 5px',
      position: 'relative',
      bottom: '2px',
    }"
    >{{ $t("profile.required") }}</span
  >
</template>

<script>
export default {
  name: "RequiredIcon",
  data: () => ({}),
  props: [],
  methods: {},
};
</script>

<style lang="scss" scoped></style>
