<template>
  <v-card :height="height" class="scroll" flat>
    <v-card-title v-if="title">
      <v-row no-gutters justify="center">
        <p :class="isPC ? 'dialog__title--pc' : 'dialog__title--sp'">
          {{ title }}
        </p>
      </v-row>
    </v-card-title>
    <v-row v-if="description" no-gutters justify="center">
      <p
        class="dialog__description"
        :class="isPC ? 'dialog__description--pc' : 'dialog__description--sp'"
      >
        {{ description }}
      </p>
    </v-row>
    <v-card-text>
      <slot name="cardContent" />
    </v-card-text>
    <div class="button-area">
      <v-row no-gutters justify="center" v-if="buttonTitle">
        <VmButton
          type="primary"
          @click="onClick($event)"
          :text="buttonTitle"
          class="button"
          :disabled="disabled"
        />
      </v-row>
      <v-row no-gutters justify="center" v-if="dangerousButtonTitle">
        <VmButton
          type="dangerous"
          @click="onClickDangerous($event)"
          :text="dangerousButtonTitle"
          class="button"
          :disabled="disabledDangerous"
        />
      </v-row>
      <v-row no-gutters justify="center" v-if="cancelButtonTitle">
        <VmButton
          type="cancel"
          @click="close($event)"
          :text="cancelButtonTitle"
          class="button"
        />
      </v-row>
    </div>
  </v-card>
</template>

<script>
import VmButton from "../Atoms/VmButton";
export default {
  name: "VmDialogCard",
  components: { VmButton },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    dangerousButtonTitle: {
      type: String,
      default: "",
    },
    cancelButtonTitle: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledDangerous: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      require: false,
      default: 500,
    },
  },
  data: () => ({}),
  created() {},
  mounted() {},
  computed: {},
  methods: {
    onClick($event) {
      if (this.disabled) return;
      this.$emit("click", $event);
    },
    onClickDangerous($event) {
      if (this.disabledDangerous) return;
      this.$emit("clickDangerous", $event);
    },
    close($event) {
      this.$emit("close", $event);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  white-space: pre-line;
}
.dialog__title {
  &--pc {
    color: #6d6f6c;
    font-weight: bold;
    font-size: 0.9em;
  }
  &--sp {
    color: #6d6f6c;
    font-weight: bold;
    font-size: 0.9em;
    margin-bottom: 20px;
  }
}
.dialog__description {
  text-align: center;
  &--pc {
    width: 80%;
    color: #6d6f6c;
    font-weight: bold;
    font-size: 0.8em;
    line-height: 2em;
  }
  &--sp {
    color: #6d6f6c;
    font-weight: bold;
    font-size: 0.7em;
    line-height: 2em;
    margin: 0 10px;
  }
  margin-bottom: 20px;
}
.button-area {
  margin-top: 20px;
  margin-bottom: 20px;
}
.button {
  margin-bottom: 12px;
}
.scroll {
  overflow-y: auto;
}
</style>
