<template>
  <VmDialogCard
    :title="cardTitle"
    :buttonTitle="$t('teacherSchedule.changeLessonButton')"
    :cancelButtonTitle="$t('teacherSchedule.cancel')"
    @click="changeLesson"
    @close="cancel"
    :disabled="!buttonValidation"
  >
    <template #cardContent>
      <v-container>
        <v-row>
          <p class="edit-lesson-subtitle">
            <!-- 現在のレッスン詳細 -->
            {{ $t("teacherSchedule.currentLessonInfo") }}
          </p>
          <TimeZoneIcon />
        </v-row>
        <v-row>
          <!-- コース：-->
          {{ $t("teacherSchedule.course") }}: {{ computedCourseName(courseId) }}
          <br />
          <!-- 生徒名：-->
          {{ $t("teacherSchedule.studentInCharge") }}: {{ studentName }}
          <br />
          <!-- 開始時刻：-->
          {{ $t("teacherSchedule.startTime") }}:
          {{ startTime }}
          <br />
          <!-- 終了時刻：-->
          {{ $t("teacherSchedule.endTime") }}:
          {{ endTime }}
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <p class="edit-lesson-subtitle">
            <!-- 変更先レッスン日時 -->
            {{ $t("teacherSchedule.changedLessonTime") }}
          </p>
          <TimeZoneIcon />
        </v-row>
        <!-- 開始日時のフォーム -->
        <DateAndTimeForm
          :formLabelText="$t('teacherSchedule.startTime')"
          :date.sync="scheduleStartDate"
          :showedDate="computedScheduleStartDate"
          :minDate="selectableStartMinDate"
          @changeDatePicker="activeButton"
          :time.sync="scheduleStartTime"
          :minuteInterval="5"
          @changeTimePicker="activeButton"
        />
        <v-row>
          <v-col cols="3" xs="3" sm="2" md="2" lg="2">
            <p>{{ $t("teacherSchedule.endTime") }}</p>
          </v-col>
          <v-col cols="9" xs="9" sm="10" md="10" lg="10" class="end-time">
            <p>{{ computedEndTime }}</p>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </VmDialogCard>
</template>

<script>
import TimeZoneIcon from "../Atoms/TimeZoneIcon";
import DateAndTimeForm from "../Molecules/DateAndTimeForm";
import VmDialogCard from "../Molecules/VmDialogCard";

export default {
  name: "EditPlannedConversationLessonCard",
  inject: ["reloadCalendar"],
  components: {
    TimeZoneIcon,
    DateAndTimeForm,
    VmDialogCard,
  },
  data: () => ({
    textFontWeight: "bold",
    scheduleStartDate: "2020-12-01",
    scheduleStartTime: "00:00",
    scheduleEndTime: "00:00",
    dateMenu: false,
    startTimeMenu: false,
    startAvailableTime: 0,
    startAvailableHour: 0,
    startAvailableMinute: 0,
    classRequiredMinutes: 20,
    remark: "",
    selectedDatePickerHour: 0,
    repetition: false,
    selectedCourseNames: [],
    isEdited: false,
  }),
  props: [
    "courseId",
    "cardTitle",
    "startTime",
    "endTime",
    "selectedOpen",
    "lessonId",
    "userInfo",
    "studentName",
    "studentId",
    "calendarValue",
  ],
  computed: {
    selectableStartMinDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    selectableMinEndDate() {
      return this.scheduleStartDate;
    },
    computedStartTime() {
      const start = this.scheduleStartDate + " " + this.scheduleStartTime;
      return this.$moment(start).format("YYYY-MM-DD HH:mm");
    },
    // 「レッスン開始時刻」にレッスン時間を足し、「レッスン終了時間」を算出する。
    computedEndTime() {
      return this.$moment(this.computedStartTime)
        .add(20, "m")
        .format("YYYY-MM-DD HH:mm");
    },
    computedScheduleStartDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.scheduleStartDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.scheduleStartDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
    computedCourseName: function () {
      return function (lessonCourseId) {
        switch (lessonCourseId) {
          case 1:
            return this.$t("teacherSchedule.CONVERSATION");
          case 2:
            return this.$t("teacherSchedule.PRIVATE");
          default:
            return "";
        }
      };
    },
    buttonValidation() {
      // 時刻が編集されてなかったらアウト
      if (!this.isEdited) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.initScheduleDate();
  },
  methods: {
    initScheduleDate() {
      this.scheduleStartDate = this.$moment(this.startTime).format(
        "YYYY-MM-DD"
      );
      this.scheduleStartTime = this.$moment(this.startTime).format("HH:mm");
    },
    changeLesson() {
      if (
        confirm(
          `${this.$t(
            "teacherSchedule.changeLessonConfirmation"
          )} \n\n◎${this.$t("teacherSchedule.beforeChange")}\n${this.$t(
            "teacherSchedule.startTime"
          )}: ${this.startTime}\n${this.$t("teacherSchedule.endTime")}: ${
            this.endTime
          }\n\n◎${this.$t("teacherSchedule.afterChange")}\n${this.$t(
            "teacherSchedule.startTime"
          )}: ${this.computedStartTime}\n${this.$t(
            "teacherSchedule.endTime"
          )}: ${this.computedEndTime}`
        )
      ) {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        const editedLessonData = {
          lessonId: this.lessonId,
          teacherId: this.userInfo.teacherId,
          startTime: this.$convertToPost(this.computedStartTime),
          endTime: this.$convertToPost(this.computedEndTime),
          lessonCourseId: this.courseId,
        };
        console.log("editedLessonData", editedLessonData);
        this.axios
          .post(`/api/lessons/change`, editedLessonData, header)
          .then((response) => {
            console.log("response.data", response.data);
            this.cancel();
            this.reloadCalendar(this.calendarValue);
          })
          .catch((error) => {
            alert(error.response.data.error.message);
          });
      }
    },
    cancel() {
      this.$emit("cancelAction");
    },
    activeButton() {
      this.isEdited = true;
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
.edit-lesson-subtitle {
  font-size: 1.4em;
  font-weight: bold;
  margin: 20px 0 15px 0;
}
.button {
  margin-bottom: 20px;
}
.end-time {
  padding: 12px 12px 12px 0;
  font-size: 1.2em;
}
</style>
