<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="regularAvailableArray"
    disable-sort
    disable-pagination
    :no-data-text="$t('top.noLesson')"
    hide-default-footer
  >
    <template v-slot:[`item.status`]="{ item }">
      <v-icon large color="primary" v-if="item.status == 'ok'"
        >mdi-circle-outline</v-icon
      >
      <v-icon large color="primary" v-if="item.status == 'ng'"
        >mdi-close</v-icon
      >
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'RegularReservationTable',
  inject: ['reloadHeader'],
  data: () => ({
    selected: [],
    headers: [
      { text: "開始時間", value: 'startTime' },
      { text: "終了時間", value: 'endTime' },
      { text: 'ステータス', value: 'status' },
    ],
    regularAvailableArray:[]
  }),
  props:['regularSchedules'],

  components: {

  },
  created() {
  },
  mounted() {
    this.makeRegularAvailableArray()
  },
  computed:{
    computedAvailabel: function() {
      return function(startTime) {
        let availableStartTimeArray = []
        for (let regularSchedule of this.regularSchedules) {
          availableStartTimeArray.push(this.$moment(regularSchedule.startTime).format("HH:mm"))
        }
        if (availableStartTimeArray.includes(startTime)) {
          return "ok"
        } else {
          return "ng"
        }
      }
    }
  },
  methods: {
    makeRegularAvailableArray() {
      this.regularSchedules.forEach(schedule => {
        const lessonItem = {
          startTime : this.$moment(schedule.startTime).format('HH:mm'),
          endTime : this.$moment(schedule.endTime).format('HH:mm') === "00:00" ? "24:00" : this.$moment(schedule.endTime).format('HH:mm'),
          status: "ok"
        }
        this.regularAvailableArray.push(lessonItem)
      })

      let pushedSchedules = []
      let count = 0
      // スキマにステータスが×の予定を入れていく
      this.regularAvailableArray.forEach((schedule, index) => {
        if (index >= 1) {
          const lessonItem = {
            startTime : this.regularAvailableArray[index - 1].endTime,
            endTime : schedule.startTime,
            status: "ng"
          }

          pushedSchedules.push({
            index:index,
            count: count,
            lessonItem : lessonItem
          })
          count = count + 1
        }
      })

      for (let pushedSchedule of pushedSchedules) {
        this.regularAvailableArray.splice(pushedSchedule.index + pushedSchedule.count, 0 ,pushedSchedule.lessonItem)
      }
    },
  },
  head: {
  }
};
</script>

<style lang="scss" scoped>
</style>

