<template>
  <v-hover v-slot="{ hover }">
    <v-card rounded="lg" :color="computedColor" :elevation="hover ? 5 : 2" hover @click="clickAction" :disabled="isDisabled">
      <v-card-text class="text-center white--text">
        <v-row justify="center" >
          <v-icon large color="white" v-if="scheduleInfo.status == 'ok'" :class="computedMargin">mdi-circle-outline</v-icon>
          <v-icon large color="white" v-if="scheduleInfo.status == 'sankaku'" :class="computedMargin">mdi-triangle-outline</v-icon>
          <v-icon large color="white" v-if="scheduleInfo.status == 'ng'" :class="computedMargin">mdi-close</v-icon>
        </v-row>
        <v-row justify="center" v-if="scheduleInfo.status != 'ng'">
          <p class="icon-text ajust-margin">
            {{scheduleInfo.startTime}}
          </p>
        </v-row>
        <v-row justify="center" v-if="scheduleInfo.status != 'ng'">
          <p class="icon-text">
            |
          </p>
        </v-row>
        <v-row justify="center" v-if="scheduleInfo.status != 'ng'">
          <p class="icon-text">
            {{scheduleInfo.endTime}}
          </p>
        </v-row>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>

export default {
  name: 'RegularScheduleIcon',

  data: () => ({
  }),

  computed: {
    computedColor() {
      switch(this.scheduleInfo.status) {
        case "ng":
          return "cancel"
        default :
          return "primary"
      }
    },
    computedMargin() {
      switch(this.scheduleInfo.status) {
        case "ng":
          return "mt-8 mb-8"
        default :
          return "ma-auto"
      }
    },
    isDisabled() {
      switch(this.scheduleInfo.status) {
        case "ng":
          return true
        default :
          return false
      }
    }
  },

  methods: {
    clickAction() {
      this.$emit('clickAction')
    }
  },

  components: {
  },

  props:['scheduleInfo']
};
</script>

<style lang="scss" scoped>

.icon-text {
  font-size: 0.9em;
  margin: 0;
}
.ajust-margin {
  margin: -1px;
}
</style>
