<template>
  <v-container>
    <!-- タイトル -->
    <PageTitle title-name="テキストコース一覧" />
    <VmBox>
      <template #header>
        <v-col class="csv-upload-area">
          <CsvUploadArea type="TEXT" @reload="reloadTable" />
        </v-col>
      </template>
      <template #content>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="textCoursesArray"
          :search="search"
          multi-sort
          class="elevation-1"
          disable-pagination
          no-data-text="テキストコースがありません"
          hide-default-footer
          v-if="showTable"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-dialog v-model="addDialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                  新規テキストコース
                </v-btn>
              </template>
              <AdminAddFormCard
                @closeAction="closeAdd"
                @addAction="add"
                :editedItem="editedItem"
                formTitle="テキストコース追加"
                @changeFile="changeFile($event)"
              />
            </v-dialog>
            <v-dialog v-model="dialog" max-width="500px">
              <AdminEditingFormCard
                @closeAction="close"
                @saveAction="save"
                :editedItem.sync="editedItem"
                formTitle="テキストコース情報編集"
                @changeFile="changeFile($event)"
              />
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <AdminDeleteFormCard
                @closeDeleteAction="closeDelete"
                @deleteItemConfirmAction="deleteItemConfirm"
                formTitle="このテキストコース情報を削除しますか？"
              />
            </v-dialog>
            <v-text-field
              v-model="search"
              label="Search"
              class="filter-item"
            ></v-text-field>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <img :src="item.image" class="table-img" />
          </template>
        </v-data-table>
      </template>
    </VmBox>
  </v-container>
</template>

<script>
import AdminEditingFormCard from '../Organisms/AdminEditingFormCard'
import AdminDeleteFormCard from '../Organisms/AdminDeleteFormCard'
import AdminAddFormCard from '../Organisms/AdminAddFormCard'
import CsvUploadArea from '../Molecules/CsvUploadArea.vue';
import PageTitle from "../Atoms/PageTitle";
import VmBox from '../Atoms/VmBox'

export default {
  name: 'AdminTextCourseList',
  components: {
    AdminEditingFormCard,
    AdminDeleteFormCard,
    AdminAddFormCard,
    CsvUploadArea,
    PageTitle,
    VmBox
  },
  data: () => ({
    textCourses: null,
    selected: [],
    textCoursesArray: [],
    isShowOnlyDone: false,
    isShowFromToday: false,
    search:'',
    dialog: false,
    addDialog: false,
    dialogDelete: false,
    selectedTextCourseId: 0,
    defaultItem: {
      teacherId: null,
      teacherName: null,
      studentId: null,
      studentIdNum: null,
      studentName: null,
      frequency: null,
    },
    editedItem: {
      teacherId: null,
      teacherName: null,
      studentId: null,
      studentIdNum: null,
      studentName: null,
      frequency: null,
    },
    uploadedImage:"",
    showTable: true,
    isLoading: false
  }),
  computed: {
    headers() {
      return [
        {
          text: 'textCourseId',
          align: 'start',
          value: 'textCourseId',
        },
        { text: 'teacherId', value: 'teacherId',},
        { text: '講師名', value: 'teacherName' },
        { text: 'studentId', value: 'studentId',},
        { text: '生徒番号', value: 'studentIdNum' },
        { text: '生徒名', value: 'studentName' },
        { text: '訂正の頻度', value: 'frequency' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    }
  },
  props:[],
  created() {
    this.fetchInitialData()
    this.reloadTable()
  },
  mounted() {
  },
  methods: {
    reloadTable() {
      this.fetchTextCourses()
        .then(() =>{
          this.makeStudentArray()
        })
        .catch(error=>{
          console.log(error)
        })
    },
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      const user_info = this.$store.getters["user/getUserInfo"];
      this.accessToken = user_info.accessToken;
    },
    fetchTextCourses() {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.accessToken}`
          }
        };
        this.axios
          .get(`/api/textCourses`, header)
          .then(response => {
            this.textCourses = response.data.textCourses
            this.isLoading = false
            resolve()
          })
          .catch(error => {
            console.log(error);
            reject()
          });
      })
    },
    makeStudentArray() {
      // リセット
      this.textCoursesArray = []
      this.textCourses.forEach( (textCourse) => {

        const textCourseData = {
          textCourseId: textCourse.id,
          teacherId: textCourse["Teacher.id"],
          teacherName: textCourse["Teacher.teacherName"],
          studentId: textCourse["Student.id"],
          studentIdNum: textCourse["Student.studentIdNum"],
          studentName: textCourse["Student.name"],
          frequency: textCourse.frequency,
        }
        this.textCoursesArray.push(
          textCourseData
        )
      })
    },
    editItem (item) {
      this.selectedTextCourseId = item.textCourseId
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.selectedTextCourseId = item.textCourseId
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    addStudent (item) {
      this.editedItem = Object.assign({}, item)
      this.addDialog = true
    },
    deleteItemConfirm () {
      const header = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`
        }
      };

      this.axios
        .delete(`/api/textCourses/${this.selectedTextCourseId}`, header)
        .then(response => {
          console.log("response.data", response.data)
          this.closeDelete()
          this.reloadTable()
        })
        .catch(error => {
          alert(error.response.data.error.message)
        });
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    closeAdd() {
      this.addDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    save() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        }
      };

      this.axios
        .put(`/api/textCourses/${this.selectedTextCourseId}`, this.editedItem, header)
        .then(response => {
          console.log("response.data", response.data)
          this.close()
          this.reloadTable()
        })
        .catch(error => {
          alert(error.response.data.error.message)
        });
    },
    add() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`
        }
      };

      this.axios
        .post(`/api/textCourses`, this.editedItem, header)
        .then(response => {
          console.log("response.data", response.data)
          this.close()
          this.reloadTable()
        })
        .catch(error => {
          alert(error.response.data.error.message)
        });
    },
    changeFile(elem) {
      this.uploadedImage = ""
      const files = elem.target.files || elem.dataTransfer.files
      this.uploadedImage = files[0]
    }
  },
};
</script>

<style lang="scss" scoped>
v-data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #dcc268;
}

thead.v-data-table-header {
  background-color: #dcc268;
}

.filter-area {
  padding: 0 20px;
}
.filter-item {
  padding: 10px 5px;
}
.table-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>
