<template>
  <v-container>
    <!-- テキストコーステーブル -->
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="textCourseList"
          :class="{ 'elevation-1': true }"
          disable-sort
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :no-data-text="$t('top.noTextCourseData')"
          hide-default-footer
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-pagination
      v-model="page"
      :length="pageCount"
      class="pagenation"
      circle
    ></v-pagination>
  </v-container>
</template>

<script>
import userInfoTranslate from '@/data/translation/userInfoTranslate';

export default {
  name: 'TextCourseTable',
  inject: ['reloadRouter'],
  data: () => ({
    userInfo: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      { text: '担当生徒', value: 'studentName' },
      { text: '頻度', value: 'frequency' },
    ],
    textCourseList: [],
    fetchedTextCourseArray:[],
  }),
  computed: {
    computedFrequencyName: function() {
      return function(frequencyCode) {
        return userInfoTranslate[frequencyCode]
      }
    }
  },
  props:[],
  components: {
  },
  async created() {
    this.fetchInitialData()

    try {
      await this.fetchTextCourseInfo()
    } catch(error) {
      console.log("error", error)
    }

    this.makeListItem()
    this.setupColumn()
  },
  mounted() {
  },
  methods: {
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },

    setupColumn() {
      // 多言語対応のため
      this.headers[0].text = this.$t('top.studentInCharge')
      this.headers[1].text = this.$t('top.textCourseFrequency')
    },

    // 担当しているテキストコースデータを取得
    fetchTextCourseInfo() {
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`
          }
        };

        this.axios
          .get(`/api/textCourses?teacherId=${this.userInfo.teacherId}`, header)
          .then(response => {
            this.fetchedTextCourseArray = response.data.textCourses
            resolve()
          })
          .catch(error => {
            console.log(error);
            reject()
          });
      })
    },

    makeListItem() {
      for (let textCourseData of this.fetchedTextCourseArray) {

        const textCourseItem = {
          studentName: textCourseData["Student.name"],
          frequency: this.computedFrequencyName(textCourseData.frequency),
        }
        this.textCourseList.push(textCourseItem)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.hoverPointer td {
  cursor: pointer;
}
.pagenation {
  margin: 20px 0 0 0;
}
</style>

