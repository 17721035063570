<template>
  <v-container>
    <v-hover disabled>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="plannedLessonArray"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :class="{ 'elevation-1': true, hoverPointer: hoverPointer }"
        hide-default-footer
        disable-sort
        :no-data-text="$t('top.noLesson')"
        @click:row="clickRow"
      >
        <template v-slot:top class="not-yubi">
          <!-- （講師）完了報告ダイアログ -->
          <v-dialog
            v-model="showReportDialog"
            v-if="showReportDialog"
            max-width="500px"
          >
            <LessonReportCard
              @closeAction="closeReportCard"
              @reportAction="report"
              :formTitle="
                selectedItem.isFirstLesson
                  ? $t('top.reportFirstLesson')
                  : $t('top.reportLesson')
              "
              :lessonInfo="selectedItem"
              :reportForm="reportForm"
            />
          </v-dialog>
          <!-- （講師）レッスン詳細ダイアログ -->
          <v-dialog
            v-model="showDetailDialog"
            v-if="showDetailDialog"
            max-width="500px"
          >
            <LessonDetailCard
              @closeAction="closeDetailCard"
              :formTitle="$t('top.lessonDetail')"
              :lessonInfo="selectedItem"
              :accessToken="userInfo.accessToken"
            />
          </v-dialog>
          <!-- （生徒）レッスンキャンセルダイアログ -->
          <v-dialog
            v-model="showCancelDialog"
            v-if="showCancelDialog"
            max-width="500px"
          >
            <LessonCancelCard
              @closeAction="closeCancelCard"
              @cancelAction="cancelLesson"
              :formTitle="$t('top.cancelLessonTitle')"
              :lessonInfo="selectedItem"
              :cancelForm="cancelForm"
              :isTeacher="isTeacher"
            />
          </v-dialog>
          <v-dialog
            v-model="showCancelDisableDialog"
            v-if="showCancelDisableDialog"
            max-width="500px"
          >
            <CancelDisableCard @closeAction="closeDisableDialog" />
          </v-dialog>
        </template>
        <template v-slot:[`item.detail`]>
          <div v-if="isTeacher">
            <v-btn text x-small>
              <v-icon color="primary">mdi-information-outline</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <!-- （講師）完了報告ボタン -->
            <v-col v-if="isTeacher" cols="6">
              <ActiveButton
                :button-title="reportButtonTitle(item)"
                @clickAction="openReportCard(item)"
                class="report-button"
                :is-disabled="isDisableReport(item)"
              />
            </v-col>
            <!-- レッスンキャンセルボタン -->
            <v-col cols="6">
              <div v-if="isDisableCancel(item)">
                <TouchableDisableButton
                  :button-title="$t('top.cancelLesson')"
                  @clickAction="openDisableDialog"
                  class="report-button"
                  :isDisabled="isTeacher"
                />
              </div>
              <div v-else>
                <ActiveButton
                  :button-title="$t('top.cancelLesson')"
                  @clickAction="openCancelCard(item)"
                  class="report-button"
                />
              </div>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-hover>
    <v-pagination
      v-model="page"
      :length="pageCount"
      class="pagenation"
      circle
    ></v-pagination>
  </v-container>
</template>

<script>
import ActiveButton from "../Atoms/ActiveButton";
import TouchableDisableButton from "../Atoms/TouchableDisableButton";
import LessonReportCard from "../Organisms/LessonReportCard";
import LessonCancelCard from "../Organisms/LessonCancelCard";
import LessonDetailCard from "../Organisms/LessonDetailCard";
import CancelDisableCard from "../Molecules/CancelDisableCard";

export default {
  name: "ScheduleTable",
  inject: ["reloadRouter", "reloadHeader"],
  data: () => ({
    userInfo: null,
    page: 1,
    pageCount: 0,
    selected: [],
    headers: [],
    plannedLessonArray: [],
    showReportDialog: false,
    showDetailDialog: false,
    showCancelDialog: false,
    showCancelDisableDialog: false,
    reportForm: {
      lessonId: null,
      status: null,
      lessonReport: "",
      lessonFeedbackGood: "",
      lessonFeedbackImprovement: "",
      lessonFeedback: "",
      lessonCourseId: null,
      studentEmail: "",
      studentName: "",
      teacherName: "",
      lessonPlanPdf: "",
      memoForTeacher: "",
      reasonForUnnecessary: "",
    },
    cancelForm: {},
    selectedItem: null,
    studentMonthlyFeedback: false,
  }),
  computed: {
    // computedに引数を持たせたまま別のcomputedを使うことが困難だったため
    // 冗長な書き方をしている
    reportButtonTitle() {
      // 完了・キャンセルの場合は「報告済み」になる
      return (item) => {
        switch (item.status) {
          case this.$t("top.completed"):
          case this.$t("top.canceledLesson"):
            return this.$t("top.reported");
          default:
            return this.$t("top.report");
        }
      };
    },
    isDisableReport() {
      // 完了・キャンセルの場合はボタンが押せなくなる
      return (item) => {
        // 終了時刻 が 現在時刻の後のものだけボタンが押せるようになる
        const now = this.$moment();
        const lessonEndTime = this.$moment(item.endTime);

        if (now.isAfter(lessonEndTime)) {
          return false;
        } else {
          return true;
        }
      };
    },
    // 授業開始まで48時間を切るとキャンセルできなくなる
    isDisableCancel() {
      return (item) => {
        const startTime = this.$moment(item.startTime);
        const tomorrow = this.$moment().add(1, "days");
        const twoDaysAfterDay = this.$moment().add(2, "days");

        // 講師は1日前まで、
        // 生徒は2日前までならキャンセルできる
        if (this.isTeacher) {
          // レッスンの開始時刻 < 1日後
          if (tomorrow.isAfter(startTime)) {
            return true;
          } else {
            return false;
          }
        } else {
          // レッスンの開始時刻 < 2日後
          if (twoDaysAfterDay.isAfter(startTime)) {
            return true;
          } else {
            return false;
          }
        }
      };
    },
    hoverPointer() {
      if (this.isTeacher) {
        return "hoverPointer";
      } else {
        return "";
      }
    },
    lessonFeedback() {
      if (this.studentMonthlyFeedback) {
        let feedback = "";
        feedback = `▫️いいところ\n${this.reportForm.lessonFeedbackGood}\n\n\n▫️もっとよくなるところ\n${this.reportForm.lessonFeedbackImprovement}`;
        return feedback;
      } else {
        return "";
      }
    },
    alertChargeText() {
      if (this.isTeacher) {
        return `${this.$t("top.student")}： ${this.selectedItem.studentName}`;
      } else {
        return `講師名： ${this.selectedItem.teacherName}先生`;
      }
    },
    itemsPerPage() {
      if (this.isPC) {
        return 10;
      } else {
        return 5;
      }
    },
  },
  props: ["lessons", "inChageColumnName", "isTeacher"],
  components: {
    ActiveButton,
    TouchableDisableButton,
    LessonReportCard,
    LessonCancelCard,
    LessonDetailCard,
    CancelDisableCard,
  },
  created() {
    this.setupColumn();
    this.fetchInitialData();
  },
  mounted() {
    this.makePlannedLessonArray();
  },
  methods: {
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    makePlannedLessonArray() {
      for (let lesson of this.lessons) {
        const plannedLesson = {
          lessonId: lesson.id,
          startTime: this.$moment(lesson.startTime).format("YYYY-MM-DD HH:mm"),
          endTime: this.$moment(lesson.endTime).format("YYYY-MM-DD HH:mm"),
          course: this.translateCourseName(lesson["LessonCourse.courseName"]),
          lessonCourseId: Number(lesson["LessonCourse.id"]),
          studentId: lesson["Student.id"],
          studentName: lesson["Student.name"],
          studentEmail: lesson["Student.email"],
          studentSex: lesson["Student.sex"],
          studentBirthDay: lesson["Student.birthDay"],
          studentCountry: lesson["Student.country"],
          studentLevel: lesson["Student.level"],
          studentCorrectionFrequency: lesson["Student.correctionFrequency"],
          studentMonthlyFeedback: lesson["Student.monthlyFeedback"],
          studentHopeInLesson: lesson["Student.hopeInLesson"],
          studentLearningGoal: lesson["Student.learningGoal"],
          studentOtherItem: lesson["Student.otherItem"],
          hopeForLesson: lesson.hopeForLesson,
          messageForTeacher: lesson.messageForTeacher,
          isFirstLesson: lesson.isFirstLesson,
          strIsFirstLesson: lesson.isFirstLesson
            ? this.$t("top.isFirstLesson")
            : "-",
          teacherId: lesson["Teacher.id"],
          teacherName: lesson["Teacher.teacherName"],
          skypeId: lesson["Teacher.skypeId"],
          status: this.stringStatus(lesson.status),
        };
        this.plannedLessonArray.push(plannedLesson);
      }
    },
    translateCourseName(value) {
      switch (value) {
        case "CONVERSATION":
          return this.$t("top.conversationCourse");
        case "PRIVATE":
          return this.$t("top.privateCourse");
        default:
          return "";
      }
    },
    stringStatus(value) {
      switch (value) {
        case 0:
          return this.$t("top.unfinished");
        case 1:
          return this.$t("top.completed");
        case 2:
          return this.$t("top.canceledLesson");
        default:
          return "";
      }
    },
    openReportCard(item) {
      this.showReportDialog = true;
      this.reportForm.lessonId = item.lessonId;
      this.reportForm.studentEmail = item.studentEmail;
      this.reportForm.studentName = item.studentName;
      this.reportForm.teacherName = item.teacherName;
      this.reportForm.lessonCourseId = item.lessonCourseId;
      this.reportForm.isFirstLesson = item.isFirstLesson;
      this.studentMonthlyFeedback = item.studentMonthlyFeedback;
      this.selectedItem = item;
    },
    openCancelCard(item) {
      this.showCancelDialog = true;
      this.cancelForm.lessonId = item.lessonId;
      this.cancelForm.teacherId = item.teacherId;
      this.cancelForm.studentId = item.studentId;
      this.cancelForm.lessonCourseId = item.lessonCourseId;
      this.cancelForm.cancelComment = "";
      if (this.isTeacher) {
        this.cancelForm.cancelBy = "TEACHER";
      } else {
        this.cancelForm.cancelBy = "STUDENT";
      }
      this.selectedItem = item;
    },
    openDisableDialog() {
      this.showCancelDisableDialog = true;
    },
    closeReportCard() {
      this.showReportDialog = false;
      this.reportForm.lessonPlanPdf = "";
      this.reportForm.reasonForUnnecessary = "";
      this.reportForm.status = null;
    },
    closeCancelCard() {
      this.showCancelDialog = false;
    },
    closeDisableDialog() {
      this.showCancelDisableDialog = false;
    },
    clickRow(row) {
      // 講師でログインしている時のみ、レッスン詳細情報が見れるようにする
      if (this.isTeacher) {
        this.showDetailDialog = true;
        this.selectedItem = row;
      }
    },
    closeDetailCard() {
      this.showDetailDialog = false;
    },
    report() {
      if (confirm(this.$t("top.reportConfirmation"))) {
        // ステータス更新
        switch (this.reportForm.status) {
          case this.$t("top.completed"):
            this.reportForm.status = 1;
            break;
          case this.$t("top.canceledLesson"):
            this.reportForm.status = 2;

            // キャンセルの時はレポート/FB書かない
            this.reportForm.lessonReport = "";
            this.reportForm.lessonFeedback = "";
            break;
        }

        // フィードバックを統合する
        this.reportForm.lessonFeedback = this.lessonFeedback;

        // レッスン計画書がある場合はフォーマットを変更しなければいけない
        if (this.reportForm.lessonPlanPdf === "") {
          const header = {
            headers: {
              Authorization: `Bearer ${this.userInfo.accessToken}`,
            },
          };
          this.axios
            .post(`/api/lessons/report`, this.reportForm, header)
            .then((response) => {
              console.log("response.data", response.data);
              this.closeReportCard();
              this.reloadRouter();
            })
            .catch((error) => {
              alert(error.response.data.error.message);
            });
        } else {
          let formData = new FormData();

          const obj = this.reportForm;
          Object.keys(obj).forEach(function(key) {
            if (obj[key] != null) {
              formData.append(key, obj[key]);
            }
          });
          formData.append("lessonPdf", this.reportForm.lessonPlanPdf);

          const header = {
            headers: {
              Authorization: `Bearer ${this.userInfo.accessToken}`,
              "Content-Type": "multipart/form-data",
            },
          };

          this.axios
            .post(`/api/lessons/report`, formData, header)
            .then((response) => {
              console.log("response.data", response.data);
              this.closeReportCard();
              this.reloadRouter();
            })
            .catch((error) => {
              alert(error.response.data.error.message);
            });
        }
      }
    },
    cancelLesson() {
      // 注意：以下のレッスンを本当にキャンセルしますか？ レッスン開始時刻: レッスン終了時刻: 生徒/講師
      if (
        confirm(
          `${this.$t("top.cancelLessonAttention")}\n ${this.$t(
            "top.lessonStartTime"
          )}： ${this.selectedItem.startTime}  \n ${this.$t(
            "top.lessonEndTime"
          )}： ${this.selectedItem.endTime}  \n ${this.alertChargeText}`
        )
      ) {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        this.axios
          .post("/api/lessons/cancel", this.cancelForm, header)
          .then((response) => {
            console.log("response.data", response.data);

            // 生徒がキャンセルした時は残りチケット枚数を更新する
            if (!this.isTeacher) {
              this.$store.commit(
                "user/SET_USER_TICKETNUM",
                response.data.student.numberOfTicket
              );
            }
            this.closeCancelCard();
            this.reloadRouter();
            this.reloadHeader();
          })
          .catch((error) => {
            alert(error.response.data.error.message);
          });
      }
    },
    setupColumn() {
      if (this.isTeacher) {
        this.headers.push({ text: "", value: "detail" });
        this.headers.push({ text: "開始時間", value: "startTime" });
        this.headers.push({ text: "終了時間", value: "endTime" });
        this.headers.push({ text: "コース", value: "course" });
        this.headers.push({ text: "担当生徒", value: "studentName" });
        this.headers.push({ text: "ステータス", value: "status" });
        this.headers.push({ text: "初回", value: "strIsFirstLesson" });
        this.headers.push({ text: "", value: "actions", sortable: false });
      } else {
        this.headers.push({ text: "開始時間", value: "startTime" });
        this.headers.push({ text: "終了時間", value: "endTime" });
        this.headers.push({ text: "コース", value: "course" });
        this.headers.push({ text: "担当講師", value: "teacherName" });
        this.headers.push({ text: "skype ID", value: "skypeId" });
        this.headers.push({ text: "", value: "actions", sortable: false });
      }

      // 多言語対応のため
      if (this.isTeacher) {
        this.headers[1].text = this.$t("top.startTime");
        this.headers[2].text = this.$t("top.endTime");
        this.headers[3].text = this.$t("top.course");
        this.headers[4].text = this.$t("top.studentInCharge");
        this.headers[5].text = this.$t("top.status");
        this.headers[6].text = this.$t("top.isFirstLesson");
      } else {
        this.headers[0].text = this.$t("top.startTime");
        this.headers[1].text = this.$t("top.endTime");
        this.headers[2].text = this.$t("top.course");
      }
    },
  },
};
</script>

<style>
.hoverPointer td {
  cursor: pointer;
}
.pagenation {
  margin: 20px 0 0 0;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  height: auto;
  min-height: 45px;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 16px;
}
/* 最終行だけ、ボタンのためheight大きめで */
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child {
  height: 50px;
}
</style>
