<template>
  <v-container>
    <v-row>
      <!-- 定期予約ダイアログ -->
      <v-dialog
        v-model="openRegularReservationCard"
        v-if="openRegularReservationCard"
        offset-x
        max-width="500px"
      >
        <RequestLoginForm @cancelAction="closeForm" v-if="!isLogin" />
        <RegularReservationFormCard
          v-else
          @cancelAction="closeForm"
          :regularSchedules="filterBySelectedWeekOfDay(this.selectedDayOfWeek)"
          :dayOfWeek="this.selectedDayOfWeek"
          :userInfo="userInfo"
          :teacherId="teacherId"
          :lessonCourseId="2"
          :teacherName="teacherName"
          :isFirstLesson="isFirstLesson"
        />
      </v-dialog>
      <v-col v-for="n in 7" :key="n" :cols="isPC ? null : 3">
        <v-row justify="center">
          <p
            class="font-weight-bold darkGray--text"
            :style="{ 'margin-top': '15px' }"
          >
            {{ computedWeek(n - 1) }}
          </p>
        </v-row>
        <!-- 定期予約アイコン -->
        <component
          :is="regularScheduleIcon"
          @clickAction="openForm(n - 1)"
          :scheduleInfo="simplifiedAvailableRegularSchedules[n - 1]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RegularScheduleIcon from "../Molecules/RegularScheduleIcon";
import RegularReservationFormCard from "../Molecules/RegularReservationFormCard";
import RequestLoginForm from "../Molecules/RequestLoginForm";

export default {
  name: "RegularSchedule",

  data: () => ({
    openRegularReservationCard: false,
    userInfo: {},
    simplifiedAvailableRegularSchedules: [],
    regularScheduleIcon: null,
    selectedDayOfWeek: null,
  }),

  props: [
    "teacherName",
    "fetchedAvailableRegularSchedules",
    "regularSchedulesWithDayOfWeek",
    "isFirstLesson",
    "teacherId",
  ],
  computed: {
    filterBySelectedWeekOfDay: function() {
      return function(selectedDayOfWeek) {
        const schedulesFilteredByWeekDay = this.regularSchedulesWithDayOfWeek.filter(
          (schedule) => schedule.dayOfWeek === selectedDayOfWeek
        );
        return schedulesFilteredByWeekDay[0].combinedSchedules;
      };
    },
    computedWeek: function() {
      return function(value) {
        let dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"];
        return dayOfWeekStr[value];
      };
    },
  },
  mounted() {
    this.fetchInitialData();
    this.makeRegularScheduleIconInfo();
  },

  methods: {
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    // 定期レッスンのアイコン 例） ◯ 10:00-18:00, △ 12:00-15:00
    makeRegularScheduleIconInfo() {
      for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
        const schedulesFilteredByWeekDay = this.regularSchedulesWithDayOfWeek.filter(
          (schedule) => schedule.dayOfWeek === dayOfWeek
        );

        let schedules = schedulesFilteredByWeekDay[0].combinedSchedules;

        // 時間順に昇順に並べる
        schedules.sort((a, b) => {
          const aTime = this.$moment(a.startTime).format("HH:mm");
          const bTime = this.$moment(b.startTime).format("HH:mm");

          if (this.$compareTime(aTime, bTime)) {
            return 1;
          } else {
            return -1;
          }
        });

        // フイルターされた結果データがなかった場合はstartTimeもendTimeもnullにして返す
        if (!schedules.length) {
          const info = {
            dayOfWeek: dayOfWeek,
            startTime: null,
            endTime: null,
            status: "ng",
          };
          this.simplifiedAvailableRegularSchedules.push(info);
          continue;
        } else {
          const startTime = this.$moment(schedules[0].startTime).format(
            "HH:mm"
          );
          const endTime = this.$moment(
            schedules[schedules.length - 1].endTime
          ).format("HH:mm");

          let status = "ok";

          // schedulesFilteredByWeekDayの配列の要素が2つ以上で
          // その間が空いていたら"sankaku"
          if (schedules.length >= 2) {
            let count = 0;
            for (let i = 0; i < schedules.length; i++) {
              if (i >= 1) {
                if (!schedules[i].startTime.isSame(schedules[i - 1].endTime)) {
                  count++;
                }
              }
            }

            if (count >= 1) {
              status = "sankaku";
            } else {
              status = "ok";
            }
          } else {
            // 要素1つなら必ずok
            status = "ok";
          }

          const info = {
            dayOfWeek: dayOfWeek,
            startTime: startTime,
            endTime: endTime === "00:00" ? "24:00" : endTime,
            status: status,
          };
          this.simplifiedAvailableRegularSchedules.push(info);
        }
      }
      // iconに伝える情報が出揃ってから、iconたちは生成する
      this.regularScheduleIcon = RegularScheduleIcon;
    },
    openForm(index) {
      this.selectedDayOfWeek = index;
      this.openRegularReservationCard = true;
    },
    closeForm() {
      this.openRegularReservationCard = false;
    },
  },

  components: {
    RegularScheduleIcon,
    RegularReservationFormCard,
    RequestLoginForm,
  },
};
</script>

<style lang="scss" scoped>
.col {
  padding: 2px !important;
}
</style>
