<template>
  <v-container>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="teacherScheduleArray"
      :search="search"
      multi-sort
      class="elevation-1"
      disable-pagination
      no-data-text="講師予定がありません"
      hide-default-footer
    >
      <template v-slot:top>
        <v-row class="filter-area">
          <v-text-field
            v-model="search"
            label="Search"
            class="filter-item"
          ></v-text-field>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

export default {
  name: 'AdminTeacherScheduleList',
  data: () => ({
    teacherSchedules: null,
    selected: [],
    teacherScheduleArray: [],
    search:'',
  }),
  computed: {
    headers() {
      return [
        {
          text: '講師予定Id',
          align: 'start',
          value: 'teacherScheduleId',
        },
        { text: '講師名', value: 'teacherName' },
        { text: '開始時刻', value: 'startTime' },
        { text: '終了時刻', value: 'endTime' },
      ]
    }
  },
  props:[],
  components: {
  },
  created() {
    this.fetchInitialData()
    this.fetchTeacherSchedules()
      .then(() =>{
        this.makeTeacherAcheduleArray()
      })
      .catch(error=>{
        console.log(error)
      })
  },
  mounted() {
  },
  methods: {
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      const user_info = this.$store.getters["user/getUserInfo"];
      this.accessToken = user_info.accessToken;
    },
    fetchTeacherSchedules() {
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.accessToken}`
          }
        };
        this.axios
          .get(`/api/teacherSchedules`, header)
          .then(response => {
            this.teacherSchedules = response.data.teacherSchedules
            console.log("teacherSchedules", this.teacherSchedules)
            resolve()
          })
          .catch(error => {
            console.log(error);
            reject()
          });
      })
    },
    makeTeacherAcheduleArray() {
      for (let teacherSchedule of this.teacherSchedules) {
        const teacherScheduleData = {
          teacherScheduleId: teacherSchedule.id,
          teacherName: teacherSchedule["Teacher.teacherName"],
          startTime: this.$moment(teacherSchedule.startTime).format('YYYY-MM-DD HH:mm'),
          endTime: this.$moment(teacherSchedule.endTime).format('YYYY-MM-DD HH:mm'),
        }
        this.teacherScheduleArray.push(
          teacherScheduleData
        )
      }
    },
  },
};
</script>

<style lang="scss" scoped>
v-data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #dcc268;
}

thead.v-data-table-header {
  background-color: #dcc268;
}

.filter-area {
  padding: 0 20px;
}
.filter-item {
  padding: 0 5px;
}
.table-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>
