<template>
  <p>{{name}}</p>
</template>

<script>

export default {
  name: 'TeacherCardNextLabel',
  data: () => ({
  }),
  components: {
  },
  methods: {
  },
  props:['name'],
};
</script>

<style lang="scss" scoped>
p {
  font-weight: bold;
  font-size: 0.8em;
  margin-bottom: 0;
  text-align: right;
}
</style>

