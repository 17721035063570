var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    background: '#FAFAFA',
    'margin-right': 'auto',
    'margin-left': 'auto',
    height: 'calc(100vh - 180px)',
  })},[_c('v-container',[_c('PageTitle',{attrs:{"title-name":_vm.$t('lessonRequest.title')}}),_c('ChangeLocale'),(_vm.lessonRequests)?_c('div',{staticClass:"lesson-request-area"},[_c('v-data-table',{class:{ 'elevation-1': true },attrs:{"headers":_vm.headers,"items":_vm.lessonRequestItems,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","disable-sort":"","loading":_vm.isLoading,"no-data-text":_vm.$t('lessonRequest.noLessonRequest')},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"statusIcon rounded-pill",style:({
              'background-color':
                item.status === _vm.$t('lessonRequest.completed')
                  ? '#CFD2D1'
                  : '#DCC268',
            })},[_vm._v(_vm._s(item.status))])]}}],null,true)}),(_vm.showLessonRequestDetail)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showLessonRequestDetail),callback:function ($$v) {_vm.showLessonRequestDetail=$$v},expression:"showLessonRequestDetail"}},[_c('LessonRequestDetailCard',{attrs:{"requestInfo":_vm.selectedItem},on:{"complete":_vm.completeRequest,"close":_vm.closeDetailCard}})],1):_vm._e(),_c('v-pagination',{staticClass:"pagenation",attrs:{"length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }