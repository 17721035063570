// dayOfWeek(2), startTime("00:00")を入れたら
// startTime 2021-01-03 00:00 を返してくれる関数
function convertToDateFormat(dayOfWeek, time) {
  let fomattedTime;

  const hour = Number(time.split(':')[0])

  // ドイツ時刻 && dayOfWeek === 6 && timeが16:00 - 24:00の時は、"2020-12-31"になる
  if (this.userInfo.timeZone === 'Europe/Berlin' && Number(dayOfWeek) === 6 && hour >= 16 && hour <= 24) {
    fomattedTime = "2020-12-31" + " " + time + ":00" + this.$moment().format("ZZ")
  } else {
    fomattedTime = "2021-01-0" + Number(Number(dayOfWeek) + 1) + " " + time + ":00" + this.$moment().format("ZZ")
  }
  return fomattedTime
}

export default convertToDateFormat
