<template>
  <VmDialogCard
    title="レッスン定期予約"
    buttonTitle="予約完了する"
    cancelButtonTitle="閉じる"
    @click="reserveRegular"
    @close="cancel"
    :disabled="!buttonValidation || loading"
  >
    <template #cardContent>
      <v-container>
        <v-row>
          <p class="report-subtitle">レッスン情報</p>
        </v-row>
        <v-row>
          <p>
            ◎担当講師:
            <span>{{ teacherName }} 先生</span>
          </p>
        </v-row>
        <v-row>
          <p>
            ◎受講コース:
            <span>{{ computedLessonCourse }}</span>
          </p>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <p class="report-subtitle">定期予約可能時間</p>
        </v-row>
        <v-row>
          <v-col cols="12">
            <RegularReservationTable :regularSchedules="regularSchedules" />
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <p class="report-subtitle">定期予約申し込み内容</p>
          <TimeZoneIcon />
        </v-row>
        <v-row>
          <p class="">◎曜日: {{ computedWeek(dayOfWeek) }}曜日</p>
        </v-row>
        <v-row>
          <p class="time-text">◎時間:</p>
        </v-row>
        <v-row no-gutters class="date-form-area">
          <!-- 開始時間タイムピッカー -->
          <v-col cols="9" xs="9" sm="5" md="5" lg="5" class="picker">
            <v-row no-gutters justify="start">
              <TimePicker
                v-model="lessonStartTime"
                :minuteInterval="5"
                :minTime="selectableMinTime"
                :maxTime="selectableMaxTime"
              />
            </v-row>
          </v-col>
          <v-col cols="1" xs="1" sm="2" md="2" lg="2" class="hyphen-text">
            <v-row no-gutters> - </v-row>
          </v-col>
          <!-- 終了時間タイムピッカー -->
          <v-col cols="9" xs="9" sm="5" md="5" lg="5" class="picker">
            <v-row no-gutters justify="start">
              <TimePicker
                v-model="lessonEndTime"
                :minuteInterval="5"
                format="kk:mm"
                :minTime="selectableMinTime"
                :maxTime="selectableMaxTime"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="!buttonValidation">
          <v-col class="validation-text-col">
            <p class="validation-text">
              予約できる時間は30分、1時間、1時間半です。
            </p>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </VmDialogCard>
</template>

<script>
import RegularReservationTable from "../Molecules/RegularReservationTable";
import TimeZoneIcon from "../Atoms/TimeZoneIcon";
import TimePicker from "../Atoms/TimePicker";
import VmDialogCard from "../Molecules/VmDialogCard";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RegularReservationFormCard",
  inject: ["reloadHeader"],
  components: {
    RegularReservationTable,
    TimeZoneIcon,
    TimePicker,
    VmDialogCard,
  },
  data: () => ({
    textFontWeight: "bold",
    lessonStartTime: "00:00",
    lessonEndTime: "00:00",
    startTimeMenu: false,
    endTimeMenu: false,
  }),
  props: [
    "regularSchedules",
    "dayOfWeek",
    "lessonCourseId",
    "teacherName",
    "isFirstLesson",
    "teacherId",
  ],
  created() {},
  mounted() {
    this.initLessonStartTime();
  },
  computed: {
    ...mapGetters({
      userInfo: "user/getUserInfo",
      loading: "reserve/loading",
    }),
    buttonValidation() {
      // バリデーション - 選択した時間が1時間半以下になるようにする
      if (!this.lessonTimevalidation) {
        return false;
      }
      // バリデーション - 予約可能な時刻かどうかを調べる
      if (!this.lessonAvailablevalidation) {
        return false;
      }
      return true;
    },
    // 'HH:mm'の形式にしないとうまく効かない
    selectableMinTime() {
      return this.$moment(this.regularSchedules[0].startTime).format("HH:mm");
    },
    selectableMaxTime() {
      const maxTime = this.$moment(
        this.regularSchedules[this.regularSchedules.length - 1].endTime
      ).format("HH:mm");

      return maxTime === "00:00" ? "24:00" : maxTime;
    },
    // バリデーション - 選択した時間帯がすべて予約可能であるか調べる関数
    lessonAvailablevalidation() {
      // 入力している時間をフォーマット通りにする
      let count = 0;
      for (let schedule of this.regularSchedules) {
        const scheduleStartTime = this.$moment(schedule.startTime).format(
          "HH:mm"
        );
        const scheduleEndTime =
          this.$moment(schedule.endTime).format("HH:mm") === "00:00"
            ? "24:00"
            : this.$moment(schedule.endTime).format("HH:mm");

        if (
          this.$compareTime(this.lessonStartTime, scheduleStartTime) &&
          this.$compareTime(scheduleEndTime, this.lessonEndTime)
        ) {
          count = count + 1;
        }
      }

      if (count === 0) {
        return false;
      } else {
        return true;
      }
    },
    // バリデーション - 単純なレッスン時間に関するバリデーション
    lessonTimevalidation() {
      // moment.jsで扱えるように"2021-01-01 "をつけている
      // 差分を計算するだけなので適当に"2021-01-01 "にしている
      const start = "2021-01-01 " + this.lessonStartTime;
      const end = "2021-01-01 " + this.lessonEndTime;

      const lessonTime = this.$moment(end).diff(start, "minutes");

      // 30分で割り切れなかったらアウト
      if (lessonTime % 30 != 0) {
        return false;
      }

      const numberOfTableItem = lessonTime / 30;

      // 開始が終了より遅い時間ならアウト
      if (this.$moment(end).isBefore(this.$moment(start))) {
        return false;
      }

      // 0分の場合アウト
      if (numberOfTableItem === 0) {
        return false;
      }

      // 2時間以上の場合アウト
      if (numberOfTableItem > 3) {
        return false;
      }

      return true;
    },
    computedWeek: function () {
      return function (value) {
        let dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"];
        return dayOfWeekStr[value];
      };
    },
    computedLessonCourse() {
      let courseNameArray = ["会話コース", "プライベートコース"];
      return courseNameArray[this.lessonCourseId - 1];
    },
  },
  methods: {
    ...mapActions({
      regularReserve: "reserve/regularReserve",
    }),
    initLessonStartTime() {
      const initialTime = this.$moment(
        this.regularSchedules[0].startTime
      ).format("HH:mm");
      const endTime = this.$moment(
        this.regularSchedules[this.regularSchedules.length - 1].endTime
      ).format("HH:mm");

      this.lessonStartTime = initialTime;
      this.lessonEndTime = endTime === "00:00" ? "24:00" : initialTime;
    },
    async reserveRegular() {
      if (
        confirm(
          `【予約確認】\n 定期予約を完了しますか？ \n 担当講師：${
            this.teacherName
          }先生 \n コース名：${
            this.computedLessonCourse
          } \n 曜日： ${this.computedWeek(
            this.dayOfWeek
          )}曜日 \n レッスン開始時刻： ${
            this.lessonStartTime
          } \n レッスン終了時刻： ${
            this.lessonEndTime
          } \n ※定期予約を解除する際は講師に連絡する必要があります。`
        )
      ) {
        // バリデーション - 選択した時間が1時間半以下になるようにする
        if (!this.lessonTimevalidation) {
          alert("予約できる時間は30分、1時間、1時間半です。");
          return;
        }

        // dayOfWeekを消して、lessonStartTime, lessonEndTimeなどと組み合わせて
        const startTime = this.$convertToDateFormat(
          this.dayOfWeek,
          this.lessonStartTime
        );
        const endTime = this.$convertToDateFormat(
          this.dayOfWeek,
          this.lessonEndTime
        );

        const reserveData = {
          studentId: this.userInfo.studentId,
          teacherId: this.teacherId,
          lessonCourseId: this.lessonCourseId,
          startTime: startTime,
          endTime: endTime,
          isFirstLesson: this.isFirstLesson,
        };

        const isSuccess = await this.regularReserve({
          accessToken: this.userInfo.accessToken,
          reserveData: reserveData,
        });

        if (isSuccess) {
          this.cancel();
          this.$router.push({
            name: "CompletePrivateReservation",
            params: {
              teacherId: this.teacherId,
            },
          });
        }
      }
    },
    cancel() {
      this.$emit("cancelAction");
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
.scroll {
  overflow-y: auto;
}
.report-subtitle {
  font-size: 1.4em;
  font-weight: bold;
  margin: 20px 0 15px 0;
}
.button {
  margin-bottom: 20px;
}
.hyphen-text {
  font-size: 1.2em;
  margin: 30px 0 0 0;
  text-align: center;
}
.picker {
  margin: 10px 0 0 0;
}
.time-text {
  margin: 0;
}
.validation-text-col {
  padding: 0 0 0 12px;
}
.validation-text {
  color: red;
  margin: 0;
}
.date-form-area {
  margin: 30px 0 0 0;
}
</style>
