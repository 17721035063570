<template>
  <v-btn
    :color="buttonColor"
    @click.stop="onClick($event)"
    :style="buttonStyle"
    tile
    elevation="0"
    class="rounded-sm button"
    :disabled="disabled"
    :outlined="type === 'primaryOutlined'"
  >
    <p :style="{ color: buttonTextColor }">{{ text }}</p>
  </v-btn>
</template>

<script>
export default {
  name: "VmButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "primary",
      validator: (value) =>
        [
          "primary",
          "primaryOutlined",
          "secondary",
          "cancel",
          "dangerous",
        ].indexOf(value) !== -1,
    },
  },
  components: {},
  data: () => ({}),
  created() {},
  mounted() {},
  computed: {
    buttonColor() {
      switch (this.type) {
        case "primary":
        case "primaryOutlined":
          return "primary";
        case "secondary":
          return "secondary";
        case "dangerous":
          return "dangerous";
        case "cancel":
          return "lightGray";
        default:
          return "primary";
      }
    },
    buttonTextColor() {
      switch (this.type) {
        case "primary":
        case "secondary":
        case "cancel":
        case "dangerous":
          return "white";
        case "primaryOutlined":
          return "primary";
        default:
          return "white";
      }
    },
    buttonStyle() {
      if (this.type === "primaryOutlined") {
        return {
          fontWeight: "bold",
          "background-color": "white",
          padding: "15px 30px",
          "font-size": "0.8em",
        };
      } else {
        return {
          fontWeight: "bold",
          padding: "15px 30px",
          "font-size": "0.8em",
        };
      }
    },
  },
  methods: {
    onClick($event) {
      if (this.disabled) return;
      this.$emit("click", $event);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.button {
  min-width: 200px !important;
}
</style>
