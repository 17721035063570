import { render, staticRenderFns } from "./BaseCirclePicture.vue?vue&type=template&id=cfc79914&scoped=true&"
import script from "./BaseCirclePicture.vue?vue&type=script&lang=js&"
export * from "./BaseCirclePicture.vue?vue&type=script&lang=js&"
import style0 from "./BaseCirclePicture.vue?vue&type=style&index=0&id=cfc79914&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfc79914",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
installComponents(component, {VAvatar})
