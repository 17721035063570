<template>
  <v-row no-gutters class="cheer-up-area">
    <v-img
      class="lesson-data-icon"
      :src="computedImage"
      max-width="60px"
      contain
    ></v-img>
    <div
      v-html="$sanitize(messageHtml)"
      class="balloon1-left"
      :style="textStyle"
    ></div>
  </v-row>
</template>

<script>
export default {
  name: "CheerUpArea",
  created() {
    this.fetchMessageForStudent();
  },
  data: () => ({
    messageArray: [],
  }),
  props: ["displayFor"],
  computed: {
    computedMessage() {
      const randomNum = this.getRandomInt(this.messageArray.length);
      return this.messageArray[randomNum];
    },
    computedImage() {
      const randomNum = this.getRandomInt(6);
      return require(`../../assets/cheer_up_${randomNum}.png`);
    },
    textStyle() {
      return {
        "font-size": this.isPC ? "0.8em" : "0.7em",
      };
    },
    messageHtml() {
      return `${this.computedMessage}`;
    },
  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    // 生徒向けメッセージを取得
    fetchMessageForStudent() {
      return new Promise((resolve, reject) => {
        this.axios
          .get(`/api/messages?displayFor=STUDENT`)
          .then((response) => {
            for (let message of response.data.message) {
              this.messageArray.push(message.content);
            }
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
p {
  white-space: pre-line;
}
.cheer-up-area {
  margin: 0;
}
.balloon1-left {
  position: relative;
  display: inline-block;
  margin: 0.8em 0 0.8em 5px;
  padding: 15px;
  min-width: 120px;
  max-width: 75%;
  color: #fff;
  font-size: 16px;
  background: #6881dc;
  border-radius: 5px;
  font-weight: bold;
}

.balloon1-left:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  margin-top: -5px;
  border: 5px solid transparent;
  border-right: 5px solid #6881dc;
}

.balloon1-left p {
  color: #fff;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
</style>
