<template>
  <validationProvider
    rules="required|max:150"
    v-slot="{ errors, valid }"
    immediate
    name="得意分野"
  >
    <p>
      <FormTitleText :titleText="$t('profile.specialty')" />
      <FormAttentionText :attentionText="$t('profile.max150')" />
    </p>
    <v-textarea
      v-model="inputedSpecialities"
      :placeholder="specialitiesLabel"
      outlined
      background-color="white"
      counter
      :success="valid"
    ></v-textarea>
    <FormErrorText :errorText="errors[0]" />
  </validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText';
import FormErrorText from '../Atoms/FormErrorText';
import FormAttentionText from '../Atoms/FormAttentionText';
import mypageText from '@/data/mypage/mypageText';

export default {
  name: 'SpecialitiesForm',
  data: () => ({
    specialitiesLabel: mypageText.specialitiesLabel,
  }),
  props: ['value'],
  mounted() {
  },
  computed: {
    inputedSpecialities: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
  },
  components: {
    FormTitleText,
    FormErrorText,
    FormAttentionText
  }
};
</script>

<style lang="scss" scoped>
</style>
