<template>
  <v-main>
    <v-container>
      <v-row justify="center" align-content="center" class="message-area">
        <v-col cols="12">
          <h1 class="primary--text">予約が完了しました！</h1>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10" xs="10" sm="10" md="8" lg="8" xl="8">
          <p class="attention-message">
            {{ attentionMessage1 }}
            キャンセルポリシー：<a
              href="https://vollmond.online/cancel-policy/"
              target="_blank"
              rel="noopener"
              >https://vollmond.online/cancel-policy/</a
            >
            ）
            {{ attentionMessage2 }}
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10" xs="10" sm="10" md="8" lg="8" xl="8">
          <active-button
            button-title="続けて予約する"
            @clickAction="toSchedule"
            class="to-reserve-button"
          />
          <router-link to="/studentTop" style="text-decoration: none">
            <p>トップ画面に戻る</p>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton';

export default {
  name: 'CompletePrivateReservation',
  props: ["teacherId"],
  data: () => ({
    attentionMessage1: "■レッスンの日時変更・お休み\n【レッスン開始48時間前まで】\n▷キャンセル料は発生しません。\n・日時の変更が可能です。担当講師へ相談してください。\n・日時変更を行わずお休みにする場合は、マイページトップ画面より「キャンセル」を行ってください。講師にキャンセル通知が届きます。\n\n【レッスン開始48時間前以降】\n▷キャンセル料が発生します( ",
    attentionMessage2:"\n・日時変更は行えず、キャンセル扱いとなります。\n・お休みにする場合、レッスン前までに講師へその旨ご連絡ください。無断キャンセルはご遠慮ください。\n・お休みしたレッスンを別日時で振替希望の場合は、別途マイページより新しくレッスン予約を行ってください。"
  }),
  methods: {
    toSchedule() {
      this.$router.push({ path: 'privateTeacherSchedule', query: { teacherId: this.teacherId } })
    }
  },

  components: {
    ActiveButton
  }
};
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
}
p {
  margin-top: 30px;
  text-align: center;
}
.to-reserve-button {
  margin-top: 40px;
}
.attention-message {
  margin: 60px 0 0 0;
  text-align: left;
  white-space: pre-line;
  padding: 30px;
  background-color: #f8f8f8;
  line-height: 1.8em;
}
.bold-text {
  font-weight: bold;
  margin: 0;
}
.important-attention {
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: red;
  background-color: yellow;
}
.accent-text {
  font-size: 1.2em;
  font-weight: bold;
  margin: 50px 0 0 0;
  line-height: 1.8em;
}
</style>
