// 日を跨いでいるスケジュールを分割する関数
// 例) 1/1 18:00 - 1/2 9:00 → ①1/1 18:00 - 1/1 24:00 ② 1/2 0:00 - 1/2 9:00
// 定期予定を扱う際にしようする
import moment from 'moment'
import convertToAccurateTime from './convertToAccurateTime'

function divideStraddlingDaySchedule(schedules) {
  let dividedSchedules = []
  schedules.forEach(schedule => {

    // 日を跨ぐ条件
    // 開始時刻の曜日と終了時刻の曜日が違う時は日を跨いでいる
    // しかし、終了時刻が0:00の時は跨いでいない。
    // TODO: scheduleのデータ失われてしまうのは困るので情報を保てるようにする
    if (convertToAccurateTime(schedule.startTime).format("DD") != convertToAccurateTime(schedule.endTime).format("DD") && convertToAccurateTime(schedule.endTime).format("HH:mm") != "00:00") {
      // 参照元の値（schedule）がつられて変わってしまわないように値渡し（のようなもの）をする
      let firstSchedule = Object.assign({}, schedule)
      let secondSchedule = Object.assign({}, schedule)

      // 分断する - 24:00
      firstSchedule.startTime = convertToAccurateTime(schedule.startTime)
      firstSchedule.endTime = moment(convertToAccurateTime(schedule.startTime)).endOf('day').add(1, "minutes")
      // 分裂によって生まれた予定であるフラグをつけておく
      firstSchedule.isDivided = true

      // 分断する 00:00 -
      secondSchedule.startTime = moment(convertToAccurateTime(schedule.endTime)).startOf('day')
      secondSchedule.endTime = convertToAccurateTime(schedule.endTime)
      // 分裂によって生まれた予定であるフラグをつけておく
      secondSchedule.isDivided = true

      dividedSchedules.push(firstSchedule)
      dividedSchedules.push(secondSchedule)

    } else {
      schedule.startTime = convertToAccurateTime(schedule.startTime)
      schedule.endTime = convertToAccurateTime(schedule.endTime)

      dividedSchedules.push(schedule)
    }

  });

  return dividedSchedules
}

export default divideStraddlingDaySchedule
