import axios from "axios";

const state = {
  userName: "",
  accessToken: "",
  studentId: "",
  teacherId: "",
  imageUrl: "",
  userAuthority: "",
  numberOfTicket: 0,
  lessonCourseArray: [],
  timeZone: "",
  beginningOfWeek: 0,
  isReservedStripe: null,
  level: "",
  studentCategory: ""
};

const getters = {
  getUserInfo(state) {
    return state;
  },
  isTeacher(state) {
    return state.userAuthority === "TEACHER"
  }
}

const mutations = {
  SET_USER_INFO(state, param) {
    state.userName = param.userName;
    state.accessToken = param.accessToken;
    state.studentId = param.studentId
    state.teacherId = param.teacherId
    state.imageUrl = param.imageUrl
    state.userAuthority = param.userAuthority
    state.numberOfTicket = param.numberOfTicket
    state.lessonCourseArray = param.lessonCourseArray
    state.timeZone = param.timeZone
    state.beginningOfWeek = param.beginningOfWeek
    state.isReservedStripe = param.isReservedStripe
    state.level = param.level
    state.studentCategory = param.studentCategory
  },
  SET_USER_TICKETNUM(state, numberOfTicket) {
    state.numberOfTicket = numberOfTicket
  },
  SET_USER_IMAGE(state, imageUrl) {
    state.imageUrl = imageUrl
  },
  SET_TEACHER_INFO(state, teacherId) {
    state.teacherId = teacherId
  },
  SET_STUDENT_INFO(state, studentId) {
    state.studentId = studentId
  },
  SET_TIMEZONE(state, timeZone) {
    state.timeZone = timeZone
  },
  SET_BEGGINING_OF_WEEK(state, beginningOfWeek) {
    state.beginningOfWeek = beginningOfWeek
  },
};

const actions = {
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/login", data)
        .then(response => {
          console.log("response", response)
          let userParam = {}

          switch (response.data.userAuthority) {
            case "STUDENT":
              userParam = {
                userName: response.data.user.name,
                accessToken: response.data.user.accessToken,
                studentId: response.data.user.id,
                imageUrl: response.data.user.imageUrl,
                userAuthority: response.data.userAuthority,
                numberOfTicket: response.data.user.numberOfTicket,
                lessonCourseArray: response.data.lessonCourseArrray,
                timeZone: response.data.user.timeZone,
                beginningOfWeek: response.data.user.beginningOfWeek,
                isReservedStripe: response.data.user.stripeId ? true : false,
                level: response.data.user.level,
                studentCategory: response.data.user.category,
              };
              break
            case "TEACHER":
              userParam = {
                userName: response.data.user.teacherName,
                accessToken: response.data.user.accessToken,
                teacherId: response.data.user.id,
                imageUrl: response.data.user.imageUrl,
                userAuthority: response.data.userAuthority,
                lessonCourseArray: response.data.lessonCourseArrray,
                timeZone: response.data.user.timeZone,
                beginningOfWeek: response.data.user.beginningOfWeek,
                isReservedStripe: false, // 講師はstripe情報を登録していないため
                level: null,
                studentCategory: null
              };
              break
            case "ADMIN":
              userParam = {
                accessToken: response.data.user.accessToken,
                userAuthority: response.data.userAuthority,
              };
              break
          }
          commit("SET_USER_INFO", userParam);
          resolve(userParam);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
