<template>
  <div :style="{ background: '#FAFAFA' }">
    <v-container>
      <PageTitle titleName="Vollmondアカウント登録" />
      <!-- チュートリアル画像 -->
      <v-row no-gutters justify="center">
        <v-col cols="6" xs="6">
          <v-img
            :src="require('../../assets/tutorial_img.png')"
            contain
          ></v-img>
        </v-col>
      </v-row>
      <!-- はじめに -->
      <v-row no-gutters justify="center">
        <v-col cols="12" xs="12" sm="8" md="8" lg="6">
          <div :style="{ background: 'white', padding: '15px' }">
            <p :style="{ 'font-size': '0.8em' }">
              【はじめに】<span :style="{ color: 'red' }"
                >Vollmondの初回体験レッスンは、責任をもって1人1人に向き合いたいという思いから、基本的に有料で提供しております</span
              >（初回料金は講師によって異なりますが、大抵の場合は通常レッスンよりリーズナブルです）。
              <br /><br />
              具体的な料金については<a
                href="https://vollmond.online/privatkurs-lehrer/"
                target="_blank"
                rel="noopener"
                >HPをご確認ください。</a
              >
            </p>
          </div>
        </v-col>
      </v-row>
      <!-- 区切り線 -->
      <v-row no-gutters justify="center">
        <hr :style="{ color: '#6D6F6C', width: '100px', margin: '15px 0' }" />
      </v-row>
      <!-- ここから各フォーム -->
      <v-row no-gutters justify="center">
        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <!-- レッスンの希望 -->
            <div class="form" :style="{ margin: '0 0 40px 0' }">
              <HopeLessonCourse
                v-model="profileForm.lessonCourseIds"
                rules="required"
              />
            </div>
            <!-- お名前 -->
            <!-- 入力をやり直す時は、名前が空文字じゃないか確認してから出現させることで、子コンポーネントに値の受け渡しができる -->
            <div
              class="form"
              v-if="modifiedProfileForm == null || profileForm.name != ''"
            >
              <RegisterNameForm v-model="profileForm.name" rules="required" />
            </div>
            <!-- メールアドレス -->
            <div class="form">
              <EmailForm v-model="profileForm.email" rules="required" />
            </div>
            <!-- パスワード -->
            <form @submit.prevent>
              <div class="form">
                <PasswordForm
                  v-model="profileForm.password"
                  rules="required"
                  :isConfirmed="false"
                />
              </div>
            </form>
            <!-- パスワード（確認用） -->
            <form @submit.prevent>
              <div class="form">
                <PasswordForm
                  v-model="profileForm.confirmedPass"
                  rules="required"
                  :isConfirmed="true"
                />
              </div>
            </form>
            <!-- 生年月日 -->
            <div class="form">
              <BirthdayForm v-model="profileForm.birthDay" rules="required" />
            </div>
            <!-- 性別 -->
            <div class="form">
              <SexForm v-model="profileForm.sex" rules="" />
            </div>
            <!-- 区分 -->
            <div class="form">
              <EnterCategoryForm
                v-model="profileForm.category"
                rules="required"
              />
            </div>
            <!-- 在籍学校 -->
            <div class="form" v-if="profileForm.category === 'STUDENT'">
              <SchoolForm v-model="profileForm.school" />
            </div>
            <!-- 専攻名 -->
            <div class="form" v-if="profileForm.category === 'STUDENT'">
              <MajorForm v-model="profileForm.major" />
            </div>
            <!-- 現在の居住地（日本・海外） -->
            <div class="form">
              <StudentCountryForm
                v-model="profileForm.country"
                rules="required"
              />
            </div>
            <!-- 現在のドイツ語レベル -->
            <div class="form">
              <LevelForm v-model="profileForm.level" rules="required" />
            </div>
            <!-- レッスンで学びたいこと（文法、読解、作文添削、リスニング、発音、会話、試験対策） -->
            <div class="form">
              <HopeInLessonForm
                v-model="profileForm.hopeInLesson"
                rules="required"
              />
            </div>
            <!-- （会話コースの場合）訂正頻度 -->
            <div
              class="form"
              v-if="profileForm.lessonCourseIds.includes('会話コース')"
            >
              <CorrectionFrequencyForm
                v-model="profileForm.correctionFrequency"
                rules="required"
              />
            </div>
            <!-- ドイツ語を勉強している目的や目標 -->
            <div class="form">
              <LearningGoalForm v-model="profileForm.learningGoal" />
            </div>
            <!-- その他講師に伝えておきたいこと -->
            <div class="form">
              <OtherItemForm v-model="profileForm.otherItem" />
            </div>

            <!-- 入力をやり直す時は、同意関係の値が空文字じゃないか確認してから出現させることで、子コンポーネントに値の受け渡しができる -->
            <!-- キャンセルポリシー -->
            <div
              class="form"
              v-if="modifiedProfileForm == null || hasAgreedCancelPolicy != ''"
            >
              <CancelPolicyForm
                v-model="hasAgreedCancelPolicy"
                rules="required"
              />
            </div>

            <!-- お支払いについて -->
            <div
              class="form"
              v-if="modifiedProfileForm == null || hasAgreedPaymentRule != ''"
            >
              <AgreePaymentRuleForm
                v-model="hasAgreedPaymentRule"
                rules="required"
              />
            </div>
            <!-- 入力内容確認画面へ進むボタン -->
            <v-row justify="center">
              <v-col cols="12">
                <v-btn
                  color="primary"
                  tile
                  elevation="0"
                  class="rounded-sm"
                  :style="{
                    'font-size': '1em',
                  }"
                  :disabled="invalid"
                  block
                  @click="toConfirm"
                  height="44px"
                >
                  <p :style="{ fontWeight: 'bold' }">入力内容を確認</p>
                </v-btn>
              </v-col>
            </v-row>
            <p :style="{ 'font-size': '0.9em', margin: '20px 0 40px 0' }">
              <a
                href="https://vollmond.online/terms/"
                target="_blank"
                rel="noopener"
                :style="{ 'text-decoration': 'none' }"
                >利用規約</a
              >・<a
                href="https://vollmond.online/privacy-policy/"
                target="_blank"
                rel="noopener"
                :style="{ 'text-decoration': 'none' }"
                >プライバシーポリシー</a
              >をお読みの上、同意してお進みください。
            </p>
          </ValidationObserver>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../Atoms/PageTitle";
import HopeLessonCourse from "../Molecules/HopeLessonCourse";
import RegisterNameForm from "../Molecules/RegisterNameForm";
import EmailForm from "../Molecules/EmailForm";
import PasswordForm from "../Molecules/PasswordForm";
import BirthdayForm from "../Molecules/BirthdayForm";
import SexForm from "../Molecules/SexForm";
import EnterCategoryForm from "../Molecules/EnterCategoryForm";
import SchoolForm from "../Molecules/SchoolForm";
import MajorForm from "../Molecules/MajorForm";
import StudentCountryForm from "../Molecules/StudentCountryForm";
import LevelForm from "../Molecules/LevelForm";
import HopeInLessonForm from "../Molecules/HopeInLessonForm";
import CorrectionFrequencyForm from "../Molecules/CorrectionFrequencyForm";
import LearningGoalForm from "../Molecules/LearningGoalForm";
import OtherItemForm from "../Molecules/OtherItemForm";
import CancelPolicyForm from "../Molecules/CancelPolicyForm";
import AgreePaymentRuleForm from "../Molecules/AgreePaymentRuleForm";

export default {
  name: "SignUp",
  props: ['modifiedProfileForm'],
  data: () => ({
    profileForm: {
      lessonCourseIds: "",
      name: "",
      email: "",
      password: "",
      confirmedPass: "",
      birthDay: "",
      sex: "",
      category: "",
      school: "",
      major: "",
      level: "",
      country: "",
      hopeInLesson: "",
      correctionFrequency: "",
      learningGoal: "",
      otherItem: ""
    },
    hasAgreedCancelPolicy: "",
    hasAgreedPaymentRule: "",
    // profileForm: {
    //   lessonCourseIds: "プライベートコース",
    //   name: "Yamada Hanako",
    //   email: "example@gmail.com",
    //   password: "example",
    //   confirmedPass: "example",
    //   birthDay: "1994/09/10",
    //   sex: "1",
    //   category: "ADULT",
    //   school: "",
    //   major: "",
    //   level: "A2",
    //   country: "JAPAN",
    //   hopeInLesson: "文法/Grammatik",
    //   correctionFrequency: "",
    //   learningGoal: "",
    //   otherItem: ""
    // },
    // hasAgreedCancelPolicy: "キャンセルポリシーに同意する",
    // hasAgreedPaymentRule: "後払いであることを理解し、期日までに入金することに同意します。"
  }),
  created() {
  },
  mounted() {
    if (this.modifiedProfileForm) {
      this.profileForm = this.modifiedProfileForm
      this.hasAgreedCancelPolicy = "キャンセルポリシーに同意する"
      this.hasAgreedPaymentRule = "後払いであることを理解し、期日までに入金することに同意します。"
    }
  },
  components: {
    PageTitle,
    HopeLessonCourse,
    RegisterNameForm,
    EmailForm,
    PasswordForm,
    BirthdayForm,
    SexForm,
    EnterCategoryForm,
    SchoolForm,
    MajorForm,
    StudentCountryForm,
    LevelForm,
    HopeInLessonForm,
    CorrectionFrequencyForm,
    LearningGoalForm,
    OtherItemForm,
    CancelPolicyForm,
    AgreePaymentRuleForm
  },
  computed: {
  },
  methods: {
    toConfirm() {
      if (this.profileForm.password != this.profileForm.confirmedPass) {
        alert("確認用パスワードが一致しているかどうかお確かめ下さい")
      }

      this.$refs.observer.validate().then(result => {
        if (result) {
          this.$router.push({ name: "ConfirmRegisteredInfo" , params: {profileForm : this.profileForm}}).catch(() => {});
        } else {
          alert("入力された内容が不十分な箇所があります。")
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  white-space: pre-line;
}
.form {
  margin: 50px 0;
}
</style>
