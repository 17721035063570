<template>
  <v-card>
    <v-card-title>
      <span class="headline">チケット追加</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="10">
          <p class="label-text">生徒名: {{ studentName }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-select
            :items="ticketNumItems"
            v-model="selectedTicket"
            label="チケット枚数を選択してください"
            solo
            hide-details
          ></v-select>
          <v-text-field
            label="チケット枚数を入力（半角数字）"
            v-model="inputedNum"
            solo
            hide-details
            v-if="selectedTicket === 'その他（手動入力）'"
            type="number"
            :style="{ 'margin-top': '10px' }"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <p
            class="unit-text"
            :style="
              selectedTicket === 'その他（手動入力）'
                ? { 'margin-top': '80px' }
                : { 'margin-top': '25px' }
            "
          >
            枚
          </p>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
      <v-btn color="blue darken-1" text @click="addTicket">
        チケット追加
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'AdminAddTicketCard',
  data: () => ({
    selectedTicket: null,
    inputedNum: null,
    ticketNumItems:[5, 10, 15, 'その他（手動入力）'],
  }),
  computed: {
    numerOfAddedTicket() {
      if (this.selectedTicket != "その他（手動入力）") {
        return this.selectedTicket
      } else {
        return this.inputedNum
      }
    }
  },
  props:['studentId', 'studentName'],
  components: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('closeAction')
    },
    addTicket() {
      const addedTicketInfo = {
        studentId: this.studentId,
        numerOfAddedTicket: this.numerOfAddedTicket
      }

      this.axios
        .post(`/api/tickets/addTicketByAdmin`, addedTicketInfo)
        .then(response => {
          console.log("response.data", response.data)
          this.$emit('reloadTable')
          this.close()
        })
        .catch(error => {
          alert(error)
        });
    },
    changeFile(elem) {
      this.$emit("changeFile", elem)
    }
  }
}

</script>

<style lang="scss" scoped>
p {
  white-space: pre-line;
}
</style>
