<template>
  <v-app id="admin-top" :style="{ background: '#FAFAFA' }">
    <!-- サイドバー -->
    <v-navigation-drawer
      class="pt-4"
      color="white lighten-3"
      permanent
      v-on:mouseover="mouseOverAction"
      v-on:mouseleave="mouseLeaveAction"
      app
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="model"
          active-class="selected"
        >
          <v-list-item
            link
            v-for="n of linkItems.length"
            :key="n"
            @click="test(n)"
          >
            <v-list-item-icon>
              <v-icon>{{ linkItems[n - 1].icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ linkItems[n - 1].title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <!-- 中身 -->
      <component :is="showedAdminScreen" class="no-hover-main" />
    </v-main>
  </v-app>
</template>

<script>
import AdminStudentList from "../Pages/AdminStudentList";
import AdminTeacherList from "../Pages/AdminTeacherList";
import AdminLessonList from "../Pages/AdminLessonList";
import AdminRegularScheduleList from "../Pages/AdminRegularScheduleList";
import AdminTextCourseList from "../Pages/AdminTextCourseList";
import AdminChargeForStudentList from "../Pages/AdminChargeForStudentList";
import AdminPrivateRewardList from "../Pages/AdminPrivateRewardList";
import AdminConversationRewardList from "../Pages/AdminConversationRewardList";
import AdminMailText from "../Pages/AdminMailText";
import AdminMessage from "../Pages/AdminMessage";
import AdminTeacherDataAnalysis from "../Pages/AdminTeacherDataAnalysis";
import AdminStudentDataAnalysis from "../Pages/AdminStudentDataAnalysis";
import AdminConnectionList from "../Pages/AdminConnectionList";

export default {
  name: "AdminTop",
  data: () => ({
    linkItems: [
      { title: "生徒一覧", icon: "mdi-account" },
      { title: "講師一覧", icon: "mdi-account-tie" },
      { title: "レッスン一覧", icon: "mdi-laptop" },
      { title: "紐付け一覧", icon: "mdi-human-greeting-proximity" },
      { title: "定期予定一覧", icon: "mdi-timer" },
      { title: "テキストコース一覧", icon: "mdi-notebook-multiple" },
      { title: "生徒への請求金額一覧", icon: "mdi-receipt" },
      { title: "【プライベート】報酬金額一覧", icon: "mdi-cash-multiple" },
      { title: "【会話】報酬金額一覧", icon: "mdi-cash" },
      { title: "メール文編集", icon: "mdi-email" },
      { title: "メッセージ編集", icon: "mdi-forum" },
      { title: "講師データ分析", icon: "mdi-finance" },
      { title: "生徒データ分析", icon: "mdi-chart-line" },
    ],
    selectedIndex: 1,
    model: 0,
    isMenuHovered: false,
  }),
  components: {
    AdminStudentList,
    AdminTeacherList,
    AdminLessonList,
    AdminConnectionList,
    AdminRegularScheduleList,
    AdminTextCourseList,
    AdminChargeForStudentList,
    AdminPrivateRewardList,
    AdminConversationRewardList,
    AdminMailText,
    AdminMessage,
    AdminTeacherDataAnalysis,
    AdminStudentDataAnalysis,
  },
  created() {},
  computed: {
    showedAdminScreen() {
      switch (this.selectedIndex) {
        case 1:
          return AdminStudentList;
        case 2:
          return AdminTeacherList;
        case 3:
          return AdminLessonList;
        case 4:
          return AdminConnectionList;
        case 5:
          return AdminRegularScheduleList;
        case 6:
          return AdminTextCourseList;
        case 7:
          return AdminChargeForStudentList;
        case 8:
          return AdminPrivateRewardList;
        case 9:
          return AdminConversationRewardList;
        case 10:
          return AdminMailText;
        case 11:
          return AdminMessage;
        case 12:
          return AdminTeacherDataAnalysis;
        case 13:
          return AdminStudentDataAnalysis;
        default:
          return AdminMessage;
      }
    },
  },
  methods: {
    test(index) {
      this.selectedIndex = index;
    },
    mouseOverAction() {
      this.isMenuHovered = true;
    },
    mouseLeaveAction() {
      this.isMenuHovered = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.choose-area {
  padding: 50px;
}
.link {
  margin: 50px 0;
  font-weight: bold;
}
.v-main {
  padding: 0 30px !important;
}
.selected {
  background-color: #DCC268;
  color: white;
}
</style>
