<template>
  <v-card elevation="2" class="mx-auto">
    <v-container>
      <div :class="{ 'clickable-area': !disabled }" @click="click">
        <v-row no-gutters dense>
          <v-col cols="5">
            <img
              :src="computedImageUrl"
              alt="teacherImage"
              :style="{
                'object-fit': 'cover',
                width: '100%',
                'aspect-ratio': '1 / 1',
                'border-radius': '10px',
                'margin-top': '10px',
              }"
            />
          </v-col>
          <v-col
            cols="7"
            :style="{
              'padding-left': ' 15px',
            }"
          >
            <v-row
              no-gutters
              :style="{
                'font-size': '1em',
                'font-weight': 'bold',
                'padding-top': ' 0.5em',
              }"
            >
              <p>{{ teacherInfo.teacherName }}先生</p>
              {{ countryEmoji }}
            </v-row>
            <v-spacer />
            <v-row
              no-gutters
              :style="{}"
              v-if="showPrivExpertIcon"
            >
              <div
                class="tag rounded-pill"
                :style="{
                  'margin-top': '4px',
                }"
              >
                <p>プライベートベテラン</p>
              </div>
            </v-row>
            <v-row
              no-gutters
              :style="{}"
              v-if="showConvExpertIcon"
            >
              <div
                class="tag rounded-pill"
                :style="{
                  'margin-top': '4px',
                }"
              >
                <p>会話ベテラン</p>
              </div>
            </v-row>
            <v-row no-gutters :style="{}" v-if="teacherInfo.category">
              <div
                class="tag rounded-pill"
                :style="{
                  'margin-top': '8px',
                }"
              >
                <p>{{ categoryText }}</p>
              </div>
            </v-row>
            <!-- 空き予定 -->
            <v-row
              no-gutters
              :style="{
                'font-size': '0.8em',
                'font-weight': 'bold',
                'padding-top': '2px',
              }"
              v-if="showEmpty"
              class="empty-plan"
            >
              <p>空き: {{ emptyText }}</p>
              <div
                :style="{
                  'max-width': '16px',
                  margin: '3px 0 0 5px',
                }"
              >
                <v-img
                  :src="emptyIcon"
                  :style="{
                    'object-fit': 'contain',
                  }"
                ></v-img>
              </div>
            </v-row>
            <!-- 対応レベル -->
            <div class="compatible-level">
              <v-row no-gutters>
                <p class="content-text compatible-level__title">レベル:</p>
                <p class="content-text">{{ compatibleLevelText }}</p>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          :style="{
            'margin-top': '10px',
          }"
          v-if="!disabled"
        >
          <!-- 予約ボタン -->
          <VmButton type="primary" text="予約する" :style="{}" @click="click" />
        </v-row>
        <v-row
          no-gutters
          :style="{
            'font-size': '1em',
            'font-weight': 'bold',
            'margin-top': '20px',
          }"
        >
          <p>{{ teacherInfo.catchPhrase }}</p>
        </v-row>
        <v-row
          no-gutters
          class="card-subtitle"
          :style="{ 'margin-top': '30px' }"
        >
          <p>得意分野</p>
        </v-row>
        <v-row no-gutters class="content-text">
          <p :class="{ specialities__hidden: !showDetail }">
            {{ teacherInfo.specialities }}
          </p>
        </v-row>
      </div>
      <v-row
        class="more-button"
        no-gutters
        dense
        justify="center"
        v-if="!showDetail"
      >
        <v-btn
          :style="{
            'text-transform': 'none',
            'font-weight': 'bold',
          }"
          text
          @click="openDetail"
        >
          more
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-row>
      <!-- 詳細画面 -->
      <div v-if="showDetail" :style="{}">
        <v-row
          no-gutters
          class="card-subtitle"
          :style="{ 'margin-top': '30px' }"
        >
          <p>経歴</p>
        </v-row>
        <v-row no-gutters class="content-text">
          <p>{{ teacherInfo.background }}</p>
        </v-row>
        <v-row
          no-gutters
          class="card-subtitle"
          :style="{ 'margin-top': '30px' }"
        >
          <p>趣味/特技</p>
        </v-row>
        <v-row no-gutters class="content-text">
          <p>{{ teacherInfo.hobbies }}</p>
        </v-row>
        <v-row
          no-gutters
          class="card-subtitle"
          :style="{ 'margin-top': '30px' }"
        >
          <p>コメント</p>
        </v-row>
        <v-row no-gutters class="content-text">
          <p>{{ teacherInfo.selfIntroduction }}</p>
        </v-row>
        <div v-if="teacherInfo.availableTextbook && showAt !== 'CONVERSATION'">
          <v-row
            no-gutters
            class="card-subtitle"
            :style="{ 'margin-top': '30px' }"
          >
            <p>対応可能な教科書</p>
          </v-row>
          <v-row no-gutters class="content-text">
            <p>{{ teacherInfo.availableTextbook }}</p>
          </v-row>
        </div>
        <v-row no-gutters dense justify="center">
          <v-btn
            :style="{
              'text-transform': 'none',
              'font-weight': 'bold',
              'margin-top': '20px',
              'margin-bottom': '20px',
            }"
            text
            @click="closeDetail"
          >
            close
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </v-row>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import VmButton from "../Atoms/VmButton";

export default {
  name: "SPTeacherCard",
  props: {
    teacherInfo: {
      type: Object,
      default: () => {},
    },
    showEmpty: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showAt: {
      type: String,
      default: "",
    },
  },
  components: {
    VmButton,
  },
  data: () => ({
    showDetail: false,
  }),
  created() {},
  mounted() {},
  computed: {
    compatibleLevelText() {
      return this.teacherInfo.compatibleLevel.replace("A0", "完全初級");
    },
    categoryText() {
      // 日本人講師 or ネイティブを表示させる
      switch (this.teacherInfo.category) {
        case "JAPANESE":
          return "日本人講師";
        case "NATIVE":
          return "ネイティブ";
        default:
          return "";
      }
    },
    emptyText() {
      // 講師予定の空き具合によって返すアイコンを変更する
      if (this.teacherInfo.availableTimes >= 2400) {
        return "多い";
      } else if (this.teacherInfo.availableTimes >= 1200) {
        return "普通";
      } else {
        return "わずか";
      }
    },
    emptyIcon() {
      // 講師予定の空き具合によって返すアイコンを変更する（分）
      if (this.teacherInfo.availableTimes > 2400) {
        return require("../../assets/full_empty_icon.png");
      } else if (this.teacherInfo.availableTimes > 1200) {
        return require("../../assets/half_empty_icon.png");
      } else {
        return require("../../assets/little_empty_icon.png");
      }
    },
    computedImageUrl() {
      return this.teacherInfo.imageUrl
        ? this.teacherInfo.imageUrl
        : require("../../assets/no_teacher.png");
    },
    countryEmoji() {
      switch (this.teacherInfo.country) {
        case "日本":
          return "🇯🇵";
        case "ドイツ":
          return "🇩🇪";
        case "オーストリア":
          return "🇦🇹";
        case "その他":
          return "";
        default:
          return "";
      }
    },
    showPrivExpertIcon() {
      return this.teacherInfo.privLevel === 'EXPERT' && (this.showAt === 'PRIVATE' || this.showAt === 'ALL')
    },
    showConvExpertIcon() {
      return this.teacherInfo.convLevel === 'EXPERT' && (this.showAt === 'CONVERSATION' || this.showAt === 'ALL')
    }
  },
  methods: {
    openDetail() {
      this.showDetail = true;
    },
    closeDetail() {
      this.showDetail = false;
    },
    click() {
      this.$emit("clickAction");
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  white-space: pre-line;
}
.tag {
  background: white;
  border: solid 1px #dcc268; /*線*/
  padding: 2px 15px;
}
.tag p {
  color: #dcc268;
  font-weight: bold;
  font-size: 0.6em;
  margin: 0;
}

.card-subtitle {
  font-size: 0.9em;
  color: #cfd2d1;
  font-weight: bold;
}
.row + .row {
  margin-top: 0;
}
.content-text {
  font-size: 0.9em;
  font-weight: bold;
  line-height: 2em;
  color: #272727;
}
.clickable-area:hover {
  cursor: pointer;
  opacity: 0.6;
}
.more-button {
  margin-top: 16px;
}
.specialities {
  &__hidden {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}
.compatible-level {
  font-size: 0.8em;
  margin-top: 4px;
  &__title {
    margin-right: 4px;
  }
}
</style>
