<template>
  <VmDialogCard
    title="講師詳細"
    :cancelButtonTitle="$t('labels.close')"
    @close="close"
  >
    <template #cardContent>
      <div>
        <v-row no-gutters dense>
          <v-col cols="5">
            <img
              :src="computedImageUrl"
              alt="teacherImage"
              :style="{
                'object-fit': 'cover',
                width: '100%',
                'aspect-ratio': '1 / 1',
                'border-radius': '10px',
                'margin-top': '10px',
              }"
            />
          </v-col>
          <v-col
            cols="7"
            :style="{
              'padding-left': ' 15px',
            }"
          >
            <v-row
              no-gutters
              :style="{
                'font-size': '1em',
                'font-weight': 'bold',
                'padding-top': ' 0.5em',
              }"
            >
              <p>{{ teacherInfo.teacherName }}先生</p>
            </v-row>
            <v-spacer />
            <v-row
              no-gutters
              :style="{}"
              v-if="teacherInfo.privLevel === 'EXPERT' && showAt == 'PRIVATE'"
            >
              <div
                class="tag rounded-pill"
                :style="{
                  'margin-top': '8px',
                }"
              >
                <p>プライベートベテラン</p>
              </div>
            </v-row>
            <v-row
              no-gutters
              :style="{}"
              v-if="teacherInfo.convLevel === 'EXPERT' && showAt == 'CONVERSATION'"
            >
              <div
                class="tag rounded-pill"
                :style="{
                  'margin-top': '8px',
                }"
              >
                <p>会話ベテラン</p>
              </div>
            </v-row>
            <v-row no-gutters :style="{}" v-if="teacherInfo.category">
              <div
                class="tag rounded-pill"
                :style="{
                  'margin-top': '8px',
                }"
              >
                <p>{{ categoryText }}</p>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters :style="{ 'margin-top': '30px' }">
          <v-col cols="6">
            <v-row no-gutters class="card-subtitle">
              <p>在住</p>
            </v-row>
            <v-row no-gutters class="content-text">
              <p>{{ teacherInfo.country }}</p>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          :style="{ 'margin-top': '20px' }"
        >
          <v-col cols="6">
            <v-row no-gutters class="card-subtitle">
              <p>対応レベル</p>
            </v-row>
            <v-row no-gutters class="content-text">
              <p>{{ compatibleLevelText }}</p>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="card-subtitle"
          :style="{ 'margin-top': '30px' }"
        >
          <p>得意分野</p>
        </v-row>
        <v-row no-gutters class="content-text">
          <p>
            {{ teacherInfo.specialities }}
          </p>
        </v-row>
        <v-row
          no-gutters
          class="card-subtitle"
          :style="{ 'margin-top': '30px' }"
        >
          <p>経歴</p>
        </v-row>
        <v-row no-gutters class="content-text">
          <p>{{ teacherInfo.background }}</p>
        </v-row>
        <v-row
          no-gutters
          class="card-subtitle"
          :style="{ 'margin-top': '30px' }"
        >
          <p>趣味/特技</p>
        </v-row>
        <v-row no-gutters class="content-text">
          <p>{{ teacherInfo.hobbies }}</p>
        </v-row>
        <v-row
          no-gutters
          class="card-subtitle"
          :style="{ 'margin-top': '30px' }"
        >
          <p>コメント</p>
        </v-row>
        <v-row no-gutters class="content-text">
          <p>{{ teacherInfo.selfIntroduction }}</p>
        </v-row>
        <div v-if="teacherInfo.availableTextbook && showAt !== 'CONVERSATION'">
          <v-row
            no-gutters
            class="card-subtitle"
            :style="{ 'margin-top': '30px' }"
          >
            <p>対応可能な教科書</p>
          </v-row>
          <v-row no-gutters class="content-text">
            <p>{{ teacherInfo.availableTextbook }}</p>
          </v-row>
        </div>
      </div>
    </template>
  </VmDialogCard>
</template>

<script>
import VmDialogCard from '../Molecules/VmDialogCard.vue'
export default {
  name: "TeacherDetailDialog",
  props: {
    teacherInfo: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    VmDialogCard
  },
  data: () => ({}),
  created() {},
  mounted() {},
  computed: {
    compatibleLevelText() {
      return this.teacherInfo.compatibleLevel.replace("A0", "完全初級");
    },
    categoryText() {
      // 日本人講師 or ネイティブを表示させる
      switch (this.teacherInfo.category) {
        case "JAPANESE":
          return "日本人講師";
        case "NATIVE":
          return "ネイティブ";
        default:
          return "";
      }
    },
    computedImageUrl() {
      return this.teacherInfo.imageUrl
        ? this.teacherInfo.imageUrl
        : require("../../assets/no_teacher.png");
    },
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  white-space: pre-line;
}
.tag {
  background: white;
  border: solid 1px #dcc268; /*線*/
  padding: 2px 15px;
  font-size: 0.9em;
}
.tag p {
  color: #dcc268;
  font-weight: bold;
  margin: 0;
}

.card-subtitle {
  color: #cfd2d1;
  font-weight: bold;
}

.content-text {
  font-weight: bold;
  line-height: 2em;
  color: #272727;
}
</style>
