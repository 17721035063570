<template>
  <div>
    <img
      class="img"
      :src="this.imageUrl"
      alt="teacherImage"
      :style="imgStyle"
      @mouseover="mouseover"
      @mouseleave="mouseleave"
    >
  </div>
</template>

<script>

export default {
  name: 'TeacherPicture',
  data: () => ({
  }),
  components: {
  },
  computed: {
    imgStyle() {
      return {
        width: this.imageSize
      }
    }
  },
  methods: {
    mouseover(){
      if (this.enableHoverAct) {
        this.imgStyle = {
          opacity: 0.5,
          "transition-duration": "0.3s"
        }
      }
    },
    mouseleave(){
      if (this.enableHoverAct) {
        this.imgStyle = {
          opacity: 1,
          "transition-duration": "0.3s"
        }
      }
    }
  },
  props: {
    imageUrl: {
      type: String,
      default: ""
    },
    imageSize: {
      type: String
    },
    enableHoverAct: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.img {
  object-fit: cover;
  border-radius: 10px;
  aspect-ratio: 1 / 1;
}
</style>

