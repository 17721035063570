<template>
  <div>
    <v-row
      justify="center"
      no-gutters
      :style="{
        'margin-bottom': '10px',
        'margin-left': '2px',
      }"
      v-if="isPC"
    >
      <v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
        <v-row justify="start" no-gutters>
          <v-select
            :items="lessonCourseItems"
            v-model="selectedLessonCourseId"
            label="受付中コース"
            hide-details
            outlined
            background-color="white"
            dense
            @change="changeAction"
            :style="{
              'max-width': '150px',
              margin: '10px 20px 10px 0',
            }"
            v-if="atAllList"
          ></v-select>
          <v-select
            :items="countryItems"
            v-model="selectedCountry"
            label="在住"
            hide-details
            outlined
            background-color="white"
            dense
            @change="changeAction"
            :style="{
              'max-width': '150px',
              margin: '10px 20px 10px 0',
            }"
          ></v-select>
          <v-select
            :items="compatibleLevelItems"
            v-model="selectedCompatibleLevel"
            label="対応レベル"
            hide-details
            outlined
            background-color="white"
            dense
            @change="changeAction"
            :style="{
              'max-width': '150px',
              margin: '10px 20px 10px 0',
            }"
          ></v-select>
          <v-select
            :items="emptyItems"
            v-model="selectedEmpty"
            label="空き予定の多さ"
            hide-details
            outlined
            background-color="white"
            dense
            @change="changeAction"
            :style="{
              'max-width': '160px',
              margin: '10px 20px 10px 0',
            }"
            v-if="!atAllList"
          ></v-select>
          <v-select
            :items="teachingLevelItems"
            v-model="selectedTeachingLevel"
            label="レッスン経験"
            hide-details
            outlined
            background-color="white"
            dense
            @change="changeAction"
            :style="{
              'max-width': '150px',
              margin: '10px 20px 10px 0',
            }"
          ></v-select>
          <VmSearch
            :style="{
              'max-width': '300px',
              margin: '10px 20px 10px 0',
            }"
            v-model="keyword"
            @search="changeAction"
          />
          <v-spacer />
        </v-row>
      </v-col>
    </v-row>
    <!-- スマホ版のソートボタン -->
    <v-row
      justify="start"
      no-gutters
      :style="{
        'margin-bottom': '20px',
        'margin-left': '2px',
      }"
      v-else
    >
      <VmButton type="primary" @click="showDialog = true" text="条件で絞る" />
      <v-dialog v-model="showDialog" max-width="500px">
        <VmDialogCard
          title="検索条件"
          buttonTitle="保存"
          @click="changeAction"
          @close="closeDialog"
        >
          <template #cardContent>
            <v-select
              :items="lessonCourseItems"
              v-model="selectedLessonCourseId"
              label="受付中コース"
              hide-details
              outlined
              background-color="white"
              dense
              :style="{
                'margin-top': '25px',
              }"
              v-if="atAllList"
            ></v-select>
            <v-select
              :items="countryItems"
              v-model="selectedCountry"
              label="在住"
              hide-details
              outlined
              background-color="white"
              dense
              :style="{
                'margin-top': '25px',
              }"
              hide-selected
            ></v-select>

            <v-select
              :items="compatibleLevelItems"
              v-model="selectedCompatibleLevel"
              label="対応レベル"
              hide-details
              outlined
              background-color="white"
              dense
              :style="{
                'margin-top': '25px',
              }"
            ></v-select>
            <v-select
              :items="emptyItems"
              v-model="selectedEmpty"
              label="空き予定の多さ"
              hide-details
              outlined
              background-color="white"
              dense
              :style="{
                'margin-top': '25px',
              }"
              v-if="!atAllList"
            ></v-select>
            <v-select
              :items="teachingLevelItems"
              v-model="selectedTeachingLevel"
              label="レッスン経験"
              hide-details
              outlined
              background-color="white"
              dense
              :style="{
                'margin-top': '25px',
              }"
            ></v-select>
            <VmSearch
              :style="{
                'margin-top': '25px',
              }"
              v-model="keyword"
            />
          </template>
        </VmDialogCard>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import VmButton from "../Atoms/VmButton";
import VmDialogCard from "../Molecules/VmDialogCard";
import VmSearch from "../Atoms/VmSearch";

export default {
  name: "SortListArea",
  props: {
    lessonCourseId: {
      type: Number,
    },
    studentLevel: {
      type: String,
      default: "",
    },
    atAllList: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VmButton,
    VmDialogCard,
    VmSearch,
  },
  data: () => ({
    lessonCourseItems: [
      { text: "指定しない", value: null },
      { text: "プライベートコース", value: 2 },
      { text: "会話コース", value: 1 },
      { text: "テキストコース", value: 3 },
    ],
    selectedLessonCourseId: null,
    countryItems: ["指定しない", "ドイツ", "日本", "スイス", "オーストリア"],
    selectedCountry: null,
    compatibleLevelItems: [],
    selectedCompatibleLevel: null,
    emptyItems: ["指定しない", "多い", "普通", "わずか"],
    selectedEmpty: null,
    teachingLevelItems: ["指定しない", "ベテラン"],
    selectedTeachingLevel: null,
    keyword: "",
    showDialog: false,
  }),
  created() {
    // 会話コースはA2以上から
    if (this.lessonCourseId === 1) {
      this.compatibleLevelItems = ["指定しない", "A2", "B1", "B2", "C1", "C2"];
    } else {
      this.compatibleLevelItems = [
        "指定しない",
        "完全初級",
        "A1",
        "A2",
        "B1",
        "B2",
        "C1",
        "C2",
      ];
    }
    if (this.studentLevel) {
      this.selectedCompatibleLevel =
        this.studentLevel === "A0" ? "完全初級" : this.studentLevel;
    }
    if (Object.keys(this.$route.query).length) {
      this.selectedLessonCourseId = Number(this.$route.query.lessonCourseId);
      this.selectedCountry = this.$route.query.country;
      this.selectedCompatibleLevel =
        this.$route.query.compatibleLevel === "A0"
          ? "完全初級"
          : this.$route.query.compatibleLevel;
      this.selectedEmpty = this.$route.query.empty;
      this.selectedTeachingLevel =
        this.$route.query.teachingLevel === "EXPERT" ? "ベテラン" : "";
    }
  },
  mounted() {},
  computed: {},
  methods: {
    changeAction() {
      this.$emit("changeAction", {
        lessonCourseId: this.selectedLessonCourseId
          ? this.selectedLessonCourseId
          : "",
        country:
          this.selectedCountry && this.selectedCountry != "指定しない"
            ? this.selectedCountry
            : "",
        compatibleLevel:
          this.selectedCompatibleLevel &&
          this.selectedCompatibleLevel != "指定しない"
            ? this.selectedCompatibleLevel
            : "",
        empty:
          this.selectedEmpty && this.selectedEmpty != "指定しない"
            ? this.selectedEmpty
            : "",
        teachingLevel:
          this.selectedTeachingLevel &&
          this.selectedTeachingLevel != "指定しない"
            ? "EXPERT"
            : "",
        keyword: this.keyword ? this.keyword : "",
      });
      this.closeDialog();
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
</style>
