<template>
  <!-- 生徒が予約する時に使うカレンダー -->
  <div>
    <p style="font-size: 0.9em; font-weight: normal">
      ※レッスン開始の48時間前まで予約できます。
      <br />
    </p>
    <p v-if="lessonCourseId === 2" style="font-size: 0.9em; font-weight: bold; margin-top: 20px">
      ↓空き予定が見つからない場合は↓
    </p>
    <v-btn
      v-if="lessonCourseId === 2"
      color="secondary"
      @click.stop="showLessonRequestDialog = true"
      :style="{
        fontWeight: 'bold',
        margin: '10px 0',
      }"
      tile
      elevation="0"
      class="rounded-sm"
    >
      <p>レッスンのリクエストをする</p>
      <v-icon :style="{ 'margin-left': '5px' }">mdi-email-fast</v-icon>
    </v-btn>
    <v-row style="margin-top: 16px">
      <!-- 表示に関するセッティング。月表示、週表示など -->
      <v-col cols="6" xs="6" sm="3" md="3" lg="3">
        <v-select
          v-model="type"
          :items="typeOptions"
          label="Type"
          hide-details
          outlined
          background-color="white"
          dense
        ></v-select>
      </v-col>
      <!-- 週初めに関するセッティング -->
      <v-col cols="6" xs="6" sm="4" md="4" lg="4">
        <SwitchBeginningOfWeek @reloadCalendar="reloadCalendar" />
      </v-col>
    </v-row>
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>

        <v-spacer></v-spacer>
      </v-toolbar>
    </v-sheet>
    <v-dialog
      v-model="showLessonRequestDialog"
      :close-on-content-click="false"
      v-if="showLessonRequestDialog && !!this.userInfo.studentId"
      offset-x
      max-width="500px"
    >
      <LessonRequestForm
        @cancelAction="closeLessonRequestDialog"
        :teacherId="teacherId"
      />
    </v-dialog>
    <v-dialog
      v-model="showLessonRequestDialog"
      :close-on-content-click="false"
      v-if="showLessonRequestDialog && !this.userInfo.studentId"
      offset-x
      max-width="500px"
    >
      <RequestLoginForm @cancelAction="closeLessonRequestDialog" />
    </v-dialog>
    <v-sheet :height="isPC ? 800 : 1600">
      <v-calendar
        ref="calendar"
        v-model="calendarValue"
        :calendarValue="calendarValue"
        :type="type"
        :events="events"
        :weekdays="computedWeekdays"
        :start="startDate"
        @change="updateRange"
        @click:event="showEvent"
        @click:more="showMore"
      >
        <template v-slot:event="{ event }">
          <p class="summary">{{ event.name }}</p>
        </template>
      </v-calendar>
      <v-dialog
        v-model="showDialog"
        :close-on-content-click="false"
        v-if="showDialog && !isLogin"
        offset-x
        max-width="500px"
      >
        <RequestLoginForm @cancelAction="closeCard" />
      </v-dialog>

      <v-dialog
        v-model="showDialog"
        :close-on-content-click="false"
        v-if="showDialog && isLogin"
        offset-x
        max-width="500px"
      >
        <!-- コースIDによって会話orプライベートの予約フォームの切り替えを行っている -->
        <ConversationReservationFormCard
          v-if="lessonCourseId === 1"
          :teacherName="teacherName"
          course-name="会話"
          :start-time="selectedEvent.start"
          :end-time="selectedEvent.end"
          :userInfo="userInfo"
          :studentId="studentId"
          :teacherId="teacherId"
          @cancelAction="closeCard"
          :lessonCourseId="lessonCourseId"
        />
        <PrivateReservationFormCard
          v-if="lessonCourseId === 2"
          :teacherName="teacherName"
          course-name="プライベート"
          :start-time="selectedEvent.start"
          :end-time="selectedEvent.end"
          :userInfo="userInfo"
          :studentId="studentId"
          :teacherId="teacherId"
          @cancelAction="closeCard"
          :lessonCourseId="lessonCourseId"
          :isFirstLesson="isFirstLesson"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import ConversationReservationFormCard from "../Molecules/ConversationReservationFormCard";
import PrivateReservationFormCard from "../Molecules/PrivateReservationFormCard";
import SwitchBeginningOfWeek from "../Atoms/SwitchBeginningOfWeek";
import RequestLoginForm from "../Molecules/RequestLoginForm";
import LessonRequestForm from "../Molecules/LessonRequestForm";

export default {
  name: "ReservationCalendar",
  components: {
    ConversationReservationFormCard,
    PrivateReservationFormCard,
    SwitchBeginningOfWeek,
    RequestLoginForm,
    LessonRequestForm,
  },
  data: () => ({
    type: "month",
    typeOptions: [
      { text: "Day", value: "day" },
      { text: "4 Day", value: "4day" },
      { text: "Week", value: "week" },
      { text: "Month", value: "month" },
    ],
    events: [],
    selectedEvent: {},
    selectedElement: null,
    showDialog: false,
    calendarValue: "",
    showLessonRequestDialog: false,
    startDate: ""
  }),
  props: [
    "availableLessons",
    "userInfo",
    "studentId",
    "teacherId",
    "isFirstLesson",
    "teacherName",
    "lessonCourseId",
  ],
  created() {
    this.startDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
  },
  mounted() {
    this.updateRange();
    // デフォルトのカレンダーの表示月を予約可能日時のうち、最も現在に近いものにする
    this.adjustMonth()
  },
  computed: {
    computedWeekdays() {
      // 週始まりを設定する
      switch (this.userInfo.beginningOfWeek) {
        case 0:
          return [0, 1, 2, 3, 4, 5, 6];
        case 1:
          return [1, 2, 3, 4, 5, 6, 0];
        default:
          return [0, 1, 2, 3, 4, 5, 6];
      }
    },
  },
  methods: {
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
      };

      if (this.showDialog) {
        this.showDialog = false;
        setTimeout(open, 10);
      } else {
        this.showDialog = true;
        open();
      }

      nativeEvent.stopPropagation();
    },
    showMore(event) {
      this.calendarValue = event.date;
      this.type = "day";
    },
    updateRange() {
      const events = [];

      for (let lesson of this.availableLessons) {
        const startDate = this.$moment(lesson.startTime).format(
          "YYYY-MM-DD HH:mm"
        );
        const endDate = this.$moment(lesson.endTime).format("YYYY-MM-DD HH:mm");

        const startDateLabel = this.$moment(lesson.startTime).format("HH:mm");
        const endDateLabel = this.$moment(lesson.endTime).format("HH:mm");

        const availableSchedule = {
          name: `${startDateLabel} - ${endDateLabel}`,
          start: startDate,
          end: endDate,
          color: "reservationAvailable",
        };
        events.push(availableSchedule);
      }
      this.events = events;
    },
    adjustMonth() {
      const sortedLessons = this.availableLessons.concat().sort((a, b) => this.$moment(a.startTime).isBefore(this.$moment(b.startTime)) ? -1 : 1)
      if (sortedLessons.length) {
        this.startDate = this.$moment(sortedLessons[0].startTime).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    closeCard() {
      this.showDialog = false;
    },
    closeLessonRequestDialog() {
      this.showLessonRequestDialog = false;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    reloadCalendar() {
      this.$emit("reload");
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.summary {
  margin-left: 4px;
  font-weight: bold;
  white-space: pre-line;
  text-overflow: inherit;
  font-size: 0.92em;
}
</style>
