<template>
  <v-sheet height="64">
    <v-toolbar flat>
      <v-btn
        fab
        text
        small
        color="grey darken-2"
        @click="prevMonth"
        :disabled="disabled"
      >
        <v-icon small> mdi-chevron-left </v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ toJaSelectedMonth }}
      </v-toolbar-title>
      <v-btn
        fab
        text
        small
        color="grey darken-2"
        @click="nextMonth"
        :disabled="disabled"
      >
        <v-icon small> mdi-chevron-right </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>
  </v-sheet>
</template>

<script>
export default {
  name: "SelectMonth",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({}),
  created() {
    this.initSelectedMonth();
  },
  mounted() {},
  computed: {
    toJaSelectedMonth() {
      return this.$moment(this.inputedMonth).format("YYYY年 MM月");
    },
    inputedMonth: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    initSelectedMonth() {
      this.inputedMonth = this.$moment();
      this.$emit("fetchData");
    },
    prevMonth() {
      this.inputedMonth = this.$moment(this.inputedMonth).subtract(1, "months");
      this.$emit("reload");
    },

    nextMonth() {
      this.inputedMonth = this.$moment(this.inputedMonth).add(1, "months");
      this.$emit("reload");
    },
  },
};
</script>

<style lang="scss" scoped></style>
