<template>
  <v-container :class="containerClass">
    <PageTitle title-name="HOME" />
    <ChangeLocale @reloadAfterChangingLang="reload()" />
    <SwitchTimeZone v-if="isShowTimeZone" @reload="reload()" />
    <!-- レッスン情報 -->
    <ScheduleHeading class="heading" />

    <!-- お知らせ - 講師 -->
    <MessageForTeacher />

    <!-- 「未確認のレッスンがあります」アラート -->
    <LessonNotification v-if="showNotification" />

    <!-- レッスン予定一覧 -->
    <div>
      <SubHeading
        :sub-heading-text="$t('top.lessonPlanList')"
        class="subheading"
      />
      <div class="attention">
        <p class="notice">
          {{ this.$t("top.lessonTableGuide") }}
        </p>
      </div>
      <component
        v-if="isShowTable"
        :is="scheduleTableComponent"
        :lessons="lessons"
        :isTeacher="true"
      />
    </div>

    <!-- スケジュール登録画面遷移ボタン -->
    <v-row class="add-schedule-button" justify="center">
      <v-col cols="10">
        <ActiveButtonWithArrow
          :button-title="$t('top.registerSchedule')"
          @clickAction="toRegisterSchedule"
        />
      </v-col>
    </v-row>

    <!-- 完了済みレッスン一覧 -->
    <div>
      <SubHeading
        :sub-heading-text="$t('top.doneLessonList')"
        class="subheading"
      />
      <div class="attention">
        <p class="notice">
          {{ $t("top.doneLessonStatement") }}
        </p>
      </div>
      <DoneLessonTable v-if="isShowDoneTable" />
    </div>

    <!-- プライベートコース生徒リスト -->
    <div>
      <SubHeading
        :sub-heading-text="$t('top.studentsInCharge')"
        class="subheading"
      />
      <div class="attention">
        <p class="notice">
          {{ $t("top.studentsInChargeStatement") }}
        </p>
      </div>
      <StudentsInChargeTable v-if="isShowStudentsInChargeTable" />
    </div>

    <!-- テキストコース一覧 -->
    <div>
      <SubHeading
        :sub-heading-text="$t('top.textCourseList')"
        class="subheading"
      />
      <TextCourseTable v-if="isShowDoneTable" />
    </div>

    <!-- 統計情報 -->
    <SubHeading
      :sub-heading-text="$t('top.staticsInfo')"
      class="subheading"
      :style="{ margin: '0 0 20px 0' }"
    />

    <!-- 総時間 - 講師 -->
    <v-row
      class="lesson-data-area"
      justify="start"
      no-gutters
      v-if="isShowData"
    >
      <v-col cols="6" xs="12" sm="4" md="4" lg="3">
        <LessonData
          class="lesson-data"
          type="HOURS"
          :lessonData="teacherLessonData"
        />
      </v-col>
      <v-col cols="6" xs="12" sm="4" md="4" lg="3">
        <LessonData
          class="lesson-data"
          type="TIMES"
          :lessonData="teacherLessonData"
        />
      </v-col>
    </v-row>
    <TeacherStaticsTable v-if="isShowTable" />

    <!-- 予約システムの使い方・注意事項 -->
    <p class="attention" :style="{ margin: '50px 0 0 10px' }">
      {{ $t("top.attentionTitle") }}<br />
    </p>
    <p
      class="attention"
      :style="{ 'font-weight': 'bold', 'font-size': '1.1em' }"
    >
      <a
        href="https://vollmond.online/lektion/privatkurs-anleitung-lehrer/"
        target="_blank"
        rel="noopener"
      >
        予約システムの使い方
      </a>
      <br />
      <a
        href="https://vollmond.online/lektion/privatkurs-anleitung-lehrer-de/"
        target="_blank"
        rel="noopener"
      >
        Leitfaden zur Benutzung des Buchungssystems
      </a>
    </p>
  </v-container>
</template>

<script>
import PageTitle from "../Atoms/PageTitle";
import ScheduleHeading from "../Molecules/ScheduleHeading";
import ConversationHeading from "../Molecules/ConversationHeading";
import PrivateHeading from "../Molecules/PrivateHeading";
import TextHeading from "../Molecules/TextHeading";
import SmallGroupHeading from "../Molecules/SmallGroupHeading";
import ScheduleTable from "../Organisms/ScheduleTable";
import SubHeading from "../Atoms/SubHeading";
import SimpleTeacherCard from "../Organisms/SimpleTeacherCard";
import ActiveButtonWithArrow from "../Atoms/ActiveButtonWithArrow";
import SwitchTimeZone from "../Atoms/SwitchTimeZone";
import DoneLessonTable from "../Organisms/DoneLessonTable";
import TeacherCardWithDetail from "../Organisms/TeacherCardWithDetail";
import TextCourseTable from "../Organisms/TextCourseTable";
import LessonData from "../Molecules/LessonData";
import CheerUpArea from "../Molecules/CheerUpArea";
import MessageForTeacher from "../Atoms/MessageForTeacher";
import TeacherStaticsTable from "../Organisms/TeacherStaticsTable";
import StudentsInChargeTable from "../Organisms/StudentsInChargeTable";
import ChangeLocale from "../Molecules/ChangeLocale";
import LessonNotification from "../Organisms/LessonNotification";

export default {
  name: "TeacherTop",
  data: () => ({
    userInfo: null,
    lessons: [],
    scheduleTableComponent: null,
    isShowTable: true,
    isShowDoneTable: true,
    isShowStudentsInChargeTable: true,
    isShowTimeZone: true,
    teacherLessonData: {},
    isShowData: true,
    showNotification: true,
  }),
  components: {
    PageTitle,
    ScheduleHeading,
    ConversationHeading,
    PrivateHeading,
    TextHeading,
    SmallGroupHeading,
    ScheduleTable,
    SubHeading,
    SimpleTeacherCard,
    ActiveButtonWithArrow,
    SwitchTimeZone,
    DoneLessonTable,
    TeacherCardWithDetail,
    TextCourseTable,
    LessonData,
    CheerUpArea,
    MessageForTeacher,
    TeacherStaticsTable,
    StudentsInChargeTable,
    ChangeLocale,
    LessonNotification,
  },
  mounted() {},
  async created() {
    this.fetchInitialData();

    // 講師の授業回数、授業時間などを取得する
    try {
      await this.fetchTeacherLessonData();
    } catch (error) {
      console.log("fetchTeacherLessonData error", error);
    }

    this.getPlannedLessons();
  },
  computed: {
    containerClass() {
      if (this.isPC) {
        return "wide-padding";
      } else {
        return "narrow-padding";
      }
    },
    attendConversation() {
      if (this.userInfo.lessonCourseArray.includes("CONVERSATION")) {
        return true;
      }
      return false;
    },
    attendPrivate() {
      if (this.userInfo.lessonCourseArray.includes("PRIVATE")) {
        return true;
      }
      return false;
    },
    attendText() {
      if (this.userInfo.lessonCourseArray.includes("TEXT")) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    // 「講師の空き予定登録」に遷移
    toRegisterSchedule() {
      this.$router.push({ path: "registerTeacherSchedule" });
    },
    getPlannedLessons() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      this.axios
        .get(
          `/api/lessons/planned?teacherId=${this.userInfo.teacherId}`,
          header
        )
        .then((response) => {
          this.lessons = response.data.lessons.filter((item) => {
            return item.status === 0;
          });
          this.scheduleTableComponent = ScheduleTable;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 講師のレッスンデータ（総受講時間・総受講回数）を取得
    fetchTeacherLessonData() {
      return new Promise((resolve) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(`/api/lessons/data?teacherId=${this.userInfo.teacherId}`, header)
          .then((response) => {
            this.teacherLessonData = response.data.lessonData;
            resolve();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    async reload() {
      this.isShowTimeZone = false;
      await this.$nextTick();
      this.isShowTimeZone = true;
      await this.reloadTable();
    },
    async reloadTable() {
      this.isShowTable = false;
      this.isShowDoneTable = false;
      this.isShowStudentsInChargeTable = false;
      this.showNotification = false;
      await this.$nextTick();
      this.isShowTable = true;
      this.isShowDoneTable = true;
      this.isShowStudentsInChargeTable = true;
      this.showNotification = true;
    },
  },
  head: {
    title: {
      inner: "home",
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  white-space: pre-line;
}
.change-lang {
  white-space: pre;
}
.attention {
  font-size: 0.9em;
  margin: 10px;
  white-space: pre-line;
  color: #6e6b6b;
}
.add-schedule-button {
  margin: 70px 0;
}
.notice {
  font-size: 0.8em;
}
.change-lang-area {
  margin: 3px 0 0 0;
}
.heading {
  margin: 50px 0 0 0;
}
.subheading {
  margin: 20px 0 0 0;
}
.attention-subtitle {
  font-weight: bold;
  font-size: 1.1em;
}
.lesson-data {
  margin: 0 5px 10px 5px;
}
</style>
