<template>
  <v-hover v-slot="{ hover }">
    <v-card
      width="100%"
      text-color="#272727"
      tile
      :elevation="hover ? 6 : 2"
      hover
      @click="clickAction"
      class="rounded"
    >
      <div v-if="!isPC">
        <div :style="{ display: 'flex'}">
          <div :style="{ width: '35%'}">
            <img
              :src="imageUrl"
              alt="teacherImage"
              :style="{
                'object-fit': 'cover',
                width: '80%',
                'aspect-ratio': '1 / 1',
                'border-radius': '10px',
                'margin-top': '10px',
                'margin-left': '12px',
              }"
            />
          </div>
          <div :style="{ width: '60%'}" class="card-content">
            <!-- 〇〇先生 -->
            <p class="teacher-name" >
              {{ teacherName }} {{$t('teacherSchedule.honorificTitle')}}
            </p>
            <p class="skype-id-text">{{skypeId}}</p>
            <TeacherCardOneWordLabel :one-word="catchPhrase" class="catch-phrase-label"/>
            <TeacherCardNextLabel name="予約する＞" class />
          </div>
        </div>
      </div>
      <div v-else>
        <v-row class="card-content">
          <v-col cols="8" xs="8" sm="3" md="3" lg="3" xl="3">
            <img
              :src="imageUrl"
              alt="teacherImage"
              :style="{
                'object-fit': 'cover',
                width: '80%',
                'aspect-ratio': '1 / 1',
                'border-radius': '10px',
                'margin-top': '10px',
                'margin-left': '12px',
              }"
            />
          </v-col>
          <!-- 〇〇先生 -->
          <v-col cols="9">
            <p class="teacher-name" >
              {{ teacherName }} {{$t('teacherSchedule.honorificTitle')}}
            </p>
            <p class="skype-id-text">{{skypeId}}</p>
            <TeacherCardOneWordLabel :one-word="catchPhrase" class="catch-phrase-label"/>
            <TeacherCardNextLabel name="予約する＞" class />
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import TeacherCardOneWordLabel from "../Atoms/TeacherCardOneWordLabel";
import TeacherCardNextLabel from "../Atoms/TeacherCardNextLabel";

export default {
  name: "SimpleTeacherCard",
  data: () => ({}),
  components: {
    TeacherCardOneWordLabel,
    TeacherCardNextLabel
  },
  methods: {
    clickAction() {
      this.$emit("clickAction");
    }
  },
  computed: {
  },
  props: ['catchPhrase', 'teacherName', 'imageUrl', 'skypeId']
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
.teacher-name {
  font-size: 1em;
  font-weight: bold;
}
.card-content {
  margin: 0;
  padding: 5px 0;
  color: #272727;
}
.skype-id-text {
  font-weight: normal;
  font-size: 0.8em;
}
.image {
  display: inline-block;
  text-align: center;
}
.skype-id-text::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url("../../assets/skype.png") no-repeat;
  background-size: contain;
  margin-right: 5px;
  position: relative;
  top: 3px;
}
.catch-phrase-label {
  margin: 5px 0 10px 0;
}
</style>
