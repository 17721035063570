<template>
  <v-btn text @click="clickAction">
    <p>
      <v-icon>mdi-chevron-up-circle-outline</v-icon>
    </p>
  </v-btn>
</template>

<script>

export default {
  name: 'TeacherCardDetailButton',
  data: () => ({
  }),
  components: {
  },
  methods: {
    clickAction() {
      this.$emit('clickAction');
    }
  },
  props:[''],
};
</script>

<style lang="scss" scoped>
p {
  /* font-weight: bold; */
  color: #272727;
  font-size: 1em;
  margin-bottom: 0;
  text-align: right;
}
</style>

