var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"no-margin-text"},[_c('FormTitleText',{attrs:{"titleText":"お名前"}}),(_vm.rules && _vm.rules.includes('required'))?_c('RequiredIcon'):_vm._e(),_c('FormAttentionText',{attrs:{"attentionText":"アルファベットのみ"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{style:(_vm.isPC ? { padding: '0 10px 0 0' } : { padding: '0 2px 0 0' }),attrs:{"cols":"6"}},[_c('validationProvider',{attrs:{"rules":{
          required: true,
          regex: /^[a-zA-Z]*$/,
        },"name":"姓"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_vm._v(" 姓 "),_c('v-text-field',{attrs:{"label":"Yamada","outlined":"","background-color":"white","success":valid,"hide-details":""},on:{"change":_vm.changeName},model:{value:(_vm.familyName),callback:function ($$v) {_vm.familyName=$$v},expression:"familyName"}}),_c('FormErrorText',{attrs:{"errorText":errors[0]}})]}}])})],1),_c('v-col',{style:(_vm.isPC ? { padding: '0 0 0 10px' } : { padding: '0 0 0 2px' }),attrs:{"cols":"6"}},[_c('validationProvider',{attrs:{"rules":{
          required: true,
          regex: /^[a-zA-Z]*$/,
        },"name":"名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_vm._v(" 名 "),_c('v-text-field',{attrs:{"label":"Hanako","outlined":"","background-color":"white","success":valid,"hide-details":""},on:{"change":_vm.changeName},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('FormErrorText',{attrs:{"errorText":errors[0]}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }