<template>
  <!-- 講師・生徒兼用のコンポーネント -->
  <v-card>
    <v-card-title>
      <v-col>
        <v-row justify="center">
          <span class="headline">{{ formTitle }}</span>
        </v-row>
      </v-col>
    </v-card-title>
    <!-- レッスン情報 -->
    <v-card-text>
      <v-container>
        <v-row>
          <!-- レッスン情報 -->
          <p class="cancel-subtitle">{{ $t("top.lessonInfo") }}</p>
          <TimeZoneIcon />
        </v-row>
        <v-row>
          <div v-if="isTeacher">
            <!-- 生徒名 -->
            {{ $t("top.studentName") }}: {{ lessonInfo.studentName }}
          </div>
          <div v-else>講師名: {{ lessonInfo.teacherName }}先生</div>
        </v-row>
        <v-row>
          <!-- 開始時間 -->
          {{ $t("top.startTime") }}: {{ lessonInfo.startTime }}
        </v-row>
        <v-row>
          <!-- 終了時間 -->
          {{ $t("top.endTime") }}: {{ lessonInfo.endTime }}
        </v-row>
        <v-row>
          {{ $t("top.course") }}:
          {{ computedLessonCourseName(lessonInfo.lessonCourseId) }}
        </v-row>
      </v-container>
      <!-- キャンセルコメント記入欄 -->
      <v-container>
        <v-row v-if="!isTeacher">
          <p>
            ※お休みではなく【日時変更】を行いたい場合は、ここでキャンセルは行わず、講師へ個別でその旨相談してください。事務局または講師が手動で日時変更を行います。
          </p>
        </v-row>
        <v-row>
          <p class="cancel-subtitle" v-if="isTeacher">
            {{ $t("top.meessageToStudent") }}
          </p>
          <p class="cancel-subtitle" v-else>先生へのメッセージ（任意）</p>
        </v-row>
        <v-row>
          <v-textarea
            v-model="inputData.cancelComment"
            :placeholder="computedCancelFormPlaceHolder"
            solo
            counter
          ></v-textarea>
        </v-row>
        <v-row>
          <div v-if="isTeacher">
            <p class="red--text cancel-attention">
              {{ $t("top.cancelAttention") }}
            </p>
          </div>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-row justify="center">
        <v-col cols="7" class="button-area">
          <v-row justify="center">
            <ActiveDangerousButton
              :button-title="$t('top.cancelLessonButton')"
              @clickAction="cancel"
              class="button"
            />
          </v-row>
          <v-row justify="center">
            <CancelButton
              :button-title="$t('top.closeForm')"
              @clickAction="close"
              class="button"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import CancelButton from "../Atoms/CancelButton";
import ActiveDangerousButton from "../Atoms/ActiveDangerousButton";
import TimeZoneIcon from "../Atoms/TimeZoneIcon";

export default {
  name: "LessonCancelCard",
  data: () => ({
    tmpAddItem: {},
    tmpCancelForm: {},
    cancelComment: "",
  }),
  computed: {
    inputData: {
      get() {
        return this.cancelForm;
      },
      set(value) {
        this.tmpCancelForm = value;
        this.$emit("update:cancelForm", this.tmpCancelForm);
      },
    },
    isPrivate() {
      if (this.lessonInfo.lessonCourseId === 2) {
        return true;
      } else {
        return false;
      }
    },
    computedLessonCourseName: function () {
      return function (lessonCourseId) {
        switch (lessonCourseId) {
          case 1:
            return this.$t("top.conversationCourse");
          case 2:
            return this.$t("top.privateCourse");
        }
      };
    },
    computedCancelFormPlaceHolder() {
      if (this.isTeacher) {
        return this.$t("top.cancelPlaceHolderToStudent");
      } else {
        return "所用でこの日はお休みします。また次回、よろしくお願いします。";
      }
    },
  },
  props: ["cancelForm", "formTitle", "lessonInfo", "isTeacher"],
  components: {
    CancelButton,
    ActiveDangerousButton,
    TimeZoneIcon,
  },
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$emit("closeAction");
    },
    cancel() {
      this.$emit("cancelAction");
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-subtitle {
  font-size: 1.4em;
  font-weight: bold;
  margin: 20px 0 15px 0;
}
.container {
  margin: 5px 0 20px 0;
}
.button-area {
  margin: 15px 0;
}
.button {
  margin: 0 0 10px 0;
}
.attention {
  font-size: 0.8em;
  font-weight: normal;
}
.feedback-attention {
  padding: 0 0 2px 0;
}
.feedback-subtitle {
  margin: 0;
  font-weight: bold;
}
.cancel-attention {
  font-weight: bold;
}
</style>
