<template>
  <v-avatar
  :size="imageSize"
  >
    <img
      class="img"
      :src="this.imageUrl"
      alt="teacherImage"
      @mouseover="mouseover"
      @mouseleave="mouseleave"
      :style="styleObject"
    >
  </v-avatar>
</template>

<script>

export default {
  name: 'BaseCirclePicture',
  data: () => ({
    //オブジェクトデータをインラインスタイルで適用
    styleObject:{}
  }),
  components: {
  },
  methods: {
    mouseover(){
      if (this.enableHoverAct) {
        this.styleObject = {
          opacity: 0.5,
          "transition-duration": "0.3s"
        }
      }
    },
    mouseleave(){
      if (this.enableHoverAct) {
        this.styleObject = {
          opacity: 1,
          "transition-duration": "0.3s"
        }
      }
    }
  },
  computed: {
  },
  props:['imageUrl', 'imageSize', 'enableHoverAct'],
};
</script>

<style lang="scss" scoped>
.img {
  object-fit: cover;
}
</style>

