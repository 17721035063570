<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <p>{{ explanation }}</p>
        </v-row>
        <v-row>
          <div v-for="n in this.addItemArray.length" :key="n">
            <v-col cols="12">
              <v-text-field
                v-model="inputData[addItemArray[n - 1]]"
                :label="addItemArray[n - 1]"
              ></v-text-field>
            </v-col>
          </div>
          <form v-if="!noImage">
            <p>画像</p>
            <input
              @change="changeFile"
              type="file"
              name="upfile[]"
              multiple
              accept="image/*"
            />
          </form>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
      <v-btn color="blue darken-1" text @click="add"> Add </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'AdminAddFormCard',
  data: () => ({
    tmpAddItem: {}
  }),
  computed: {
    addItemArray() {
      return Object.keys(this.inputData)
    },
    inputData: {
      get () {
        return this.editedItem
      },
      set (value) {
        this.tmpAddItem = value
        this.$emit('update:editedItem', this.tmpAddItem)
      }
    },
  },
  props:['editedItem', 'formTitle', 'explanation', 'noImage'],
  components: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('closeAction')
    },
    add() {
      this.$emit('addAction')
    },
    changeFile(elem) {
      this.$emit("changeFile", elem)
    }
  }
}

</script>

<style lang="scss" scoped>
p {
  white-space: pre-line;
}
</style>
