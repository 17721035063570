var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('PageTitle',{attrs:{"titleName":"Willkommen!!"}}),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"10","xs":"10","sm":"3","md":"3","lg":"3"}},[_c('v-img',{attrs:{"src":require('../../assets/welcome.png'),"contain":""}})],1)],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('p',{style:({
        'font-weight': 'bold',
        'margin-top': '50px',
        'font-size': this.isPC ? '0.8em' : '0.7em',
        'margin-bottom': '-20px',
      })},[_vm._v(" さっそくログインしてレッスンを予約してみましょう！ ")])]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"6"}},[_c('v-btn',{staticClass:"rounded-sm",style:({
          'font-size': '1em',
          'margin-bottom': '50px',
        }),attrs:{"color":"primary","tile":"","elevation":"0","block":"","height":"44px"},on:{"click":_vm.toLogin}},[_c('p',{style:({ fontWeight: 'bold' })},[_vm._v("ログイン画面へすすむ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }