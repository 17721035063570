<template>
  <VmDialogCard
    :title="cardTitle"
    :buttonTitle="$t('teacherSchedule.registerScheduleButton')"
    :cancelButtonTitle="$t('teacherSchedule.cancel')"
    @click="registerSchedule"
    @close="cancel"
    :disabled="!buttonValidation"
  >
    <template #cardContent>
      <div>
        <v-row>
          <VmDialogSubTitle
            :text="$t('teacherSchedule.availableTime')"
            class="subTitle"
          />
          <TimeZoneIcon :style="{ margin: '12px 0 0 0' }" />
        </v-row>
        <div class="date-form-area">
          <!-- 開始日時のフォーム -->
          <DateAndTimeForm
            :formLabelText="$t('teacherSchedule.startTime')"
            :date.sync="scheduleStartDate"
            :showedDate="computedScheduleStartDate"
            :minDate="selectableStartMinDate"
            @closeDatePicker="inputEndDate"
            :time.sync="scheduleStartTime"
            :minuteInterval="5"
            @closeTimePicker="inputEndTime"
          />
          <!-- 終了日時のフォーム -->
          <DateAndTimeForm
            :formLabelText="$t('teacherSchedule.endTime')"
            :date.sync="scheduleEndDate"
            :showedDate="computedScheduleEndDate"
            :minDate="selectableMinEndDate"
            :time.sync="scheduleEndTime"
            :minuteInterval="5"
          />
        </div>
      </div>
      <div>
        <v-row>
          <!-- レッスンコース（1つ以上選択） -->
          <VmDialogSubTitle
            :text="$t('teacherSchedule.lessonCourse')"
            class="subTitle"
          />
          <p v-if="!isOnlyConversationTeacher" class="subTitle">
            （{{ $t("teacherSchedule.selectMoreThanOne") }}）
          </p>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            v-for="courseName in courseNames"
            :key="courseName"
            class="checkbox-col"
          >
            <v-checkbox
              v-model="selectedCourseNames"
              :label="courseName"
              :value="courseName"
              color="#DCC268"
              hide-details
              class="checkbox"
              dense
            ></v-checkbox>
            <v-checkbox
              v-if="showRepetition(courseName)"
              v-model="selectedRepetitions"
              :value="courseName"
              class="check-box-text"
              :label="$t('teacherSchedule.repeteSchedule')"
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>
    </template>
  </VmDialogCard>
</template>

<script>
import TimeZoneIcon from "../Atoms/TimeZoneIcon";
import DateAndTimeForm from "../Molecules/DateAndTimeForm";
import VmDialogCard from "../Molecules/VmDialogCard";
import VmDialogSubTitle from "../Atoms/VmDialogSubTitle";

export default {
  name: "RegisterTeacherScheduleCard",
  inject: ["reloadCalendar"],
  components: {
    TimeZoneIcon,
    DateAndTimeForm,
    VmDialogCard,
    VmDialogSubTitle,
  },
  data: () => ({
    scheduleStartDate: "2020-12-01",
    scheduleEndDate: "2020-12-01",
    scheduleStartTime: "00:00",
    scheduleEndTime: "00:00",
    selectedCourseNames: [],
    selectedRepetitions: [],
  }),
  props: [
    "courseId",
    "cardTitle",
    "startTime",
    "endTime",
    "teacherScheduleId",
    "userInfo",
    "calendarValue",
  ],
  computed: {
    selectableStartMinDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    selectableMinEndDate() {
      return this.scheduleStartDate;
    },
    computedStartTime() {
      const start = this.scheduleStartDate + " " + this.scheduleStartTime;
      return this.$moment(start).format("YYYY-MM-DD HH:mm");
    },
    computedEndTime() {
      const end = this.scheduleEndDate + " " + this.scheduleEndTime;
      return this.$moment(end).format("YYYY-MM-DD HH:mm");
    },
    computedScheduleStartDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.scheduleStartDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.scheduleStartDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
    computedScheduleEndDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.scheduleEndDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.scheduleEndDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
    // どのチェックボックスを用意するか計算する
    courseNames() {
      let courseNames = [];

      // 被りをなくす
      // this.userInfo.lessonCourseArrayが["CONVERSATION", "CONVERSATION"]
      // みたいなことになったりするので
      let lessonCourseArray = [];
      for (let lessonCourse of this.userInfo.lessonCourseArray) {
        // 重複を弾くためのif文
        // Array.indexOf()は該当がなかったら -1になるらしい
        if (lessonCourseArray.indexOf(lessonCourse) === -1) {
          lessonCourseArray.push(lessonCourse);
        }
      }

      for (let lessonCourse of lessonCourseArray) {
        switch (lessonCourse) {
          case "CONVERSATION":
            courseNames.push(this.$t("teacherSchedule.CONVERSATION"));
            break;
          case "PRIVATE":
            courseNames.push(this.$t("teacherSchedule.PRIVATE"));
            break;
          default:
            break;
        }
      }

      return courseNames;
    },
    computedSelectedCourseIds() {
      const jaDict = ["会話コース", "プライベートコース"];
      const deDict = ["Konversationskurs", "Privatkurs"];

      let lessonCourseIdArray = [];
      switch (this.$i18n.locale) {
        case "ja":
          for (let selectedCourseName of this.selectedCourseNames) {
            let index = jaDict.indexOf(selectedCourseName);
            lessonCourseIdArray.push(index + 1);
          }
          return lessonCourseIdArray;
        case "de":
          for (let selectedCourseName of this.selectedCourseNames) {
            let index = deDict.indexOf(selectedCourseName);
            lessonCourseIdArray.push(index + 1);
          }
          return lessonCourseIdArray;
        default:
          return lessonCourseIdArray;
      }
    },
    buttonValidation() {
      // 時間がおかしかったらアウト
      if (!this.lessonTimeValidation) {
        return false;
      }

      // 何も選択されていなかったらアウト
      if (this.computedSelectedCourseIds.length === 0) {
        return false;
      }
      return true;
    },
    // バリデーション - 終了時刻 > 開始時刻になっていればok
    lessonTimeValidation() {
      const start = this.$moment(
        this.scheduleStartDate + " " + this.scheduleStartTime
      );
      const end = this.$moment(
        this.scheduleEndDate + " " + this.scheduleEndTime
      );

      // 終了時刻 = 開始時刻になっているためアウト
      if (this.scheduleStartTime === this.scheduleEndTime) {
        return false;
      }

      if (end.isBefore(start)) {
        return false;
      }
      return true;
    },
    // 会話コース専任講師かどうか判断する
    isOnlyConversationTeacher() {
      const lessonCourseArray = this.userInfo.lessonCourseArray;

      // 会話コース以外のコースが1つでもあればfalse
      for (let lessonCourse of lessonCourseArray) {
        if (lessonCourse != "CONVERSATION") {
          return false;
        }
      }
      return true;
    },
    // プライベートコース専任講師かどうか判断する
    isOnlyPrivateTeacher() {
      const lessonCourseArray = this.userInfo.lessonCourseArray;

      // プライベートコース以外のコースが1つでもあればfalse
      for (let lessonCourse of lessonCourseArray) {
        if (lessonCourse != "PRIVATE") {
          return false;
        }
      }
      return true;
    },
    computedCourseName: function () {
      return function (lessonCourseId) {
        switch (lessonCourseId) {
          case 1:
            return this.$t("teacherSchedule.CONVERSATION");
          case 2:
            return this.$t("teacherSchedule.PRIVATE");
          default:
            return "";
        }
      };
    },
    showRepetition: function () {
      return function (courseName) {
        switch (courseName) {
          // プライベートコースがチェックされている状態
          case this.$t("teacherSchedule.PRIVATE"):
            // プライベートコースが含まれていたら
            if (this.computedSelectedCourseIds.includes(2)) {
              return true;
            } else {
              return false;
            }
          // 会話コースがチェックされている状態
          case this.$t("teacherSchedule.CONVERSATION"):
            // 会話コースが含まれていたら
            if (this.computedSelectedCourseIds.includes(1)) {
              return true;
            } else {
              return false;
            }
          default:
            return false;
        }
      };
    },
  },
  mounted() {
    this.initScheduleDate();
    this.initCheckBox();
  },
  methods: {
    initScheduleDate() {
      this.scheduleStartDate = this.$moment(this.calendarValue).format(
        "YYYY-MM-DD"
      );
      this.scheduleEndDate = this.$moment(this.calendarValue).format(
        "YYYY-MM-DD"
      );
    },
    initCheckBox() {
      // 会話コースだけを担当している人には初めから会話コースだけにチェックを入れておく
      // プライベートコースだけの人には
      if (this.isOnlyConversationTeacher) {
        this.selectedCourseNames.push(this.$t("teacherSchedule.CONVERSATION"));
      }
      if (this.isOnlyPrivateTeacher) {
        this.selectedCourseNames.push(this.$t("teacherSchedule.PRIVATE"));
      }
    },
    async registerSchedule() {
      if (this.scheduleValidation()) {
        return;
      }

      for (let computedSelectedCourseId of this.computedSelectedCourseIds) {
        try {
          await this.postTeacherSchedule(computedSelectedCourseId);
        } catch (error) {
          return console.log(error);
        }
      }

      // フォームを閉じる
      this.cancel();
      // 今編集している月を表示させるために必要
      this.reloadCalendar(this.calendarValue);
    },
    postTeacherSchedule(lessonCourseId) {
      return new Promise((resolve) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        // リピートボックスにチェックが入っているかどうか
        if (this.isRepeated(lessonCourseId)) {
          for (let n = 0; n < 12; n++) {
            const startTime = this.$moment(this.computedStartTime)
              .add(7 * n, "d")
              .format();
            const endTime = this.$moment(this.computedEndTime)
              .add(7 * n, "d")
              .format();
            const scheduleData = {
              teacherId: this.userInfo.teacherId,
              startTime: startTime,
              endTime: endTime,
              lessonCourseId: lessonCourseId,
            };
            this.axios
              .post("/api/teacherSchedules", scheduleData, header)
              .then(() => {
                if (n === 11) {
                  resolve();
                }
              })
              .catch((error) => {
                alert(
                  error.response.data.error.message +
                    "\n開始時刻:" +
                    this.$moment(startTime).format("YYYY/MM/DD HH:mm")
                );
              });
          }
        } else {
          const scheduleData = {
            teacherId: this.userInfo.teacherId,
            startTime: this.$convertToPost(this.computedStartTime),
            endTime: this.$convertToPost(this.computedEndTime),
            lessonCourseId: lessonCourseId,
          };
          this.axios
            .post("/api/teacherSchedules", scheduleData, header)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              alert(error.response.data.error.message);
            });
        }
      });
    },
    cancel() {
      this.$emit("cancelAction");
    },
    inputEndDate(val) {
      this.scheduleEndDate = val;
    },
    scheduleValidation() {
      if (this.computedStartTime >= this.computedEndTime) {
        alert("終了時刻は開始時刻より後の時刻を選択してください");
        return true;
      }
      return false;
    },
    isRepeated(lessonCourseId) {
      if (
        this.selectedRepetitions.includes(
          this.computedCourseName(lessonCourseId)
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    inputEndTime(val) {
      // 開始時刻のtimepickerが入力された後の挙動

      // 既存スケジュール編集時には働かないようにする
      // 入力されたstartTimeの時間を分解
      const valHour = Number(val.split(":")[0]);
      const valMinute = Number(val.split(":")[1]);

      // それ以外の場合は1時間プラスした時間を入れる
      let strValHour = String(valHour + 1);
      if (valHour + 1 < 10) {
        strValHour = "0" + (valHour + 1);
      }

      let strValMinute = String(valMinute);
      if (valMinute < 10) {
        strValMinute = "0" + valMinute;
      }
      this.scheduleEndTime = strValHour + ":" + strValMinute;
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
.check-box-text {
  white-space: pre-line;
}
.subTitle {
  margin-top: 30px;
  margin-bottom: 0;
}
.date-form-area {
  margin: 20px 0 0 0;
}
.checkbox-col {
  padding: 0;
}
</style>
