<template>
  <h3>{{ subHeadingText }}</h3>
</template>

<script>

export default {
  name: 'SubHeading',
  data: () => ({
  }),
  components: {
  },
  methods: {
  },
  props:['subHeadingText'],
  head: {
    link: [
      {rel: 'stylesheet', href: 'https://use.fontawesome.com/releases/v5.6.1/css/all.css'}
    ]
  }
};
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 10px;
  padding: 2px 5px 2px 20px;
  font-size: 1.2em;
  color: #272727;
}
</style>

