<template>
  <div>
    <VmDialogCard
      title="レッスン予約"
      buttonTitle="予約完了する"
      cancelButtonTitle="閉じる"
      @click="reserve"
      @close="cancel"
      :disabled="!buttonValidation || loading"
      :height="computedCardHeight"
      v-if="CanReserve"
    >
      <template #cardContent>
        <v-container>
          <v-row>
            <p class="report-subtitle">レッスン情報</p>
          </v-row>
          <v-row>
            <p>
              ◎担当講師:
              <span>{{ teacherName }} 先生</span>
            </p>
          </v-row>
          <v-row>
            <p>
              ◎受講コース:
              <span>{{ courseName }}</span>
            </p>
          </v-row>
          <v-row>
            <p>
              ◎所要時間:
              <span>20分</span>
            </p>
          </v-row>
          <SharedStudentInfo />
        </v-container>
        <v-container>
          <v-row>
            <p class="report-subtitle">レッスン時間</p>
            <TimeZoneIcon />
          </v-row>
          <!-- 開始日時のフォーム -->
          <DateAndTimeForm
            :formLabelText="$t('teacherSchedule.startTime')"
            :date.sync="lessonStartDate"
            :showedDate="lessonStartDate"
            :minDate="selectableMinDate"
            :maxDate="selectableMaxDate"
            :time.sync="lessonStartTime"
            :minuteInterval="5"
            :minTime="selectableMinTime"
            :maxTime="selectableMaxTime"
          />
          <v-row>
            <v-col cols="3" xs="3" sm="2" md="2" lg="2">
              <p>終了：</p>
            </v-col>
            <v-col cols="9" xs="9" sm="10" md="10" lg="10" class="end-time">
              <p>{{ computedEndTime }}</p>
            </v-col>
          </v-row>
          <v-row v-if="!timeRangevalidation">
            <v-col class="validation-text-col">
              <p class="validation-text">
                講師予定内の時刻を選択してください。
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <p class="report-subtitle">
              レッスンの要望
              <span class="required">※必須</span>
            </p>
          </v-row>
          <v-row>
            <v-select
              :items="hopeForLessonItems"
              v-model="hopeForLesson"
              label="選択してください"
              solo
            ></v-select>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <p class="report-subtitle">先生へのメッセージ</p>
          </v-row>
          <v-row>
            <v-textarea
              v-model="messageForTeacher"
              :placeholder="messageForTeacherLabel"
              solo
              counter
            ></v-textarea>
          </v-row>
        </v-container>
        <v-container>
          <p class="attention" :style="{ 'margin-left': '-10px' }">
            ※レッスン開始時刻48時間前「以降」の日時変更はキャンセル扱いとなり、チケットのお返しはしません。
          </p>
        </v-container>
      </template>
    </VmDialogCard>
    <VmDialogCard
      cancelButtonTitle="閉じる"
      @close="cancel"
      :height="computedCardHeight"
      v-else
    >
      <template #cardContent>
        <div class="error-text">
          <v-col>
            <v-row justify="center">
              <p class="report-subtitle">
                チケットの有効期限範囲外のレッスンです
              </p>
            </v-row>
            <v-row>
              <p class="howManyTickets">
                チケットの有効期限内のレッスンを選択してください
              </p>
            </v-row>
            <v-row>
              <p class="howManyTickets">
                只今のチケットの有効期限:
                <br />
                {{
                  $moment(computedTicketValidStart).format("YYYY/MM/DD HH:mm")
                }}
                -
                {{ $moment(computedTicketValidEnd).format("YYYY/MM/DD HH:mm") }}
              </p>
            </v-row>
          </v-col>
        </div>
      </template>
    </VmDialogCard>
  </div>
</template>

<script>
import userInfoTranslate from "@/data/translation/userInfoTranslate";
import TimeZoneIcon from "../Atoms/TimeZoneIcon";
import DateAndTimeForm from "../Molecules/DateAndTimeForm";
import VmDialogCard from "../Molecules/VmDialogCard";
import SharedStudentInfo from "../Molecules/SharedStudentInfo";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ConversationReservationFormCard",
  inject: ["reloadHeader"],
  components: {
    TimeZoneIcon,
    DateAndTimeForm,
    VmDialogCard,
    SharedStudentInfo,
  },
  data: () => ({
    textFontWeight: "bold",
    lessonStartDate: "2020-12-01",
    lessonStartTime: "00:00",
    hopeForLesson: "",
    messageForTeacher: "",
    hopeForLessonItems: [
      "フリートーク（例: Was hast du am Wochenende gemacht?）",
      "テーマに沿った会話（例: Soll man Englisch können?）",
      "日本文化を紹介（例: Was ist 'Hinamatsuri'?）",
      "スピーキング試験対策",
    ],
    messageForTeacherLabel:
      "（例）話すのが苦手なのでゆっくりお願いします。テーマ◯◯について話したいです。",
  }),
  props: [
    "teacherName",
    "courseName",
    "startTime",
    "endTime",
    "selectedOpen",
    "studentId",
    "teacherId",
    "lessonCourseId",
  ],
  computed: {
    ...mapGetters({
      userInfo: "user/getUserInfo",
      loading: "reserve/loading",
      student: "student/student",
    }),
    // レッスン予約できない時はカードの高さを下げる
    computedCardHeight() {
      if (this.CanReserve) {
        return 500;
      } else {
        return 300;
      }
    },
    computedTicketMonth() {
      // 今が何月分のチケットが有効なのかを調べる
      // 有効期限例: 6/30 0:00 （月末-1） - 7/29 23:59（月末-2）
      const today = this.$moment();

      // 月末-2の23:59を取得
      const ticketExpirationDate = this.$moment()
        .endOf("month")
        .subtract(2, "d");

      // 月末-2の23:59以降の日付なら次のチケットになっている
      let ticketMonth = null;
      if (today.isAfter(ticketExpirationDate)) {
        ticketMonth = this.$moment(today).add(1, "months");
      } else {
        ticketMonth = this.$moment(today);
      }
      return ticketMonth;
    },
    computedTicketValidStart() {
      // チケットの期限を算出する
      const ticketValidStart = this.$moment(this.computedTicketMonth).startOf(
        "months"
      );

      // ドイツ時刻が選択されている場合は 月末-2日前-日本とドイツの時差
      // 例: 月末→ 7/31 23:59:59
      if (this.userInfo.timeZone === "Europe/Berlin") {
        return this.$moment(ticketValidStart).subtract(
          9 - Number(this.$moment(ticketValidStart).format("ZZ").split("")[2]),
          "hours"
        );
      } else {
        return this.$moment(ticketValidStart);
      }
    },
    computedTicketValidEnd() {
      // チケットの期限を算出する
      const ticketValidEnd = this.$moment(this.computedTicketMonth).endOf(
        "months"
      );

      // ドイツ時刻が選択されている場合は 月末-2日前-日本とドイツの時差
      // 例: 月末→ 7/31 23:59:59
      if (this.userInfo.timeZone === "Europe/Berlin") {
        return this.$moment(ticketValidEnd).subtract(
          9 - Number(this.$moment(ticketValidEnd).format("ZZ").split("")[2]),
          "hours"
        );
      } else {
        return this.$moment(ticketValidEnd);
      }
    },
    CanReserve() {
      const start = this.$moment(this.startTime);

      // 開こうとしているレッスンが、チケットの有効期限の範囲内にあるか判断する
      if (
        start.isAfter(this.computedTicketValidStart) &&
        start.isBefore(this.computedTicketValidEnd)
      ) {
        return true;
      }

      return false;
    },
    selectableMinDate() {
      return this.$moment(this.startTime).format("YYYY-MM-DD");
    },
    selectableMaxDate() {
      return this.$moment(this.endTime).format("YYYY-MM-DD");
    },
    selectableMinTime() {
      if (
        this.lessonStartDate ===
        this.$moment(this.startTime).format("YYYY-MM-DD")
      ) {
        return this.$moment(this.startTime).format("HH:mm");
      } else {
        return "00:00";
      }
    },
    selectableMaxTime() {
      if (
        this.lessonStartDate === this.$moment(this.endTime).format("YYYY-MM-DD")
      ) {
        return this.$moment(this.endTime).subtract(20, "m").format("HH:mm");
      } else {
        return "24:00";
      }
    },
    computedStartTime() {
      const start = this.lessonStartDate + " " + this.lessonStartTime;
      return this.$moment(start).format("YYYY-MM-DD HH:mm");
    },

    // 「レッスン開始時刻」にレッスン時間を足し、「レッスン終了時間」を算出する。
    computedEndTime() {
      return this.$moment(this.computedStartTime)
        .add(20, "m")
        .format("YYYY-MM-DD HH:mm");
    },
    computedHopeForLesson: {
      get() {
        return userInfoTranslate[this.hopeForLesson];
      },
      set(value) {
        this.hopeForLesson = userInfoTranslate[value];
      },
    },
    timeRangevalidation() {
      // 講師予定の開始時刻 < 開始時刻となっているか
      if (
        !this.$moment(this.computedStartTime).isSameOrAfter(
          this.$moment(this.startTime)
        )
      ) {
        return false;
      }
      // 終了時刻 < 講師予定の終了時刻となっているか
      if (
        !this.$moment(this.endTime).isSameOrAfter(
          this.$moment(this.computedEndTime)
        )
      ) {
        return false;
      }

      return true;
    },
    buttonValidation() {
      // バリデーション - 指定した時間が講師予定の範囲に収まっているかどうか判定
      if (!this.timeRangevalidation) {
        return false;
      }

      // レッスン要望は必須
      if (!this.hopeForLesson) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.initClassStartDate();
  },
  methods: {
    ...mapActions({
      conversationReserve: "reserve/conversationReserve",
      fetchStudentInfo: "student/fetchStudentInfo",
    }),
    initClassStartDate() {
      this.lessonStartDate = this.$moment(this.startTime).format("YYYY-MM-DD");
      this.lessonStartTime = this.$moment(this.startTime).format("HH:mm");
    },
    // 会話コースの予約
    async reserve() {
      if (
        confirm(
          `【予約確認】\n 予約を完了しますか？ \n レッスン開始時刻： ${this.computedStartTime} \n レッスン終了時刻： ${this.computedEndTime} \n ※「はい」を押すとチケットが1枚消費され予約が完了します。`
        )
      ) {
        const reserveData = {
          studentId: this.studentId,
          teacherId: this.teacherId,
          lessonCourseId: this.lessonCourseId,
          startTime: this.$convertToPost(this.computedStartTime),
          endTime: this.$convertToPost(this.computedEndTime),
          hopeForLesson: this.computedHopeForLesson,
          messageForTeacher: this.messageForTeacher,
          status: 0,
        };

        const isSuccess = await this.conversationReserve({
          accessToken: this.userInfo.accessToken,
          reserveData: reserveData,
        });

        if (isSuccess) {
          // 会話コースの場合はチケットを消費するので、ヘッダーのチケット枚数を変更しないといけない
          await this.fetchStudentInfo({
            studentId: this.studentId,
            accessToken: this.userInfo.accessToken,
          });
          this.$store.commit(
            "user/SET_USER_TICKETNUM",
            this.student.numberOfTicket
          );
          this.cancel();
          this.$router.push({
            name: "CompleteConversationReservation",
            params: {
              teacherId: this.teacherId,
            },
          });
          this.reloadHeader();
        }
      }
    },
    cancel() {
      this.$emit("cancelAction");
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
.scroll {
  overflow-y: auto;
}
.report-subtitle {
  font-size: 1.4em;
  font-weight: bold;
  margin: 20px 0 15px 0;
}
.end-time {
  padding: 12px 12px 12px 0;
  font-size: 1.2em;
}
.button {
  margin-bottom: 20px;
}
.bold-text {
  font-weight: bold;
}
.required {
  font-weight: normal;
  font-size: 0.8em;
}
.startStr {
  margin: 20px 0 0 0;
  display: inline-block;
  vertical-align: bottom;
}
.picker {
  padding: 0 20px 0 0;
}
.attention {
  margin: 0;
}
.error-text {
  margin-top: 30px;
}
.howManyTickets {
  margin: 15px 10px 15px 10px;
}
.validation-text-col {
  padding: 0 0 0 12px;
}
.validation-text {
  color: red;
  margin: 0;
}
</style>
