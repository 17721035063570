import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import VueHead from 'vue-head'
import axios from "axios";
import VueAxios from "vue-axios";
import moment from 'moment-timezone'
import VueJsonToCsv from 'vue-json-to-csv';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import convertToPost from './util/convertToPost'
import convertToDateFormat from './util/convertToDateFormat'
import convertToAccurateTime from './util/convertToAccurateTime'
import divideStraddlingDaySchedule from './util/divideStraddlingDaySchedule'
import makeScheduleFromRegularInfo from './util/makeScheduleFromRegularInfo'
import modifySummerTimeLesson from './util/modifySummerTimeLesson'
import validationForRegularLessonTime from './util/validationForRegularLessonTime'
import compareTime from './util/compareTime'
import { i18n } from './i18n.js'
import { extend, localize, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email, alpha_num, regex, size, image } from 'vee-validate/dist/rules';
import ja from '@/lang/vee-validate/ja';
import mixinFunc from '../mixins/mixinFunc'
import VueScrollTo from 'vue-scrollto'
import { VueLoading } from 'vue-loading-template'
import limitedText30 from './util/limitText'
import sanitizeHTML from 'sanitize-html'

// vee-validateの設定
extend('required', required);
extend('email', email);
extend('alpha_num', alpha_num);
extend('regex', regex);
extend('size', size);
extend('image', image);
extend('date', value => {
  const regex = "[0-9]{4}/[0-9]{2}/[0-9]{2}"
  if (!value.match(regex)) {
    return false
  }
  let y = value.split("/")[0];
  let m = value.split("/")[1] - 1;
  let d = value.split("/")[2];
  let date = new Date(y, m, d);

  if (date.getFullYear() != y || date.getMonth() != m || date.getDate() != d) {
    return false;
  }
  return true
});
extend('max', {
  validate(value, args) {
    const length = value.length;
    return length <= args.max;
  },
  params: ['max']
});
extend('min', {
  validate(value, args) {
    const length = value.length;
    return args.min <= length;
  },
  params: ['min']
});

localize('ja', ja);

Vue.use(VueHead)
Vue.use(VueAxios, axios);
Vue.config.productionTip = false
Vue.component('VueJsonToCsv', VueJsonToCsv)
Vue.component('VueTimepicker', VueTimepicker)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('VueLoading', VueLoading)
Vue.mixin(mixinFunc)
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

// moment.tz.setDefault('Asia/Tokyo')
Vue.prototype.$moment = moment
Vue.prototype.$convertToPost = convertToPost
Vue.prototype.$convertToDateFormat = convertToDateFormat
Vue.prototype.$convertToAccurateTime = convertToAccurateTime
Vue.prototype.$compareTime = compareTime
Vue.prototype.$divideStraddlingDaySchedule = divideStraddlingDaySchedule
Vue.prototype.$makeScheduleFromRegularInfo = makeScheduleFromRegularInfo
Vue.prototype.$modifySummerTimeLesson = modifySummerTimeLesson
Vue.prototype.$validationForRegularLessonTime = validationForRegularLessonTime
Vue.prototype.$limitedText30 = limitedText30
Vue.prototype.$sanitize = sanitizeHTML

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.withCredentials = true;

//エラーハンドリング
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error.response);
    if (error.response.status === 401) {
      window.localStorage.removeItem("vuex");
      alert("アクセストークンが不正です。もう一度ログインして下さい。")
      router.push("/");
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App),
  head: {
    title: {
      inner: "vollmond-lesson-system"
    }
  }
}).$mount('#app')
