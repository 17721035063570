<template>
  <div class="accordion" @click.stop="onClick($event)">
    <p class="accordion__text" style="text-transform: none">{{ text }}</p>
    <v-icon
      :style="{
        'font-size': this.isPC ? '1.2em' : '1em',
      }"
    >
      mdi-menu-down
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "VmAccordionMenu",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  components: {},
  data: () => ({}),
  created() {},
  mounted() {},
  computed: {},
  methods: {
    onClick($event) {
      this.$emit("click", $event);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  color: #6D6F6C;
  font-weight: bold;
}
.accordion {
  display: flex;
  cursor: pointer;

  &__text {
    font-size: 0.9em;
  }
}
</style>
