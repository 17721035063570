<template>
  <div>
    <VmAccordionMenu
      text="以下の生徒情報が共有されます。"
      @click="showStudentInfo = !showStudentInfo"
      :style="{ 'margin-left': '-8px' }"
    />
    <VmStudentInfo
      v-if="showStudentInfo"
      :student="student"
      :style="{ 'margin-top': '5px' }"
    />
    <VmButton
      type="primary"
      @click="toProfile"
      text="プロフィールを編集する"
      :style="{
        'margin-top': '20px',
        'margin-left': '-16px',
      }"
      v-if="showStudentInfo"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import VmStudentInfo from "../Molecules/VmStudentInfo";
import VmAccordionMenu from "../Atoms/VmAccordionMenu";
import VmButton from "../Atoms/VmButton";
export default {
  name: "SharedStudentInfo",
  props: [],
  components: {
    VmStudentInfo,
    VmAccordionMenu,
    VmButton,
  },
  data: () => ({
    showStudentInfo: false,
  }),
  created() {},
  mounted() {
    this.fetchStudentInfo();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user,
    }),
  },
  methods: {
    fetchStudentInfo() {
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(`/api/students/${this.userInfo.studentId}`, header)
          .then((response) => {
            this.student = response.data.student;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    toProfile() {
      let routeData = this.$router.resolve({
        path: "studentMypage",
        query: {},
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped></style>
