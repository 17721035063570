<template>
  <div>
    <!-- タイトル -->
    <PageTitle title-name="レッスン一覧" />
    <VmBox>
      <template #header>
        <v-row no-gutters>
          <VmButton
            type="primary"
            @click="openAddDialog"
            text="レッスンを追加する"
            :style="{
              'margin-right': '20px',
            }"
          />
          <CsvUploadArea type="LESSON" @reload="reload" />
          <v-spacer />
          <VmButton
            type="dangerous"
            @click="openDeleteDialog"
            text="選択レッスン一括削除"
          />
          <VueJsonToCsv
            :json-data="downloadedCSVData"
            :csv-title="computedCSVTitle"
          >
            <v-btn class="csv-button"> csvダウンロード </v-btn>
          </VueJsonToCsv>
        </v-row>
      </template>
      <template #content>
        <!-- 月変更できるところ -->
        <v-row>
          <SelectMonth
            v-model="selectedMonth"
            @fetchData="setUpLessonData"
            @reload="reload"
            :disabled="isDisabledMonthSelect"
          />
          <v-checkbox
            v-model="isShowFromToday"
            label="今日以降"
            class="fromTodayButton"
          ></v-checkbox>
          <v-text-field v-model="search" label="Search"></v-text-field>
        </v-row>
        <v-data-table
          v-model="selectedLessons"
          show-select
          item-key="lessonId"
          :headers="headers"
          :items="lessonArray"
          :search="search"
          multi-sort
          class="elevation-1 overflow-y-auto"
          disable-pagination
          no-data-text="レッスン記録がありません"
          hide-default-footer
          :loading="isLoading"
          fixed-header
          :height="lessonArray.length > 15 ? 48 * 15 - 1 : undefined"
        >
          <template v-slot:top>
            <v-row class="filter-area">
              <v-dialog v-model="dialog" max-width="500px">
                <AdminEditingFormCard
                  @closeAction="close"
                  @saveAction="save"
                  :editedItem.sync="editedItem"
                  formTitle="レッスン内容編集"
                />
              </v-dialog>
            </v-row>
          </template>
          <template slot="body.prepend">
            <tr class="sticky-table-header">
              <td>
                <v-icon>mdi-filter-variant</v-icon>
              </td>
              <td>
                <v-text-field
                  v-model="lessonId"
                  type="number"
                  label="lessonId"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="teacherName"
                  type="text"
                  label="teacherName"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="teacherSystemId"
                  type="number"
                  label="teacherSystemId"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="studentName"
                  type="text"
                  label="studentName"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="studentSystemId"
                  type="number"
                  label="studentSystemId"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="startTime"
                  type="date"
                  label="startTime"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="endTime"
                  type="date"
                  label="endTime"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="lessonTime"
                  type="number"
                  label="lessonTime"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="course"
                  type="text"
                  label="course"
                ></v-text-field>
              </td>
              <td>
                <v-select
                  :items="statusItems"
                  v-model="isFilterStatus"
                  label="ステータス"
                  outline
                ></v-select>
              </td>
              <td>
                <v-text-field
                  v-model="lessonReport"
                  type="text"
                  label="lessonReport"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="memoForTeacher"
                  type="text"
                  label="memoForTeacher"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="isFirstLesson"
                  type="number"
                  label="isFirstLesson"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="isChecked"
                  type="number"
                  label="isChecked"
                ></v-text-field>
              </td>
              <td></td>
            </tr>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </template>
    </VmBox>
    <v-dialog v-model="showAddDialog" v-if="showAddDialog" max-width="500px">
      <VmAdminLessonAddCard
        v-model="showAddDialog"
        v-if="showAddDialog"
        @complete="addComplete"
        @close="closeAddDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="showDeleteDialog"
      v-if="showDeleteDialog"
      max-width="500px"
    >
      <AdminDeleteFormCard
        @closeDeleteAction="closeDeleteDialog"
        @deleteItemConfirmAction="deleteAllSelectedLessons"
        formTitle="レッスン一括削除"
        :content="deletedLessonText"
      />
    </v-dialog>
  </div>
</template>

<script>
import AdminEditingFormCard from "../Organisms/AdminEditingFormCard";
import PageTitle from "../Atoms/PageTitle";
import CsvUploadArea from "../Molecules/CsvUploadArea";
import SelectMonth from "../Molecules/SelectMonth";
import VmButton from "../Atoms/VmButton";
import { VmAdminLessonAddCard } from "../domains/admin";
import AdminDeleteFormCard from "../Organisms/AdminDeleteFormCard";
import { computeLessonTime } from "../../util/computeLessonTime";
import VmBox from "../Atoms/VmBox";

export default {
  name: "AdminLessonList",
  inject: ["reloadRouter"],
  components: {
    AdminEditingFormCard,
    PageTitle,
    SelectMonth,
    CsvUploadArea,
    VmButton,
    VmAdminLessonAddCard,
    AdminDeleteFormCard,
    VmBox,
  },
  data: () => ({
    lessonId: "",
    teacherName: "",
    teacherSystemId: "",
    studentName: "",
    studentSystemId: "",
    startTime: "",
    endTime: "",
    lessonTime: "",
    course: "",
    status: "",
    lessonReport: "",
    memoForTeacher: "",
    isFirstLesson: "",
    selectedMonth: null,
    accessToken: null,
    selectedLessons: [],
    statusItems: ["全て", "未", "完了", "キャンセル"],
    lessonArray: [],
    isFilterStatus: null,
    isShowFromToday: false,
    search: "",
    dialog: false,
    dialogDelete: false,
    selectedLessonId: 0,
    defaultItem: {
      startTime: "",
      endTime: "",
      status: 0,
      isFirstLesson: 0,
    },
    addExplanation:
      "statusについて\n 「未」の場合は0、「完了」の場合は1、「キャンセル」の場合は2を入力してください。（全て半角数字）",
    editedItem: {
      startTime: "",
      endTime: "",
      status: 0,
      isFirstLesson: 0,
    },
    downloadedCSVData: [],
    isLoading: false,
    showAddDialog: false,
    showDeleteDialog: false,
    isDisabledMonthSelect: false,
    isChecked: false
  }),
  computed: {
    headers() {
      return [
        {
          text: "lessonId",
          align: "start",
          value: "lessonId",
          filter: (value) => {
            if (!this.lessonId) return true;
            return (
              String(value)
                .toLowerCase()
                .indexOf(this.lessonId.toLowerCase()) != -1
            );
          },
        },
        {
          text: "講師",
          value: "teacherName",
          filter: (value) => {
            if (!this.teacherName) return true;
            return (
              String(value)
                .toLowerCase()
                .indexOf(this.teacherName.toLowerCase()) != -1
            );
          },
        },
        {
          text: "講師ID",
          value: "teacherSystemId",
          filter: (value) => {
            if (!this.teacherSystemId) return true;
            return (
              String(value)
                .toLowerCase()
                .indexOf(this.teacherSystemId.toLowerCase()) != -1
            );
          },
        },
        {
          text: "生徒",
          value: "studentName",
          filter: (value) => {
            if (!this.studentName) return true;
            return (
              String(value)
                .toLowerCase()
                .indexOf(this.studentName.toLowerCase()) != -1
            );
          },
        },
        {
          text: "生徒ID",
          value: "studentSystemId",
          filter: (value) => {
            if (!this.studentSystemId) return true;
            return (
              String(value)
                .toLowerCase()
                .indexOf(this.studentSystemId.toLowerCase()) != -1
            );
          },
        },
        {
          text: "開始時間",
          value: "startTime",
          filter: (value) => {
            if (this.startTime) {
              return (
                String(value)
                  .toLowerCase()
                  .indexOf(this.startTime.toLowerCase()) != -1
              );
            }
            if (this.isShowFromToday) {
              return value >= this.$moment().format("YYYY-MM-DD HH:mm");
            }
            return true;
          },
        },
        { text: "終了時間", value: "endTime" },
        {
          text: "レッスン時間（分）",
          value: "lessonTime",
          filter: (value) => {
            if (!this.lessonTime) return true;
            return (
              String(value)
                .toLowerCase()
                .indexOf(this.lessonTime.toLowerCase()) != -1
            );
          },
        },
        {
          text: "コース",
          value: "course",
          filter: (value) => {
            if (!this.course) return true;
            return (
              String(value).toLowerCase().indexOf(this.course.toLowerCase()) !=
              -1
            );
          },
        },
        {
          text: "レッスンステータス",
          value: "status",
          filter: (value) => {
            if (!this.isFilterStatus) {
              return true;
            }
            switch (this.isFilterStatus) {
              case "全て":
                return true;
              default:
                return value === this.isFilterStatus;
            }
          },
        },
        {
          text: "レッスン内容報告",
          value: "lessonReport",
          filter: (value) => {
            if (!this.lessonReport) return true;
            return (
              String(value)
                .toLowerCase()
                .indexOf(this.lessonReport.toLowerCase()) != -1
            );
          },
        },
        {
          text: "講師メモ",
          value: "memoForTeacher",
          filter: (value) => {
            if (!this.memoForTeacher) return true;
            return (
              String(value)
                .toLowerCase()
                .indexOf(this.memoForTeacher.toLowerCase()) != -1
            );
          },
        },
        {
          text: "初回レッスン",
          value: "isFirstLesson",
          filter: (value) => {
            if (!this.isFirstLesson) return true;
            return (
              String(value)
                .toLowerCase()
                .indexOf(this.isFirstLesson.toLowerCase()) != -1
            );
          },
        },
        {
          text: "チェック済み",
          value: "isChecked",
          filter: (value) => {
            if (!this.isChecked) return true;
            return (
              String(value)
                .toLowerCase()
                .indexOf(this.isChecked.toLowerCase()) != -1
            );
          },
        },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    computedCSVTitle() {
      return (
        this.$moment(this.selectedMonth).format("YYYY_MM_") + "レッスン一覧"
      );
    },
    deletedLessonText() {
      let deletedLessonText = "";
      for (let selectedLesson of this.selectedLessons) {
        deletedLessonText =
          deletedLessonText +
          `id: ${selectedLesson.lessonId}\n講師名: ${selectedLesson.teacherName}\n生徒名: ${selectedLesson.studentName}\n開始時刻: ${selectedLesson.startTime}\n終了時刻: ${selectedLesson.endTime}\nコース: ${selectedLesson.course}\n---------------------\n\n`;
      }
      return deletedLessonText;
    },
  },
  props: [],
  async created() {
    this.fetchInitialData();
  },
  mounted() {},
  methods: {
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      const user_info = this.$store.getters["user/getUserInfo"];
      this.accessToken = user_info.accessToken;
    },
    async reload() {
      this.isDisabledMonthSelect = true;
      this.lessonArray = [];
      this.selectedLessons = [];

      try {
        await this.setUpLessonData();
      } catch (error) {
        this.isDisabledMonthSelect = false;
        console.log("error", error);
      }
      this.isDisabledMonthSelect = false;
    },
    setUpLessonData() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        };

        const startTime = this.$moment(this.selectedMonth)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm");
        const endTime = this.$moment(this.selectedMonth)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm");

        this.axios
          .get(`/api/lessons?startTime=${startTime}&endTime=${endTime}`, header)
          .then((response) => {
            const lessons = response.data.lessons;

            this.makeLessonArray(lessons);
            this.isLoading = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    makeLessonArray(lessons) {
      this.downloadedCSVData = [];

      for (let lesson of lessons) {
        const lessonData = {
          lessonId: Number(lesson.id),
          teacherName: lesson["Teacher.teacherName"],
          teacherSystemId: lesson["Teacher.id"],
          studentName: lesson["Student.name"],
          studentEmail: lesson["Student.email"],
          studentSystemId: lesson["Student.id"],
          studentVollmondNumber: lesson["Student.studentIdNum"],
          startTime: this.$moment(lesson.startTime).format("YYYY-MM-DD HH:mm"),
          endTime: this.$moment(lesson.endTime).format("YYYY-MM-DD HH:mm"),
          lessonTime: computeLessonTime(lesson.startTime, lesson.endTime),
          course: lesson["LessonCourse.courseName"],
          status: this.stringStatus(lesson.status),
          lessonReport: lesson.lessonReport,
          memoForTeacher: lesson.memoForTeacher,
          isFirstLesson: lesson.isFirstLesson,
          isChecked: lesson.isChecked,
        };
        this.lessonArray.push(lessonData);
        // CSV出力用に入れておく
        this.downloadedCSVData.push(lessonData);
      }
    },
    stringStatus(value) {
      switch (value) {
        case 0:
          return "未";
        case 1:
          return "完了";
        case 2:
          return "キャンセル";
        default:
          return "";
      }
    },
    editItem(item) {
      this.selectedLessonId = item.lessonId;

      const filterItemArray = Object.keys(this.editedItem);
      for (let filterItem of filterItemArray) {
        this.editedItem[filterItem] = item[filterItem];
      }
      this.dialog = true;
    },
    async deleteItem(item) {
      console.dir(item);
      if (
        confirm(
          `この予定を削除しますか？\n\nid: ${item.lessonId}\n講師名: ${item.teacherName}\n生徒名: ${item.studentName}\n開始時刻: ${item.startTime}\n終了時刻: ${item.endTime}\nコース: ${item.course}`
        )
      ) {
        try {
          await this.delete(item.lessonId);
        } catch (error) {
          console.log(error);
        }
        this.closeDelete();
        this.reload();
      }
    },
    async deleteAllSelectedLessons() {
      for (let selectedLesson of this.selectedLessons) {
        try {
          await this.delete(selectedLesson.lessonId);
        } catch (error) {
          console.log(error);
        }
      }
      this.selectedLessons = [];
      this.closeDeleteDialog();
      this.reload();
    },
    delete(lessonId) {
      return new Promise((resolve) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        };

        this.axios
          .delete(`/api/lessons/${lessonId}`, header)
          .then((response) => {
            console.log("response.data", response.data);
            resolve();
          })
          .catch((error) => {
            alert(error.response.data.error.message);
          });
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    save() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      };

      switch (this.editedItem.status) {
        case "未":
          this.editedItem.status = 0;
          break;
        case "完了":
          this.editedItem.status = 1;
          break;
        case "キャンセル":
          this.editedItem.status = 2;
          break;
        default:
          this.editedItem.status = 0;
          break;
      }

      this.editedItem.startTime = this.$convertToPost(
        this.editedItem.startTime
      );
      this.editedItem.endTime = this.$convertToPost(this.editedItem.endTime);

      this.axios
        .put(`/api/lessons/${this.selectedLessonId}`, this.editedItem, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.close();
          this.reload();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    openAddDialog() {
      this.showAddDialog = true;
    },
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    closeAddDialog() {
      this.showAddDialog = false;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    addComplete() {
      this.showAddDialog = false;
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
v-data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #dcc268;
}

thead.v-data-table-header {
  background-color: #dcc268;
}

.filter-area {
  padding: 0 20px;
}
.filter-item {
  padding: 0 5px;
}
.csv-button {
  margin-left: 15px;
}
.fromTodayButton {
  margin-right: 15px;
}
.sticky-table-header td {
  position: -webkit-sticky;
  position: sticky;
  top: 124px;
  background-color: white;
  /* tbody内のセルより手前に表示する */
  z-index: 2;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: white !important;
}
</style>
