<template>
  <validationProvider
    rules="max:400"
    v-slot="{ errors, valid }"
    immediate
    name="その他講師に伝えておきたいこと"
  >
    <p>
      <FormTitleText titleText="その他講師に伝えておきたいこと" />
      <FormAttentionText :attentionText="$t('profile.max400')" />
    </p>
    <v-textarea
      v-model="inputedOtherItem"
      placeholder=""
      outlined
      background-color="white"
      counter
      :success="valid"
    ></v-textarea>
    <FormErrorText :errorText="errors[0]" />
  </validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText';
import FormErrorText from '../Atoms/FormErrorText';
import FormAttentionText from '../Atoms/FormAttentionText';

export default {
  name: 'OtherItemForm',
  data: () => ({
  }),
  props: ['value'],
  mounted() {
  },
  computed: {
    inputedOtherItem: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
  },
  components: {
    FormTitleText,
    FormErrorText,
    FormAttentionText
  }
};
</script>

<style lang="scss" scoped>
</style>
