<template>
  <component :is="tag" :class="classes">
    <header v-if="hasHeader || title" class="box__header">
      <component
        :is="`h${ level }`"
        v-if="title"
        class="box__title"
      >
        {{ title }}
      </component>
      <slot v-if="hasHeader" name="header" />
    </header>
    <div class="box__content" :style="styles">
      <slot name="content" />
    </div>
    <footer v-if="hasFooter" class="box__footer">
      <slot name="footer" />
    </footer>
  </component>
</template>

<script>
export default {
  props: {
    tag: { type: String, default: 'section' },
    level: { type: Number, default: 2 },
    title: { type: String },
    contentHeight: { type: String },
    border: { type: Boolean, default: false },
  },
  computed: {
    hasHeader() {
      return Object.prototype.hasOwnProperty.call(this.$slots, 'header')
    },
    hasFooter() {
      return Object.prototype.hasOwnProperty.call(this.$slots, 'footer')
    },
    styles() {
      const styles = {}
      if (this.contentHeight) {
        styles['max-height'] = this.contentHeight
        styles['overflow-y'] = 'auto'
      }
      return styles
    },
    classes() {
      const classes = ['box']
      if (this.border) classes.push('--border')
      return classes
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 0 rgb(63 63 68 / 5%);
  background-color: #FFF;
  &__header {
    padding: 16px;
    border-bottom: 1px solid #E4E7EB;
  }
  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.25;
  }
  &__content {
    padding: 16px;
  }
  &__footer {
    padding: 16px;
    border-top: 1px solid #E4E7EB;
  }
  &.--border {
    border: 1px solid #E4E7EB;
  }
}
</style>
