<template>
  <v-container>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="regularLessonArray"
      :class="{ 'elevation-1': true }"
      disable-sort
      disable-pagination
      :no-data-text="$t('top.noLesson')"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <!-- studentNameが空なら編集 -->
        <v-icon
          v-if="item.studentName != $t('teacherSchedule.vacant')"
          small
          class="mr-2"
          @click="openEditForm(item)"
        >
          mdi-pencil
        </v-icon>
        <!-- studentNameが空でないなら削除 -->
        <v-icon small @click="openDeleteForm(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <div class="add-regular-schedule-button">
      <!-- 定期予約受付可能日時の登録（ボタン） -->
      <ActiveButton
        :button-title="$t('teacherSchedule.registerAvailableRegularSchedule')"
        @clickAction="openAddRegularScheduleForm"
      />
      <!-- 定期レッスン追加ボタン -->
      <v-row justify="center" class="add-regular-lesson-area">
        <v-btn color="primary" text @click="openAddRegularLessonCard">
          <!-- 定期レッスンを追加する -->
          <p class="add-regular-lesson-btn-text">
            {{ $t("teacherSchedule.addRegularLessonTitle") }}
          </p>
        </v-btn>
      </v-row>
    </div>
    <v-dialog v-model="isOpenEditDialog" max-width="500px">
      <!-- 定期レッスン予定の変更 -->
      <EditRegularLessonCard
        @cancelAction="closeEditRegularScheduleCard"
        @reload="reload"
        courseName="プライベートコース"
        :cardTitle="$t('teacherSchedule.changeRegularLesson')"
        :userInfo="userInfo"
        :scheduleInfo="editedItem"
      />
    </v-dialog>
    <v-dialog v-model="isOpenDeleteDialog" max-width="500px">
      <!-- 定期予約受付可能日時の削除 -->
      <DeleteRegularScheduleCard
        @cancelAction="closeDeleteRegularScheduleCard"
        courseName="プライベートコース"
        :cardTitle="$t('teacherSchedule.deleteAvailableRegularSchedule')"
        :userInfo="userInfo"
        :scheduleInfo="deletedItem"
      />
    </v-dialog>
    <!-- 定期レッスンの追加フォーム -->
    <v-dialog
      v-model="isOpenAddRegularLessonCard"
      :close-on-content-click="false"
      offset-x
      v-if="isOpenAddRegularLessonCard"
      max-width="500px"
    >
      <AddRegularLessonCardForTeacher
        @cancelAction="closeAddRegularLessonCard"
        @reload="reload"
        :cardTitle="$t('teacherSchedule.addRegularLessonTitle')"
        :userInfo="userInfo"
      />
    </v-dialog>
    <!-- 予定の新規追加フォーム -->
    <v-dialog
      v-model="isOpenAddRegularScheduleCard"
      :close-on-content-click="false"
      offset-x
      v-if="isOpenAddRegularScheduleCard"
      max-width="500px"
    >
      <!-- 定期予約受付可能日時の登録（フォーム） -->
      <TeacherSchedulesRegularCard
        @cancelAction="closeAddRegularScheduleCard"
        courseName="プライベートコース"
        :cardTitle="$t('teacherSchedule.registerAvailableRegularSchedule')"
        :userInfo="userInfo"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import ActiveButton from "../Atoms/ActiveButton";
import TeacherSchedulesRegularCard from "../Molecules/TeacherSchedulesRegularCard";
import EditRegularLessonCard from "../Molecules/EditRegularLessonCard";
import DeleteRegularScheduleCard from "../Molecules/DeleteRegularScheduleCard";
import AddRegularLessonCardForTeacher from "../Molecules/AddRegularLessonCardForTeacher";

export default {
  name: "TeacherScheduleRegularTable",
  inject: ["reloadRouter"],
  data: () => ({
    userInfo: {},
    selected: [],
    headers: [
      { text: "曜日", value: "dayOfWeek" },
      { text: "開始時間", value: "startTime" },
      { text: "終了時間", value: "endTime" },
      { text: "コース", value: "course" },
      { text: "生徒", value: "studentName" },
      { text: "受付開始日", value: "receptionStartDate" },
    ],
    fetchedRegularSchedules: [],
    arrangedRegularSchedules: [],
    regularLessonArray: [],
    isOpenAddRegularScheduleCard: false,
    isOpenEditDialog: false,
    isOpenDeleteDialog: false,
    editedItem: null,
    deletedItem: null,
    isOpenAddRegularLessonCard: false,
  }),
  props: [],
  components: {
    ActiveButton,
    TeacherSchedulesRegularCard,
    EditRegularLessonCard,
    DeleteRegularScheduleCard,
    AddRegularLessonCardForTeacher,
  },
  async created() {
    this.setupColumn();
    this.fetchInitialData();

    try {
      await this.fetchRegularSchedules();
    } catch (error) {
      console.log(error);
    }

    try {
      await this.addDayOfWeekToSchedules();
    } catch (error) {
      console.log(error);
    }

    this.makeRegularLessonArray();
  },
  mounted() {},
  computed: {
    computedCourseName: function () {
      return function (lessonCourseId) {
        switch (lessonCourseId) {
          case 1:
            return this.$t("teacherSchedule.CONVERSATION");
          case 2:
            return this.$t("teacherSchedule.PRIVATE");
          default:
            return "";
        }
      };
    },
    computedWeek: function () {
      return function (value) {
        const weekDayArrayJa = ["日", "月", "火", "水", "木", "金", "土"];
        const weekDayArrayDe = [
          "So.",
          "Mo.",
          "Di.",
          "Mi.",
          "Do.",
          "Fr.",
          "Sa.",
        ];
        switch (this.$i18n.locale) {
          case "ja":
            return weekDayArrayJa[value];
          case "de":
            return weekDayArrayDe[value];
          default:
            return weekDayArrayJa[value];
        }
      };
    },
    // 引数のscheduleが指定の曜日（引数のdayOfWeek）とマッチするかどうかを判断する
    judgeMatchDayOfWeek: function () {
      return function (schedule, dayOfWeek) {
        const scheduleDayOfWeek = Number(schedule.startTime.format("DD") - 1);

        if (scheduleDayOfWeek > 29) {
          if (Number(dayOfWeek) === 6) {
            return true;
          }
        } else if (scheduleDayOfWeek > 6) {
          if (Number(dayOfWeek) === 0) {
            return true;
          }
        } else {
          return scheduleDayOfWeek === dayOfWeek;
        }
      };
    },
  },
  methods: {
    // ここでテーブルの列名を設定する
    setupColumn() {
      this.headers.push({ text: "", value: "actions", sortable: false });

      // 多言語対応のため
      this.headers[0].text = this.$t("teacherSchedule.dayOfWeek");
      this.headers[1].text = this.$t("teacherSchedule.startTime");
      this.headers[2].text = this.$t("teacherSchedule.endTime");
      this.headers[3].text = this.$t("teacherSchedule.course");
      this.headers[4].text = this.$t("teacherSchedule.studentInCharge");
      this.headers[5].text = this.$t("teacherSchedule.openDay");
    },
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    translateCourseName(value) {
      switch (value) {
        case "CONVERSATION":
          return this.$t("teacherSchedule.CONVERSATION");
        case "PRIVATE":
          return this.$t("teacherSchedule.PRIVATE");
        default:
          return "";
      }
    },
    // 講師の定期予定スケジュール（空き予定を含む）を取得
    fetchRegularSchedules() {
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        // 講師の定期予定スケジュールを取得（空き予定も含めて取得しているため、RegisterTeacherSchedule.vueの関数とは取得してくる内容が違う）
        this.axios
          .get(
            `/api/regularSchedules?teacherId=${this.userInfo.teacherId}`,
            header
          )
          .then((response) => {
            this.fetchedRegularSchedules = response.data.regularSchedules;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },

    // 取得した予定に曜日情報を付け加える
    addDayOfWeekToSchedules() {
      // fetchedRegularSchedulesで日を跨ぐものは分割する（ここでconvertToAccurateTimeも使用している）
      let dividedAvailableRegularSchedules = this.$divideStraddlingDaySchedule(
        this.fetchedRegularSchedules
      );
      // テーブルに順番に表示するため、分割されたデータを昇順に並び替える
      dividedAvailableRegularSchedules = this.sortAscendingSchedules(
        dividedAvailableRegularSchedules
      );

      for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
        const schedulesFilteredByWeekDay =
          dividedAvailableRegularSchedules.filter((schedule) =>
            this.judgeMatchDayOfWeek(schedule, dayOfWeek)
          );

        this.arrangedRegularSchedules.push({
          dayOfWeek: dayOfWeek,
          schedules: schedulesFilteredByWeekDay,
          lessonCourseId: 2,
        });
      }
    },
    // スケジュール情報を昇順に並び替える関数
    sortAscendingSchedules(schedules) {
      const sortedSchedules = schedules.sort((a, b) => {
        if (this.$moment(a.startTime).isAfter(this.$moment(b.startTime))) {
          return 1;
        } else {
          return -1;
        }
      });
      return sortedSchedules;
    },
    // 曜日情報を付け加えられたスケジュール情報をテーブルで使用するデータに変換していく
    makeRegularLessonArray() {
      for (let arrangedRegularSchedule of this.arrangedRegularSchedules) {
        for (let schedule of arrangedRegularSchedule.schedules) {
          const startTime = this.$moment(schedule.startTime).format("HH:mm");
          const endTime = this.$moment(schedule.endTime).format("HH:mm");

          const regularLesson = {
            regularScheduleId: schedule.id,
            dayOfWeek: this.computedWeek(arrangedRegularSchedule.dayOfWeek),
            rawDayOfWeek: arrangedRegularSchedule.dayOfWeek,
            startTime: startTime,
            endTime: endTime === "00:00" ? "24:00" : endTime,
            course: this.computedCourseName(schedule.lessonCourseId),
            studentId: schedule["Student.id"] ? schedule["Student.id"] : null,
            studentName: schedule["Student.name"]
              ? schedule["Student.name"]
              : this.$t("teacherSchedule.vacant"),
            receptionStartDate: schedule.receptionStartDate
              ? this.$moment(schedule.receptionStartDate).format("YYYY/MM/DD")
              : "-",
          };

          this.regularLessonArray.push(regularLesson);
        }
      }
    },
    openAddRegularScheduleForm() {
      this.isOpenAddRegularScheduleCard = true;
    },
    closeAddRegularScheduleCard() {
      this.isOpenAddRegularScheduleCard = false;
    },
    openEditForm(item) {
      this.isOpenEditDialog = true;
      this.editedItem = item;
    },
    openDeleteForm(item) {
      this.isOpenDeleteDialog = true;
      this.deletedItem = item;
      console.log("this.deletedItem", this.deletedItem);
    },
    closeEditRegularScheduleCard() {
      this.isOpenEditDialog = false;
    },
    closeDeleteRegularScheduleCard() {
      this.isOpenDeleteDialog = false;
    },
    openAddRegularLessonCard() {
      this.isOpenAddRegularLessonCard = true;
    },
    closeAddRegularLessonCard() {
      this.isOpenAddRegularLessonCard = false;
    },
    async reload() {
      this.fetchedRegularSchedules = [];
      this.arrangedRegularSchedules = [];
      this.regularLessonArray = [];

      try {
        await this.fetchRegularSchedules();
      } catch (error) {
        console.log(error);
      }

      try {
        await this.addDayOfWeekToSchedules();
      } catch (error) {
        console.log(error);
      }

      this.makeRegularLessonArray();

      // 変更をカレンダーにも反映させる
      this.reloadCalendar();
    },
    reloadCalendar() {
      this.$emit("reloadCalendar");
    },
  },
};
</script>

<style>
.add-regular-schedule-button {
  margin: 50px 0;
}
.add-regular-lesson-area {
  margin: 20px 0 0 0;
}
.add-regular-lesson-btn-text {
  margin: 0;
  font-weight: bold;
  text-decoration: underline;
}
</style>
