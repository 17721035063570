<template>
  <div>
    <v-menu v-model="dateMenu" :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-text-field
          :value="showedDate"
          readonly
          v-on="on"
          :style="{ fontWeight: 'bold' }"
          outlined
          background-color="white"
          hide-details
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="inputedDate"
        :min="minDate"
        :max="maxDate"
        @input="closeDatePicker"
        @change="changeDatePicker"
        :first-day-of-week="userInfo.beginningOfWeek"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DatePicker",
  data: () => ({
    dateMenu: false,
    userInfo: null,
  }),
  props: ["value", "showedDate", "minDate", "maxDate"],
  computed: {
    inputedDate: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() {
    this.fetchInitialData();
  },
  methods: {
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    closeDatePicker(val) {
      this.dateMenu = false;
      this.$emit("closeDatePicker", val);
    },
    changeDatePicker() {
      this.$emit("changeDatePicker");
    },
  },
};
</script>

<style lang="scss" scoped></style>
