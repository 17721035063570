<template>
  <VmDialogCard
    :title="cardTitle"
    :buttonTitle="$t('teacherSchedule.changeLessonButton')"
    :cancelButtonTitle="$t('teacherSchedule.cancel')"
    @click="changeLesson"
    @close="cancel"
    :disabled="!buttonValidation"
  >
    <template #cardContent>
      <v-container>
        <v-row>
          <p class="edit-lesson-subtitle">
            <!-- 現在のレッスン詳細 -->
            {{ $t("teacherSchedule.currentLessonInfo") }}
          </p>
          <TimeZoneIcon />
        </v-row>
        <v-row>
          <!-- コース：-->
          {{ $t("teacherSchedule.course") }}: {{ computedCourseName(courseId) }}
          <br />
          <!-- 生徒名：-->
          {{ $t("teacherSchedule.studentInCharge") }}: {{ studentName }}
          <br />
          <!-- 開始時刻：-->
          {{ $t("teacherSchedule.startTime") }}: {{ startTime }}
          <br />
          <!-- 終了時刻：-->
          {{ $t("teacherSchedule.endTime") }}: {{ endTime }}
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <p class="edit-lesson-subtitle">
            <!-- 変更先レッスン日時 -->
            {{ $t("teacherSchedule.changedLessonTime") }}
          </p>
          <TimeZoneIcon />
        </v-row>
        <v-row v-if="!buttonValidation">
          <p class="attention-text">
            {{ $t("teacherSchedule.changeLessonAttention") }}
          </p>
        </v-row>
        <div class="date-form-area">
          <!-- 開始日時のフォーム -->
          <DateAndTimeForm
            :formLabelText="$t('teacherSchedule.startTime')"
            :date.sync="scheduleStartDate"
            :showedDate="computedScheduleStartDate"
            :minDate="selectableStartMinDate"
            @closeDatePicker="inputEndDate"
            @changeDatePicker="activeButton"
            :time.sync="scheduleStartTime"
            :minuteInterval="5"
            @changeTimePicker="activeButton"
          />
          <!-- 終了日時のフォーム -->
          <DateAndTimeForm
            :formLabelText="$t('teacherSchedule.endTime')"
            :date.sync="scheduleEndDate"
            :showedDate="computedScheduleEndDate"
            :minDate="selectableMinEndDate"
            @changeDatePicker="activeButton"
            :time.sync="scheduleEndTime"
            :minuteInterval="5"
            @changeTimePicker="activeButton"
          />
        </div>
      </v-container>
    </template>
  </VmDialogCard>
</template>

<script>
import TimeZoneIcon from "../Atoms/TimeZoneIcon";
import DateAndTimeForm from "../Molecules/DateAndTimeForm";
import VmDialogCard from "../Molecules/VmDialogCard";

export default {
  name: "EditPlannedPrivateLessonCard",
  inject: ["reloadCalendar"],
  components: {
    TimeZoneIcon,
    DateAndTimeForm,
    VmDialogCard,
  },
  data: () => ({
    textFontWeight: "bold",
    scheduleStartDate: "2020-12-01",
    scheduleEndDate: "2020-12-01",
    scheduleStartTime: "00:00",
    scheduleEndTime: "00:00",
    isEdited: false,
  }),
  props: [
    "courseId",
    "cardTitle",
    "startTime",
    "endTime",
    "selectedOpen",
    "lessonId",
    "userInfo",
    "studentName",
    "calendarValue",
  ],
  computed: {
    selectableStartMinDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    selectableMinEndDate() {
      return this.scheduleStartDate;
    },
    computedStartTime() {
      const start = this.scheduleStartDate + " " + this.scheduleStartTime;
      return this.$moment(start).format("YYYY-MM-DD HH:mm");
    },
    computedEndTime() {
      const end = this.scheduleEndDate + " " + this.scheduleEndTime;
      return this.$moment(end).format("YYYY-MM-DD HH:mm");
    },
    computedScheduleStartDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.scheduleStartDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.scheduleStartDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
    computedScheduleEndDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.scheduleEndDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.scheduleEndDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
    buttonValidation() {
      // 時間がおかしかったらアウト
      if (!this.lessonTimeValidation) {
        return false;
      }

      // 時刻が編集されてなかったらアウト
      if (!this.isEdited) {
        return false;
      }

      return true;
    },
    // バリデーション - 終了時刻 > 開始時刻になっていればok
    lessonTimeValidation() {
      const start = this.$moment(
        this.scheduleStartDate + " " + this.scheduleStartTime
      );
      const end = this.$moment(
        this.scheduleEndDate + " " + this.scheduleEndTime
      );

      // 30分単位じゃないものはアウト
      if (this.$moment(end).diff(start, "minutes") % 30 != 0) {
        return false;
      }

      // 終了時刻 = 開始時刻になっているためアウト
      if (this.scheduleStartTime === this.scheduleEndTime) {
        return false;
      }

      if (end.isBefore(start)) {
        return false;
      }
      return true;
    },
    computedCourseName: function () {
      return function (lessonCourseId) {
        switch (lessonCourseId) {
          case 1:
            return this.$t("teacherSchedule.CONVERSATION");
          case 2:
            return this.$t("teacherSchedule.PRIVATE");
          default:
            return "";
        }
      };
    },
  },
  mounted() {
    this.initScheduleDate();
  },
  methods: {
    initScheduleDate() {
      this.scheduleStartDate = this.$moment(this.startTime).format(
        "YYYY-MM-DD"
      );
      this.scheduleEndDate = this.$moment(this.endTime).format("YYYY-MM-DD");

      this.scheduleStartTime = this.$moment(this.startTime).format("HH:mm");
      this.scheduleEndTime = this.$moment(this.endTime).format("HH:mm");
    },
    changeLesson() {
      if (
        confirm(
          `${this.$t(
            "teacherSchedule.changeLessonConfirmation"
          )} \n\n◎${this.$t("teacherSchedule.beforeChange")}\n${this.$t(
            "teacherSchedule.startTime"
          )}: ${this.startTime}\n${this.$t("teacherSchedule.endTime")}: ${
            this.endTime
          }\n\n◎${this.$t("teacherSchedule.afterChange")}\n${this.$t(
            "teacherSchedule.startTime"
          )}: ${this.computedStartTime}\n${this.$t(
            "teacherSchedule.endTime"
          )}: ${this.computedEndTime}`
        )
      ) {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        const editedLessonData = {
          lessonId: this.lessonId,
          teacherId: this.userInfo.teacherId,
          startTime: this.$convertToPost(this.computedStartTime),
          endTime: this.$convertToPost(this.computedEndTime),
          lessonCourseId: this.courseId,
        };
        console.log("editedLessonData", editedLessonData);
        this.axios
          .post(`/api/lessons/change`, editedLessonData, header)
          .then((response) => {
            console.log("response.data", response.data);
            this.cancel();
            this.reloadCalendar(this.calendarValue);
          })
          .catch((error) => {
            alert(error.response.data.error.message);
          });
      }
    },
    cancel() {
      this.$emit("cancelAction");
    },
    inputEndDate(val) {
      this.scheduleEndDate = val;
    },
    activeButton() {
      this.isEdited = true;
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
.edit-lesson-subtitle {
  font-size: 1.4em;
  font-weight: bold;
  margin: 20px 0 15px 0;
}
.button {
  margin-bottom: 20px;
}
.attention-text {
  color: red;
  white-space: pre-line;
}
</style>
