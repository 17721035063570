import axios from 'axios'

export const reserve = {
  namespaced: true,
  state: {
    loading: false
  },
  getters: {
    loading: (state) => {
      return state.loading
    },
  },
  mutations: {
    prepareConnect(state) {
      state.loading = true
    },
    stopLoading(state) {
      state.loading = false
    },
  },
  actions: {
    async privateReserve({ commit }, { accessToken, reserveData }) {
      commit('prepareConnect')
      const header = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      return axios
        .post("/api/lessons/privateReserve", reserveData, header)
        .then(() => {
          commit('stopLoading')
          return true
        })
        .catch((error) => {
          alert(error.response.data.error.message);
          commit('stopLoading')
          return false
        });
    },
    async conversationReserve({ commit }, { accessToken, reserveData }) {
      commit('prepareConnect')
      const header = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      return axios
        .post("/api/lessons/conversationReserve", reserveData, header)
        .then(() => {
          commit('stopLoading')
          return true
        })
        .catch((error) => {
          alert(error.response.data.error.message);
          commit('stopLoading')
          return false
        });
    },
    async regularReserve({ commit }, { accessToken, reserveData }) {
      commit('prepareConnect')
      const header = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      return axios
        .post("/api/lessons/regularReserve", reserveData, header)
        .then(() => {
          commit('stopLoading')
          return true
        })
        .catch((error) => {
          alert(error.response.data.error.message);
          commit('stopLoading')
          return false
        });
    }
  }
}
