<template>
  <div>
    <p>{{ formTitle }}: ￥{{ fee }}</p>
  </div>
</template>

<script>
export default {
  name: "LessonFeeForm",
  data: () => ({
    isEditedEmail: false,
  }),
  props: ["formTitle", "fee"],
  mounted() {},
  computed: {},
  methods: {},
  components: {},
};
</script>

<style lang="scss" scoped></style>
