<template>
  <!-- 講師が自分のスケジュールを変更・追加する時に使うカレンダー -->
  <div>
    <p class="notes">
      <!-- レッスンが可能な時間を「レッスン可能」として登録してください。既に登録している「レッスン可能」は変更/削除が可能です。 -->
      {{ this.$t("teacherSchedule.registerScheduleGuide1") }}
      <br />
      {{ this.$t("teacherSchedule.registerScheduleGuide2") }}
    </p>
    <p style="font-size: 0.9em; font-weight: normal">
      <!-- ※予定変更を行う際は、既存の「予約済みレッスン予定」・「レッスン可能」の時間に被らないように変更して下さい。 -->
      {{ this.$t("teacherSchedule.registerScheduleGuide3") }}
    </p>
    <v-row>
      <v-col cols="12">
        <div class="calendar-icon vacant-icon"></div>
        <p class="icon-text">{{ $t("teacherSchedule.VACANT") }}</p>
        <div class="calendar-icon private-icon"></div>
        <p class="icon-text">{{ $t("teacherSchedule.PRIVATE") }}</p>
        <br v-if="!isPC" />
        <div class="calendar-icon conversation-icon"></div>
        <p class="icon-text">{{ $t("teacherSchedule.CONVERSATION") }}</p>
      </v-col>
    </v-row>

    <v-row>
      <!-- 表示に関するセッティング。月表示、週表示など -->
      <v-col cols="6" xs="6" sm="3" md="3" lg="3">
        <v-select
          v-model="type"
          :items="typeOptions"
          label="Type"
          hide-details
          outlined
          background-color="white"
          dense
        ></v-select>
      </v-col>
      <!-- 週初めに関するセッティング -->
      <v-col cols="6" xs="6" sm="4" md="4" lg="4">
        <SwitchBeginningOfWeek @reloadCalendar="reloadCalendar" />
      </v-col>
    </v-row>
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ title }}
        </v-toolbar-title>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-sheet>

    <v-sheet :height="isPC ? 800 : 1600">
      <v-calendar
        ref="calendar"
        v-model="calendarValue"
        :calendarValue="calendarValue"
        :type="type"
        :events="events"
        :weekdays="computedWeekdays"
        @change="updateRange"
        :locale="$i18n.locale"
        @click:event="showEvent"
        @click:date="showDate"
        @click:more="showMore"
        :event-color="getEventColor"
      >
        <template v-slot:event="{ event }">
          <p class="summary">{{ event.name }}</p>
        </template>
      </v-calendar>
      <!-- 授業の変更フォーム -->
      <v-dialog
        v-model="isPlannedPrivateLesson"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
        v-if="isPlannedPrivateLesson"
        max-width="500px"
      >
        <EditPlannedPrivateLessonCard
          :startTime="selectedEvent.start"
          :endTime="selectedEvent.end"
          :lessonId="selectedEvent.lessonId"
          :studentName="selectedEvent.studentName"
          :courseId="selectedEvent.courseId"
          @cancelAction="closePlannedPrivateLesson"
          :cardTitle="$t('teacherSchedule.changeLessonTitle')"
          :userInfo="userInfo"
          :calendarValue="calendarValue"
        />
      </v-dialog>
      <v-dialog
        v-model="isPlannedConversationLesson"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
        v-if="isPlannedConversationLesson"
        max-width="500px"
      >
        <EditPlannedConversationLessonCard
          :startTime="selectedEvent.start"
          :endTime="selectedEvent.end"
          :lessonId="selectedEvent.lessonId"
          :studentName="selectedEvent.studentName"
          :courseId="selectedEvent.courseId"
          @cancelAction="closePlannedConversationLesson"
          :cardTitle="$t('teacherSchedule.changeLessonTitle')"
          :userInfo="userInfo"
          :calendarValue="calendarValue"
        />
      </v-dialog>

      <!-- 登録済み予定の変更フォーム -->
      <v-dialog
        v-model="isPlannedSchedule"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
        v-if="isPlannedSchedule"
        max-width="500px"
      >
        <EditTeacherScheduleCard
          :start-time="selectedEvent.start"
          :end-time="selectedEvent.end"
          :teacherScheduleId="selectedEvent.teacherScheduleId"
          :isEdited="true"
          :courseId="selectedEvent.courseId"
          @cancelAction="closePlannedSchedule"
          :cardTitle="$t('teacherSchedule.changeSchedule')"
          :userInfo="userInfo"
          :calendarValue="calendarValue"
        />
      </v-dialog>
    </v-sheet>
    <!-- プレビューボタン -->
    <v-btn
      color="primary"
      class="mb-2"
      @click="toPrivatePreview"
      text
      small
      v-if="userInfo.lessonCourseArray.includes('PRIVATE')"
    >
      <p :style="{ fontWeight: 'bold' }" class="preview-button">
        {{ $t("teacherSchedule.previewForPriv") }}
      </p>
    </v-btn>
    <v-btn
      color="primary"
      class="mb-2"
      @click="toConvPreview"
      text
      small
      v-if="userInfo.lessonCourseArray.includes('CONVERSATION')"
    >
      <p :style="{ fontWeight: 'bold' }" class="preview-button">
        {{ $t("teacherSchedule.previewForConv") }}
      </p>
    </v-btn>
    <!-- フォームはこのあたりで実装 -->
    <div>
      <!-- 予定の新規追加フォーム -->
      <v-dialog
        v-model="isOpenAddScheduleCard"
        :close-on-content-click="false"
        offset-x
        v-if="isOpenAddScheduleCard"
        max-width="500px"
      >
        <RegisterTeacherScheduleCard
          @cancelAction="closeAddScheduleCard"
          :cardTitle="$t('teacherSchedule.addSchedule')"
          :isEdited="false"
          :userInfo="userInfo"
          :calendarValue="calendarValue"
        />
      </v-dialog>
      <!-- レッスンの追加フォーム -->
      <v-dialog
        v-model="isOpenAddLessonCard"
        :close-on-content-click="false"
        offset-x
        v-if="isOpenAddLessonCard"
        max-width="500px"
      >
        <AddLessonCardForTeacher
          @cancelAction="closeAddLessonCard"
          :cardTitle="$t('teacherSchedule.addLessonTitle')"
          :userInfo="userInfo"
          :calendarValue="calendarValue"
        />
      </v-dialog>
    </div>
    <v-row justify="center" class="add-schedule-area">
      <ActiveButton
        :button-title="$t('teacherSchedule.registerSchedule')"
        @clickAction="openAddScheduleCard"
      />
    </v-row>
    <v-row
      v-if="!isOnlyConversationTeacher"
      justify="center"
      class="add-lesson-area"
    >
      <v-btn color="primary" text @click="openAddLessonCard">
        <!-- レッスンを追加する -->
        <p class="add-lesson-btn-text">
          {{ $t("teacherSchedule.addLesson") }}
        </p>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import ActiveButton from "../Atoms/ActiveButton";
import EditTeacherScheduleCard from "../Molecules/EditTeacherScheduleCard";
import RegisterTeacherScheduleCard from "../Molecules/RegisterTeacherScheduleCard";
import AddLessonCardForTeacher from "../Molecules/AddLessonCardForTeacher";
import EditPlannedPrivateLessonCard from "../Molecules/EditPlannedPrivateLessonCard";
import EditPlannedConversationLessonCard from "../Molecules/EditPlannedConversationLessonCard";
import SwitchBeginningOfWeek from "../Atoms/SwitchBeginningOfWeek";

export default {
  name: "TeacherScheduleCalendar",
  components: {
    ActiveButton,
    EditTeacherScheduleCard,
    RegisterTeacherScheduleCard,
    AddLessonCardForTeacher,
    EditPlannedPrivateLessonCard,
    EditPlannedConversationLessonCard,
    SwitchBeginningOfWeek,
  },
  data: () => ({
    type: "month",
    typeOptions: [
      { text: "Day", value: "day" },
      { text: "4 Day", value: "4day" },
      { text: "Week", value: "week" },
      { text: "Month", value: "month" },
    ],
    events: [],
    selectedEvent: {},
    selectedElement: null,
    calendarValue: null,
    isOpenAddScheduleCard: false,
    isOpenAddLessonCard: false,
    isPlannedSchedule: false,
    isPlannedPrivateLesson: false,
    isPlannedConversationLesson: false,
  }),
  props: ["teacherSchedules", "plannedTeacherLessons", "userInfo", "usedMonth"],
  mounted() {
    this.addEvents();
    if (this.usedMonth) {
      this.calendarValue = this.$moment(this.usedMonth).format("YYYY-MM-DD");
    } else {
      this.calendarValue = this.$moment().format("YYYY-MM-DD");
    }
  },
  computed: {
    title: function () {
      return this.$moment(this.calendarValue).format("YYYY/MM");
    },
    computedCourseName: function () {
      return function (lessonCourseId) {
        switch (lessonCourseId) {
          case 1:
            return this.$t("teacherSchedule.CONVERSATION");
          case 2:
            return this.$t("teacherSchedule.PRIVATE");
          default:
            return "";
        }
      };
    },
    // 会話コース専任講師かどうか判断する
    isOnlyConversationTeacher() {
      const lessonCourseArray = this.userInfo.lessonCourseArray;

      // 会話コース以外のコースが1つでもあればfalse
      for (let lessonCourse of lessonCourseArray) {
        if (lessonCourse != "CONVERSATION") {
          return false;
        }
      }
      return true;
    },
    computedWeekdays() {
      // 週始まりを設定する
      switch (this.userInfo.beginningOfWeek) {
        case 0:
          return [0, 1, 2, 3, 4, 5, 6];
        case 1:
          return [1, 2, 3, 4, 5, 6, 0];
        default:
          return [0, 1, 2, 3, 4, 5, 6];
      }
    },
  },
  methods: {
    addEvents() {
      const teacherScheduleEvents = this.addTeacherSchedulesToEvents();
      const addPlannedLessonsEvents = this.addPlannedLessonsToEvents();

      this.events = teacherScheduleEvents.concat(addPlannedLessonsEvents);
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
      };

      // teacherScheduleIdがないということは予約済みレッスンを選択肢したということなので
      // 予約済み授業を編集するフォームを表示させる
      if (!event.teacherScheduleId) {
        // 定期予定の場合は開かない
        if (event.isRegular) {
          return;
        }

        const twoDaysAfterDay = this.$moment().add(2, "days");
        const start = this.$moment(event.start);

        // レッスンの開始時刻 < 2日後 の場合はクリックできないようにする
        if (twoDaysAfterDay.isAfter(start)) {
          return;
        }

        switch (Number(event.courseId)) {
          // 会話コースの場合
          case 1:
            if (this.isPlannedConversationLesson) {
              this.isPlannedConversationLesson = false;
              setTimeout(open, 10);
            } else {
              this.isPlannedConversationLesson = true;
              open();
            }
            break;

          // プライベートコースの場合
          case 2:
            if (this.isPlannedPrivateLesson) {
              this.isPlannedPrivateLesson = false;
              setTimeout(open, 10);
            } else {
              this.isPlannedPrivateLesson = true;
              open();
            }
            break;
          default:
            return;
        }
        return;
      }

      // 講師スケジュールの変更フォームの表示
      if (this.isPlannedSchedule) {
        this.isPlannedSchedule = false;
        setTimeout(open, 10);
      } else {
        this.isPlannedSchedule = true;
        open();
      }

      nativeEvent.stopPropagation();
    },
    showDate() {
      this.isOpenAddScheduleCard = true;
    },
    showMore(event) {
      this.calendarValue = event.date;
      this.type = "day";
    },
    updateRange() {},
    addTeacherSchedulesToEvents() {
      let events = [];
      for (let schedule of this.teacherSchedules) {
        const startDate = this.$moment(schedule.startTime).format(
          "YYYY-MM-DD HH:mm"
        );
        const endDate = this.$moment(schedule.endTime).format(
          "YYYY-MM-DD HH:mm"
        );
        const startDateLabel = this.$moment(schedule.startTime).format("HH:mm");

        const endDateLabel = this.$moment(schedule.endTime).format("HH:mm");

        const availableSchedule = {
          //  e.g. 空 - プライベートコース
          name: `${startDateLabel} - ${endDateLabel} ${this.$t(
            "teacherSchedule.vacant"
          )}${
            schedule.isRegular ? `/${this.$t("teacherSchedule.regular")}` : ""
          } : ${this.computedCourseName(schedule.lessonCourseId)}`,
          start: startDate,
          end: endDate,
          teacherScheduleId: schedule.id,
          color: "vacantSchedule",
          courseId: schedule.lessonCourseId,
          isRegular: schedule.isRegular,
        };
        events.push(availableSchedule);
      }
      return events;
    },
    addPlannedLessonsToEvents() {
      let events = [];

      for (let plannedLesson of this.plannedTeacherLessons) {
        const startDate = this.$moment(plannedLesson.startTime).format(
          "YYYY-MM-DD HH:mm"
        );
        const endDate = this.$moment(plannedLesson.endTime).format(
          "YYYY-MM-DD HH:mm"
        );
        const startDateLabel = this.$moment(plannedLesson.startTime).format(
          "HH:mm"
        );

        const endDateLabel = this.$moment(plannedLesson.endTime).format(
          "HH:mm"
        );

        const availableSchedule = {
          name: `${startDateLabel} - ${endDateLabel} ${this.$t(
            "teacherSchedule.reserved"
          )}: ${plannedLesson["Student.name"]}`,
          start: startDate,
          end: endDate,
          color:
            plannedLesson.lessonCourseId === 1
              ? "conversationCourse"
              : "privateCourse",
          courseId: plannedLesson.lessonCourseId,
          studentName: plannedLesson["Student.name"],
          lessonId: plannedLesson.id,
        };
        events.push(availableSchedule);
      }
      return events;
    },
    openAddScheduleCard() {
      this.isOpenAddScheduleCard = true;
    },
    openAddLessonCard() {
      this.isOpenAddLessonCard = true;
    },
    closeAddScheduleCard() {
      this.isOpenAddScheduleCard = false;
    },
    closePlannedSchedule() {
      this.isPlannedSchedule = false;
    },
    closePlannedPrivateLesson() {
      this.isPlannedPrivateLesson = false;
    },
    closePlannedConversationLesson() {
      this.isPlannedConversationLesson = false;
    },
    closeAddLessonCard() {
      this.isOpenAddLessonCard = false;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },
    reloadCalendar() {
      this.$emit("reload");
    },
    // プライベートコースのプレビュー
    toPrivatePreview() {
      let resolvedRoute = this.$router.resolve({
        path: "privateTeacherSchedule",
        query: { teacherId: this.userInfo.teacherId },
      });
      window.open(resolvedRoute.href, "_blank");
    },
    // 会話コースのプレビュー
    toConvPreview() {
      let resolvedRoute = this.$router.resolve({
        path: "conversationTeacherSchedule",
        query: { teacherId: this.userInfo.teacherId },
      });
      window.open(resolvedRoute.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.add-schedule-area {
  margin: 50px 0 0 0;
}
.notes {
  margin: 30px 0 10px 0;
  font-weight: bold;
}
.add-lesson-area {
  margin: 20px 0 0 0;
}
.add-lesson-btn-text {
  margin: 0;
  font-weight: bold;
  text-decoration: underline;
}
.calendar-icon {
  display: inline-block;
  width: 28px;
  height: 16px;
  border-radius: 8px;
  position: relative;
  top: 2px;
}
.vacant-icon {
  background-color: #9d9d9d;
}
.private-icon {
  background-color: #de755e;
}
.conversation-icon {
  background-color: #eaa142;
}
.icon-text {
  font-size: 0.9em;
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
}
.preview-button {
  margin: 10px 0 0 0;
  position: relative;
  right: 15px;
  /* font-size: 0.8em; */
}
.summary {
  margin-left: 4px;
  font-weight: bold;
  white-space: pre-line;
  text-overflow: inherit;
  font-size: 0.92em;
}
</style>
