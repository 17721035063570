<template>
  <div>
     <vue-loading
        type="spin"
        color="#DCC268"
        :size="{ width: '50px', height: '50px' }"
      >
      </vue-loading>
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template'

export default {
  name: 'VmLoading',
  components: {
    VueLoading,
  },

}
</script>

<style>
</style>
