<template>
  <validationProvider
    rules="required|max:50"
    v-slot="{ errors, valid }"
    immediate
    name="趣味・特技"
  >
    <p>
      <FormTitleText :titleText="$t('profile.hobbies')" />
      <FormAttentionText :attentionText="$t('profile.max50')" />
    </p>
    <v-textarea
      v-model="inputedHobbies"
      :placeholder="hobbiesLabel"
      outlined
      background-color="white"
      counter
      :success="valid"
    ></v-textarea>
    <FormErrorText :errorText="errors[0]" />
  </validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText';
import FormErrorText from '../Atoms/FormErrorText';
import FormAttentionText from '../Atoms/FormAttentionText';
import mypageText from '@/data/mypage/mypageText';

export default {
  name: 'HobbiesForm',
  data: () => ({
    hobbiesLabel: mypageText.hobbiesLabel,
  }),
  props: ['value'],
  mounted() {
  },
  computed: {
    inputedHobbies: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
  },
  components: {
    FormTitleText,
    FormErrorText,
    FormAttentionText
  }
};
</script>

<style lang="scss" scoped>
</style>
