var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{style:({
    background: 'red',
    padding: '3px',
    color: 'white',
    'font-weight': 'bold',
    'font-size': '0.5em',
    'border-radius': '3px',
    margin: '0 5px',
    position: 'relative',
    bottom: '2px',
  })},[_vm._v(_vm._s(_vm.$t("profile.required")))])}
var staticRenderFns = []

export { render, staticRenderFns }