<template>
  <div>
    <p><FormTitleText :titleText="$t('profile.lessonCourseInCharge')" /></p>
    <p v-for="(name, i) of lessonCourseNames" :key="i">{{ name }}</p>
  </div>
</template>

<script>
import FormTitleText from "../Atoms/FormTitleText";

export default {
  name: "LessonCourseInChargeForm",
  data: () => ({
    isEditedEmail: false,
  }),
  props: ["formTitle", "lessonCourses"],
  mounted() {},
  computed: {
    lessonCourseNames() {
      let lessonCourseNames = [];
      for (let lessonCourse of this.lessonCourses) {
        switch (lessonCourse) {
          case "CONVERSATION":
            lessonCourseNames.push(this.$t("profile.CONVERSATION"));
            break;
          case "PRIVATE":
            lessonCourseNames.push(this.$t("profile.PRIVATE"));
            break;
          case "TEXT":
            lessonCourseNames.push("テキストコース");
            break;
        }
      }
      return lessonCourseNames;
    },
  },
  methods: {},
  components: {
    FormTitleText,
  },
};
</script>

<style lang="scss" scoped></style>
