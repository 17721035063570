<template>
  <!-- 日時フォーム -->
  <v-row class="date-form">
    <!-- 開始、終了などのテキスト -->
    <v-col cols="8" xs="3" sm="2" md="2" lg="2">
      <p class="form-title-text">{{ formLabelText }}：</p>
    </v-col>
    <!-- dateピッカー -->
    <v-col cols="10" xs="10" sm="5" md="5" lg="5" class="picker">
      <DatePicker
        v-model="inputedDate"
        :showedDate="showedDate"
        :minDate="minDate"
        :maxDate="maxDate"
        @closeDatePicker="closeDatePicker"
        @changeDatePicker="changeDatePicker"
      />
    </v-col>
    <!-- timeピッカー -->
    <v-col cols="10" xs="10" sm="5" md="5" lg="5" class="picker">
      <TimePicker
        v-model="inputedTime"
        :minuteInterval="minuteInterval"
        :minTime="minTime"
        :maxTime="maxTime"
        :form="timeForm"
        @closeTimePicker="closeTimePicker"
        @changeTimePicker="changeTimePicker"
      />
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from "../Atoms/DatePicker";
import TimePicker from "../Atoms/TimePicker";

export default {
  name: "DateAndTimeForm",
  data: () => ({}),
  props: [
    "formLabelText",
    "date",
    "showedDate",
    "minDate",
    "maxDate",
    "time",
    "minuteInterval",
    "minTime",
    "maxTime",
    "timeForm",
  ],
  computed: {
    inputedDate: {
      get() {
        return this.date;
      },
      set(value) {
        this.$emit("update:date", value);
      },
    },
    inputedTime: {
      get() {
        return this.time;
      },
      set(value) {
        this.$emit("update:time", value);
      },
    },
  },
  methods: {
    closeDatePicker(val) {
      this.$emit("closeDatePicker", val);
    },
    closeTimePicker(val) {
      this.$emit("closeTimePicker", val);
    },
    changeDatePicker() {
      this.$emit("changeDatePicker");
    },
    changeTimePicker() {
      this.$emit("changeTimePicker");
    },
  },
  components: {
    DatePicker,
    TimePicker,
  },
};
</script>

<style lang="scss" scoped>
.date-form-area {
  margin: 30px 0 10px 0;
}
.date-form {
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-title-text {
  margin: 0 0 0 0;
  display: inline-block;
  vertical-align: bottom;
}
</style>
