import axios from 'axios'

export const lessonPlan = {
  namespaced: true,
  state: {
    lessonPlans: [],
    loading: false
  },
  getters: {
    loading: (state) => {
      return state.loading
    },
    lessonPlans: (state) => {
      return state.lessonPlans
    },
  },
  mutations: {
    prepareConnect(state) {
      state.loading = true
    },
    stopLoading(state) {
      state.loading = false
    },
    setLessonPlans(state, data) {
      state.lessonPlans = data
    }
  },
  actions: {
    fetchLessonPlans({ commit }, payload) {
      commit('prepareConnect')
      const header = {
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      };
      return axios
        .get(`/api/lessonPlans?studentId=${payload.studentId}`, header)
        .then(res => {
          commit('setLessonPlans', res.data.lessonPlans)
        })
        .catch(err => {
          commit('stopLoading')
          console.log(err);
        });
    },
  }
}
