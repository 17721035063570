var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{class:{ 'elevation-1': true },attrs:{"headers":_vm.headers,"items":_vm.staticsItems,"disable-sort":"","no-data-text":_vm.$t('top.noStaticsData'),"hide-default-footer":"","loading":_vm.isLoading},scopedSlots:_vm._u([_vm._l((_vm.headers),function(headerItem,key,index){return {key:("item." + (headerItem.value)),fn:function(ref){
var item = ref.item;
return [(headerItem.value == 'name')?_c('div',{key:index},[_vm._v(" "+_vm._s(item[headerItem.value])+" ")]):(
              _vm.judgeHighOrLowAvg(item[headerItem.value], headerItem.value) &&
              item.name != 'vollmond平均'
            )?_c('v-chip',{key:index,attrs:{"color":"secondary","dark":""}},[_vm._v(" "+_vm._s(_vm.fomatPercent(item[headerItem.value]))+" ")]):_c('div',{key:index},[_vm._v(" "+_vm._s(_vm.fomatPercent(item[headerItem.value]))+" ")])]}}})],null,true)})],1)],1),_c('v-row',[_c('VmAccordionMenu',{style:({ 'margin-left': '12px' }),attrs:{"text":_vm.$t('top.howToCalcRate')},on:{"click":function($event){_vm.showHowToCalc = !_vm.showHowToCalc}}})],1),(_vm.showHowToCalc)?_c('v-row',{style:({ padding: '15px' })},[_c('div',{style:({ background: '#FAFAFA', padding: '10px 15px' })},[_c('p',{style:({
          'font-size': '0.8em',
          'line-height': '2.3',
        })},[_vm._v(" "+_vm._s(_vm.$t("top.explanationOfCalc"))+" ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }