import Vue from "vue";
import Router from "vue-router";
import Login from "../components/Pages/Login";
import StudentTop from "../components/Pages/StudentTop";
import TeacherTop from "../components/Pages/TeacherTop";
import ConversationTeacherList from "../components/Pages/ConversationTeacherList";
import PrivateTeacherList from "../components/Pages/PrivateTeacherList";
import AllTeacherList from "../components/Pages/AllTeacherList";
import ConversationTeacherSchedule from "../components/Pages/ConversationTeacherSchedule";
import PrivateTeacherSchedule from "../components/Pages/PrivateTeacherSchedule";
import RegisterTeacherSchedule from "../components/Pages/RegisterTeacherSchedule";
import CompleteConversationReservation from "../components/Pages/CompleteConversationReservation";
import CompletePrivateReservation from "../components/Pages/CompletePrivateReservation";
import BuyTicketTop from "../components/Pages/BuyTicketTop";
import LessonRequestList from "../components/Pages/LessonRequestList";
import AdminTop from "../components/Pages/AdminTop";
import AdminLessonList from "../components/Pages/AdminLessonList";
import AdminTeacherList from "../components/Pages/AdminTeacherList";
import AdminStudentList from "../components/Pages/AdminStudentList";
import AdminTeacherScheduleList from "../components/Pages/AdminTeacherScheduleList";
import AdminRegularScheduleList from "../components/Pages/AdminRegularScheduleList";
import AdminTextCourseList from "../components/Pages/AdminTextCourseList";
import AdminChargeForStudentList from "../components/Pages/AdminChargeForStudentList";
import AdminPrivateRewardList from "../components/Pages/AdminPrivateRewardList";
import AdminConversationRewardList from "../components/Pages/AdminConversationRewardList";
import AdminMailText from "../components/Pages/AdminMailText";
import AdminMessage from "../components/Pages/AdminMessage";
import TeacherMypage from "../components/Pages/TeacherMypage";
import StudentMypage from "../components/Pages/StudentMypage";
import Maintenance from "../components/Pages/Maintenance";
import PasswordForget from "../components/Pages/PasswordForget";
import PasswordReset from "../components/Pages/PasswordReset";
import LessonHistory from "../components/Pages/LessonHistory";
import SignUp from "../components/Pages/SignUp";
import ConfirmRegisteredInfo from "../components/Pages/ConfirmRegisteredInfo";
import CompletedAccountRegistration from "../components/Pages/CompletedAccountRegistration";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/signUp",
    name: "SignUp",
    component: SignUp,
    props: true
  },
  {
    path: "/confirmRegisteredInfo",
    name: "ConfirmRegisteredInfo",
    component: ConfirmRegisteredInfo,
    props: true
  },
  {
    path: "/completedAccountRegistration",
    name: "CompletedAccountRegistration",
    component: CompletedAccountRegistration,
    props: true
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance
  },
  {
    path: "/passwordForget",
    name: "PasswordForget",
    component: PasswordForget
  },
  {
    path: "/passwordReset/:token",
    name: "PasswordReset",
    component: PasswordReset,
    props: route => ({
      token: route.params.token,
      email: route.query.email
    })
  },
  {
    path: "/studentTop",
    name: "StudentTop",
    component: StudentTop
  },
  {
    path: "/lessonHistory",
    name: "LessonHistory",
    component: LessonHistory
  },
  {
    path: "/teacherTop",
    name: "TeacherTop",
    component: TeacherTop
  },
  {
    path: "/conversationTeacherList",
    name: "ConversationTeacherList",
    component: ConversationTeacherList
  },
  {
    path: "/privateTeacherList",
    name: "PrivateTeacherList",
    component: PrivateTeacherList
  },
  {
    path: "/conversationTeacherSchedule",
    name: "ConversationTeacherSchedule",
    component: ConversationTeacherSchedule,
    props: route => ({
      teacherId: Number(route.query.teacherId),
    })
  },
  {
    path: "/allTeacherList",
    name: "AllTeacherList",
    component: AllTeacherList
  },
  {
    path: "/privateTeacherSchedule",
    name: "PrivateTeacherSchedule",
    component: PrivateTeacherSchedule,
    props: route => ({
      teacherId: Number(route.query.teacherId),
    })
  },
  {
    path: "/registerTeacherSchedule",
    name: "RegisterTeacherSchedule",
    component: RegisterTeacherSchedule
  },
  {
    path: "/completeConversationReservation",
    name: "CompleteConversationReservation",
    component: CompleteConversationReservation,
    props: true
  },
  {
    path: "/completePrivateReservation",
    name: "CompletePrivateReservation",
    component: CompletePrivateReservation,
    props: true
  },
  {
    path: "/buyTicketTop",
    name: "BuyTicketTop",
    component: BuyTicketTop
  },
  {
    path: "/lessonRequestList",
    name: "LessonRequestList",
    component: LessonRequestList
  },
  {
    path: "/teacherMypage",
    name: "TeacherMypage",
    component: TeacherMypage
  },
  {
    path: "/studentMypage",
    name: "StudentMypage",
    component: StudentMypage
  },
  {
    path: "/admin/top",
    name: "AdminTop",
    component: AdminTop
  },
  {
    path: "/admin/lessonList",
    name: "AdminLessonList",
    component: AdminLessonList
  },
  {
    path: "/admin/teacherList",
    name: "AdminTeacherList",
    component: AdminTeacherList
  },
  {
    path: "/admin/studentList",
    name: "AdminStudentList",
    component: AdminStudentList
  },
  {
    path: "/admin/teacherScheduleList",
    name: "AdminTeacherScheduleList",
    component: AdminTeacherScheduleList
  },
  {
    path: "/admin/adminRegularScheduleList",
    name: "AdminRegularScheduleList",
    component: AdminRegularScheduleList
  },
  {
    path: "/admin/adminTextCourseList",
    name: "AdminTextCourseList",
    component: AdminTextCourseList
  },
  {
    path: "/admin/adminChargeForStudentList",
    name: "AdminChargeForStudentList",
    component: AdminChargeForStudentList
  },
  {
    path: "/admin/AdminPrivateRewardList",
    name: "AdminPrivateRewardList",
    component: AdminPrivateRewardList
  },
  {
    path: "/admin/AdminConversationRewardList",
    name: "AdminConversationRewardList",
    component: AdminConversationRewardList
  },
  {
    path: "/admin/adminMailText",
    name: "AdminMailText",
    component: AdminMailText
  },
  {
    path: "/admin/adminMessage",
    name: "AdminMessage",
    component: AdminMessage
  }
]

export default new Router({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
