<template>
  <div>
    <p>
      {{ formTitle }}:
      {{ status ? $t("profile.reception") : $t("profile.receptionStop") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ReceptionStatusForm",
  data: () => ({
    isEditedEmail: false,
  }),
  props: ["formTitle", "status"],
  mounted() {},
  computed: {},
  methods: {},
  components: {},
};
</script>

<style lang="scss" scoped></style>
