import axios from 'axios'

export const adminLesson = {
  namespaced: true,
  state: {
    lessons: null,
    loading: false
  },
  getters: {
    loading: (state) => {
      return state.loading
    },
    lessons: (state) => {
      return state.lessons
    },
  },
  mutations: {
    prepareConnect(state) {
      state.loading = true
    },
    stopLoading(state) {
      state.loading = false
    },
  },
  actions: {
    createLessons({ commit }, { accessToken, payload }) {
      commit('prepareConnect')
      const header = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      return axios
        .post(`/api/lessons`, payload, header)
        .then(() => {
          commit('stopLoading')
          return true
        })
        .catch(() => {
          commit('stopLoading')
        });
    }
  }
}
