<template>
  <div>
    <!-- タイトル -->
    <PageTitle title-name="生徒一覧" />
    <VmBox>
      <template #header>
        <v-col class="csv-upload-area">
          <CsvUploadArea type="STUDENT" @reload="reloadTable" />
        </v-col>
      </template>
      <template #content>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="studentArray"
          :search="search"
          multi-sort
          class="elevation-1 overflow-y-auto"
          disable-pagination
          no-data-text="生徒がいません"
          hide-default-footer
          v-if="showTable"
          :loading="isLoading"
          fixed-header
          :height="studentArray.length > 15 ? 48 * 15 - 1 : undefined"
        >
          <template v-slot:top>
            <v-btn color="primary" dark class="mb-2" @click="showAddDialog = true">
              新規生徒
            </v-btn>
            <v-dialog v-model="showAddDialog" max-width="500px">
              <AdminAddFormCard
                @closeAction="closeAdd"
                @addAction="add"
                :editedItem="editedItem"
                formTitle="生徒追加"
              />
            </v-dialog>
            <v-dialog v-model="showEditDialog" max-width="500px">
              <AdminEditingFormCard
                @closeAction="close"
                @saveAction="save"
                :editedItem.sync="editedItem"
                formTitle="生徒情報編集"
              />
            </v-dialog>
            <v-dialog v-model="showDeleteDialog" max-width="500px">
              <AdminDeleteFormCard
                @closeDeleteAction="closeDelete"
                @deleteItemConfirmAction="deleteItemConfirm"
                formTitle="この生徒情報を削除しますか？"
              />
            </v-dialog>
            <v-dialog v-model="showTicketDialog" max-width="500px">
              <AdminAddTicketCard
                :studentId="selectedStudentId"
                :studentName="selectedStudentName"
                @closeAction="closeTicketDialog"
                @reloadTable="reloadTable"
              />
            </v-dialog>
            <v-text-field
              v-model="search"
              label="Search"
              class="filter-item"
            ></v-text-field>
          </template>
          <!-- 文字数制限をかけるため以下のような実装をする -->
          <template
            v-for="(headerItem, key, index) in headers"
            v-slot:[`item.${headerItem.value}`]="{ item }"
          >
            <p :style="{ margin: '0' }" :key="index">
              {{ $limitedText30(item[headerItem.value]) }}
            </p>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
            <v-icon small @click="openTicketDialog(item)"> mdi-ticket </v-icon>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </template>
    </VmBox>
  </div>
</template>

<script>
import AdminEditingFormCard from "../Organisms/AdminEditingFormCard";
import AdminDeleteFormCard from "../Organisms/AdminDeleteFormCard";
import AdminAddFormCard from "../Organisms/AdminAddFormCard";
import AdminAddTicketCard from "../Organisms/AdminAddTicketCard";
import CsvUploadArea from "../Molecules/CsvUploadArea.vue";
import PageTitle from "../Atoms/PageTitle";
import VmBox from '../Atoms/VmBox'

export default {
  name: "AdminStudentList",
  components: {
    AdminEditingFormCard,
    AdminDeleteFormCard,
    AdminAddFormCard,
    AdminAddTicketCard,
    CsvUploadArea,
    PageTitle,
    VmBox
  },
  data: () => ({
    students: null,
    selected: [],
    studentArray: [],
    isShowOnlyDone: false,
    isShowFromToday: false,
    search: "",
    showEditDialog: false,
    showAddDialog: false,
    showDeleteDialog: false,
    showTicketDialog: false,
    selectedStudentId: 0,
    selectedStudentName: "",
    defaultItem: {
      studentIdNum: 0,
      name: "",
      email: "",
      lessonCourseIds: "",
      password: "",
      birthDay: null,
      sex: 0,
      country: "",
      numberOfTicket: 0,
      paymentMethod: "",
      category: "",
      level: "",
      correctionFrequency: "",
      monthlyFeedback: false,
      otherItem: "",
      hopeInLesson: "",
      learningGoal: "",
    },
    editedItem: {
      studentIdNum: 0,
      name: "",
      email: "",
      lessonCourseIds: "",
      password: "",
      birthDay: null,
      sex: 0,
      country: "",
      numberOfTicket: 0,
      paymentMethod: "",
      category: "",
      level: "",
      correctionFrequency: "",
      monthlyFeedback: false,
      otherItem: "",
      hopeInLesson: "",
      learningGoal: "",
    },
    showTable: true,
    isLoading: false,
  }),
  computed: {
    headers() {
      return [
        { text: "actions", value: "edit", sortable: false, align: "start" },
        // { text: '', value: 'ticket', sortable: false },
        {
          text: "studentId",
          value: "studentId",
        },
        { text: "名前", value: "name" },
        { text: "email", value: "email" },
        { text: "チケット数", value: "numberOfTicket" },
        { text: "受講コース", value: "lessonCourseIds" },
        { text: "生年月日", value: "birthDay" },
        { text: "性別", value: "sex" },
        { text: "国", value: "country" },
        { text: "生徒番号", value: "studentIdNum" },
        { text: "カテゴリー", value: "category" },
        { text: "レベル", value: "level" },
        { text: "訂正の頻度", value: "correctionFrequency" },
        { text: "月末フィードバックの有無", value: "monthlyFeedback" },
        { text: "その他", value: "otherItem" },
        { text: "レッスンの希望", value: "hopeInLesson" },
        { text: "レッスンの目標", value: "learningGoal" },
        { text: "在籍学校", value: "school" },
        { text: "専攻名", value: "major" },
        { text: "Stripe顧客ID", value: "stripeId" },
        { text: "プライベートリマインド", value: "isRemindedPriv" },
        { text: "会話リマインド", value: "isRemindedConv" },
        { text: "", value: "delete", sortable: false },
      ];
    },
  },
  headerValues() {
    let headerValues = [];
    this.headers.forEach((headerItem) => {
      headerValues.push(headerItem);
    });
    return headerValues;
  },
  props: [],
  created() {
    this.fetchInitialData();
    this.reloadTable();
  },
  mounted() {},
  methods: {
    reloadTable() {
      this.fetchStudents()
        .then(() => {
          this.makeStudentArray();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      const user_info = this.$store.getters["user/getUserInfo"];
      this.accessToken = user_info.accessToken;
    },
    fetchStudents() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        };
        this.axios
          .get(`/api/students`, header)
          .then((response) => {
            this.students = response.data.students;
            this.isLoading = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    makeStudentArray() {
      // リセット
      this.studentArray = [];
      this.students.forEach((student, index) => {
        // Student - StudentLessonCoursesで結合しているので、複数コースを担当している人がいたら
        // 複数データが連続で入ることになる（2コース担当していたら2連続）
        // 連続で同じ人のデータが入っていたら"lessonCourseIds"の値を"1,2"みたいな感じでコンマ区切りで表示できるようにする
        if (index != 0 && student.email === this.students[index - 1].email) {
          const length = this.studentArray.length;
          this.studentArray[length - 1].lessonCourseIds +=
            "," + student["StudentLessonCourses.lessonCourseId"];
        } else {
          const studentData = {
            studentId: student.id,
            studentIdNum: student.studentIdNum,
            name: student.name,
            email: student.email,
            lessonCourseIds: student["StudentLessonCourses.lessonCourseId"],
            birthDay: student.birthDay
              ? this.$moment(student.birthDay).format("YYYY-MM-DD")
              : "",
            sex: student.sex,
            country: student.country,
            numberOfTicket: student.numberOfTicket,
            category: student.category,
            level: student.level,
            correctionFrequency: student.correctionFrequency,
            monthlyFeedback: student.monthlyFeedback,
            otherItem: student.otherItem,
            hopeInLesson: student.hopeInLesson,
            learningGoal: student.learningGoal,
            school: student.school,
            major: student.major,
            stripeId: student.stripeId,
            isRemindedPriv: student.isRemindedPriv,
            isRemindedConv: student.isRemindedConv
          };
          this.studentArray.push(studentData);
        }
      });
    },
    editItem(item) {
      this.selectedStudentId = item.studentId;
      this.editedItem = Object.assign({}, item);
      this.showEditDialog = true;
    },
    deleteItem(item) {
      this.selectedStudentId = item.studentId;
      this.editedItem = Object.assign({}, item);
      this.showDeleteDialog = true;
    },
    openTicketDialog(item) {
      this.showTicketDialog = true;
      this.selectedStudentName = item.name;
      this.selectedStudentId = item.studentId;
    },
    addStudent(item) {
      this.editedItem = Object.assign({}, item);
      this.showAddDialog = true;
    },
    deleteItemConfirm() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      };

      this.axios
        .delete(`/api/students/${this.selectedStudentId}`, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.closeDelete();
          this.reloadTable();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    close() {
      this.showEditDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeAdd() {
      this.showAddDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeDelete() {
      this.showDeleteDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeTicketDialog() {
      this.showTicketDialog = false;
    },
    save() {
      let formData = new FormData();

      const obj = this.editedItem;
      Object.keys(obj).forEach(function (key) {
        if (obj[key] != null) {
          formData.append(key, obj[key]);
        }
      });

      const header = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      this.axios
        .put(`/api/students/${this.selectedStudentId}`, formData, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.close();
          this.reloadTable();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    add() {
      let formData = new FormData();

      const obj = this.editedItem;
      Object.keys(obj).forEach(function (key) {
        if (obj[key] != null) {
          formData.append(key, obj[key]);
        }
      });

      const header = {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      this.axios
        .post(`/api/students`, formData, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.closeAdd();
          this.reloadTable();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
v-data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #dcc268;
}

thead.v-data-table-header {
  background-color: #dcc268;
}

.filter-area {
  padding: 0 20px;
}
.filter-item {
  padding: 10px 5px;
}
.table-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.csv-upload-area {
  margin-bottom: 10px;
}
.v-data-table {
  white-space: nowrap;
}
</style>
