<template>
  <v-container>
    <v-container>
      <v-row v-if="isPC">
        <v-col sm="2" md="2" lg="2" xl="1">
          <v-img
            :src="require('../../assets/Vollmond_logo.png')"
            class="my-3"
            contain
          ></v-img>
        </v-col>
      </v-row>
      <v-row justify="center" v-else>
        <v-col cols="6" xs="6" sm="4" md="3" lg="3" xl="2">
          <v-img
            :src="require('../../assets/Vollmond_logo.png')"
            class="my-3"
            contain
          ></v-img>
        </v-col>
      </v-row>
      <v-row justify="center">
        <p class="login-title">予約システムログイン</p>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" xs="6" sm="4" md="3" lg="3" xl="2" class="illust-area">
          <v-img
            :src="require('../../assets/login_illust.jpg')"
            class="my-3"
            contain
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="5"
          lg="5"
          xl="3"
          class="text-field-area"
        >
          <v-text-field
            label="E-Mail"
            color="#F0AF67"
            v-model="email"
            type="email"
            outlined
            background-color="white"
            dense
            class="login-text-field"
            hide-details
            @keydown.enter="login"
          ></v-text-field>
          <v-text-field
            label="Passwort"
            color="#F0AF67"
            v-model="password"
            outlined
            background-color="white"
            dense
            class="login-text-field"
            hide-details
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            @keydown.enter="login"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" class="login-button-area">
        <v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="3">
          <ActiveButton
            :button-title="$t('login.login')"
            @clickAction="login"
            class="login-button"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <v-row justify="center">
            <router-link to="/passwordForget" class="help-text"
              >パスワードを忘れた方・パスワードの再発行はこちら</router-link
            >
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <a
              href="https://vollmond.online/einloggen/"
              target="_blank"
              rel="noopener"
              class="help-text"
              >その他ログインでお困りの方はこちら</a
            >
          </v-row>
        </v-col>
        <v-col cols="12">
          <p class="to-beginner-text">
            ※初めての方は、まず<a
              href="https://vollmond.online/deutschkurse/"
              target="_blank"
              rel="noopener"
              >HP</a
            >より<br />気になるコースへお申し込みください。
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-row v-if="isPC" class="absolute-lower-right-text">
      <p class="recommended-environment">推奨利用環境: Chrome</p>
    </v-row>
    <v-row justify="center" v-else>
      <p class="recommended-environment">推奨利用環境: Chrome</p>
    </v-row>
  </v-container>
</template>

<script>
import ActiveButton from "../Atoms/ActiveButton";

export default {
  name: "Login",
  data: () => ({
    email: "",
    password: "",
    showPass: false
  }),
  components: {
    ActiveButton
  },
  computed: {
  },
  methods: {
    login() {
      const data = { email: this.email, password: this.password };

      this.$store
        .dispatch("user/login", data)
        .then((userParam) => {
          const user_info = this.$store.getters["user/getUserInfo"];
          const userAuthority = user_info.userAuthority;
          this.$moment.tz.setDefault(userParam.timeZone)
          switch(userAuthority) {
            case "ADMIN":
              this.$moment.tz.setDefault('Asia/Tokyo')
              this.$router.push("/admin/top");
              break;
            case "STUDENT":
              this.$router.push("/studentTop");
              break;
            case "TEACHER":
              this.$router.push("/teacherTop");
              break;
          }
        })
        .catch(error => {
          alert(error.response.data.error.message)
        });
    },
    changeLocale(lang) {
      this.$i18n.locale = lang
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  font-weight: bold;
}
.login-title {
  margin-top: 30px;
  font-weight: bold;
  color: #dcc268;
  font-size: 1.5em;
}
.illust-area {
  padding: 0;
}
.text-field-area {
  padding-top: 0;
}
.login-text-field {
  border-radius: 8px;
  border-width: 1px;
  margin-top: 10px !important;
}
.login-button-area {
  margin-top: 10px;
}
.login-button {
  border-radius: 8px;
}
.pass-forget-area {
  margin-top: 40px;
  margin-bottom: 20px;
}
.help-text {
  margin: 10px 0 0 0;
  font-size: 0.8em;
}
.to-beginner-text {
  text-align: center;
  font-size: 0.8em;
}
.recommended-environment {
  color: darkgray;
  font-size: 0.8em;
}
.absolute-lower-right-text {
  position: absolute;
  bottom: 20px;
  right: 50px;
}
</style>
