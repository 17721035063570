<template>
  <VmDialogCard
    :title="cardTitle"
    :buttonTitle="$t('teacherSchedule.changeScheduleButton')"
    :dangerousButtonTitle="$t('teacherSchedule.deleteSchedule')"
    :cancelButtonTitle="$t('teacherSchedule.cancel')"
    @click="changeSchedule"
    @clickDangerous="deleteSchedule"
    @close="cancel"
    :disabled="!buttonValidation"
  >
    <template #cardContent>
      <div>
        <v-row>
          <VmDialogSubTitle
            :text="$t('teacherSchedule.availableTime')"
            class="subTitle"
          />
          <TimeZoneIcon :style="{ margin: '12px 0 0 0' }" />
        </v-row>
        <v-row>
          <!-- コース：-->
          <p :style="{ margin: '0 0 0 12px' }">
            {{ $t("teacherSchedule.course") }}:
            {{ computedCourseName(courseId) }}
          </p>
        </v-row>
        <div class="date-form-area">
          <!-- 開始日時のフォーム -->
          <DateAndTimeForm
            :formLabelText="$t('teacherSchedule.startTime')"
            :date.sync="scheduleStartDate"
            :showedDate="computedScheduleStartDate"
            :minDate="selectableStartMinDate"
            @closeDatePicker="inputEndDate"
            @changeDatePicker="activeButton"
            :time.sync="scheduleStartTime"
            :minuteInterval="5"
            @changeTimePicker="activeButton"
          />
          <!-- 終了日時のフォーム -->
          <DateAndTimeForm
            :formLabelText="$t('teacherSchedule.endTime')"
            :date.sync="scheduleEndDate"
            :showedDate="computedScheduleEndDate"
            :minDate="selectableMinEndDate"
            @changeDatePicker="activeButton"
            :time.sync="scheduleEndTime"
            :minuteInterval="5"
            @changeTimePicker="activeButton"
          />
        </div>
      </div>
    </template>
  </VmDialogCard>
</template>

<script>
import TimeZoneIcon from "../Atoms/TimeZoneIcon";
import DateAndTimeForm from "../Molecules/DateAndTimeForm";
import VmDialogCard from "../Molecules/VmDialogCard";
import VmDialogSubTitle from "../Atoms/VmDialogSubTitle";

export default {
  name: "EditTeacherScheduleCard",
  inject: ["reloadCalendar"],
  components: {
    TimeZoneIcon,
    DateAndTimeForm,
    VmDialogCard,
    VmDialogSubTitle,
  },
  data: () => ({
    scheduleStartDate: "2020-12-01",
    scheduleEndDate: "2020-12-01",
    scheduleStartTime: "00:00",
    scheduleEndTime: "00:00",
    hasEdited: false,
  }),
  props: [
    "courseId",
    "cardTitle",
    "startTime",
    "endTime",
    "teacherScheduleId",
    "userInfo",
    "calendarValue",
  ],
  computed: {
    selectableStartMinDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    selectableMinEndDate() {
      return this.scheduleStartDate;
    },
    computedStartTime() {
      const start = this.scheduleStartDate + " " + this.scheduleStartTime;
      return this.$moment(start).format("YYYY-MM-DD HH:mm");
    },
    computedEndTime() {
      const end = this.scheduleEndDate + " " + this.scheduleEndTime;
      return this.$moment(end).format("YYYY-MM-DD HH:mm");
    },
    computedScheduleStartDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.scheduleStartDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.scheduleStartDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
    computedScheduleEndDate: {
      get() {
        this.$moment.locale(this.$i18n.locale);
        return this.$moment(this.scheduleEndDate).format("YYYY-MM-DD(ddd)");
      },
      set(value) {
        this.scheduleEndDate = this.$moment(value).format("YYYY-MM-DD");
      },
    },
    // 会話コース専任講師かどうか判断する
    isOnlyConversationTeacher() {
      const lessonCourseArray = this.userInfo.lessonCourseArray;

      // 会話コース以外のコースが1つでもあればfalse
      for (let lessonCourse of lessonCourseArray) {
        if (lessonCourse != "CONVERSATION") {
          return false;
        }
      }
      return true;
    },
    // プライベートコース専任講師かどうか判断する
    isOnlyPrivateTeacher() {
      const lessonCourseArray = this.userInfo.lessonCourseArray;

      // プライベートコース以外のコースが1つでもあればfalse
      for (let lessonCourse of lessonCourseArray) {
        if (lessonCourse != "PRIVATE") {
          return false;
        }
      }
      return true;
    },
    computedCourseName: function () {
      return function (lessonCourseId) {
        switch (lessonCourseId) {
          case 1:
            return this.$t("teacherSchedule.CONVERSATION");
          case 2:
            return this.$t("teacherSchedule.PRIVATE");
          default:
            return "";
        }
      };
    },
    buttonValidation() {
      return this.hasEdited;
    },
  },
  mounted() {
    this.initScheduleDate();
  },
  methods: {
    initScheduleDate() {
      this.scheduleStartDate = this.$moment(this.startTime).format(
        "YYYY-MM-DD"
      );
      this.scheduleEndDate = this.$moment(this.endTime).format("YYYY-MM-DD");

      this.scheduleStartTime = this.$moment(this.startTime).format("HH:mm");
      this.scheduleEndTime = this.$moment(this.endTime).format("HH:mm");
    },
    changeSchedule() {
      if (this.scheduleValidation()) {
        return;
      }

      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      const scheduleData = {
        teacherId: this.userInfo.teacherId,
        startTime: this.$convertToPost(this.computedStartTime),
        endTime: this.$convertToPost(this.computedEndTime),
        lessonCourseId: this.courseId,
      };
      this.axios
        .put(
          `/api/teacherSchedules/${this.teacherScheduleId}`,
          scheduleData,
          header
        )
        .then(() => {
          this.cancel();
          // 今編集している月を表示させるために必要
          this.reloadCalendar(this.calendarValue);
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    deleteSchedule() {
      if (confirm("この予定を削除しますか？")) {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        this.axios
          .delete(`/api/teacherSchedules/${this.teacherScheduleId}`, header)
          .then(() => {
            this.cancel();
            // 今編集している月を表示させるために必要
            this.reloadCalendar(this.calendarValue);
          })
          .catch((error) => {
            alert(error.response.data.error.message);
          });
      }
    },
    cancel() {
      this.$emit("cancelAction");
    },
    inputEndDate(val) {
      this.scheduleEndDate = val;
    },
    activeButton() {
      this.hasEdited = true;
    },
    scheduleValidation() {
      if (this.computedStartTime >= this.computedEndTime) {
        alert("終了時刻は開始時刻より後の時刻を選択してください");
        return true;
      }
      return false;
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
.subTitle {
  margin-top: 30px;
  margin-bottom: 0;
}
.date-form-area {
  margin: 20px 0 0 0;
}
</style>
