<template>
  <VmDialogCard
    :title="cardTitle"
    :buttonTitle="$t('teacherSchedule.addRegularLessonButton')"
    :cancelButtonTitle="$t('teacherSchedule.cancel')"
    @click="addRegularLesson"
    @close="cancel"
    :disabled="!buttonValidation"
  >
    <template #cardContent>
      <v-container>
        <!-- 曜日選択 -->
        <v-row>
          <!-- 曜日 -->
          <p class="subtitle">{{ $t("teacherSchedule.dayOfWeek") }}</p>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-select
              :items="dayOfWeekItems"
              v-model="selectedDayOfWeek"
              :label="$t('teacherSchedule.requireSelection')"
              solo
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <!-- 時間 -->
          <p class="subtitle">{{ $t("teacherSchedule.time") }}</p>
          <TimeZoneIcon />
        </v-row>
        <v-row no-gutters class="date-form-area">
          <!-- 開始時間タイムピッカー -->
          <v-col cols="9" xs="9" sm="5" md="5" lg="5" class="picker">
            <v-row no-gutters justify="start">
              <TimePicker v-model="lessonStartTime" :minuteInterval="5" />
            </v-row>
          </v-col>
          <!-- 終了時間タイムピッカー -->
          <v-col cols="1" xs="1" sm="2" md="2" lg="2" class="hyphen-text">
            -
          </v-col>
          <v-col cols="9" xs="9" sm="5" md="5" lg="5" class="picker">
            <v-row no-gutters justify="start">
              <TimePicker
                v-model="lessonEndTime"
                :minuteInterval="5"
                format="kk:mm"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="!lessonTimeValidation">
          <v-col class="validation-text-col">
            <p class="validation-text">
              {{ $t("teacherSchedule.reserveScheduleAlert") }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <!-- 生徒選択 -->
        <v-row>
          <!-- 生徒名 -->
          <p class="subtitle">{{ $t("teacherSchedule.studentName") }}</p>
        </v-row>
        <!-- 生徒を選択してください -->
        <v-row>
          <v-col cols="8">
            <v-select
              :items="studentNames"
              v-model="selectedStudent"
              :label="$t('teacherSchedule.requireSelectionStudents')"
              solo
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <!-- レッスン開始日 -->
          <p class="subtitle">{{ $t("teacherSchedule.lessonStartDay") }}</p>
        </v-row>
        <v-row>
          <v-col cols="8" class="picker">
            <v-select
              :items="computedStartDateArray"
              v-model="computedStartDate"
              :label="$t('teacherSchedule.requireStartDate')"
              solo
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </VmDialogCard>
</template>

<script>
import TimeZoneIcon from "../Atoms/TimeZoneIcon";
import TimePicker from "../Atoms/TimePicker";
import VmDialogCard from "../Molecules/VmDialogCard";

export default {
  name: "AddRegularLessonCardForTeacher",
  inject: ["reloadRouter"],
  components: {
    TimeZoneIcon,
    TimePicker,
    VmDialogCard,
  },
  data: () => ({
    startTimeMenu: false,
    endTimeMenu: false,
    selectedDayOfWeek: "",
    lessonStartTime: "00:00",
    lessonEndTime: "24:00",
    receptionStartDate: null,
    studentNames: [],
    selectedStudent: "",
    hasGivenClassesStudents: [],
    regularLessonStartDate: null,
  }),
  props: ["courseName", "cardTitle", "userInfo"],
  computed: {
    dayOfWeekItems() {
      const weekDayArrayJa = ["日", "月", "火", "水", "木", "金", "土"];
      const weekDayArrayDe = ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."];
      switch (this.$i18n.locale) {
        case "ja":
          return weekDayArrayJa;
        case "de":
          return weekDayArrayDe;
        default:
          return weekDayArrayJa;
      }
    },
    // 曜日を数字で扱う
    computedIntDayOfWeek() {
      const weekDayArrayJa = ["日", "月", "火", "水", "木", "金", "土"];
      const weekDayArrayDe = ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."];
      switch (this.$i18n.locale) {
        case "ja":
          return weekDayArrayJa.indexOf(this.selectedDayOfWeek);
        case "de":
          return weekDayArrayDe.indexOf(this.selectedDayOfWeek);
        default:
          return weekDayArrayJa.indexOf(this.selectedDayOfWeek);
      }
    },
    computedStartTime() {
      return "2021-01-01 " + this.lessonStartTime;
    },
    computedEndTime() {
      if (this.lessonEndTime === "00:00") {
        return "2021-01-01 " + "24:00";
      }
      return "2021-01-01 " + this.lessonEndTime;
    },
    buttonValidation() {
      if (this.lessonEndTime === "24:30") {
        return false;
      }

      if (!this.selectedDayOfWeek) {
        return false;
      }

      if (!this.lessonTimeValidation) {
        return false;
      }

      // 誰も選択されていなかったらアウト
      if (!this.selectedStudent) {
        return false;
      }

      return true;
    },
    // 定期予定用のバリデーション関数を呼び出す
    lessonTimeValidation() {
      return this.$validationForRegularLessonTime(
        this.lessonStartTime,
        this.lessonEndTime
      );
    },
    selectableStartMinDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    computedStartDate: {
      get() {
        return this.$moment(this.regularLessonStartDate).format(
          "YYYY-MM-DD HH:mm"
        );
      },
      set(value) {
        this.regularLessonStartDate = this.$moment(value).format();
      },
    },
    // 選択された曜日・開始時刻・終了時刻から開始日の選択肢を生成する
    computedStartDateArray() {
      let startDateArray = [];
      // selectedDayOfWeekとlessonStartTimeとlessonEndTimeから選択肢を生成する
      // 曜日・日時に対応する直近の日付を算出
      let startDate =
        this.$moment()
          .add(2, "days")
          .startOf("week")
          .add(this.computedIntDayOfWeek, "days")
          .format("YYYY-MM-DD ") +
        " " +
        this.lessonStartTime +
        this.$moment().format("ZZ");

      let twoDaysAfterTime = this.$moment().add(2, "days");
      if (twoDaysAfterTime.isAfter(startDate)) {
        startDate = this.$moment(startDate).add(1, "weeks");
      }

      for (let i = 0; i < 8; i++) {
        const startTime = this.$moment(startDate)
          .add(i, "weeks")
          .format("YYYY-MM-DD HH:mm");
        startDateArray.push(startTime);
      }

      return startDateArray;
    },
  },
  async mounted() {
    this.initScheduleDate();

    try {
      await this.fetchStudentsInCharge();
    } catch (error) {
      console.log(error);
    }
    this.connectStudentIdAndName();
  },
  methods: {
    initScheduleDate() {
      this.receptionStartDate = this.$moment().format("YYYY-MM-DD");
    },
    fetchStudentsInCharge() {
      // APIから今まで授業をしたことのある生徒情報を取得する
      return new Promise((resolve) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(
            `/api/students/hasGivenClasses?teacherId=${this.userInfo.teacherId}&lessonCourseId=2`,
            header
          )
          .then((response) => {
            this.hasGivenClassesStudents = response.data.students.filter(
              (v) => v
            );

            resolve();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    connectStudentIdAndName() {
      // 生徒情報を加工して 123_example の形にする
      for (let student of this.hasGivenClassesStudents) {
        const idName = student.id + "_" + student.name;
        this.studentNames.push(idName);
      }
    },
    async addRegularLesson() {
      // 以下のレッスンを新しく追加しますか？\n ※生徒にレッスン予約完了メールが送信されます \n 生徒: \n 曜日: \n 開始: \n 終了:
      if (
        confirm(
          `${this.$t("teacherSchedule.addLessonConfirmation")}\n\n ${this.$t(
            "teacherSchedule.student"
          )}: ${this.selectedStudent} \n ${this.$t(
            "teacherSchedule.dayOfWeek"
          )}: ${this.selectedDayOfWeek} \n ${this.$t(
            "teacherSchedule.startTime"
          )}： ${this.lessonStartTime}  \n ${this.$t(
            "teacherSchedule.endTime"
          )}： ${
            this.lessonEndTime === "00:00" ? "24:00" : this.lessonEndTime
          } \n ${this.$t("teacherSchedule.lessonStartDay")}: ${
            this.computedStartDate
          } `
        )
      ) {
        // 加工済みの生徒名からstudentIdを取り出す
        const studentId = Number(this.selectedStudent.split("_")[0]);

        let isFirstLesson = null;
        try {
          isFirstLesson = await this.judgeFirstLesson(
            this.userInfo.teacherId,
            studentId
          );
        } catch (error) {
          console.log(error);
        }

        const startTime = this.$convertToDateFormat(
          this.computedIntDayOfWeek,
          this.lessonStartTime
        );
        const endTime = this.$convertToDateFormat(
          this.computedIntDayOfWeek,
          this.lessonEndTime
        );

        const regularScheduleData = {
          teacherId: this.userInfo.teacherId,
          studentId: studentId,
          lessonCourseId: 2, // 今は必ずプライベートコースなので
          startTime: startTime,
          endTime: endTime,
          isFirstLesson: isFirstLesson,
          receptionStartDate: this.$convertToPost(this.receptionStartDate),
          regularLessonStartDate: this.regularLessonStartDate,
        };

        this.registerRequest(regularScheduleData);
      }
    },
    registerRequest(regularScheduleData) {
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      this.axios
        .post("/api/lessons/regularReserve", regularScheduleData, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.reload();
          this.cancel();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    cancel() {
      this.$emit("cancelAction");
    },
    reload() {
      this.$emit("reload");
    },
    // 初回授業かどうかの判定のために、studentIdと今開いている講師のteacherIdで授業を絞って取得する
    judgeFirstLesson(teacherId, studentId) {
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        this.axios
          .get(
            `/api/lessons?teacherId=${teacherId}&studentId=${studentId}&lessonCourseId=2&status=1`,
            header
          )
          .then((response) => {
            const lessons = response.data.lessons;

            if (lessons.length > 0) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
  },
  head: {},
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 1.4em;
  font-weight: bold;
  margin: 20px 0 15px 0;
}
.button {
  margin-bottom: 20px;
}
.hyphen-text {
  font-size: 1.2em;
  margin: 30px 0 0 0;
}
.picker {
  margin: 10px 0 0 0;
}
.attention-text-area {
  margin-top: 0;
}
.attention-text {
  margin: 0;
  color: red;
}
.date-form-area {
  margin: 30px 0 0 0;
}
.validation-text-col {
  padding: 0 0 0 12px;
}
.validation-text {
  color: red;
  margin: 0;
}
</style>
