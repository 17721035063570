<template>
  <v-btn
    color="cancel white--text"
    :style="{ fontWeight: textFontWeight }"
    v-on:click="clickAction"
    block
    tile
  >{{buttonTitle}}</v-btn>
</template>

<script>
export default {
  name: 'CancelButton',
  data: () => ({
    textFontWeight:"bold"
  }),
  props: ['buttonTitle'],
  methods: {
      clickAction(){
        this.$emit('clickAction');
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
