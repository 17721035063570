<template>
  <v-container>
    <PageTitle title-name="レッスン受講履歴" />
    <SwitchTimeZone v-if="isShowTimeZone" @reload="reloadTable" />

    <v-row>
      <v-col cols="12">
        <p
          class="red--text"
          :style="{ margin: '20px 0 0 10px', fontSize: '0.8em' }"
        >
          ステータスは講師の完了報告に基づいています。事実に一致しない場合、大変お手数ですが
          <a
            href="https://vollmond.online/kontaktieren/"
            target="_blank"
            rel="noopener"
            >事務局までお問い合わせ</a
          >ください。
        </p>
      </v-col>
    </v-row>

    <!-- 受講済みレッスン一覧 -->
    <div class="lesson-history-area">
      <v-data-table
        :headers="headers"
        :items="lessonHistoryItems"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :class="{ 'elevation-1': true, hoverPointer: hoverPointer }"
        hide-default-footer
        disable-sort
        :loading="isLoading"
        no-data-text="受講履歴がありません"
      >
      </v-data-table>
      <v-pagination
        v-model="page"
        :length="pageCount"
        class="pagenation"
        circle
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../Atoms/PageTitle";
import SwitchTimeZone from "../Atoms/SwitchTimeZone"

export default {
  name: 'LessonHistory',
  async created() {
    this.fetchInitialData()
    this.fetchThisMonthLessons()
  },
  data: () => ({
    userInfo: null,
    isShowTimeZone: true,
    isShowTable: true,
    isLoading: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      { text: "開始時間", value: 'startTime' },
      { text: "終了時間", value: 'endTime' },
      { text: 'レッスンコース', value: 'lessonCourseName' },
      { text: '担当講師', value: 'teacherName' },
      { text: 'レッスンステータス', value: 'lessonStatus' },
    ],
    lessonHistoryItems: []
  }),
  computed: {
  },
  methods: {
    async reloadTable() {
      this.isShowTable = false
      this.fetchThisMonthLessons()
      await this.$nextTick()
      this.isShowTable = true
    },
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },

    // 受講してきたレッスンを取得する
    fetchThisMonthLessons() {
      this.lessonHistoryItems = []
      return new Promise((resolve, reject) => {
        this.isLoading = true
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`
          }
        };

        this.axios
          .get(`/api/lessons/history?studentId=${this.userInfo.studentId}`, header)
          .then(response => {
            const fetchedlessonHistory = response.data.lessonHistory
            this.makeLessonHistoryItems(fetchedlessonHistory)
            this.isLoading = false
            resolve()
          })
          .catch(error => {
            console.log(error);
            reject()
          });
      })
    },
    makeLessonHistoryItems(lessonHistory) {
      for (let lesson of lessonHistory) {
        const lessonHistoryItem = {
          startTime: this.$moment(lesson.startTime).format('YYYY-MM-DD HH:mm'),
          endTime: this.$moment(lesson.endTime).format('YYYY-MM-DD HH:mm'),
          lessonCourseName: this.translateCourseName(lesson.lessonCourseId),
          teacherName: lesson.Teacher.teacherName,
          lessonStatus: this.stringStatus(lesson.status),
        }
        this.lessonHistoryItems.push(lessonHistoryItem)
      }
    },
    translateCourseName(value){
      switch(value) {
        case 1:
          return "会話"
        case 2:
          return "プライベート"
        default:
          return ""
      }
    },
    stringStatus(value){
      switch(value) {
        case 0:
          return this.$t('top.unfinished')
        case 1:
          return this.$t('top.completed')
        case 2:
          return this.$t('top.canceledLesson')
        default:
          return ""
      }
    },
  },
  components: {
    PageTitle,
    SwitchTimeZone
  }
};
</script>

<style lang="scss" scoped>
.lesson-history-area {
  margin-top: 50px;
}
</style>
