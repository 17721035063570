<template>
  <div>
    <p>
      <FormTitleText titleText="【ご確認】お支払いについて" />
      <RequiredIcon v-if="rules && rules.includes('required')" />
    </p>
    <v-textarea
      :value="content"
      placeholder=""
      outlined
      background-color="white"
      readonly
    ></v-textarea>
    <validationProvider
      :rules="{ required: { allowFalse: false } }"
      v-slot="{ errors }"
      name="お支払いルールに関する同意"
    >
      <v-checkbox
        v-model="isChecked"
        label="後払いであることを理解し、期日までに入金することに同意します。"
        value="後払いであることを理解し、期日までに入金することに同意します。"
        color="#DCC268"
        hide-details
        @change="check"
        :style="{ margin: '-20px 0 0 0' }"
      ></v-checkbox>

      <FormErrorText :errorText="errors[0]" />
    </validationProvider>
  </div>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText';
import FormErrorText from '../Atoms/FormErrorText';
import RequiredIcon from '../Atoms/RequiredIcon';

export default {
  name: 'AgreePaymentRuleForm',
  data: () => ({
    content: 'プライベートコースは月毎の「後払い制」となっております。\n支払い方法は銀行振込またはPaypalです。\n\n月毎に請求メールをVollmondより送信いたしますので、期日までのご入金をお願いいたします。',
    isChecked: false
  }),
  props: ['rules'],
  mounted() {
  },
  computed: {
  },
  methods: {
    check() {
      this.$emit('input', this.isChecked)
    }
  },
  components: {
    FormTitleText,
    FormErrorText,
    RequiredIcon
  }
};
</script>

<style lang="scss" scoped>
</style>
