// 曜日情報を付け加えられたスケジュール情報をカレンダー情報に変換していく関数
// 定期予定の情報（曜日と時間）から、向こう2ヶ月分データを作る
function makeScheduleFromRegularInfo(schedules) {
  let regularSchedules = []
  // 日から土まで回して、レッスン予定を作っていく。
  for (let schedule of schedules) {

    // レッスン追加する最初の日付（基準の日）
    let referenceDate = this.$moment().add(2, 'days').startOf('isoWeek').add(schedule.dayOfWeek - 1, 'days')

    const twoDaysAfterDay = this.$moment().add(2, 'days')
    if (twoDaysAfterDay.isAfter(referenceDate)) {
      referenceDate = this.$moment(referenceDate).add(1, 'weeks')
    }

    // 来月末まで何週あるか計算する
    const endOfNextMonth = this.$moment().add(1, 'months').endOf('month')
    const addedWeeks = this.$moment(endOfNextMonth).diff(referenceDate, 'weeks')

    // 基準日から1週間ごとに、来月末まで予定を追加していく
    for (let i = 0; i < addedWeeks + 1; i++) {
      const startDate = this.$moment(referenceDate).add(i, 'weeks')

      // その日に複数予定の塊がある場合、for文で追加していく
      for (let combinedSchedule of schedule.combinedSchedules) {
        // TODO: 「やっぱりサマータイムの影響消したい！」ってなった時のために例外的に置いておく
        // サマータイムの影響を消す
        // const modifiedStartTime = this.$convertToAccurateTime(combinedSchedule.startTime).format("HH:mm")
        // const modifiedEndTime = this.$convertToAccurateTime(combinedSchedule.endTime).format("HH:mm") === "00:00" ? "24:00" : this.$convertToAccurateTime(combinedSchedule.endTime).format("HH:mm")

        // let startTime = this.$moment(startDate).format("YYYY-MM-DD ") + modifiedStartTime
        // let endTime = this.$moment(startDate).format("YYYY-MM-DD ") + modifiedEndTime

        let startTime = this.$moment(startDate).format("YYYY-MM-DD ") + this.$moment(combinedSchedule.startTime).format("HH:mm")

        let endTime = null;
        if (this.$moment(combinedSchedule.endTime).format('HH:mm') === "00:00") {
          // endTimeが00:00ということは次の日のこと
          endTime = this.$moment(startDate).add(1, "days").format("YYYY-MM-DD ") + this.$moment(combinedSchedule.endTime).format("HH:mm")
        } else {
          endTime = this.$moment(startDate).format("YYYY-MM-DD ") + this.$moment(combinedSchedule.endTime).format("HH:mm")
        }

        // // HACK:場当たり的な対処法をしてしまっている
        // // 10/31あたりのサマータイムの境界線を跨ぐ時の対処法
        // // startTimeの1週間前が10月でstartTimeが11月になっている時
        if (this.userInfo.timeZone === 'Europe/Berlin') {
          // // 10月時の11月予定の調整
          // if (this.$moment().format("ZZ") === "+0200" && this.$moment(startDate).format("ZZ") === "+0100") {
          //   startTime = this.$moment(startTime).subtract(1, "hours").format("YYYY-MM-DD HH:mm")

          //   endTime = this.$moment(endTime).subtract(1, "hours").format("YYYY-MM-DD HH:mm")
          // }

          // 3月時の4月予定の調整（正確にはサマータイム跨いでる時なので3/27 2:00-）
          if (this.$moment().format("ZZ") === "+0100" && this.$moment(startDate).format("ZZ") === "+0200") {
            // 調整前のstartTimeの時間が00:00 かつ それが分裂によってできた予定である時は、00:00-のまま
            if (this.$moment(startTime).format('HH:mm') === "00:00" && combinedSchedule.isDivided) {
              // 処理なし
            } else {
              startTime = this.$moment(startTime).add(1, "hours").format("YYYY-MM-DD HH:mm")
            }

            // 調整前のendTimeの時間が00:00の場合はendTimeは-00:00のまま
            if (this.$moment(endTime).format('HH:mm') === "00:00") {
              // 処理なし
            } else {
              endTime = this.$moment(endTime).add(1, "hours").format("YYYY-MM-DD HH:mm")
            }
          }
        }

        const scheduleItem = {
          startTime: this.$moment(startTime).format(),
          endTime: this.$moment(endTime).format(),
          lessonCourseId: schedule.lessonCourseId,
          isRegular: true
        }
        regularSchedules.push(scheduleItem)
      }
    }
  }
  return regularSchedules
}

export default makeScheduleFromRegularInfo
