const state = {
  loading: false
};

const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  }
}

const getters = {
  getLoading(state) {
    return state.loading;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
