<template>
  <div>
    <v-dialog
      v-model="openApplyCard"
      v-if="openApplyCard"
      offset-x
      max-width="500px"
    >
      <BuyTicketFormCard
        @cancelAction="closeApplyForm($event)"
        :userInfo="userInfo"
      />
    </v-dialog>
    <ol>
      <li>簡単1分！チケット追加申し込み</li>
      <v-row no-gutters>
        <v-img
          :src="require('../../assets/ticket_apply.png')"
          max-width="100px"
          contain
        ></v-img>
      </v-row>
      <v-row no-gutters>
        <v-col cols="10">
          以下のフォームからチケット申し込み！
          <br />
          ※現在購入可能なチケット: {{ showedticketDate }}
          <br />
          （有効期限 {{ ticketValidatedDateStart }}〜{{
            ticketValidatedDateEnd
          }}）
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" xs="10" sm="6" md="4" lg="4" xl="4">
          <active-button
            button-title="チケットを申し込む"
            @clickAction="openApplyForm"
          />
        </v-col>
      </v-row>
      <v-row v-if="isApplied">
        <v-col cols="10">
          <p class="thankyou-text">チケット申込ありがとうございました！！</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>
            ※申し込み後メールが送信されます。メールが届かない場合はお手数ですが
            <a
              href="https://vollmond.online/kontaktieren/"
              target="_blank"
              rel="noopener"
              >事務局までお問い合わせ</a
            >ください。
          </p>
        </v-col>
      </v-row>
      <li>2日以内に指定の方法に沿ってお支払いください</li>
      <v-img
        :src="require('../../assets/ticket_pay.png')"
        max-width="100px"
        contain
      ></v-img>
      <p class="procedure-text">
        チケット追加申込みが完了した段階でメールがきます。メールに支払い方法等が書かれていますのでご確認ください。<br />お支払い方法は銀行振込、PayPal、クレジットカード、またはWiseです。
      </p>
      <li>ご入金確認次第、事務局よりマイページにチケット追加！</li>
      <v-img
        :src="require('../../assets/ticket_get.png')"
        max-width="100px"
        contain
      ></v-img>
      <p class="procedure-text">
        前月申込み分のチケット付与は、対象月の2日前より始まります。
        <br />
        （例: 1月中に申し込んだ2月分のチケット→1月30日よりマイページに追加）
        <br />
        <br />
        ※入金から時間が経ってもチケット付与されない場合はお手数ですが
        <a
          href="https://vollmond.online/kontaktieren/"
          target="_blank"
          rel="noopener"
          >事務局までお問い合わせ</a
        >ください
      </p>
    </ol>
  </div>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton';
import BuyTicketFormCard from '../Molecules/BuyTicketFormCard';

export default {
  name: "BuyTicketProcedure",
  created() {},
  data: () => ({
    isApplied: false,
    openApplyCard: false,
    referedTicketDate: null
  }),
  computed: {
    showedticketDate() {
      return this.$moment(this.ticketDate).format("YYYY年M月分")
    },
    ticketDate() {
      const today = this.$moment()

      // ○月20日を取得
      const twentiethDate = this.$moment().startOf('month').add(19, 'd')

      // ○月20日00:00までは今月のチケットを、それ以降はあ来月分のチケット購入
      if (today < twentiethDate) {
        return this.$moment()
      } else {
        return this.$moment().add(1, 'M')
      }
    },
    ticketValidatedDateStart() {
      // 例:3月分のチケットの有効期限: 2/27（月末-1） - 3/29（月末-2）
      return this.$moment(this.ticketDate).subtract(1, 'M').endOf('month').subtract(1, 'd').format("M月DD日")
    },
    ticketValidatedDateEnd() {
      // 例: 3月分のチケットの有効期限: 2/27（月末-1） - 3/29（月末-2）
      return this.$moment(this.ticketDate).endOf('month').subtract(2, 'd').format("M月DD日")
    }
  },
  methods: {
    openApplyForm() {
      this.openApplyCard = true

    },
    closeApplyForm(elem) {
      this.openApplyCard = false
      if (elem) {
        this.isApplied = true
      }
    }
  },
  components: {
    ActiveButton,
    BuyTicketFormCard
  },
  props: ['userInfo']
};
</script>

<style lang="scss" scoped>
ol {
  counter-reset: number; /*数字をリセット*/
  list-style-type: none !important; /*数字を一旦消す*/
  padding: 0;
}
ol li {
  position: relative;
  padding: 0.5em;
  line-height: 1.5em;
  background: #f4f4f4;
  border-left: solid 35px#DCC268;
  margin-bottom: 5px;
  margin-top: 30px;
}
ol li:before {
  /* 以下数字をつける */
  position: absolute;
  counter-increment: number;
  content: counter(number);
  /*数字のデザイン変える*/
  display: inline-block;
  color: white;
  font-family: "Avenir", "Arial Black", "Arial", sans-serif;
  font-weight: bold;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -35px;
  width: 35px;
  height: 1em;
  line-height: 1;
  text-align: center;
}
.procedure-text {
  margin: 10px 0;
}
.thankyou-text {
  color: red;
  margin: 0;
}
</style>
