<template>
  <p class="error-text">{{ errorText }}</p>
</template>

<script>
export default {
  name: 'FormErrorText',
  data: () => ({
  }),
  props: ['errorText'],
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.error-text {
  color:red;
  margin:0 ;
  font-size: 0.8em;
}
</style>
