import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import user from "./user";
import loading from "./loading";
import { lessonRequest } from "./lessonRequest";
import { student } from "./student";
import { lessonPlan } from "./lessonPlan";
import { reserve } from "./reserve";
import { notifiedLesson } from "./notifiedLesson";
import { adminStudent, adminTeacher, adminLesson } from "./admin"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    loading,
    lessonRequest,
    student,
    lessonPlan,
    adminStudent,
    adminTeacher,
    adminLesson,
    notifiedLesson,
    reserve
  },
  plugins: [createPersistedState()]
});

export default store
