<template>
  <v-switch
    v-model="inputedRemind"
    inset
    :label="`${text}: ${
      inputedRemind ? $t('profile.pleaseRemind') : $t('profile.dontRemind')
    }`"
  ></v-switch>
</template>

<script>


export default {
  name: 'RemindMailForm',
  data: () => ({
  }),
  props: ['value', 'text'],
  mounted() {
  },
  computed: {
    inputedRemind: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
  },
  components: {
  }
};
</script>

<style lang="scss" scoped>
</style>
