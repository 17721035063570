<template>
  <div>
    <p>
      <FormTitleText :titleText="title" />
    </p>
    <p>{{ content }}</p>
  </div>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText';

export default {
  name: 'ConfirmedForm',
  data: () => ({
    isVisibleDefinition: false
  }),
  props: ['title', 'content'],
  mounted() {
  },
  computed: {

  },
  methods: {
  },
  components: {
    FormTitleText,
  }
};
</script>

<style lang="scss" scoped>
</style>
