<template>
  <v-container>
    <!-- 担当生徒テーブル -->
    <v-row>
      <v-col>
        <!-- 担当生徒追加ボタン -->
        <v-btn
          color="primary"
          @click.stop="showAddDialog = true"
          :style="{ fontWeight: 'bold', margin: '0 0 10px 0' }"
          tile
          elevation="0"
          class="rounded-sm"
        >
          <!-- 担当生徒追加 -->
          <p>{{ $t("top.addStudentsInCharge") }}</p>
        </v-btn>
        <v-dialog v-model="showAddDialog" max-width="500px">
          <v-card>
            <v-container>
              <v-card-title
                ><v-row no-gutters justify="center">
                  <!-- 担当生徒追加 -->
                  <p class="headline" :style="{ 'font-weight': 'bold' }">
                    {{ $t("top.addStudentsInCharge") }}
                  </p></v-row
                >
              </v-card-title>
              <v-card-text>
                <p>
                  {{ $t("top.addConnectionDialogStatement") }}
                </p>
                <p :style="{ 'font-size': '0.8em' }" class="red--text">
                  {{ $t("top.addConnectionDialogAttention") }}
                </p>
                <p class="subtitle">
                  {{ $t("top.studentId") }}
                  <span
                    :style="{ 'font-size': '0.8em', 'font-weight': 'normal' }"
                    >{{ $t("top.onlyNum") }}</span
                  >
                </p>
                <v-text-field
                  :label="$t('top.studentId')"
                  color="#F0AF67"
                  v-model="connectedStudentId"
                  outlined
                  background-color="white"
                  dense
                  class="login-text-field"
                  hide-details
                  width="50"
                  @input="fetchStudentName"
                  type="number"
                ></v-text-field>

                <p>{{ $t("top.studentName") }} : {{ connectedStudentName }}</p>
              </v-card-text>
              <v-row
                no-gutters
                justify="center"
                :style="{ padding: '0 10px', margin: '10px 0' }"
              >
                <v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
                  <v-btn
                    color="primary"
                    :style="{ fontWeight: 'bold' }"
                    tile
                    block
                    elevation="0"
                    class="rounded-sm"
                    @click="addConnection"
                    :disabled="!buttonValidation"
                  >
                    <!-- 生徒名を確認の上追加 -->
                    {{ $t("top.addConnectionButton") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                justify="center"
                :style="{ padding: '0 10px', margin: '10px 0' }"
              >
                <v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
                  <v-btn
                    color="cancel"
                    :style="{ fontWeight: 'bold' }"
                    tile
                    block
                    elevation="0"
                    class="rounded-sm white--text"
                    @click="closeAddDialog"
                  >
                    <p>{{ $t("top.cancel") }}</p>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>

        <!-- テーブル -->
        <v-data-table
          :headers="headers"
          :items="studentsInChargeItems"
          :class="{ 'elevation-1': true }"
          disable-sort
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :no-data-text="$t('top.noStudentsInCharge')"
          hide-default-footer
          mobile-breakpoint="0"
          :height="
            studentsInChargeItems.length > 5
              ? 50 * 5 + 62
              : 50 * studentsInChargeItems.length + 62
          "
          @click:row="clickRow"
        >
          <template v-slot:[`item.detail`]>
            <v-btn text x-small>
              <v-icon color="primary">mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <v-icon small @click="deleteRegularLesson(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showStudentDetailCard"
      v-if="showStudentDetailCard"
      max-width="500px"
    >
      <StudentDetailCard
        v-if="showStudentDetailCard"
        :studentId="selectedStudentItem.studentId"
        @closeAction="closeStudentDetailCard"
      />
    </v-dialog>
    <v-pagination
      v-model="page"
      :length="pageCount"
      class="pagenation"
      circle
    ></v-pagination>
  </v-container>
</template>

<script>
import StudentDetailCard from "../Organisms/StudentDetailCard";
import { mapGetters } from "vuex";

export default {
  name: "StudentsInChargeTable",
  inject: ["reloadRouter"],
  components: { StudentDetailCard },
  data: () => ({
    selectedMonth: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    studentsInChargeItems: [],
    showAddDialog: false,
    showStudentDetailCard: false,
    selectedStudentItem: null,
    selectedRegularScheduleId: null,
    connectedStudentId: null,
    connectedStudentName: "-",
    notShowInfo: false,
  }),
  computed: {
    ...mapGetters({
      userInfo: "user/getUserInfo",
    }),
    headers() {
      return [
        { text: "", value: "detail" },
        { text: "", value: "delete" },
        { text: this.$t("top.studentId"), value: "studentId" },
        { text: this.$t("top.oneStudent"), value: "studentName" },
        { text: this.$t("top.sex"), value: "sex" },
        { text: this.$t("top.country"), value: "country" },
        { text: this.$t("top.studentAttribute"), value: "category" },
        { text: this.$t("top.level"), value: "level" },
        { text: this.$t("top.school"), value: "school" },
        { text: this.$t("top.major"), value: "major" },
        { text: this.$t("top.hopeInLesson"), value: "hopeInLesson" },
        { text: this.$t("top.learningGoal"), value: "learningGoal" },
        { text: this.$t("top.otherItem"), value: "otherItem" },
      ];
    },
    computedSelectedMonth() {
      return this.$moment(this.selectedMonth).format("YYYY/ MM");
    },
    toJaLessonCourseName: function () {
      return function (lessonCourseName) {
        switch (lessonCourseName) {
          case "CONVERSATION":
            return this.$t("top.conversationCourse");
          case "PRIVATE":
            return this.$t("top.privateCourse");
          default:
            return "";
        }
      };
    },
    toJaStudentAttribute: function () {
      return function (studentAttribute) {
        switch (studentAttribute) {
          case "ADULT":
            return this.$t("top.adult");
          case "STUDENT":
            return this.$t("top.student");
          case "CHILD":
            return this.$t("top.child");
          default:
            return "";
        }
      };
    },
    buttonValidation() {
      if (!this.connectedStudentId) {
        return false;
      }
      if (this.connectedStudentName === this.$t("top.noStudentId")) {
        return false;
      }
      return true;
    },
  },
  props: [],
  async created() {
    try {
      await this.fetchStudentsInCharge();
    } catch (error) {
      console.log("error", error);
    }
  },
  mounted() {},
  methods: {
    // 紐付けを全て取得する
    fetchStudentsInCharge() {
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(
            `/api/regularSchedules/connections?teacherId=${this.userInfo.teacherId}`,
            header
          )
          .then((response) => {
            const connectionDataArray = response.data.connections;
            this.makeListItem(connectionDataArray);
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },

    makeListItem(connectionDataArray) {
      for (let connectionData of connectionDataArray) {
        const studentItem = {
          regularScheduleId: connectionData.id,
          studentId: connectionData["Student.id"],
          studentName: connectionData["Student.name"],
          sex: this.convertedSex(connectionData["Student.sex"]),
          country: this.convertedCountry(connectionData["Student.country"]),
          category: this.convertedCategory(connectionData["Student.category"]),
          level: connectionData["Student.level"]
            ? connectionData["Student.level"]
            : "-",
          school: connectionData["Student.school"]
            ? connectionData["Student.school"]
            : "-",
          major: connectionData["Student.major"]
            ? connectionData["Student.major"]
            : "-",
          hopeInLesson: connectionData["Student.hopeInLesson"]
            ? connectionData["Student.hopeInLesson"]
            : "-",
          learningGoal: connectionData["Student.learningGoal"]
            ? connectionData["Student.learningGoal"]
            : "-",
          otherItem: connectionData["Student.otherItem"]
            ? connectionData["Student.otherItem"]
            : "-",
        };
        this.studentsInChargeItems.push(studentItem);
      }
    },

    convertedCountry(country) {
      switch (country) {
        case "JAPAN":
          return this.$t("top.japan");
        case "ABROAD":
          return this.$t("top.abroad");
        default:
          return "-";
      }
    },
    convertedSex(sex) {
      switch (sex) {
        case 0:
          return "不明";
        case 1:
          return this.$t("top.man");
        case 2:
          return this.$t("top.woman");
        case 9:
          return this.$t("top.otherSex");
        default:
          return "-";
      }
    },
    convertedCategory(category) {
      switch (category) {
        case "ADULT":
          return this.$t("top.adult");
        case "STUDENT":
          return this.$t("top.oneStudent");
        case "CHILD":
          return this.$t("top.child");
        default:
          return "-";
      }
    },
    deleteRegularLesson(item) {
      this.notShowInfo = true; // アラートをキャンセルや実行した後にinfoが出てしまうので
      this.deletedItem = Object.assign({}, item);
      this.selectedRegularScheduleId = item.regularScheduleId;

      if (
        confirm(
          `${this.$t("top.deleteAlert")} \n${this.$t("top.vollmondStudent")}:${
            this.deletedItem.studentName
          }`
        )
      ) {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        // 紐付けの場合はregularSchedulesのidで削除するだけ
        this.axios
          .delete(
            `/api/regularSchedules/${this.selectedRegularScheduleId}`,
            header
          )
          .then((response) => {
            console.log("response.data", response.data);
            this.reloadTable();
          })
          .catch((error) => {
            alert(error.response.data.error.message);
          });
      }
    },
    reloadTable() {
      this.studentsInChargeItems = [];
      this.fetchStudentsInCharge();
    },
    closeAddDialog() {
      this.showAddDialog = false;
    },
    addConnection() {
      const linkRequestBody = {
        teacherId: this.userInfo.teacherId,
        studentId: this.connectedStudentId,
      };
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      this.axios
        .post(`/api/regularSchedules/connections`, linkRequestBody, header)
        .then(() => {
          this.connectedStudentId = null;
          this.reloadTable();
          this.showAddDialog = false;
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    fetchStudentName() {
      // 入力された生徒idが更新されたら生徒名を取得する
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      this.axios
        .get(`/api/students/${this.connectedStudentId}`, header)
        .then((response) => {
          console.log("response.data", response.data);
          if (response.data.student) {
            this.connectedStudentName = response.data.student.name;
          } else {
            this.connectedStudentName = this.$t("top.noStudentId");
          }
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    clickRow(row) {
      if (!this.notShowInfo) {
        this.showStudentDetailCard = true;
        this.selectedStudentItem = row;
      }
      this.notShowInfo = false;
    },
    closeStudentDetailCard() {
      this.showStudentDetailCard = false;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.v-data-table {
  white-space: nowrap;
}

.hoverPointer td {
  cursor: pointer;
}
.pagenation {
  margin: 20px 0 0 0;
}

.subtitle {
  font-size: 1.2em;
  font-weight: bold;
  margin: 20px 0 15px 0;
  color: #6d6f6c;
}
</style>
