<template>
  <div>
    <!-- タイトル -->
    <PageTitle title-name="定期予定一覧" />
    <VmBox>
      <template #header>
        <p>
          ※講師-生徒の紐付けを登録する場合
          <br />「新規定期予約」からteacherIdとstudentIdだけ入力。<br />
        </p>
        <v-btn color="primary" dark class="mb-2" @click="openAddForm()">
          新規定期予約
        </v-btn>
      </template>
      <template #content>
        <!-- 各人の定期予約一覧 -->
        <div
          class="regular-schedule-list-area"
          v-if="regularScheduleList.length > 0"
          :style="{ padding: '15px 15px' }"
        >
          <div
            v-for="(regularSchedule, index) of regularScheduleList"
            :key="regularSchedule.teacherId"
          >
            <v-row>
              <v-col cols="12" xs="6" sm="6" md="3" lg="3">
                <!-- 講師番号 -->
                {{ regularSchedule.teacherId }} -
                <!-- 名前 -->
                {{ regularSchedule.teacherName }} 先生
                <!-- 「詳細を見る」ボタン -->
                <v-btn
                  color="primary"
                  text
                  @click="openDetail(index)"
                  :style="{
                    position: 'relative',
                    right: '10px',
                    bottom: '2px',
                  }"
                >
                  詳細を見る
                  <v-icon small> mdi-chevron-down </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- 定期予定詳細テーブル -->
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="regularSchedule.regularScheduleArray"
                  :class="{ 'elevation-1': true }"
                  disable-sort
                  disable-pagination
                  no-data-text="担当したレッスンはありません"
                  hide-default-footer
                  v-show="computedShowDataTable(index)"
                  :loading="isLoading"
                >
                  <template v-slot:[`item.edit`]="{ item }">
                    <v-icon small class="mr-2" @click="openEditForm(item)">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="deleteRegularLesson(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:[`item.image`]="{ item }">
                    <img :src="item.image" class="table-img" />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-else class="regular-schedule-list-area">
          <div v-if="isLoading">
            <vue-loading
              type="spin"
              color="#DCC268"
              :size="{ width: '50px', height: '50px' }"
            ></vue-loading>
          </div>
          <v-row justify="center" v-else>
            <p class="no-data-text">表示する定期予定データがありません</p>
          </v-row>
        </div>
      </template>
    </VmBox>
    <v-dialog v-model="showAddDialog" v-if="showAddDialog" max-width="500px">
      <AdminAddFormCard
        @closeAction="closeAdd"
        @addAction="add"
        :editedItem="addedItem"
        formTitle="定期予定追加"
        :explanation="addRegularExplanation"
      />
    </v-dialog>
    <v-dialog v-model="showEditDialog" v-if="showEditDialog" max-width="500px">
      <AdminEditingFormCard
        @closeAction="closeEdit"
        @saveAction="edit"
        :editedItem.sync="editedItem"
        formTitle="定期予定編集"
        :explanation="editRegularExplanation"
      />
    </v-dialog>
  </div>
</template>

<script>
import AdminEditingFormCard from "../Organisms/AdminEditingFormCard";
import AdminAddFormCard from "../Organisms/AdminAddFormCard";
import PageTitle from "../Atoms/PageTitle";
import VmBox from "../Atoms/VmBox";

export default {
  name: "AdminRegularScheduleList",
  components: {
    AdminEditingFormCard,
    AdminAddFormCard,
    PageTitle,
    VmBox,
  },
  data: () => ({
    userInfo: null,
    regularSchedules: [],
    regularScheduleList: [],
    regularScheduleArray: [],
    selectedRegularScheduleId: null,
    editedItem: null,
    deletedItem: {
      regularScheduleId: null,
      teacherId: null,
      studentId: null,
      lessonCourseId: null,
      dayOfWeek: null,
      startTime: null,
      endTime: null,
      isFirstLesson: false,
    },
    addedItem: {
      regularScheduleId: null,
      teacherId: null,
      studentId: null,
      lessonCourseId: null,
      dayOfWeek: null,
      startTime: null,
      endTime: null,
      isFirstLesson: false,
      receptionStartDate: null,
      regularLessonStartDate: null,
    },
    // フォームを閉じた時のリセットに使われている
    defaultItem: {
      regularScheduleId: null,
      teacherId: null,
      studentId: null,
      lessonCourseId: null,
      dayOfWeek: null,
      startTime: null,
      endTime: null,
      isFirstLesson: false,
    },
    showEditDialog: false,
    showAddDialog: false,
    openedIndexArray: [],
    addRegularExplanation:
      "※lessonCourseId\n会話コース:1, プライベートコース:2\n\n※dayOfWeek\n日:0, 月:1, 火:2, 水:3, 木:4, 金:5, 土:6\n\n※startTime, endTimeは 〇〇:〇〇の形式で書いて下さい\n\n※regularLessonStartDateは「レッスンの開始日」です。\n「2022-02-20 00:00」の形式で書いてください。",
    editRegularExplanation:
      "※lessonCourseId\n会話コース:1, プライベートコース:2\n\n※dayOfWeek\n日:0, 月:1, 火:2, 水:3, 木:4, 金:5, 土:6\n\n※startTime, endTimeは 〇〇:〇〇の形式で書いて下さい\n\n※originStartTime, originEndTimeは 変更しないでください！",
    isShowDataTable: true,
    isLoading: false,
  }),
  computed: {
    headers() {
      return [
        { text: "", value: "edit", align: "start", sortable: false },
        {
          text: "regularScheduleId",
          value: "regularScheduleId",
        },
        {
          text: "teacherId",
          value: "teacherId",
        },
        {
          text: "講師名",
          value: "teacherName",
        },
        {
          text: "studentId",
          value: "studentId",
        },
        {
          text: "生徒名",
          value: "studentName",
        },
        {
          text: "レッスンコース名",
          value: "lessonCourseName",
        },
        {
          text: "曜日",
          value: "dayOfWeek",
        },
        {
          text: "開始時刻",
          value: "startTime",
        },
        {
          text: "終了時刻",
          value: "endTime",
        },
        {
          text: "受付開始日",
          value: "receptionStartDate",
        },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    computedDayOfWeek: function () {
      return function (startTime) {
        // dayOfWeekを計算する
        const dayOfWeek = Number(this.$moment(startTime).format("DD") - 1);
        const weekDayArrayJa = ["日", "月", "火", "水", "木", "金", "土"];
        return weekDayArrayJa[dayOfWeek];
      };
    },
    computedLessonCourse: function () {
      return function (lessonCourseId) {
        let courseNameArray = ["会話コース", "プライベートコース"];
        return courseNameArray[lessonCourseId - 1];
      };
    },
    computedShowDataTable: function () {
      return function (index) {
        if (!this.isShowDataTable) {
          return false;
        }
        if (this.openedIndexArray.indexOf(index) != -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    // 曜日を数字で扱う
    computedIntDayOfWeek: function () {
      return function (stringDayOfWeek) {
        const weekDayArrayJa = ["日", "月", "火", "水", "木", "金", "土"];
        const weekDayArrayDe = [
          "So.",
          "Mo.",
          "Di.",
          "Mi.",
          "Do.",
          "Fr.",
          "Sa.",
        ];
        switch (this.$i18n.locale) {
          case "ja":
            return weekDayArrayJa.indexOf(stringDayOfWeek);
          case "de":
            return weekDayArrayDe.indexOf(stringDayOfWeek);
          default:
            return weekDayArrayJa.indexOf(stringDayOfWeek);
        }
      };
    },
  },
  props: [],
  created() {
    this.fetchInitialData();

    this.reloadTable();
  },
  mounted() {},
  methods: {
    async reloadTable() {
      try {
        await this.fetchRegularSchedules();
      } catch (error) {
        console.log("error", error);
      }
      this.isShowDataTable = false;

      await this.$nextTick();
      this.regularScheduleList = [];
      this.divideRegularScheduleArray();

      this.isShowDataTable = true;
    },
    // API通信を行うのに必要な情報を取得する
    fetchInitialData() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
    },
    fetchRegularSchedules() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };
        this.axios
          .get(`/api/regularSchedules`, header)
          .then((response) => {
            // 講師の空き予定(studentIdがnullのやつ)、紐付けのデータは表示しない
            this.regularSchedules = response.data.regularSchedules.filter(
              (regularSchedule) => {
                return (
                  regularSchedule.studentId != null &&
                  regularSchedule.startTime != null
                );
              }
            );
            this.isLoading = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    // 講師ごとに仕分ける
    divideRegularScheduleArray() {
      this.regularScheduleArray = [];

      // 定期予定がある講師一覧を作成する
      let regularScheduleTeachers = [];
      let teacherIds = []; //被りを排除するためだけに使う
      for (let regularSchedule of this.regularSchedules) {
        const regularScheduleTeacher = {
          id: regularSchedule.teacherId,
          name: regularSchedule["Teacher.teacherName"],
          email: regularSchedule["Teacher.email"],
        };

        // 重複を弾くためのif文
        // Array.indexOf()は該当がなかったら -1になる
        if (teacherIds.indexOf(regularScheduleTeacher.id) === -1) {
          regularScheduleTeachers.push(regularScheduleTeacher);
          teacherIds.push(regularSchedule.teacherId);
        }
      }

      // 生徒番号順に並び替える
      regularScheduleTeachers = this.arrangeTeacherWithId(
        regularScheduleTeachers
      );

      // 請求がある生徒一覧をfor文で回してその生徒ごとのデータを取得する
      for (let teacher of regularScheduleTeachers) {
        let regularScheduleArray = [];
        for (let regularSchedule of this.regularSchedules) {
          if (teacher.id === regularSchedule.teacherId) {
            const regularScheduleData = {
              regularScheduleId: regularSchedule.id,
              teacherId: regularSchedule.teacherId,
              teacherName: regularSchedule["Teacher.teacherName"],
              studentId: regularSchedule.studentId,
              studentName: regularSchedule["Student.name"],
              lessonCourseId: regularSchedule.lessonCourseId,
              lessonCourseName: this.computedLessonCourse(
                regularSchedule.lessonCourseId
              ),
              dayOfWeek: regularSchedule.startTime
                ? this.computedDayOfWeek(regularSchedule.startTime)
                : "",
              startTime: regularSchedule.startTime
                ? this.$moment(regularSchedule.startTime).format("HH:mm")
                : "",
              endTime: regularSchedule.endTime
                ? this.$moment(regularSchedule.endTime).format("HH:mm")
                : "",
              receptionStartDate: regularSchedule.receptionStartDate
                ? regularSchedule.receptionStartDate
                : null,
            };
            regularScheduleArray.push(regularScheduleData);
          }
        }
        const teacherRegularScheduleItem = {
          teacherId: teacher.id,
          teacherName: teacher.name,
          regularScheduleArray: regularScheduleArray,
          studentId: null,
        };
        this.regularScheduleList.push(teacherRegularScheduleItem);
      }
    },

    arrangeTeacherWithId(teachers) {
      teachers.sort((a, b) => {
        // 昇順
        if (a.id > b.id) {
          return 1;
        } else {
          return -1;
        }
      });

      return teachers;
    },

    openDetail(index) {
      if (this.computedShowDataTable(index)) {
        // 既に開いている場合はindexを消す
        const deletedIndex = this.openedIndexArray.indexOf(index);
        this.openedIndexArray.splice(deletedIndex, 1);
      } else {
        // まだ開かれていない時はindexを追加する
        this.openedIndexArray.push(index);
      }
    },

    openEditForm(item) {
      this.selectedRegularScheduleId = item.regularScheduleId;
      this.editedItem = Object.assign({}, item);
      this.editedItem.originalStartTime = item.startTime;
      this.editedItem.originalEndTime = item.endTime;
      this.editedItem.dayOfWeek = this.computedIntDayOfWeek(item.dayOfWeek);
      this.editedItem.originalDayOfWeek = this.computedIntDayOfWeek(
        item.dayOfWeek
      );
      this.showEditDialog = true;
    },
    addRegularSchedule(item) {
      this.addedItem = Object.assign({}, item);
      this.showAddDialog = true;
    },
    deleteRegularLesson(item) {
      this.deletedItem = Object.assign({}, item);
      this.selectedRegularScheduleId = item.regularScheduleId;

      if (
        confirm(
          `以下の予定を削除します \n講師:${this.deletedItem.teacherName} \n生徒:${this.deletedItem.studentName}\n曜日: ${this.deletedItem.dayOfWeek} \nレッスン開始時刻： ${this.deletedItem.startTime}  \nレッスン終了時刻： ${this.deletedItem.endTime}`
        )
      ) {
        const header = {
          headers: {
            Authorization: `Bearer ${this.userInfo.accessToken}`,
          },
        };

        // 紐付けの場合はregularSchedulesのidで削除するだけ
        // 生徒に予約された定期予定の場合は関連する授業も削除しないといけないのでエンドポイントが変わる
        const dayOfWeek = this.computedIntDayOfWeek(this.deletedItem.dayOfWeek);
        this.deletedItem.startTime = this.$convertToDateFormat(
          dayOfWeek,
          this.deletedItem.startTime
        );
        this.deletedItem.endTime = this.$convertToDateFormat(
          dayOfWeek,
          this.deletedItem.endTime
        );

        this.axios
          .post("/api/regularSchedules/delete", this.deletedItem, header)
          .then((response) => {
            console.log("response.data", response.data);
            this.reloadTable();
          })
          .catch((error) => {
            alert(error.response.data.error.message);
          });
      }
    },
    closeEdit() {
      this.showEditDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeAdd() {
      this.showAddDialog = false;
      this.$nextTick(() => {
        this.addedItem = Object.assign({}, this.defaultItem);
      });
    },
    edit() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };
      this.editedItem.byAdmin = true;

      this.editedItem.teacherId = Number(this.editedItem.teacherId);
      this.editedItem.studentId = Number(this.editedItem.studentId);

      this.editedItem.lessonCourseId = 2;

      this.editedItem.startTime = this.$convertToDateFormat(
        this.editedItem.dayOfWeek,
        this.editedItem.startTime
      );
      this.editedItem.endTime = this.$convertToDateFormat(
        this.editedItem.dayOfWeek,
        this.editedItem.endTime
      );
      this.editedItem.originalStartTime = this.$convertToDateFormat(
        this.editedItem.originalDayOfWeek,
        this.editedItem.originalStartTime
      );
      this.editedItem.originalEndTime = this.$convertToDateFormat(
        this.editedItem.originalDayOfWeek,
        this.editedItem.originalEndTime
      );

      this.axios
        .put("/api/regularSchedules/update", this.editedItem, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.closeEdit();
          this.reloadTable();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    add() {
      const header = {
        headers: {
          Authorization: `Bearer ${this.userInfo.accessToken}`,
        },
      };

      // 定期予約と同じAPIを叩くがバックエンド側でメール送信しないようになっている。
      // dayOfWeekを消して、lessonStartTime, lessonEndTimeなどと組み合わせて
      this.addedItem.startTime = this.$convertToDateFormat(
        this.addedItem.dayOfWeek,
        this.addedItem.startTime
      );
      this.addedItem.endTime = this.$convertToDateFormat(
        this.addedItem.dayOfWeek,
        this.addedItem.endTime
      );
      this.addedItem.byAdmin = true;
      this.addedItem.regularLessonStartDate = this.$moment(
        this.addedItem.regularLessonStartDate
      );

      this.axios
        .post("/api/lessons/regularReserve", this.addedItem, header)
        .then((response) => {
          console.log("response.data", response.data);
          this.closeAdd();
          this.reloadTable();
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    },
    openAddForm(teacherId) {
      if (teacherId) {
        this.addedItem.teacherId = teacherId;
      }
      this.addedItem.lessonCourseId = 2;
      this.showAddDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
v-data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #dcc268;
}

thead.v-data-table-header {
  background-color: #dcc268;
}

.filter-area {
  padding: 0 20px;
}
.filter-item {
  padding: 0 5px;
}
.table-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.v-data-table {
  white-space: nowrap;
}

.regular-schedule-list-area {
  max-height: 700px;
  overflow: auto;
}
</style>
